import './DeclarationDetails.scss';
import React, { useMemo } from 'react';
import { IonItem, IonImg, IonText, IonLabel } from '@ionic/react';
import { useSelector } from 'react-redux';
import { Tso, Substitution, ApplicationData, DeclarationType } from '../../models';
import useLocale from '../../hooks/useLocale';
import { RootState } from '../../store/reducers';
import { getSubstitutedJobOtherId } from './../declaration/SubstitutionJobs';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { parseLongDate } from '../../lib/utils';

interface DeclarationDetailsProps {
    type: DeclarationType;
    declaration: Tso | Substitution;
}

const DeclarationDetails:React.FC<DeclarationDetailsProps> = ({ type, declaration }) => {

    const locale = useLocale();
    const localizedContent = useLocalizedContent();
    const appData:ApplicationData = useSelector((state:RootState) => state.app.data);
    const tsoCauses = useSelector((state:RootState) => state.app.tsoCauses);

    const tso:Tso = (declaration as Tso);
    const substitution:Substitution = (declaration as Substitution);    

    const institutions = appData.institutions;
    const installations = appData.installations;
    const jobs = appData.jobs;

    const createdAtDate = useMemo(() => parseLongDate(declaration.created_at_moment, locale), [declaration, locale]);
    const submittedForDate = useMemo(() => parseLongDate(declaration.date_submitted_for_moment, locale), [declaration, locale]);
    const isTso = type === 'tso';
    const urgent = isTso && tso.urgency_state;

    const institutionName = institutions[declaration.institution_id]?.name || '';
    const installationName = installations[declaration.installation_id]?.name || '';

    return (
        <div className={'declaration-details' + (urgent ? ' urgent' : '')}>
            <div className="declaration-created-at">
                { localizedContent.addedOn + ' ' + createdAtDate }
            </div>
            { urgent ? 
                <div className="declaration-statuses">
                    <div className="declaration-urgent">{ localizedContent.urgent }</div>
                </div>
            : null }
            <div className="declaration-hours">
                <IonItem lines="none" className="hours-regular">
                    <IonImg slot="start" src="/assets/icon/hours-regular.svg" />
                    <IonText>{ isTso ? localizedContent.regularHours : localizedContent.workedHours }</IonText>
                    <IonText slot="end">{ isTso ? tso.regular_hours : substitution.hours_spent }h</IonText>
                </IonItem>
                { isTso ? 
                    <IonItem lines="none" className="hours-overtime">
                        <IonImg slot="start" src="/assets/icon/hours-overtime.svg" />
                        <IonText>{ localizedContent.overtimeHours }</IonText>
                        <IonText slot="end">{ tso.overtime_hours }h</IonText>
                    </IonItem>
                : null }
            </div>
            <div className="declaration-base-details">
                <IonItem lines="none" className="submitted-date">
                    <img src="/assets/icon/calendar-pale.svg" alt="icon-calendar" />
                    <IonLabel className="ion-text-wrap">
                        <span className="label">{ localizedContent.workDoneOn }</span> <span className="content">{ submittedForDate }</span>
                    </IonLabel>
                </IonItem>
                <IonItem lines="none" className="name">
                    <img src="/assets/icon/name-pale.svg" alt="icon-name" />
                    <IonLabel className="ion-text-wrap">
                        <span className="label">{ localizedContent.name }</span> <span className="content">{ declaration.first_name + ' ' + declaration.last_name }</span>
                    </IonLabel>
                </IonItem>
                <IonItem lines="none" className="registration-number">
                    <img src="/assets/icon/matricule-pale.svg" alt="icon-matricule" />
                    <IonLabel className="ion-text-wrap">
                        <span className="label">{ localizedContent.matricule }</span> <span className="content"># { declaration.registration_number }</span> <span className="extra">({ localizedContent.matricule })</span></IonLabel>
                </IonItem>
                <IonItem lines="none" className="institution">
                    <img src="/assets/icon/institution-pale.svg" alt="icon-institution" />
                    <IonLabel className="ion-text-wrap"><span className="label">{ localizedContent.institution }</span> <span className="content">{ institutionName }</span></IonLabel>
                </IonItem>
                <IonItem lines="none" className="installation">
                    <img src="/assets/icon/installation-pale.svg" alt="icon-installation" />
                    <IonLabel className="ion-text-wrap"><span className="label">{ localizedContent.installation }</span> <span className="content">{ installationName }</span></IonLabel>
                </IonItem>                
            </div>
            { declaration.was_forewarn ? 
                <div className="categorized-content">
                    <div className="category">{ localizedContent.notice }</div>
                    <div className="content">{ declaration.forewarn_hours + 'h ' + localizedContent.beforeEndOfService }</div>
                </div>
            : null }
            { (type === 'tso')  ? 
                <div className="categorized-content">
                    <div className="category">{ localizedContent.requestCauses }</div>                     
                    { tso.causes ? tso.causes.map(cause =>
                        <div key={ 'cause-' + cause } className="content">
                            { tsoCauses.find(tsoCause => tsoCause.key === cause)?.name[locale] }
                        </div>
                    ) : null }
                    { tso.cause_other ? 
                        <div className="content">{ localizedContent.other + 's: ' + tso.cause_other }</div>
                    : null }
                    { !tso.cause_other && (!tso.causes?.length) ?
                        <div className="content">{ localizedContent.iDontKnow }</div>
                    : null }
                </div>
            :
            <>
                <div className="categorized-content">
                    <div className="category">{ localizedContent.currentJobTitle }</div>
                    <div className="content">{ jobs[substitution.current_job_id]?.title[locale] }</div>
                </div>
                <div className="categorized-content">
                    <div className="category">{ localizedContent.substitutedJobTitle }</div>
                    <div className="content">
                        { substitution.substituted_job_id && substitution.substituted_job_id !== getSubstitutedJobOtherId() ?
                            jobs[substitution.substituted_job_id] ?// @TODO only because of old malformated substitution
                                jobs[substitution.substituted_job_id].title[locale]
                            :
                                substitution.substituted_job_id// @TODO only because of old malformated substitution
                        :
                            ( jobs[substitution.substituted_job_id] ?// @TODO only because of old malformated substitution
                                jobs[substitution.substituted_job_id].title[locale] + ': '
                            : '') + substitution.substituted_job_other
                        }
                    </div>
                </div>
                { substitution.description ?
                    <div className="categorized-content">
                        <div className="category">{ localizedContent.tasksDone }</div>
                        <div className="content">{ substitution.description }</div>
                    </div>
                : null }
            </>
            }
        </div>
    );
}

export default DeclarationDetails;