import React from 'react';
import ActivateNotifications from '../../../components/common-content/ActivateNotifications';
import AuthorizeNotificationsButtons from '../../../components/buttons/AuthorizeNotificationsButtons';

import './DesktopActivateNotifications.scss';

const DesktopActivateNotifications: React.FC= () => {

	return (
		<div className={'desktop-activate-notifications'}>
            <div className="activate-notifications-container">
                <ActivateNotifications  />
            </div>
			<div className="activate-notifications-actions">
				<AuthorizeNotificationsButtons showCloseButton />
			</div>
		</div>
	);
};

export default DesktopActivateNotifications;