import React, { useEffect, useRef } from 'react';

import './ScrollableWhiteContent.scss';

interface ScrollableWhiteContentProps {
    stopTouchMovePropagationWhileScrolling?: boolean;
    paddingBottomPercent?: number;
    overlayContent?(): any;
    hideTopNotchMask?: boolean;
}

const ScrollableWhiteContent:React.FC<ScrollableWhiteContentProps> = ({ paddingBottomPercent = 60, children, hideTopNotchMask, overlayContent, stopTouchMovePropagationWhileScrolling }) => {

    const el = useRef(null);
    const content = useRef(null);

    // hack pour empêcher le <IonRefresher> de se triggerer pendant un scroll, ah Ionic
    useEffect( () => {
        if (stopTouchMovePropagationWhileScrolling) {
            const elCurrent = el.current;
            const contentCurrent = content.current;
            const stopPropagationWhileScrolling = (e:TouchEvent) => {
                if (elCurrent.scrollTop > 0) {
                    e.stopPropagation();
                }
            };
            contentCurrent.addEventListener('touchmove', stopPropagationWhileScrolling);

            return () => {
                contentCurrent.removeEventListener('touchmove', stopPropagationWhileScrolling);
            }
        }
    }, [el, content, stopTouchMovePropagationWhileScrolling]);

    return (
        <div className="scrollable-white-content" ref={ el }>
            { !hideTopNotchMask ?
                <div className="top-notch-mask" />
            : null }            
            <div className="scrollable-spacer" style={{ paddingBottom: paddingBottomPercent + '%' }}>
                { overlayContent ? overlayContent() : undefined }
            </div>
            <div className="scrollable-white-content-inner" ref={ content }>{ children }</div>
        </div>
    );
}

export default ScrollableWhiteContent;