import React, { useRef, useEffect, useState } from 'react';
import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import MobileHeader from '../../../components/mobile/MobileHeader';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { News } from '../../../models';
import useLocale from '../../../hooks/useLocale';
import { useHistory } from 'react-router-dom';
import { readEntry } from '../../../store/entries-actions';
import { updateStatusBar } from '../../../lib/status-bar';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { parseLongDate } from '../../../lib/utils';
import PullToRefreshEntries from '../../../components/mobile/PullToRefreshEntries';

import './MobileNews.scss';

const MobileNews: React.FC = () => {

    // Update tab color
    useIonViewWillEnter( () => updateStatusBar('blue'));
    
    
    const dispatch = useDispatch();
    const history = useHistory();
    const locale = useLocale();
    const localizedContent = useLocalizedContent();
    const entries = useSelector((state:RootState) => state.entries.data);
    const news = entries.news;
    const unreadCount = news.filter(singleNews => singleNews.status !== 'read').length;

    const handleNewsClick = (singleNews:News) => {
        const newsId = singleNews.id;
        history.push('/dashboard/news/' + newsId);
        if (singleNews.status !== 'read') {
            dispatch(readEntry('news', newsId));
        }        
    }

    // Unread count

    const [hideUnreadCount, setHideUnreadCount] = useState(false);
    const ionContent = useRef(null);
    useEffect( () => {
        let scrollContainer: HTMLDivElement;
        const checkScroll = () => {
            const scrolledToBottom = scrollContainer.scrollTop >= scrollContainer.scrollHeight - scrollContainer.offsetHeight - 60;
            if (scrolledToBottom && !hideUnreadCount) {
                setHideUnreadCount(true);
            } else if (!scrolledToBottom && hideUnreadCount) {
                setHideUnreadCount(false);
            }
        }

        ionContent.current.getScrollElement().then( (_scrollContainer:HTMLDivElement) => {
            scrollContainer = _scrollContainer;
            scrollContainer.addEventListener('scroll', checkScroll);
            checkScroll();
        });        

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', checkScroll);
            }            
        }
    }, [ionContent, hideUnreadCount]);

    return (
        <IonPage className="with-tabs mobile-news" data-page="news" >
            <MobileHeader blue title={ localizedContent.negociations } />
            <IonContent ref={ionContent}>
                <PullToRefreshEntries />              
                <div className="news-list" >                    
                    <div className="list-items">
                        { news.map((singleNews, i) => 
                            <div key={'nego'+ singleNews.id + '-' + i} className={ 'single-news ' + (singleNews.status !== 'read' ? 'unread' : 'read') + ' ' + (i === 0 ? 'with-background' : 'with-thumbnail') }>
                                { i === 0 ? (
                                    <button type="button" onClick={ e => handleNewsClick(singleNews) } className="single-news-item ion-padding" style={ singleNews.images?.large[locale] ? { backgroundImage: 'url('+singleNews.images.large[locale] +')' } : null }>
                                        <div className="single-news-content">
                                            <div className="single-news-content-inner">
                                                <div className="single-news-text-container">
                                                    <div className="single-news-text">
                                                        { singleNews.title[locale] }
                                                    </div>
                                                    <div className="read-status" />
                                                </div>
                                                <div className="single-news-date">
                                                    { parseLongDate(singleNews.created_at_moment, locale) }
                                                </div>                                        
                                            </div>
                                        </div>
                                    </button>  
                                ) : (
                                    <button className="single-news-item" type="button" onClick={ e => handleNewsClick(singleNews) }>
                                        <div className="single-news-text-container">
                                            <div className="single-news-text">
                                                { singleNews.title[locale] }
                                            </div>
                                            <div className="single-news-date">
                                                { parseLongDate(singleNews.created_at_moment, locale) }
                                            </div>
                                        </div>
                                        <div className="single-news-thumbnail" style={ singleNews.images?.thumb[locale] ? { backgroundImage: 'url('+ singleNews.images.thumb[locale] +')'} : null } />
                                        <div className="read-status" />
                                    </button>
                                )}
                            </div>
                        ) }
                    </div>
                </div>
                <div className={'unread-toast' + ( hideUnreadCount || !unreadCount ? ' hidden' : '' )} slot="fixed">
                    { unreadCount + ' ' + (
                        locale === 'fr' ?
                            localizedContent.singularNegociation + 
                            (unreadCount > 1 ? 's':'') + ' ' + localizedContent.unread + 'e' + (unreadCount > 1 ? 's':'')
                        :
                            localizedContent.unread + ' ' + localizedContent.singularNegociation + (unreadCount > 1 ? 's':'')
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default MobileNews;

