import React from 'react';
import GraphicsLegend from './GraphicsLegend';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import useLocale from '../../hooks/useLocale';

interface DeclarationTypesLegendProps {
	useLongNames?: boolean;
}

const DeclarationTypesLegend:React.FC<DeclarationTypesLegendProps> = ({ useLongNames }) => {

	const localizedContent = useLocalizedContent();
	const locale = useLocale();
	const prefix = useLongNames ? localizedContent.declarations + ' ' : '';

	let tsoLabel = prefix + localizedContent.tsos;
	let urgentLabel = prefix + localizedContent.urgentTsos;
	let subLabel = prefix ? prefix + localizedContent.workSubstitutions.toLowerCase() : localizedContent.workSubstitutions;
	if (locale === "en") {
		tsoLabel = "MOT reports";
		urgentLabel = "Urgent MOT reports";
		subLabel = "Job substitution reports";
	}

    const declarationCirclesLegend = [
		{
			label: tsoLabel,
			color: 'var(--color-tso)'
		},
		{
			label: urgentLabel,
			color: 'var(--color-tso-urgent)'
		},
		{
			label: subLabel,
			color: 'var(--color-substitution)'
		}
	];

    return <GraphicsLegend legendItems={ declarationCirclesLegend } />
}

export default DeclarationTypesLegend;