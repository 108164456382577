import React, { useMemo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { DeclarationType, ApplicationData } from '../../models';
import useLocale from '../../hooks/useLocale';
import useStoredDeclaration from '../../hooks/useStoredDeclaration';
import gsap, { Quad } from 'gsap';

import './SubstitutionJobs.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import AutoCompleteSelect from '../AutocompleteSelect';

export const getSubstitutedJobOtherId = () => 39;

const SubstitutionJobs: React.FC<{ type: DeclarationType }> = ({ type }) => {

    const localizedContent = useLocalizedContent();
    const jobOther = useRef(null);
    const appData:ApplicationData = useSelector((state:any) => state.app.data);
    const jobsMap = appData.jobs;
    const locale = useLocale();
    const storedDeclaration = useStoredDeclaration(type);
    const storedSubstitution = storedDeclaration.substitution;

    const currentJobId = storedSubstitution?.current_job_id;
    const substitutedJobId = storedSubstitution?.substituted_job_id;
    const substitutedJobOther = storedSubstitution?.substituted_job_other || '';
    const description = storedSubstitution?.description || '';

    const currentJob = currentJobId ? { ...jobsMap[currentJobId], name: jobsMap[currentJobId].title[locale]} : null;
    const substitutedJob = substitutedJobId ? { ...jobsMap[substitutedJobId], name: jobsMap[substitutedJobId].title[locale]} : null;
    const substitutedJobOtherId = getSubstitutedJobOtherId();
    const isSubstitutedJobOtherSelected = substitutedJobId === substitutedJobOtherId;    

    const jobsList = useMemo(() => {
        const list = [];
        for (var jobId in jobsMap) {
            if (jobsMap[jobId].id !== substitutedJobOtherId) {
                list.push({ ...jobsMap[jobId], name: jobsMap[jobId].title[locale]});
            }            
        }

        list.sort( (a, b) => a.name.localeCompare(b.name) );
        list.push({ ...jobsMap[substitutedJobOtherId], name: jobsMap[substitutedJobOtherId].title[locale] })
        return list;
    }, [jobsMap, substitutedJobOtherId, locale]);

    const currentJobsList = jobsList.filter(job => !job.can_be_substituted);
    const substitutedJobsList = jobsList.filter(job => job.can_be_substituted);

    useEffect( () => {
        if (jobOther) {
            const el = jobOther.current;
            const animParams = { duration: 0.3, height: 0, ease: Quad.easeInOut };
            if (isSubstitutedJobOtherSelected) {
                gsap.set(el, { height: 'auto' });
                gsap.from(el, animParams);
            } else {
                gsap.to(el, animParams);
            }
        }
        
    }, [isSubstitutedJobOtherSelected, jobOther]);

	return (
        <div className="substitution-jobs">
            <div className="ion-margin-bottom">
                <AutoCompleteSelect
                    options={ currentJobsList }
                    value={ currentJob }
                    onChange={(e:any, v:any) => {
                        storedDeclaration.setData({ current_job_id: v?.id } )
                    } }
                    label={ localizedContent.myJobTitle }
                />
            </div>
            <div className="ion-margin-bottom">
                <AutoCompleteSelect
                    options={ substitutedJobsList }
                    value={ substitutedJob }
                    onChange={(e:any, v:any) => {
                        const update:any = { substituted_job_id: v?.id };
                        if (v?.id !== substitutedJobOtherId) {
                            update.substituted_job_other = '';
                        }
                        storedDeclaration.setData(update);
                    } }
                    label={ localizedContent.substitutedJobTitle }
                />
            </div>
            <div className={'substituted-job-other' + (isSubstitutedJobOtherSelected ? ' active' : '')} ref={jobOther}>
                <TextField
                    label={ localizedContent.other + ' (' + localizedContent.specify + ')' }
                    variant="outlined"
                    fullWidth
                    defaultValue={ substitutedJobOther }
                    multiline
                    rows={3}
                    onChange={ e => storedDeclaration.setData({ substituted_job_other: e.target.value }) }
                />
            </div>
            <div className="description-caption">
                { localizedContent.tasksDone }
            </div>
            <TextField
                label={ '(' + localizedContent.optional + ')' }
                variant="outlined"
                fullWidth
                defaultValue={ description }
                multiline
                rows={10}
                onChange={ e => storedDeclaration.setData({ description: e.target.value }) }
            />
        </div>
	);
};

export default SubstitutionJobs;