import React from 'react';
import RadioButtons from './RadioButtons';

import './YesNoButtons.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface YesNoButtonsProps {
    value?: boolean;
    onChange?:Function;
}

const YesNoButtons: React.FC<YesNoButtonsProps> = ({ value, onChange }) => {

    const localizedContent = useLocalizedContent();

    const handleSelect = (index:number) => {
        if (onChange)
            onChange(!index);
    };

    const choices = [
        {
            label: localizedContent.yes,
            icon: '/assets/icon/yes.svg'
        },
        {
            label: localizedContent.no,
            icon: '/assets/icon/no.svg'
        }
    ];

    return (
        <div className="yes-no-buttons">
            <RadioButtons choices={ choices} value={ value === undefined ? undefined : Number(!value) } onChange={ handleSelect } />
        </div>
    )
};

export default YesNoButtons;