import React, { useState, useEffect, useRef } from 'react';
import QuebecMap from './../QuebecMap';
import MapInfosButton from '../buttons/MapInfosButton';
import DateFilter from './../filters/DateFilter';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageFilter, readTutorial } from './../../store/settings-actions';
import { RootState } from '../../store/reducers';

import './DesktopMapRegions.scss';
import MapLegendModal from './../modals/center-modals/MapLegendModal';
import RegionStatus from '../stats/RegionStatus';
import useDateFilteredEntries from '../../hooks/useDateFilteredEntries';
import { Tso, Substitution, Region } from '../../models';

import gsap from 'gsap';
import useLocalizedContent from '../../hooks/useLocalizedContent';


interface DesktopMapRegionsProps {
}

const DesktopMapRegions: React.FC<DesktopMapRegionsProps> = () => {

    const localizedContent = useLocalizedContent();
    const dispatch = useDispatch();
    const mapContainer = useRef(null);
    const regionsDetails = useRef([]);
    const regions = useSelector((state:RootState) => state.app.data.regions);

    const tsos = useSelector((state:RootState) => state.entries.data.tsos);
    const substitutions = useSelector((state:RootState) => state.entries.data.substitutions);

    const dateFilteredTsos = useDateFilteredEntries('declarations', tsos) as Tso[];
    const dateFilteredSubstitutions = useDateFilteredEntries('declarations', substitutions) as Substitution[];

    const handleSelectRegion = (regionNumber?: number) => {
		dispatch(updatePageFilter('declarations', { region_id: regions.find(region => region.region_number === regionNumber)?.id }) );
    }

    // Handle legend map opening 
    const seenMapTutorial = useSelector((state:RootState) => state.settings.tutorialsRead.indexOf('map') > -1);
	const [mapLegendOpened, setMapLegendOpened] = useState(!seenMapTutorial);
	const openMapLegend = () => {
		setMapLegendOpened(true);
	}
	const closeMapLegend = () => {
        if (!seenMapTutorial) {
			dispatch(readTutorial('map'));
		}
		setMapLegendOpened(false);
    }
    
    // Handle map interactivity ------

    const updateRegionDetailsPositions = (mapContainer:HTMLDivElement, regions:Region[]) => {

        const getRegionNumber = (region:SVGGElement):number => {
            return parseInt(region.id.split('_')[1]);
        };

        const mapContainerBound = mapContainer.getBoundingClientRect();

        const regionsGroups:NodeListOf<SVGGElement> = mapContainer.querySelectorAll('g.region:not(.unsupported-region');
        regionsGroups.forEach(region => {
            const regionNumber = getRegionNumber(region);
            const regionPoint:SVGGElement = region.querySelector('circle.point');
            const pointBound = regionPoint.getBoundingClientRect();
            gsap.set(regionsDetails.current[regionNumber], { x: pointBound.x - mapContainerBound.x, y: pointBound.y - mapContainerBound.y });
        });
    }

    useEffect( () => {
        // lol
        const interval = setInterval(() => {
            updateRegionDetailsPositions(mapContainer.current, regions);
        }, 10);
        setTimeout( () => { clearInterval(interval)}, 1000);
        
    },[mapContainer, regions]);

    const handleUpdateRegionsDetails = () => {
        updateRegionDetailsPositions(mapContainer.current, regions);
    }

    const handlePanning = () => {
        handleUpdateRegionsDetails();
    }

    const handleZoomChange = () => {
        handleUpdateRegionsDetails();
    }

    const handlePanningStop = () => {
        handleUpdateRegionsDetails();
    }

    const handleRegionEnter = (regionNumber: number) => {
        gsap.set(regionsDetails.current[regionNumber], { zIndex: 2 });
        gsap.set(regionsDetails.current[regionNumber].querySelector('.region-details'), { opacity: 1 });
    }

    const handleRegionLeave = (regionNumber: number) => {
        gsap.set(regionsDetails.current[regionNumber], { zIndex: undefined, clearProps: 'zIndex'});
        gsap.set(regionsDetails.current[regionNumber].querySelector('.region-details'), { opacity: 0, clearProps: 'opacity' });
    }

	return (
        <div className="desktop-map-regions">
            <div className="map-container" ref={ mapContainer }>
                <QuebecMap
                    selectable
                    onSeletableRegionClick={ handleSelectRegion }
                    onSelectableRegionEnter={ handleRegionEnter }
                    onSelectableRegionLeave={ handleRegionLeave}
                    onPanning={ data => handlePanning() }
                    onZoomChange={ data => handleZoomChange() }
                    onPanningStop={ data => handlePanningStop() }
                />
                <div className="regions-details">
                    { regions.map( region => {

                        const tsos = dateFilteredTsos.filter(tso => tso.region_id === region.id);
                        const substitutions = dateFilteredSubstitutions.filter(substitution => substitution.region_id === region.id);

                        const tsosCount = tsos.length;
                        const substitutionsCount = substitutions.length;
                        const declarationsCount = tsosCount + substitutionsCount;
                        const urgentTsosCount = tsos.filter(tso => tso.urgency_state).length;
                        const tsosUrgentRatio = urgentTsosCount / tsosCount;

                        return (
                            <div ref={ el => regionsDetails.current[region.region_number] = el } className={'region-container region-' + region.region_number } key={ 'region-' + region.id } >
                                <div className="region-content">
                                    <div className="status-icon">
                                        <div className="region-status-container">
                                            <RegionStatus urgentRatio={ tsosUrgentRatio } />
                                        </div>
                                    </div>
                                    <div className="region-details">
                                        <div className="region-details-content">
                                            <div className="region-name">{ region.name }</div>
                                            <div className="declarations-types">
                                                <div className="declarations-type type-total">
                                                    <div className="declarations-content">
                                                        <span className="label">{ localizedContent.totalDeclarations }</span>
                                                    </div>
                                                    <div className="declarations-type-count">{ declarationsCount }</div>
                                                </div>
                                                <div className="declarations-type type-tsos">
                                                    <div className="declarations-type-content">
                                                        <span className="circle" />
                                                        <span className="label">{  localizedContent.tsos }</span>
                                                    </div>
                                                    <div className="declarations-type-count">{ tsosCount }</div>
                                                </div>
                                                <div className="declarations-type type-urgent-tsos">
                                                    <div className="declarations-type-content">
                                                        <span className="circle" />
                                                        <span className="label">{ localizedContent.urgentTsos }</span>
                                                    </div>
                                                    <div className="declarations-type-count">{ urgentTsosCount }</div>
                                                </div>
                                                <div className="declarations-type type-substitutions">
                                                    <div className="declarations-type-content">
                                                        <span className="circle" />
                                                        <span className="label">{ localizedContent.workSubstitutions }</span>
                                                    </div>
                                                    <div className="declarations-type-count">{ substitutionsCount }</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <MapInfosButton onClick={ openMapLegend } />
                <div className={'map-legend-container' + (mapLegendOpened ? ' opened' : '')}>
                    <MapLegendModal onClose={ closeMapLegend } />
                </div>                
            </div>
            <div className="dates-filter-container">
                <DateFilter page="declarations" />
            </div>
        </div>
	);
};

export default DesktopMapRegions;