import React, { useState } from 'react';
import { isPlatform } from '@ionic/react';

import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/auth-actions';

import './Settings.scss';
import { updateLocale } from '../../store/settings-actions';
import useLocale from '../../hooks/useLocale';
import { RootState } from '../../store/reducers';
import PushNotificationsSettings from '../PushNotificationsSettings';
import { Locale } from '../../models';
import AdminUserInputs from './../login/AdminUserInputs';
import SimpleModalController from '../modals/SimpleModalController';
import EditAdminEmailModal from './../modals/center-modals/EditAdminEmailModal';
import EditAdminPasswordModal from './../modals/center-modals/EditAdminPasswordModal';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import AuthorizeNotificationsButtons from '../buttons/AuthorizeNotificationsButtons';
import FiqButton from '../buttons/FiqButton';


interface SettingsProps {
    hideSocialLogins?: boolean;
}

const Settings: React.FC<SettingsProps> = ({ hideSocialLogins }) => {
    
    const localizedContent = useLocalizedContent();
    const user = useSelector((state:RootState) => state.auth.user);
    const updating = useSelector((state:RootState) => state.settings.updating);
    const settingsEmail = useSelector((state:RootState) => state.settings.email);
    const institutions = useSelector((state: RootState) => state.app.data.institutions);

    const isNative = isPlatform('capacitor');
    const notificationsAccepted = useSelector((state: RootState) => state.settings.notificationsAccepted);
    const notificationsUnsupported = useSelector((state: RootState) => state.settings.notificationsUnsupported);

    const isUserAdmin = user?.is_admin || false;

    const dispatch = useDispatch();
    const locale = useLocale();
    
    const newsletterUrl = useSelector((state:RootState) => state.app.data.configurations.newsletter_subscribe_url)[locale]

    function handleLogout(e:React.MouseEvent) {
        e.preventDefault();
        dispatch(logout());
    }

    // handle newsletter subscription sync

    const email = isUserAdmin ? user.email : settingsEmail;

    // Handle locale sync

    const handleLocaleChange = (locale: Locale) => {
        dispatch(updateLocale(locale, true));
    }

    // Handle Admin user edit

    const [editAdminEmailOpened, setEditAdminEmailOpened] = useState(false);
    const [editAdminPasswordOpened, setEditAdminPasswordOpened] = useState(false);
    const openEditAdminEmail = () => {
        setEditAdminEmailOpened(true);
    }
    const closeEditAdminEmail = () => {
        setEditAdminEmailOpened(false);
    }
    const openEditAdminPassword = () => {
        setEditAdminPasswordOpened(true);
    }
    const closeEditAdminPassword = () => {
        setEditAdminPasswordOpened(false);
    }

    return (
        <div className="settings-section">
            { isUserAdmin ? 
                <div className="fiq-user-login-infos">
                    <AdminUserInputs email={email} editBtn onEditEmail={ openEditAdminEmail } onEditPassword={ openEditAdminPassword } />
                </div>
            :
                <div className="items-container user-login-infos">
                    <div className="item item-first-name">
                        <div className="item-content">
                            <div className="item-label">{ localizedContent.firstName }</div>
                            <div className="item-value">{ user?.first_name }</div>
                        </div>
                    </div>
                    <div className="item item-last-name">
                        <div className="item-content">
                            <div className="item-label">{ localizedContent.lastName }</div>
                            <div className="item-value">{ user?.last_name }</div>
                        </div>
                    </div>
                    <div className="item item-registration-numbers">
                        <div className="item-content">
                            <div className="item-label">{ localizedContent.registrationNumber }</div>
                            <div className="item-value">{ user?.registration_numbers.map( (registration, i) =>
                                <div key={'number-'+i} className="item-sub-value">
                                    { registration.registration_number }
                                </div>
                            )}</div>
                        </div>
                    </div>
                    <div className="item item-institutions">
                        <div className="item-content">
                            <div className="item-label">{ localizedContent.institution }</div>
                            <div className="item-value">{ user?.registration_numbers.map( (registration, i) =>
                                <div key={'institution-'+i} className="item-sub-value">
                                    { institutions[registration.institution_id].name }
                                </div>
                            )}</div>
                        </div>
                    </div>
                </div>
            }
            <div className="notifications-title">
                <h3>{ localizedContent.notifications }</h3>
                { !isNative && !notificationsAccepted && !notificationsUnsupported ?
                    <div className="items-container authorize-button">
                        <AuthorizeNotificationsButtons />
                    </div>
                : null }
            </div>            
            
            <div className="items-container push-notifications-container">
                <PushNotificationsSettings />
            </div>

            <div className="items-container subscribe-container">
                <div className="item">
                    <div className="item-content">
                        <a className="button-newsletter" href={ newsletterUrl } target="_blank" rel="noopener noreferrer">
                            { localizedContent.newsletterSubscribeCta }
                        </a>
                    </div>
                </div>
            </div>

            <h3>{ localizedContent.language }</h3>
            <div className="items-container languages-container">
                <div className="item">
                    <div className="item-content">
                        <div className="languages-selector">
                            <button className={locale === 'fr' ? 'selected':''} type="button" disabled={ updating } onClick={ e => handleLocaleChange('fr') }>Français - FR</button>
                            <button className={locale === 'en' ? 'selected':''} type="button" disabled={ updating } onClick={ e => handleLocaleChange('en') }>English - EN</button>
                        </div>
                    </div>                    
                </div>
            </div>
            
            <div className="submit-container">                
                <FiqButton color="danger" fill="outline" expand="block" onClick={ handleLogout }>
                    <span style={{ marginRight: '6px', letterSpacing: 0 }}>{ localizedContent.logoutCta }</span>
                    <img src="/assets/icon/logoff.svg" alt="icon-logoff" />
                </FiqButton>
            </div>
            <SimpleModalController opened={ editAdminEmailOpened } onClose={ closeEditAdminEmail }>
                <EditAdminEmailModal currentEmail={email} onClose={ closeEditAdminEmail } />
            </SimpleModalController>
            <SimpleModalController opened={ editAdminPasswordOpened } onClose={ closeEditAdminPassword }>
                <EditAdminPasswordModal onClose={ closeEditAdminPassword } />
            </SimpleModalController>
        </div>
    );
};

export default Settings;