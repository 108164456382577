import React from 'react';
import Settings from '../../../components/common-content/Settings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import useLocalizedContent from '../../../hooks/useLocalizedContent';

import './DesktopSettings.scss';

const DesktopSettings: React.FC = () => {

	const localizedContent = useLocalizedContent();
	const user = useSelector((state:RootState) => state.auth.user);
	const canSocialLogin = !user?.is_admin;

	return (
       <div className={'desktop-settings' + ( canSocialLogin ? ' can-social-login' : '' )}>
		   <div className="scrollable-content">
		   		<h2>{ localizedContent.settings }</h2>
				<Settings hideSocialLogins />
		   </div>		   
		</div>
	);
};

export default DesktopSettings;