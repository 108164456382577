import React from 'react';
import { useHistory } from 'react-router';
import useAbleToWorkForm from '../../../hooks/useAbleToWorkForm';
import DesktopTutorialPages from '../../../components/desktop/DesktopTutorialPages';

import './DesktopAbleToWork.scss';

const DesktopAbleToWork: React.FC= () => {

	const history = useHistory();
	const { isTutorial, currentPage, tutorialPage, isAbleToWork, setAbleToWork, goBack, goNext, backBtn, nextBtn } = useAbleToWorkForm();

	const tutorialPagesProps = {
		TutorialComponent: tutorialPage.Component,
		PageComponent: currentPage.Component,
		pageProps: {
			ableToWork: isAbleToWork,
			setAbleToWork,
			goBack,
			goNext
		},
		pageKey: currentPage.key,
		isTutorial,
		backBtn,
		nextBtn,
		goBack,
		goNext: () => { nextBtn?.name === 'end' ? history.replace('/dashboard') : goNext(); }
	};

	return (
		<div className="desktop-able-to-work">
			<DesktopTutorialPages {...tutorialPagesProps }>
				<currentPage.Component {...tutorialPagesProps.pageProps } />
			</DesktopTutorialPages>
		</div>
	);
};

export default DesktopAbleToWork;