import React from 'react';
import ScrollablePage from '../../../components/ScrollablePage';
import LoginForm from '../../../components/login/LoginForm';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import LoginFormSubmit from '../../../components/login/LoginFormSubmit';

interface DesktopLoginFormParams {
    create?: boolean;
}

const DesktopLoginForm:React.FC<DesktopLoginFormParams> = ({ create }) => {

    const localizedContent = useLocalizedContent();
    
    return (
        <ScrollablePage
            headerBackUrl="/login" headerTitle={ create ? localizedContent.createAccount : localizedContent.loginFormTitle }
            renderFooter={ () => <LoginFormSubmit create={ create } /> }
        >
            <LoginForm />
        </ScrollablePage>
    );
};

export default DesktopLoginForm;