import React, { useRef } from 'react';
import { IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import gsap, { Quad } from 'gsap';

import FiqButton from '../buttons/FiqButton';
import './DesktopTutorialPages.scss';
import DonutProgress from '../declaration/DonutProgress';
import { TutorialPageAction } from '../../models';

interface DesktopTutorialPagesProps {
    TutorialComponent: React.FC<any>;
    pageProps: any;
    isTutorial: boolean;
    pageKey: string;
    pageNumber?: number;
    pagesCount?: number;
    backBtn?: TutorialPageAction;
    nextBtn?: TutorialPageAction;
    goBack?(): void;
    goNext?(): void;
    onTransitionStart?(): void;
    onTransitionComplete?(): void;
}

const DesktopTutorialPages: React.FC<DesktopTutorialPagesProps> = ({ children, TutorialComponent, pageProps, isTutorial, pageKey, pageNumber, pagesCount, backBtn, nextBtn, goBack, goNext, onTransitionStart, onTransitionComplete }) => {
    
    const transitionContainer = useRef(null);
    const duration = 500;

    const handlePageTransition = (node:HTMLElement) => {

        gsap.from(transitionContainer.current, {
            height: node.offsetHeight,
            duration: duration / 1000,
            ease: Quad.easeInOut,
            clearProps: 'height',
            onStart: onTransitionStart,
            onComplete: onTransitionComplete
        });
    }

    const backHidden = backBtn.hidden;
    const nextHidden = nextBtn.hidden;

	return (
        <div className={'desktop-tutorial-pages' + (isTutorial ? ' is-tutorial' : '') }>
            <div className="pages-container">
                <div className="image-container">
                    <div className="image-content" />
                </div>
                <div className="pages-navigation">
                    <div className="page-container">
                        <div className="page-content">
                            { pagesCount ?
                                <div className="page-progress">
                                    <DonutProgress current={ pageNumber + 1 } total={ pagesCount } size={ 80 } />
                                </div>
                            : null }
                            <div className="navigation-content" ref={ transitionContainer }>                     
                                <TransitionGroup component={null}>
                                    <CSSTransition key={ pageKey } timeout={ duration } onExit={ handlePageTransition }>
                                        <div className="component-transition">
                                            { children }
                                        </div>
                                    </CSSTransition>
                                </TransitionGroup>
                            </div> 
                        </div>
                    </div>
                    <div className="page-actions">
                        <button type="button" className={'back-btn' + (backHidden ? ' hidden' : '')} onClick= { goBack } disabled={ backBtn.disabled }>
                            <IonIcon icon={ chevronBack } />
                            <span className="label">{ backBtn.label }</span>
                        </button>
                        <div className="next-btn">
                            <FiqButton onClick= { goNext } hidden={ nextHidden } disabled={ nextBtn.disabled }>{ nextBtn.label }</FiqButton>
                        </div>
                    </div>                    
                </div>
            </div>
            <div className="tutorial-container">
                <div className="tutorial-content">
                    <TutorialComponent {...pageProps} />
                    <div className="next-btn">
                        <FiqButton onClick={ goNext } hidden={ nextHidden } disabled={ nextBtn.disabled }>{ nextBtn.label }</FiqButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DesktopTutorialPages;