import React from 'react';

import './CreateAccountCta.scss';

const CreateAccountCta: React.FC = () => {


    return (
        <div className="create-account-cta">
          
        </div>
    );
};

export default CreateAccountCta;