import { useSelector } from 'react-redux';
import { Locale } from '../models';

type Hook = (locale?:Locale) => Locale

const useLocale:Hook = (locale) => {
    const userLocale = useSelector((state:any) => state.settings.locale);
    return locale ? locale : userLocale;
}

export default useLocale;