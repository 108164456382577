import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useStore, useDispatch, useSelector } from 'react-redux';

import { AuthStatus } from '../store/auth-actions';
import { load as loadSettings, readTutorial } from '../store/settings-actions';
import { RootState } from '../store/reducers';

const AuthMonitor: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const store = useStore();
	const dashboardHome = '/dashboard';
	const dashboardNotifications = '/dashboard/notifications';
	const hasSeenNotifications = useSelector((state:RootState) => state.settings.tutorialsRead.indexOf('notifications') > -1);
	const notificationsUnsupported = useSelector((state: RootState) => state.settings.notificationsUnsupported);

	useEffect(() => {
		const previousAuth = store.getState().auth;
		let previousAuthStatus:number = previousAuth.status;

		const unsubscribe = store.subscribe(() => {
			const state:RootState = store.getState();
			const currentStatus = state.auth.status;
			const currentUser = state.auth.user;

			if (previousAuthStatus !== currentStatus) {
				previousAuthStatus = currentStatus;

				const pathname = history.location.pathname;
				const isFromMilitantLogin = pathname.startsWith('/login/militant');
				const isFromLogin = pathname.startsWith('/login');
				const isFromDashboard = pathname.startsWith('/dashboard');
				const isFromNotifications = pathname.startsWith(dashboardNotifications);

				switch (currentStatus) {
					case AuthStatus.LoggedIn:
						dispatch(loadSettings(currentUser));
						if (!hasSeenNotifications && notificationsUnsupported) {
							dispatch(readTutorial('notifications'));
						}
						if (!hasSeenNotifications && !isFromNotifications && !notificationsUnsupported) {
							history.push(dashboardNotifications);
						} else if (!isFromDashboard) {
							history.push(dashboardHome);
						}							
					break;
					case AuthStatus.MilitantNeedsLogin:
						dispatch(loadSettings(currentUser));
						if (!isFromMilitantLogin) {
							if (isFromDashboard) {
								history.replace('/login/militant');
							} else {
								history.replace('/login/militant', { direction: 'forward' });
							}
						}									
					break;
					case AuthStatus.LoggedOut:
						dispatch(loadSettings());
						if (isFromMilitantLogin) {
							history.replace('/login/form', { direction: 'back' });
						} else if(!isFromLogin) {
							history.replace('/login', { direction: 'back' });
						}
					break;
				}
			}			
		});
		return unsubscribe;
	});
	

	// Block access from route change	
	useEffect( () => {
		history.listen((location, action) => {
			const auth = store.getState().auth;
			if (auth.status === AuthStatus.Uninitialized) {
				return;
			}
			if (location.pathname.startsWith('/login') && auth.status === AuthStatus.LoggedIn) {
				//history.replace(dashboardHome, { direction: 'none'});
				document.body.innerHTML = '';
				window.location.pathname = dashboardHome;
			} else if (location.pathname.startsWith('/dashboard') && auth.status !== AuthStatus.LoggedIn) {
				//history.replace('/login', { direction: 'none'});
				document.body.innerHTML = '';				
				window.location.pathname = auth.status === AuthStatus.MilitantNeedsLogin ? '/login/militant' : '/login';
			} else if (location.pathname.startsWith('/login/militant') && auth.status !== AuthStatus.MilitantNeedsLogin) {
				//history.replace('/login', { direction: 'none'});
				document.body.innerHTML = '';
				window.location.pathname = '/login';
			}
		});
	}, [history, store, dashboardHome]);

	

	return <></>;
};

export default AuthMonitor;
