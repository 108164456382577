import React from 'react';
import ScrollablePage from '../../../components/ScrollablePage';
import MilitantNoticed from '../../../components/login/MilitantNoticed';
import MilitantActions from '../../../components/login/MilitantActions';
import { loginMilitantBack } from '../../../store/auth-actions';
import { useDispatch } from 'react-redux';

const DesktopLoginMilitant:React.FC = () => {

    const dispatch = useDispatch();

	function handleMilitantBack() {
		dispatch(loginMilitantBack());
	}
    
    return (
        <ScrollablePage headerBackUrl="/login/form" headerBackClick={ handleMilitantBack } renderFooter={ () => <MilitantActions /> }>
            <MilitantNoticed />
        </ScrollablePage>
    );
};

export default DesktopLoginMilitant;