import { IonPage, useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import MobileHeader from '../../../components/mobile/MobileHeader';
import { updateStatusBar } from '../../../lib/status-bar';
import LoginForm from '../../../components/login/LoginForm';
import LoginFormSubmit from '../../../components/login/LoginFormSubmit';
import MobileContent from '../../../components/mobile/MobileContent';

interface LoginFormProps {
	create: boolean;
}

const MobileLoginForm: React.FC<LoginFormProps> = ({ create }) => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));

	const localizedContent = useLocalizedContent();

	return (
		<IonPage>
			<MobileHeader defaultBackHref="/login" alignLeft title={ create ? localizedContent.createAccount : localizedContent.loginFormTitle }/>
			<MobileContent renderFooterContent={ () => <LoginFormSubmit create={ create } /> }>
				<LoginForm />
			</MobileContent>
		</IonPage>
	);
};

export default MobileLoginForm;