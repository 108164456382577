import React from 'react';
import { archive } from 'ionicons/icons';
import DateFilter from '../../../components/filters/DateFilter';
import ExcelButton from '../../../components/buttons/ExcelButton';
import OpenedTestimonial from '../../../components/modals/side-modals/OpenedTestimonial';
import PageTitleActions from '../../../components/desktop/PageTitleActions';
import SortableTable from '../../../components/desktop/SortableTable';
import SimpleModalController from '../../../components/modals/SimpleModalController';
import useSortableTestimonials from '../../../hooks/useSortableTestimonials';
import TestimonialStatusFilter from '../../../components/filters/TestimonialStatusFilter';
import { SortableTableColumn } from '../../../components/desktop/SortableTable';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/reducers';
import useDateFilteredEntries from '../../../hooks/useDateFilteredEntries';
import { Testimonial, SortableTestimonial } from '../../../models';

import './DesktopTestimonials.scss';
import NewTestimonialButton from '../../../components/buttons/NewTestimonialButton';
import { readEntry } from '../../../store/entries-actions';
import { archiveEntries } from '../../../store/entries-actions';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useRouteIdParam from '../../../hooks/useRouteIdParam';
import { useHistory } from 'react-router';

const DesktopTestimonials: React.FC= () => {

	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector((state:RootState) => state.auth.user);
    const isUserAdmin = user.is_admin || false;
	const localizedContent = useLocalizedContent();
	const pageFilters = useSelector((state:RootState) => state.settings.pageFilters.testimonials);
	const entries = useSelector((state:RootState) => state.entries.data);
	const testimonials = entries.testimonials;
	const dateFilteredTestimonials = useDateFilteredEntries('testimonials', testimonials) as Testimonial[];

	const archivesTestimonials = dateFilteredTestimonials.filter(testimonial => testimonial.status === 'archived');

	const totalCount = dateFilteredTestimonials.length;
	const archivedsCount = archivesTestimonials.length;
	const activesCount = totalCount - archivedsCount;

	const sortableTestimonials = useSortableTestimonials(dateFilteredTestimonials).filter(testimonial => {
		return pageFilters.status === 'archived' ?
			testimonial.data.status === pageFilters.status
		:
			testimonial.data.status === 'read' || testimonial.data.status === 'unread';// @TODO ah noel
	});	

	// Handle opened entry
	const openedEntryId = useRouteIdParam();
	const openedEntry = openedEntryId ? testimonials.find((entry:any) => entry.id === openedEntryId) : null;

	const handleEntryOpen = (entry: SortableTestimonial) => {
		const entryId = entry.id;
		
		history.push('/dashboard/testimonials/' + entryId);
		if (entry.data.status === 'unread') {// @TODO ah noel
			dispatch(readEntry('testimonials', entryId));
		}
	}

	const handleEntryClose = () => {
		history.replace('/dashboard/testimonials');
	}

	const tableColumns:SortableTableColumn[] = [
		{
			type: 'read-status'
		},
		{
			type: 'select'
		},
		{
			type: 'sort',
			sortKey: 'name',
			label: localizedContent.name,
			renderContent: (entry: SortableTestimonial) => entry.name
		},
		{
			label: localizedContent.message,
			renderTitle: (entry: SortableTestimonial) => entry.content,
			renderContent: (entry:SortableTestimonial) => <>
				{ entry.data.images ?
					<span className="thumbnail" style={{ backgroundImage: 'url(' + entry.data.images.thumb + ')' }} />
				: null }
				<span className="message">{ entry.content }</span>
			</>
		},
		{
			type: 'sort',
			sortKey: 'date',
			label: localizedContent.addedDate,
			renderContent: (entry: SortableTestimonial) => entry.date.format('DD/MM/YYYY')
		}
	];

	// Handle entry archiving
	const handleSelectedEntriesArchive = (selectedEntries: SortableTestimonial[]) => {
		dispatch(archiveEntries('testimonials', selectedEntries.map(entry => entry.id)));
	};
	
	if (pageFilters.status === 'archived') {
		tableColumns.splice(1, 1);
	}

	return (
		<div className="desktop-testimonials">
			<div className="dates-filter-container">
				<DateFilter page="testimonials"/>
			</div>
			<PageTitleActions count={ totalCount } title={ localizedContent.testimonials } dateFilters="testimonials">
				{ !isUserAdmin ?
					<NewTestimonialButton />
				: null }
				<ExcelButton page="testimonials" />
			</PageTitleActions>
			<div className="archived-status-selector-container">
				<TestimonialStatusFilter page="testimonials" activesCount={ activesCount}  archivedsCount={ archivedsCount } />
			</div>
			<SortableTable entries={sortableTestimonials} onEntryClick={ handleEntryOpen } selectOptions={{
				entryName: localizedContent.testimonial,
				actionIcon: archive,
				actionIonIcon: true,
				actionName: localizedContent.archiveCta,
				onAction: handleSelectedEntriesArchive
			}} columns={tableColumns} />
			<SimpleModalController position="right" opened={ openedEntry !== null } onClose={ handleEntryClose }>
				<OpenedTestimonial testimonial={ openedEntry } onClose={ handleEntryClose } />
			</SimpleModalController>
		</div>
	);
};

export default DesktopTestimonials;