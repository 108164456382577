import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { News } from '../../../models';
import DesktopCard from '../../../components/desktop/DesktopCard';
import SimpleModalController from '../../../components/modals/SimpleModalController';
import OpenedSingleNews from '../../../components/modals/side-modals/OpenedSingleNews';
import { readEntry } from '../../../store/entries-actions';
import { RootState } from '../../../store/reducers';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useRouteIdParam from '../../../hooks/useRouteIdParam';
import { useHistory } from 'react-router';

import './DesktopNews.scss';

const DesktopNews: React.FC= () => {

	const history = useHistory();
	const dispatch = useDispatch();
	const localizedContent = useLocalizedContent();
	const entries = useSelector((state:RootState) => state.entries.data);

	const news = entries.news;
	const singleNewsId = useRouteIdParam();

	const openedSingleNews = singleNewsId ? news.find(singleNews => singleNews.id === singleNewsId) || null : null;

	const handleNewsOpen = (singleNews: News) => {		
		history.push('/dashboard/news/' + singleNews.id);
		if (singleNews.status !== 'read') {
			dispatch(readEntry('news', singleNews.id));
		}
	}

	const handleNewsClose = () => {
		history.replace('/dashboard/news');
	}

	return (
		<div className="desktop-news">
			<h1>{ localizedContent.negociations }</h1>
			<div className="cards-list">
				{ news.map((singleNews, i) => <DesktopCard key={ 'card' + i } { ...singleNews } onClick={ () => handleNewsOpen(singleNews) } /> ) }
			</div>
			<SimpleModalController position="right" opened={ openedSingleNews !== null } onClose={ handleNewsClose }>
				<OpenedSingleNews singleNews={ openedSingleNews } onClose={ handleNewsClose } />
			</SimpleModalController>
		</div>
	);
};

export default DesktopNews;