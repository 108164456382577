import React, { useState } from 'react';
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './TransitionableScrollableWhiteContent.scss';

interface TransitionableScrollableWhiteContentProps {
    transitionKey: string;
    onTransitionStart?():void;
    onTransitionComplete?():void;
    renderFooter?():React.ReactNode;
}

const TransitionableScrollableWhiteContent:React.FC<TransitionableScrollableWhiteContentProps> = ({ transitionKey, children, onTransitionStart, onTransitionComplete, renderFooter }) => {

    // Handles blue corners fix
    const [didEnter, setDidEnter] = useState(false);

    useIonViewWillEnter(() => {
        setDidEnter(true);
    });

    useIonViewWillLeave(() => {
        setDidEnter(false);
    });

    return (
        <div className={ 'transitionable-scrollable-white-content' + (didEnter ? ' entered' : '') }>
            <div className="scrollable-white-content-inner">
                <div className="transition-container">
                    <TransitionGroup component={null}>
                        <CSSTransition key={ transitionKey } timeout={{ enter: 600, exit: 300}} onEnter={ onTransitionStart } onEntered= { onTransitionComplete }>
                            <div className="transition-content">
                                <div className="transition-content-page">
                                    { children }
                                </div>
                                { renderFooter ? 
                                    <div className="transition-content-footer">
                                        { renderFooter() }
                                    </div>
                                : null }           
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>              
            </div>
        </div>
    );
}

export default TransitionableScrollableWhiteContent;