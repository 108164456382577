import { AuthActionTypes, AuthStatus } from '../auth-actions';
import { User, SocialTokens, LocaleString, ThirdPartyLoginType } from '../../models';

interface AuthState {
	loading: boolean;
	status: AuthStatus;
	error?: LocaleString;
	user?: User;
	socialTokens?: SocialTokens;
	militantLoggingIn: boolean;
	thirdPartyLogginIn: ThirdPartyLoginType;
	adminPasswordResetSuccess?: boolean;
	adminUserUpdateSuccess?: boolean;
}

const auth = (
	state: AuthState = { loading: false, status: AuthStatus.Uninitialized, militantLoggingIn: false, thirdPartyLogginIn: null },
	action: any
) => {
	switch (action.type) {
		case AuthActionTypes.updateSocialTokens:
			return {
				...state,
				loading: false,
				socialTokens: { ...state.socialTokens, ...action.payload as SocialTokens }
			}
		case AuthActionTypes.updateUser:
			return {
				...state,
				user: {...state.user, ...(action.payload as User)}
			}		

		case AuthActionTypes.loading:
			return {
				...state,
				loading: true,
				militantLoggingIn: false,
				thirdPartyLogginIn: null,
				error: undefined
			};
		case AuthActionTypes.loginMilitantInit:
			return {
				...state,
				loading: true,
				militantLoggingIn: true,
				error: undefined				
			};
		case AuthActionTypes.loginSocialInit:
			return {
				...state,
				loading: true,
				thirdPartyLogginIn: action.payload as ThirdPartyLoginType,
				error: undefined				
			};

		case AuthActionTypes.loginSuccess:
			return {
				...state,
				loading: false,
				militantLoggingIn: false, 
				thirdPartyLogginIn: null,
				status: action.payload.status as AuthStatus,
				user: action.payload.user as User,
				error: action.payload.error as LocaleString
			};
		
		case AuthActionTypes.loginMilitantBack:
			return {
				...state,
				status: AuthStatus.LoggedOut,
				user: undefined
			};

		case AuthActionTypes.logoutSuccess:
			return {
				...state,
				loading: false,
				status: AuthStatus.LoggedOut
			};

		case AuthActionTypes.loginFailure:
			return {
				...state,
				loading: false,
				militantLoggingIn: false,
				thirdPartyLogginIn: null,
				status: AuthStatus.Invalid,
				error: action.error as LocaleString
			};
		case AuthActionTypes.logoutFailure:
		case AuthActionTypes.otherFailure:
			return {
				...state,
				loading: false,
				militantLoggingIn: false,
				thirdPartyLogginIn: null,
				error: action.error as LocaleString
			};

		case AuthActionTypes.adminPasswordResetLoading:
			return {
				...state,
				error: undefined,
				adminPasswordResetSuccess: false,
				loading: true
			};
		case AuthActionTypes.adminPasswordResetSuccess:
			return {
				...state,
				adminPasswordResetSuccess: true,
				loading: false
			};
		case AuthActionTypes.adminUserUpdateLoading:
			return {
				...state,
				error: undefined,
				adminUserUpdateSuccess: false,
				loading: true
			};
		case AuthActionTypes.adminUserUpdateSuccess:
			return {
				...state,
				adminUserUpdateSuccess: true,
				loading: false
			};
		case AuthActionTypes.sessionSet:
		case AuthActionTypes.sessionCleared:
		default:
			return state;
	}
};
export default auth;
