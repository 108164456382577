import { useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import FiqButton from '../../components/buttons/FiqButton';
import { TextField } from '@material-ui/core';
import { resetAdminPassword } from './../../store/auth-actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import useLocalizedContent from './../../hooks/useLocalizedContent';
import useEmailValidation from './../../hooks/useEmailValidation';
import { updateStatusBar } from './../../lib/status-bar';

import './ForgotPassword.scss';

const ForgotPassword: React.FC = () => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));

	const dispatch = useDispatch();
	const history = useHistory();
	const localizedContent = useLocalizedContent();
	const showFeedback = useSelector((state:RootState) => state.auth.adminPasswordResetSuccess);

	const [email, setEmail] = useState('');
	const emailValid = useEmailValidation(email);
    const backHref = '/login';
    
    const handleBackClick = (e: React.MouseEvent) => {
		e.preventDefault();		
		if (history.length > 2) {
			history.goBack()
		} else if (backHref) {
			history.replace(backHref, { direction: 'back' });
		}
	}

    const handleSubmitClick = () => {
		if (emailValid) {
			dispatch(resetAdminPassword(email));
			setEmail('');
		}		
    }
	
	return (
		<div className="forgot-password">
			<div className="title">{ localizedContent.resetPassword }</div>
            <form className="input-container" onSubmit={ e => { e.preventDefault(); handleSubmitClick(); }}>
                <TextField
                    type="email"
                    name="email"
                    label={ localizedContent.email }
                    variant="outlined"
                    fullWidth
                    value={ email }
                    onChange={e => setEmail(e.target.value) }
                />
                <div className={'feedback-container' + ( showFeedback ? ' show' : '' )}>{ localizedContent.resetPasswordSuccess }</div>
                <input type="submit" value="submit" tabIndex={-1} style={{ display: 'none' }} />
            </form>                    
            <div className="actions-container">
                <FiqButton color="white" fill="outline" onClick={ handleBackClick }>{ localizedContent.back }</FiqButton>
                <FiqButton disabled={ !emailValid } onClick={ handleSubmitClick }>{ localizedContent.reset }</FiqButton>
            </div>		
		</div>
	);
};

export default ForgotPassword;