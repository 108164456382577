import React from 'react';

import './CreateAccount.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

const CreateAccount: React.FC = () => {	

	const localizedContent = useLocalizedContent();

	return (
		<div className="create-account flex-vertical-center">
			<div className="title" dangerouslySetInnerHTML={{ __html: localizedContent.loginCreateTitle }} />

		</div>
	);
};

export default CreateAccount;