import React from 'react';
import useLocalizedContent from '../hooks/useLocalizedContent';
import FiqButton from './buttons/FiqButton';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/reducers';

import './WebNotifications.scss';
import useLocale from '../hooks/useLocale';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { updateWebNotification } from '../store/app-actions';

const WebNotifications:React.FC = () => {

    const webNotification = useSelector((state: RootState) => state.app.webNotification);

    const dispatch = useDispatch();
    const locale = useLocale();
    const localizedContent = useLocalizedContent();

    const handleActionClick = () => {
        if (webNotification.actionClick) {
            webNotification.actionClick();
        }
        handleCloseClick();
    }

    const handleCloseClick = () => {
        dispatch(updateWebNotification(undefined));
    }

    return (
        <div className={ 'web-notifications'}>
            <TransitionGroup component={null}>
                <CSSTransition key={ webNotification?.id } timeout={500}>
                    <div className="notification-container">
                        { !!webNotification ?
                            <div className="notification-content">
                                <div className="notification-title">
                                    { webNotification?.title[locale] }
                                </div>
                                { webNotification?.body ?
                                    <div className="notification-body">
                                        { webNotification.body[locale] }
                                    </div>
                                : null }
                                <div className="notification-actions">
                                    { webNotification?.actionLabel ? 
                                        <FiqButton disabled={ !webNotification } onClick={ handleActionClick }>
                                            { webNotification.actionLabel[locale] }
                                        </FiqButton>
                                    : null }
                                    <FiqButton color="white" fill="outline" disabled={ !webNotification } onClick={ handleCloseClick }>
                                        { localizedContent.close }
                                    </FiqButton>
                                </div>
                            </div>
                        : null}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </div>
    )
}

export default WebNotifications;