import React, { useEffect, useRef } from 'react';
import YesNoButtons from '../buttons/YesNoButtons';
import useLocalizedContent from './../../hooks/useLocalizedContent';
import useStoredDeclaration from './../../hooks/useStoredDeclaration';
import { DeclarationType } from '../../models';
import TextFieldNumber from '../TextFieldNumber';
import gsap, { Quad } from 'gsap';

import './DeclarationForewarn.scss';
import AutoCompleteSelect from '../AutocompleteSelect';

interface PeriodType {
    name: string;
    hours: number;
};

const DeclarationForewarn: React.FC<{ type: DeclarationType }>  = ({ type }) => {

    const warnedContainer = useRef(null);
    const storedDeclaration = useStoredDeclaration(type);
    const localizedContent = useLocalizedContent();

    const warned = storedDeclaration.declaration?.was_forewarn;
    const periodCount = storedDeclaration.declaration?.forewarn_period_count || '';
    const periodTypeIndex = storedDeclaration.declaration?.forewarn_period_index || 0;

    const periodTypes:PeriodType[] = [
        {
            name: localizedContent.hours,
            hours: 1
        },
        {
            name: localizedContent.days,
            hours: 24
        }
    ];

    const getTotalHours = (count?: string, index?: number ) => {
        const countFloat = parseFloat(count) || 0;
        return countFloat * periodTypes[index].hours;
    }

    const setWarned = (warned: Boolean) => {
        storedDeclaration.setData({ was_forewarn: warned });
    }

    const setCount = (count: string) => {
        storedDeclaration.setData({ forewarn_period_count: count, forewarn_hours: getTotalHours(count, periodTypeIndex) });
    }

    const setPeriodType = (index: number) => {
        storedDeclaration.setData({ forewarn_period_index: index, forewarn_hours: getTotalHours(periodCount, index) });
    }

    useEffect( () => {
        if (warnedContainer) {
            const el = warnedContainer.current;
            const animParams = { duration: 0.3, height: 0, ease: Quad.easeInOut };
            if (warned) {
                gsap.set(el, { height: 'auto' });
                gsap.from(el, animParams);
            } else {
                gsap.to(el, animParams);
            }
        }
        
    }, [warned, warnedContainer]);

    return (
        <div className="declaration-forewarn">
            <h2 className="ion-text-center">{ localizedContent.wasForewarn }</h2>
            <div className="ion-padding-vertical">
                <YesNoButtons value={ warned } onChange={ (yes:boolean) => setWarned(yes) } />
            </div>
            <div ref={ warnedContainer } className={'warned-container' + (!warned ? ' hidden' : '')}>
                <div className="warned-content">
                    <div className="hours-container">
                        <TextFieldNumber
                            value={ periodCount }
                            onChange={ setCount }
                        />
                    </div>
                    <div className="period-container">
                        <AutoCompleteSelect
                            options={ periodTypes }
                            value={ periodTypes[periodTypeIndex] }
                            onChange={ (e:any, v:PeriodType) => setPeriodType(periodTypes.findIndex(period => period.name === v.name)) }
                            label={ localizedContent.period }
                        />
                    </div>
                </div>
                <div className="warned-footer">{ localizedContent.beforeEndOfMyService }</div>
            </div>
        </div>
    );
};

export default DeclarationForewarn;
