import { environment, ApiSuccessPayload } from './environment';
import { TsoCauseKey } from '../models';
import { CreateEventRequestPayload, CreateNewsRequestPayload, EditNewsRequestPayload, EditEventRequestPayload } from '../store/post-actions';

interface PostPayload extends ApiSuccessPayload {
	data?: any;
}

export interface DeclarationPayload {
	institution_id?: number;
	installation_id?: number;
	was_forewarn: boolean;
	forewarn_hours: number;
	date_submitted_for: string;
}

export interface TsoPayload extends DeclarationPayload {	
	regular_hours: number;
	overtime_hours: number;
	causes: TsoCauseKey[];
	cause_other?: string;
}

export interface SubstitutionPayload extends DeclarationPayload {
	current_job_id: number;
	substituted_job_id: number;
	substituted_job_other?: string;
	description: string;
	hours_spent: number;
}

export interface TestimonialPayload {
	content: string;
	allow_sharing: boolean;
	image?: string;
}

class PostService {
    async createTso(token: string, payload: TsoPayload): Promise<PostPayload> {
		const response = await fetch(`${environment.api}/tso`, {
			method: 'PUT',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify(payload)
		});
		return await response.json() || { data: null };
	}

    async createSubstitution(token: string, payload: SubstitutionPayload): Promise<PostPayload> {
		const response = await fetch(`${environment.api}/substitution`, {
			method: 'PUT',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify(payload)
		});
		return await response.json() || { data: null };
	}

    async createTestimonial(token: string, payload:TestimonialPayload): Promise<PostPayload> {
		const response = await fetch(`${environment.api}/testimonial`, {
			method: 'PUT',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify(payload)
		});
		return await response.json() || { data: null };
	}

	async createNews(token: string, payload: CreateNewsRequestPayload): Promise<PostPayload> {
		const response = await fetch(`${environment.api}/news`, {
			method: 'PUT',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify(payload)
		});
		return await response.json() || { data: null };
	}

	async createEvent(token: string, payload: CreateEventRequestPayload): Promise<PostPayload> {
		const response = await fetch(`${environment.api}/event`, {
			method: 'PUT',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify(payload)
		});
		return await response.json() || { data: null };
	}

	async editNews(token: string, payload: EditNewsRequestPayload): Promise<PostPayload> {
		const response = await fetch(`${environment.api}/news/update`, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify(payload)
		});
		return await response.json() || { data: null };
	}

	async editEvent(token: string, payload: EditEventRequestPayload): Promise<PostPayload> {
		const response = await fetch(`${environment.api}/event/update`, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify(payload)
		});
		return await response.json() || { data: null };
	}

	async deleteNews(token: string, id: number): Promise<PostPayload> {
		const response = await fetch(`${environment.api}/news/delete`, {
			method: 'DELETE',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify({ id })
		});
		return await response.json() || { data: null };
	}

	async deleteEvent(token: string, id: number): Promise<PostPayload> {
		const response = await fetch(`${environment.api}/event/delete`, {
			method: 'DELETE',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify({ id })
		});
		return await response.json() || { data: null };
	}
}

export const postService = new PostService();