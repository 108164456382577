import React from 'react';

import './MapInfosButton.scss';

interface MapInfosButtonProps {
    onClick?():void;
}

const MapInfosButton: React.FC<MapInfosButtonProps> = ({ onClick }) => {

	return (
        <button type="button" className="map-info-button" onClick={ onClick } />
	);
};

export default MapInfosButton;