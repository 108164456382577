import { IonPage, useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MobileHeader from '../../../components/mobile/MobileHeader';
import { updateStatusBar } from '../../../lib/status-bar';
import CreateAccount from '../../../components/login/CreateAccount';
import LoginCta from '../../../components/login/LoginCta';
import MobileContent from '../../../components/mobile/MobileContent';

const MobileCreateAccount: React.FC<RouteComponentProps> = () => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));

	const loginHref = '/login';

	return (
		<IonPage>
			<MobileHeader defaultBackHref={ loginHref } />
			<MobileContent noFooterBorder renderFooterContent={ () => <LoginCta /> }>
				<CreateAccount />
			</MobileContent>
		</IonPage>
	);
};

export default MobileCreateAccount;
