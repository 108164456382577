import { entriesService } from '../services/entries.service';
import { Plugins } from '@capacitor/core';
import { Entries, EntriesStats, TestimonialStatus, EntryTypes, ReadableEntryType, ExportableEntryType, LocaleString } from '../models';
import { ApiSuccessPayload } from '../services/environment';
import localeStrings from '../data/locale-strings.json';
const { Storage } = Plugins;

export interface ChangeEntriesStatusRequestPayload {
	[type: string] : number[];
}

export interface ExportEntriesRequestPayload {
	types?: ExportableEntryType[],
	filters?: {
		date_from?: string,
		date_to?: string,
		status?: TestimonialStatus[]// en ce moment le status est juste appliqué aux testimonials, sera aussi pour les déclarations en v2
	}
}

export interface EntriesStatsSuccessPayload extends ApiSuccessPayload {
	data?: EntriesStats;
}

export interface EntriesSuccessPayload extends ApiSuccessPayload {
	data?: Entries;
	skipReplace?: boolean;
}

export enum EntriesActionType {
	loading = '[Entries] Loading',	
	loadingEntriesStats = '[Entries] Loading stats',
	stopLoading = '[Entries] Stop loading',
	setEntries = '[Entries] Entries set',
	deleteEntry = '[Entries] Entry delete',
	setEntriesStats = '[Entries] Set stats',
	clearEntriesStats = '[Entries] Clear stats',
	dataFailure = '[Entries] Entries failure',
	entriesStatsFailure = '[Entries] Stats failure',
	clearEntries = '[Entries] Entries clear',
	updateEntriesStatus = '[Entries] Update entries status'
}

async function getToken(): Promise<string> {	
	const { value } = await Storage.get({ key: 'token' });
	return value ? value.toString() : undefined;
}

export const getEntries = (filters?: any, types?: EntryTypes[]) => {
	return async (dispatch: any) => {
		dispatch(loading());
		const token = await getToken();
		try {
			const res = await entriesService.getEntries(token, filters, types);
			if (res.data) {
				return dispatch(setEntries(res));
			} else {
				return dispatch(dataFailure(localeStrings.errorEntries));
			}
		} catch (e) {
			return dispatch(dataFailure(localeStrings.errorAPI));
		}
	};
}

export const refreshEntries = async (filters?: any, types?: EntryTypes[]):Promise<any> => {
	const token = await getToken();
	try {
		const res = await entriesService.getEntries(token, filters, types);
		return res;
	} catch (e) {
		return null;
	}
}

export const exportEntries = (payload: ExportEntriesRequestPayload) => {
	return async (dispatch: any) => {
		dispatch(loading());
		const token = await getToken();
		const res = await entriesService.exportEntries(token, payload);
		if (res.data) {
			let link = document.createElement('a');
			let blob = window.URL.createObjectURL(new Blob([res.data]));
			link.href = blob
			link.download = res.fileName;
			link.click();
			setTimeout( () => {
				window.URL.revokeObjectURL(blob);
				link = null;
				blob = null;
			}, 1000);			
		}
		return dispatch({ type: EntriesActionType.stopLoading });
	}
}

export const addEntry = (type: EntryTypes, entry: any, skipReplace?: boolean) => ({
	type: EntriesActionType.setEntries,
	payload: {
		data: {
			[type] : [entry]
		},
		skipReplace
	}
});

export const deleteEntry = (type: EntryTypes, id: number) => {
	return async (dispatch: any) => {
		return dispatch({
			type: EntriesActionType.deleteEntry,
			payload: { type, id }
		});
	}
};

export const readEntry = (type: ReadableEntryType, id: number) => {
	return async (dispatch: any) => {
		dispatch(updateEntriesStatus(type, 'read', [id]));
		const token = await getToken();
		try {
			const res = await entriesService.readEntry(token, { [type]: [id] });
			if (!res.data) {
				dispatch(dataFailure(localeStrings.errorReadEntry));
			}
		} catch (e) {
			dispatch(dataFailure(localeStrings.errorAPI));
		}
	};
};

export const archiveEntries = (type: ReadableEntryType, ids: number[]) => {
	return async (dispatch: any) => {
		dispatch(updateEntriesStatus(type, 'archived', ids));
		const token = await getToken();
		try {
			const res = await entriesService.archiveEntries(token, { [type]: ids });
			if (!res.data) {
				dispatch(dataFailure(localeStrings.errorArchiveEntry));
			}
		} catch (e) {
			dispatch(dataFailure(localeStrings.errorAPI));
		}
	};	
};

const updateEntriesStatus = (type: ReadableEntryType, status: string, ids: number[]) => ({
	type: EntriesActionType.updateEntriesStatus,
	payload: {
		type, status, ids
	}
})

export const loading = () => ({
	type: EntriesActionType.loading
});

export const loadingEntriesStats = () => ({
	type: EntriesActionType.loadingEntriesStats
});

export const setEntries = (payload: EntriesSuccessPayload) => ({
	type: EntriesActionType.setEntries,
	payload
});

export const setEntriesStats = (payload: EntriesStatsSuccessPayload) => ({
	type: EntriesActionType.setEntriesStats,
	payload
});

export const clearEntriesStats = () => ({
	type: EntriesActionType.clearEntriesStats
});

export const clearEntries = () => ({
	type: EntriesActionType.clearEntries
});

const dataFailure = (error: LocaleString) => ({
	type: EntriesActionType.dataFailure,
	error
});

