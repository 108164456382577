import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useState, useRef } from 'react';

import QuebecMap from '../../../components/QuebecMap';
import { useSelector, useDispatch } from 'react-redux';
import DeclarationsCircles from '../../../components/stats/DeclarationsCircles';
import useSortableDeclarations from '../../../hooks/useSortableDeclarations';

import './MobileMap.scss';
import { RootState } from '../../../store/reducers';
import CountTitle from '../../../components/stats/CountTitle';
import DeclarationsTypeFilter from '../../../components/filters/DeclarationTypeFilter';
import { useHistory } from 'react-router-dom';
import RegionStatus from '../../../components/stats/RegionStatus';
import MapInfosButton from '../../../components/buttons/MapInfosButton';
import ControlledIonModal from '../../../components/modals/ControlledIonModal';
import MapLegendModal from '../../../components/modals/center-modals/MapLegendModal';
import { readTutorial } from '../../../store/settings-actions';
import { SortableDeclaration, Tso, Substitution } from '../../../models/entries';
import { readEntry } from '../../../store/entries-actions';
import MobileHeader from '../../../components/mobile/MobileHeader';
import ScrollableWhiteContent from '../../../components/mobile/ScrollableWhiteContent';
import FixedContent from '../../../components/mobile/FixedContent';
import { updateStatusBar } from '../../../lib/status-bar';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useLocale from '../../../hooks/useLocale';
import { parseLongDate } from '../../../lib/utils';
import LocationFilter from '../../../components/filters/LocationFilter';
import useLocationFilteredEntries from '../../../hooks/useLocationFilteredEntries';
import useElementSize from '../../../hooks/useElementSize';
import PullToRefreshEntries from '../../../components/mobile/PullToRefreshEntries';

const MobileDeclarationsMap: React.FC = () => { 

    // Update tab color
    useIonViewWillEnter( () => updateStatusBar('blue'));

    const locale = useLocale();
    const history = useHistory();
    const user = useSelector((state:RootState) => state.auth.user);
    const isUserMilitant = user.is_militant || false;
    const isUserAdmin = user.is_admin || false;
    const pageFilters = useSelector((state:RootState) => state.settings.pageFilters.declarations);
    const adminRegionId = pageFilters.region_id || 1;
    const adminInstitutionId = pageFilters.institution_id;
    const regionId = isUserMilitant && !isUserAdmin ? user.registration_numbers[0].region_id : adminRegionId;
    const localizedContent = useLocalizedContent();
    const dispatch = useDispatch();

    const appData = useSelector((state:RootState) => state.app.data);
    const regions = appData.regions;
    const institutions = appData.institutions;
    const installations = appData.installations;
    
    const region = regions.find(region => region.id === regionId);

    const institutionId = isUserMilitant && !isUserAdmin ? user.registration_numbers[0].institution_id : adminInstitutionId;
    const installationId = pageFilters.installation_id;

    // get declarations

    const entries = useSelector((state:RootState) => state.entries.data);
    const tsos = entries.tsos;
    const substitutions = entries.substitutions;

    // filter by location

    const locationFilteredTsos = useLocationFilteredEntries('declarations', tsos) as Tso[];
    const locationFilteredSubstitutions = useLocationFilteredEntries('declarations', substitutions) as Substitution[];
    
    // no need to filter by dates since its always last month (the default) for mobile

    // get declarations counts

    const tsosCount = locationFilteredTsos.length;
    const urgentTsosCount = locationFilteredTsos.filter(tso => tso.urgency_state).length;
    const substitutionsCount = locationFilteredSubstitutions.length;
    const totalCount = tsosCount + substitutionsCount;
    const urgentTsosRatio = urgentTsosCount / tsosCount;
    
    // combine declaration in a sortable format

    const sortableDeclarations = useSortableDeclarations({
        tsos: locationFilteredTsos,
        substitutions: locationFilteredSubstitutions
    });

    // local filter: declaration types
    
    const declarationTypes = pageFilters.declaration_types;
	const isTypeUnfiltered = declarationTypes === undefined || declarationTypes.length === 0;	
	const showTsos = isTypeUnfiltered || declarationTypes.indexOf('tso') > -1;
    const showSubstitutions = isTypeUnfiltered || declarationTypes.indexOf('substitution') > -1;
    
    // apply local filters

	const sortableFilteredDeclarations = sortableDeclarations.filter((declaration) => {
		const validType = (declaration.type === 'tso' && showTsos) || (declaration.type === 'substitution' && showSubstitutions);
        return validType;
    });
    
    // sort by emergency first + date descending

    sortableFilteredDeclarations.sort((a, b) => { 
        let result = b.urgent - a.urgent;

        if (result === 0) {
            result = b.date.unix() - a.date.unix();
        }

        return result;
    });

    // handle map legend

    const seenMapTutorial = useSelector((state:RootState) => state.settings.tutorialsRead.indexOf('map') > -1);
    const [mapLegendOpened, setMapLegendOpened] = useState(!seenMapTutorial);

	const openMapLegend = () => {
		setMapLegendOpened(true);
	}
	const closeMapLegend = () => {
        if (!seenMapTutorial) {
            dispatch(readTutorial('map'));
        }
		setMapLegendOpened(false);
    }
    
    // Handle declaration click

    const handleDeclarationClick = (declaration: SortableDeclaration) => {
        const declarationType = declaration.type;
        const declarationId = declaration.id;
        history.push('/dashboard/' + declarationType + '-details/' + declarationId);
        if (!declaration.read) {
            dispatch(readEntry((declarationType + 's' as 'tsos' | 'substitutions'), declarationId));
        }		
    }

    const scrollableContent = useRef(null);
    const scrollableContentSize = useElementSize(scrollableContent);

	return (
        <IonPage className="mobile-map" data-page="map">
            <MobileHeader blue title={ region?.name } />
            <IonContent>
                <PullToRefreshEntries />
                <FixedContent paddingBottomPercent={70} width={ scrollableContentSize?.width }>
                    <div className="map-container">
                        <QuebecMap region_number={ region.region_number } />
                    </div>
                    <div className="circles-container">
                        <DeclarationsCircles urgentTsosCount={ urgentTsosCount } tsosCount={ tsosCount } substitutionsCount={ substitutionsCount } triangleLayout size={80} />
                    </div>
                </FixedContent>
                <ScrollableWhiteContent
                    stopTouchMovePropagationWhileScrolling
                    paddingBottomPercent={70}
                    overlayContent={ () => <MapInfosButton onClick={ openMapLegend } /> }
                    hideTopNotchMask
                >
                    <div className="location-container" ref={ scrollableContent }>
                        { isUserAdmin ?
                            <div className="location-filter-container">
                                <LocationFilter page="declarations" defaultRegionId={ regionId } hideAllRegions vertical />
                            </div>
                        : null }                            
                        <div className="location-content">
                            <RegionStatus urgentRatio={urgentTsosRatio} />
                            <span className="institution-name">
                                {
                                    !isUserAdmin ? 
                                        institutions[institutionId].name
                                    : (
                                        installationId ?
                                            installations[installationId].name
                                        : institutionId ?
                                            institutions[institutionId].name
                                        :
                                            region.name
                                    )                                            
                                }
                            </span>
                        </div>
                    </div>
                    <div className="count-title-container">
                        <CountTitle count={ totalCount } titleSize={18} title={ localizedContent.declarations + ' ' + localizedContent.inTheLast30days } />
                    </div>
                    <div className="declaration-type-filter-container">
                        <DeclarationsTypeFilter page="declarations" tsosCount={ tsosCount } substitutionsCount={ substitutionsCount } />
                    </div>
                    <div className="declarations-list">
                        { sortableFilteredDeclarations.map((declaration, i) => (
                            <button
                                key={'declaration'+i}
                                type="button"
                                onClick={ e => handleDeclarationClick(declaration) }
                                className={ 'item' + (!declaration.read ? ' unread': '') + (declaration.urgent ? ' urgent' : '') }
                            >
                                <div className="item-content">
                                    <div className="declaration-header">
                                        <span className="declaration-type">
                                            { declaration.type === 'tso' ? localizedContent.declarationTso : localizedContent.workSubstitution }
                                        </span>                                                
                                        { declaration.urgent ? 
                                            <span className="urgent">{ localizedContent.urgent }</span>
                                        : null }
                                    </div>
                                    <div className="declaration-content">
                                        <div className="declaration-line line-hours">
                                            <img src="/assets/icon/hours-overtime-pale.svg" alt="icon-hours" />
                                            <span className="hours-label">{ localizedContent.totalHours }</span>
                                            <span className="hours-count">{ declaration.hours }h</span>
                                        </div>
                                        <div className="declaration-line line-name">
                                            <img src="/assets/icon/name-pale.svg" alt="icon-name" />
                                            <span className="declaration-author">{ declaration.name }</span>
                                        </div>
                                        <div className="declaration-line line-date">
                                            <img src="/assets/icon/calendar-pale.svg" alt="icon-calendar" />
                                            <span className="created-at">{ localizedContent.addedOn + ' ' + parseLongDate(declaration.date, locale) }</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="item-icon" />
                            </button>
                        ))}
                    </div>
                </ScrollableWhiteContent>
            </IonContent>
            <ControlledIonModal type="small" opened={ mapLegendOpened } onClose={ closeMapLegend }>
                <MapLegendModal mobile onClose={ closeMapLegend } />
            </ControlledIonModal>
        </IonPage>
	);
};

export default MobileDeclarationsMap;