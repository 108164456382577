import React from 'react';
import { IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonIcon, IonLabel, IonItemDivider, IonPage, IonContent, useIonViewWillEnter } from '@ionic/react';
import { location } from 'ionicons/icons';
import MobileHeader from '../../../components/mobile/MobileHeader';
import useLocale from '../../../hooks/useLocale';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { updateStatusBar } from '../../../lib/status-bar';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import PullToRefreshEntries from '../../../components/mobile/PullToRefreshEntries';

import './MobileEvents.scss';

const MobileEvents: React.FC = () => {

    // Update tab color
    useIonViewWillEnter( () => updateStatusBar('blue'));
    const locale = useLocale();
    const localizedContent = useLocalizedContent();
    const entries = useSelector((state:RootState) => state.entries.data);
    const events = entries.events;

    return (
        <IonPage className="with-tabs mobile-events" data-page="events">
            <MobileHeader blue title={ localizedContent.events } />
            <IonContent>
                <PullToRefreshEntries />
                <div className="events-list ion-padding" style={{backgroundColor: '#F1F9FF', minHeight: '100%'}} >
                    { events.map( (event, i) => 
                        <IonCard color="light" key={ 'event-' + event.id + '-' + i } className="ion-margin-bottom" mode="md" routerLink={ '/dashboard/events/' + event.id }>
                            <div className="image-container" style={ event.images?.medium[locale] ? { backgroundImage: 'url(' + event.images.medium[locale] + ')'} : null }>
                                <div className="date">
                                    <div className="month">{ event.start_date_moment.locale(locale).format('MMM') }</div>
                                    <div className="day">{ event.start_date_moment.locale(locale).format('DD') }</div>
                                </div>
                            </div>
                            <IonCardHeader>
                                <IonCardTitle><span>{ event.title[locale] }</span></IonCardTitle>
                            </IonCardHeader>
                            <div className="card-content-separator">
                                <IonItemDivider style={{"--background": "transparent", minHeight: 1}} />
                            </div>
                            <IonCardContent className="ion-padding-bottom">
                                <div className="card-content-inner">
                                    <IonIcon icon={location} />
                                    <IonLabel>{ event.location }</IonLabel>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    )}
                </div>
            </IonContent>
        </IonPage>
        
    );
};

export default MobileEvents;