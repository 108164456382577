import React from 'react';
import useLocalizedContent from '../../hooks/useLocalizedContent';

import './MilitantNoticed.scss';

const MilitantNoticed: React.FC = () => {

	const localizedContent = useLocalizedContent();

	return (
		<div className="militant-noticed">
			<img alt="icon-militant" src="/assets/icon/militant.svg" />
			<h2>{ localizedContent.loginMilitantTitle }</h2>
			<p dangerouslySetInnerHTML={{ __html: localizedContent.loginMilitantSubtitle }} />
		</div>
	);
};

export default MilitantNoticed;