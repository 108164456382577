import { useSelector } from 'react-redux';
import usePrevious from './usePrevious';
import { RootState } from '../store/reducers';

type Hook = () => boolean

const useBackButtonHit:Hook = () => {
    const backButtonHitCount = useSelector((state: RootState) => state.app.backButtonHitCount);
    const previousBackButtonHitCount = usePrevious(backButtonHitCount);
    return previousBackButtonHitCount !== null && previousBackButtonHitCount !== backButtonHitCount && backButtonHitCount > 0;
}

export default useBackButtonHit;