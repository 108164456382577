import React from 'react';
import './ScrollablePage.scss';
import { useHistory } from 'react-router';
import { IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

interface ScrollablePageProps {
    headerTitle?: string;
    headerBackUrl?: string;
    headerBackClick?(): void;
    renderFooter?(): any;
}

const ScrollablePage:React.FC<ScrollablePageProps> = ({ headerBackClick, headerTitle, headerBackUrl, renderFooter, children }) => {
    
    const history = useHistory();

    const handleBackClick = () => {
        if (headerBackClick) {
            headerBackClick();
        } else {
            if (history.length > 2) {
                history.goBack()
            } else if (headerBackUrl) {
                history.replace(headerBackUrl, { direction: 'back' });
            }
        }        
    }

    return (
        <div className="scrollable-page">
            { !!headerTitle || !!headerBackUrl ? 
                <div className="scrollable-page-header">
                    { !! headerBackUrl ? 
                        <button onClick={ handleBackClick }>
                            <IonIcon icon={ chevronBack } />
                        </button>
                    : null }
                    { !! headerTitle ? 
                        <span className="page-header-title">{ headerTitle }</span>
                    : null }
                </div>
            : null }            
            <div className="scrollable-page-content">
                { children }
            </div>
            {renderFooter ? 
                <div className="scrollable-page-footer">
                    {renderFooter() }
                </div>
            : null }        
        </div>
    );
};

export default ScrollablePage;