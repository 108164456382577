import React, { useState, useRef, useEffect, useCallback } from 'react';
import { IonPage, IonContent, useIonViewWillEnter } from "@ionic/react";
import { useRouteMatch, useHistory } from 'react-router';
import { close } from 'ionicons/icons';
import { DeclarationType } from '../../../models';
import useElementSize from '../../../hooks/useElementSize';
import useDeclarationForm from '../../../hooks/useDeclarationForm';
import useStoredDeclaration from '../../../hooks/useStoredDeclaration';
import DonutProgress from '../../../components/declaration/DonutProgress';
import MobileHeader from '../../../components/mobile/MobileHeader';
import ControlledIonModal from '../../../components/modals/ControlledIonModal';
import DeclarationExitWarning from '../../../components/modals/bottom-modals/DeclarationExitWarning';
import TransitionableScrollableWhiteContent from '../../../components/mobile/TransitionableScrollableWhiteContent';
import MobileFooterButtons from '../../../components/mobile/MobileFooterButtons';
import usePrevious from '../../../hooks/usePrevious';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { updateStatusBar } from '../../../lib/status-bar';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useBackButtonHit from '../../../hooks/useBackButtonHit';
import gsap from 'gsap';

import './MobileNewReport.scss';
import ToastError from '../../../components/ToastError';

const MobileNewReport: React.FC = () => {

    // Update tab color
    useIonViewWillEnter( () => updateStatusBar('blue'));

    const scrollContainer = useRef(null);
    const localizedContent = useLocalizedContent();
    const history = useHistory();
    const headerContent = useRef(null);
    const headerContentSize = useElementSize(headerContent);
    const routeMatch = useRouteMatch();
    const type:DeclarationType = (routeMatch.params as any)?.type || 'tso';

    const storedDeclaration = useStoredDeclaration(type);

    const declarationProcess = useDeclarationForm(type);
    const { goBack, goNext, currentPage, pageNumber, pagesCount, isTutorial, tutorialPage, isSuccess, backBtn, nextBtn, error, posting } = declarationProcess;

    const declarationJustSent = !usePrevious(isSuccess) && isSuccess;

    const renderedPage = isTutorial ? tutorialPage : currentPage;

    const mainTitle = type === 'tso' ? localizedContent.declarationTso : localizedContent.workSubstitution;

    // Handles navigation and exit warning modal

    const [openedExitWarning, setOpenedExitWarning] = useState(false);
    const [exiting, setExiting] = useState(false);

    const openExitWarning = () => {
        setOpenedExitWarning(true);
    }

    const closeExitWarning = () => {
        setOpenedExitWarning(false);
    }

    const goDashboardHome = useCallback(() => {
        setExiting(true);
        if (history.length > 2) {
            history.goBack();
        } else {
            history.replace('/dashboard', { direction: 'back'});
        }
    }, [history]);

    const handleExit = useCallback( () => {
        if (storedDeclaration.declaration) {
            openExitWarning();          
        } else {
            goDashboardHome();
        }
    }, [storedDeclaration, goDashboardHome]);

    const handleExitConfirmed = useCallback ( () => {
        closeExitWarning();
        goDashboardHome();
    }, [goDashboardHome]);

    useEffect( () => {
        if (declarationJustSent) {
            history.push('/dashboard/report-sent/'+type);
        }
    }, [declarationJustSent, history, type]);

    const onTransitionStart = () => {
        scrollContainer.current.scrollTop = 0;
        gsap.set(scrollContainer.current, { overflow: 'hidden' });
    }

    const onTransitionComplete = () => {
        gsap.set(scrollContainer.current, { overflow: 'auto' });
    }

    // Back button management

    const backbuttonHit = useBackButtonHit();

    useEffect( () => {
        if (backbuttonHit) {
            if (openedExitWarning) {
                handleExitConfirmed();
            } else {
                if (isTutorial) {
                    handleExit();
                } else {
                    goBack();
                }
            }                     
        }
    }, [backbuttonHit, isTutorial, openedExitWarning, goBack, handleExitConfirmed, handleExit]);

	return (
        <IonPage className="mobile-new-declaration" data-page="new-declaration">
            <MobileHeader blue backIcon={ close } title={ isTutorial ? mainTitle : null } onBackClick={ handleExit } backDisabled={ exiting } />
            <IonContent>
                <div className="declaration-header-content" ref={ headerContent }>
                    <div className="page-title">{currentPage?.title}</div>
                    <DonutProgress current={ pageNumber + 1 } total={ pagesCount } />
                </div>
                <div className="white-content-container" ref={ scrollContainer } >
                    <div className="scrollable-spacer" style={{ height: !isTutorial ? headerContentSize?.height : 0 }} />
                    <TransitionableScrollableWhiteContent
                        transitionKey={renderedPage.key}
                        onTransitionStart={ onTransitionStart }
                        onTransitionComplete={ onTransitionComplete }
                        renderFooter={ () => <MobileFooterButtons backBtn={ backBtn } nextBtn={ nextBtn } onBackClicked={ goBack } onNextClicked={ goNext } /> }
                    >
                        <renderedPage.Component type={type} />                        
                    </TransitionableScrollableWhiteContent>
                </div>
            </IonContent>
            <ControlledIonModal type="bottom" opened={ openedExitWarning } onClose={ closeExitWarning }>
				<DeclarationExitWarning onExit={ handleExitConfirmed } onResume={ closeExitWarning } />
			</ControlledIonModal>
            <LoadingOverlay loading={ posting } />
            <ToastError error={ error } />
        </IonPage>
	);
};

export default MobileNewReport;