import { environment } from './environment';
import { GetApplicationDataSuccessPayload, GetApplicationDataHashSuccessPayload } from '../store/app-actions';

class AppService {

    public applicationDataHash: string;

	async getApplicationData(): Promise<GetApplicationDataSuccessPayload> {
        const response = await fetch(`${environment.api}/application-data`, {
            method: 'GET'
        });
        const returnedResponse = await response.json() || { data: null };
        this.applicationDataHash = returnedResponse.hash;
        return returnedResponse;
    }

    async getApplicationDataHash(): Promise<GetApplicationDataHashSuccessPayload> {
        const response = await fetch(`${environment.api}/application-data/hash`, {
            method: 'GET'
        });
        return await response.json() || { data: null };
    }
}

export const appService = new AppService();