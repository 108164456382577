import React from 'react';

import './FixedContent.scss';

interface FixedContentProps {
    image?: string;
    width?: number;
    paddingBottomPercent?: number;
}

const FixedContent:React.FC<FixedContentProps> = ({ paddingBottomPercent = 60, width, image, children }) => {

    const style:React.CSSProperties = { paddingBottom: paddingBottomPercent + '%', width };

    if (image) {
        style.backgroundImage = 'url(' + image + ')';
    }

    return (
        <div className="fixed-content" style={ style }>
            { children }
        </div>
    );
}

export default FixedContent;