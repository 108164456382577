import { IonPage, useIonViewWillEnter } from '@ionic/react';
import React from 'react';

import { updateStatusBar } from '../../../lib/status-bar';
import LoginHome from '../../../components/login/LoginHome';
import MobileContent from '../../../components/mobile/MobileContent';


const MobileLoginHome: React.FC = () => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));	
	
	return (
		<IonPage>
			<MobileContent 
				noFooterBorder 
			>
				<LoginHome />
			</MobileContent>
		</IonPage>
	);
};

export default MobileLoginHome;
