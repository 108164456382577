import { EntriesActionType } from '../entries-actions';
import { Entries, EntriesStats, LocaleString, EntryTypes } from '../../models';
import moment from 'moment';

interface DataState {
	loading: boolean;
	entriesStatsLoading: boolean;
	error?: LocaleString;
	data: Entries;
}

const data = (
	state: DataState = {
		loading: false,
		entriesStatsLoading: false,
		data: {
			events: [],
			news: [],
			tsos: [],
			substitutions: [],
			testimonials: []
		}
	},
	action: any
) => {
	switch (action.type) {
		case EntriesActionType.loading:
			return {
				...state,
				loading: true,
				error: undefined
			};
		case EntriesActionType.loadingEntriesStats:
			return {
				...state,
				entriesStatsLoading: true,
				error: undefined
			};
		// i know c'est affreux, un jour ma cutify
		case EntriesActionType.setEntries:
			const data = (action.payload.data as Entries);

			const currentTsos = [...state.data.tsos];
			const currentSubstitutions = [...state.data.substitutions];
			const currentTestimonials = [...state.data.testimonials];
			const currentNews = [...state.data.news];
			const currentEvents = [...state.data.events];

			if (data) {		
						
				// only add new ids

				const currentTsosIds = currentTsos?.map(tso => tso.id) || [];
				const currentSubstitutionsIds = currentSubstitutions?.map(substitution => substitution.id) || [];
				const currentTestimonialsIds = currentTestimonials?.map(testimonial => testimonial.id) || [];
				const currentNewsIds = currentNews?.map(singleNews => singleNews.id) || [];
				const currentEventsIds = currentEvents?.map(event => event.id) || [];

				data.tsos = data.tsos?.filter(tso => {
					const foundIndex = currentTsosIds.indexOf(tso.id);
					const foundEntry = foundIndex > -1;
					if (foundEntry && !action.payload.skipReplace) {
						currentTsos[foundIndex] = {...currentTsos[foundIndex], ...tso };				
					}
					return !foundEntry;
				}) || [];

				data.substitutions = data.substitutions?.filter(substitution => {
					const foundIndex = currentSubstitutionsIds.indexOf(substitution.id);
					const foundEntry = foundIndex > -1;
					if (foundEntry && !action.payload.skipReplace) {
						currentSubstitutions[foundIndex] = {...currentSubstitutions[foundIndex], ...substitution };
					}
					return !foundEntry;
				}) || [];

				data.testimonials = data.testimonials?.filter(testimonial => {
					const foundIndex = currentTestimonialsIds.indexOf(testimonial.id);
					const foundEntry = foundIndex > -1;
					if (foundEntry && !action.payload.skipReplace) {
						currentTestimonials[foundIndex] = {...currentTestimonials[foundIndex], ...testimonial };
					}
					return !foundEntry;
				}) || [];

				data.news = data.news?.filter(singleNews => {
					const foundIndex = currentNewsIds.indexOf(singleNews.id);
					const foundEntry = foundIndex > -1;
					if (foundEntry && !action.payload.skipReplace) {
						currentNews[foundIndex] = {...currentNews[foundIndex], ...singleNews };
					}
					return !foundEntry;
				}) || [];

				data.events = data.events?.filter(event => {
					const foundIndex = currentEventsIds.indexOf(event.id);
					const foundEntry = foundIndex > -1;
					if (foundEntry && !action.payload.skipReplace) {
						currentEvents[foundIndex] = {...currentEvents[foundIndex], ...event };
					}
					return !foundEntry;
				}) || [];

				// create moment objects for dates

				data.tsos = data.tsos.map(tso => ({	...tso,
					created_at_moment: moment(tso.created_at),
					date_submitted_for_moment: moment(tso.date_submitted_for)
				}));
				data.substitutions = data.substitutions.map(substitution => ({ ...substitution,
					created_at_moment: moment(substitution.created_at),
					date_submitted_for_moment: moment(substitution.date_submitted_for)
				}));
				data.testimonials = data.testimonials.map(testimonial => ({ ...testimonial,
					created_at_moment: moment(testimonial.created_at)
				}));
				data.news = data.news.map(single => ({ ...single,
					created_at_moment: moment(single.created_at)
				}));
				data.events = data.events.map(event => ({ ...event,
					start_date_moment: moment(event.start_date),
					end_date_moment: moment(event.end_date)
				}));
			}
			
			// sort all entries by date (needed for when new entries gets added to the store)			

			data.tsos = [...currentTsos, ...(data.tsos || [])];
			data.tsos.sort((a, b) => b.created_at_moment.unix() - a.created_at_moment.unix());

			data.substitutions = [...currentSubstitutions, ...(data.substitutions || [])];
			data.substitutions.sort((a, b) => b.created_at_moment.unix() - a.created_at_moment.unix());

			data.testimonials = [...currentTestimonials, ...(data.testimonials || [])];
			data.testimonials.sort((a, b) => b.created_at_moment.unix() - a.created_at_moment.unix());

			data.news = [...currentNews, ...(data.news || [])];
			data.news.sort((a, b) => b.created_at_moment.unix() - a.created_at_moment.unix());

			data.events = [...currentEvents, ...(data.events || [])];
			data.events.sort((a, b) => b.start_date_moment.unix() - a.start_date_moment.unix());
			
			return {
				...state,
				loading: false,
				data: {...state.data, ...data,
					tsos: data.tsos,
					substitutions: data.substitutions,
					testimonials: data.testimonials,
					news: data.news,
					events: data.events
				},
				error: undefined
			};
		case EntriesActionType.deleteEntry:

			const currentEntries = {...state.data} as Entries;
			const currentTypeEntries = currentEntries[action.payload.type as EntryTypes];
			const deletedIndex = currentTypeEntries.findIndex((entry:any) => entry.id === action.payload.id);
			if (deletedIndex > -1) {
				currentTypeEntries.splice(deletedIndex, 1);
			}

			return {
				...state,
				data: currentEntries
			};

		case EntriesActionType.stopLoading:
			return {
				...state,
				loading: false
			};
		case EntriesActionType.setEntriesStats:
			return {
				...state,
				entriesStatsLoading: false,
				data: {...state.data, ...(action.payload.data as EntriesStats) }
			};

		case EntriesActionType.clearEntriesStats:
			return {
				...state,
				data: {...state.data, 
					compared_tsos_count: undefined,
					compared_tsos_average_hours: undefined,
					compared_substitutions_count: undefined,
					compared_substitutions_average_hours: undefined,
					compared_testimonials_count: undefined
				}
			};
		case EntriesActionType.entriesStatsFailure:
			return {
				...state,
				entriesStatsLoading: false,
				error: action.error as LocaleString
			};
		case EntriesActionType.dataFailure:
			return {
				...state,
				loading: false,
				loadingEntriesStats: false,
				error: action.error as LocaleString
			};
		case EntriesActionType.clearEntries:
			return {
				...state,
				data: {
					events: [],
					news: [],
					tsos: [],
					substitutions: [],
					testimonials: []
				}
			};	
		case EntriesActionType.updateEntriesStatus:
			const entries = {...state.data} as Entries;

			const entryType = (action.payload.type as 'tsos' | 'substitutions' | 'testimonials' | 'news');
			const typeEntries = entries[entryType];
			action.payload.ids.forEach( (id: number) => {
				const entryIndex:any = typeEntries.findIndex((entry:any) => entry.id === id);
				typeEntries[entryIndex].status = action.payload.status;
			});

			return {
				...state,
				data: entries
			};
		default:
			return state;
	}
};
export default data;
