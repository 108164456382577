import React from 'react';
import { IonContent } from '@ionic/react';

import './MobileContent.scss';

interface MobileContentProps {
    noFooterBorder?: boolean;
    renderFooterContent?(): React.ReactNode;
}

const MobileContent:React.FC<MobileContentProps> = ({ noFooterBorder, renderFooterContent, children }) => {

    return (
        <IonContent>
            <div className="mobile-content">
                <div className="mobile-content-inner">
                    { children }
                </div>
                { renderFooterContent ?
                    <div className={'mobile-content-footer' + (noFooterBorder ? ' no-border' : '' )}>
                        { renderFooterContent() }
                    </div>
                : null }
            </div>           
        </IonContent>
    );
}

export default MobileContent;