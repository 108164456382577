import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import useLocalizedContent from "./useLocalizedContent";

type Hook = () => { loading: boolean, label: string }

const useLoginLoading:Hook = () => {
    const dataLoading = useSelector((state:RootState) => state.entries.loading);
    const loading = useSelector((state:RootState) => state.auth.loading) && !dataLoading;
    const militantLoggingIn = useSelector((state:RootState) => state.auth.militantLoggingIn);
    const thirdPartyLogginIn = useSelector((state:RootState) => state.auth.thirdPartyLogginIn);
    const localizedContent = useLocalizedContent();
    const label = loading ? (
        militantLoggingIn ?
            localizedContent.waitingMilitantLogin
        : thirdPartyLogginIn ?
            localizedContent.waitingFor + ' ' + thirdPartyLogginIn.charAt(0).toUpperCase() + thirdPartyLogginIn.slice(1) + '...'
        :
            undefined
    ) : undefined;

    return { loading, label };
}

export default useLoginLoading;

