import { IonPage, useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import { updateStatusBar } from '../../../lib/status-bar';
import LoginAdminForm from '../../../components/login/LoginAdminForm';
import MobileContent from '../../../components/mobile/MobileContent';

const MobileLoginAdmin: React.FC = () => {	

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));	
	
	return (
		<IonPage>
			<MobileContent>
				<LoginAdminForm />
			</MobileContent>
		</IonPage>
	);
};

export default MobileLoginAdmin;