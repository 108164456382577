import { Moment } from "moment";
import { Locale } from "../models";

export const openPopupCenterWindow = (url:string, title:string, win:Window, w:number, h:number):Window => {
    const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
    const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
    return win.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x);
}

export const parseLongDate = (date: Moment, locale: Locale) => {
    return date.locale(locale).format(locale === 'fr' ? 'LL' : 'MMMM Do, YYYY');
}