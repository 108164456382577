import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import { updateStatusBar } from '../../../lib/status-bar';
import MobileHeader from '../../../components/mobile/MobileHeader';
import useLocalizedContent from '../../../hooks/useLocalizedContent';

import './MobilePostEditor.scss';
import MobileUnsupportedSection from '../../../components/mobile/MobileUnsupportedSection';

interface MobilePostEditorProps {
    type: 'news' | 'events';
    create?: boolean;
}

const MobilePostEditor: React.FC<MobilePostEditorProps> = ({ type, create }) => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('blue'));

    const localizedContent = useLocalizedContent();

    const pageTitle = ( create ? localizedContent.create : localizedContent.edit ) + ' ' +
        ( type === 'news' ?
            localizedContent[create ? 'aNegociation' : 'theNegociation']
        :
            localizedContent[create ? 'anEvent' : 'theEvent']
        )
    ;

	return (
		<IonPage className="mobile-post-editor" data-page="post-editor">
            <MobileHeader blue title={ pageTitle } />
			<IonContent>
                <MobileUnsupportedSection />
     		</IonContent>
		</IonPage>
	);
};

export default MobilePostEditor;