import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/reducers';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { TextField } from '@material-ui/core';
import DateSelector from '../../../components/stats/DateSelector';
import moment, { Moment } from 'moment';
import FileUpload from '../../../components/FileUpload';
import { Locale, News, Event, Post } from '../../../models';
import { useHistory } from 'react-router';
import FiqButton from '../../../components/buttons/FiqButton';
import { createNews, createEvent, editNews, editEvent, deleteNews, deleteEvent, clearCmsSuccess } from '../../../store/post-actions';
import LoadingOverlay from '../../../components/LoadingOverlay';
import usePrevious from '../../../hooks/usePrevious';
import { Toast } from '@capacitor/core';

import './DesktopPostEditor.scss';
import { eye, create as createIcon, trash, arrowBack } from 'ionicons/icons';
import useRouteIdParam from '../../../hooks/useRouteIdParam';
import SimpleModalController from '../../../components/modals/SimpleModalController';
import OpenedEvent from '../../../components/modals/side-modals/OpenedEvent';
import OpenedSingleNews from '../../../components/modals/side-modals/OpenedSingleNews';
import LocationAutoComplete from '../../../components/LocationAutocomplete';
import ToastError from '../../../components/ToastError';
import useServerNow from '../../../hooks/useServerNow';

interface DesktopPostEditorProps {
    type: 'news' | 'events';
    create?: boolean;
}

const DesktopPostEditor: React.FC<DesktopPostEditorProps> = ({ type, create }) => {

    const user = useSelector((state:RootState) => state.auth.user);
    const localizedContent = useLocalizedContent();

    const pageTitle = ( create ? localizedContent.create : localizedContent.edit ) + ' ' +
        ( type === 'news' ?
            localizedContent[create ? 'aNegociation' : 'theNegociation']
        :
            localizedContent[create ? 'anEvent' : 'theEvent']
        )
    ;

    const canEdit = user?.is_admin || false;
    const history = useHistory();
    const dispatch = useDispatch();
    
	const entries = useSelector((state:RootState) => state.entries.data);
    const entryId = useRouteIdParam();

    const post = useSelector((state:RootState) => state.post);
    const postLoading = post.cmsLoading;
    const postError = post.cmsError;
    const postSuccessId = post.cmsSuccessPostId;

    const entry = (entries[type] as Post[]).find((entry:Post) => entry.id === entryId);
    const entryNews = entry as News;
    const entryEvent = entry as Event;

    const [action, setAction] = useState<'add' | 'edit' | 'delete'>(null);

    const postIsNowSuccess = !usePrevious(postSuccessId) && postSuccessId;

    // !create -> get from redux

    // common fields
    const [title_fr, setTitleFR] = useState(create ? '' : entry?.title['fr'] || '');
    const [title_en, setTitleEN] = useState(create ? '' : entry?.title['en'] || '');
    const [content_fr, setContentFR] = useState(create ? '' : entry?.content['fr'] || '');
    const [content_en, setContentEN] = useState(create ? '' : entry?.content['en'] || '');

    const [image_fr, setImageUrlFR] = useState(create ? null : entry?.images?.medium['fr'] || null);
    const [image_en, setImageUrlEN] = useState(create ? null : entry?.images?.medium['en'] || null);
    const [imageFileFR, setImageFileFR] = useState(null);
    const [imageFileEN, setImageFileEN] = useState(null);

    // news-specific
    const [sub_title_fr, setSubtitleFR] = useState(create ? '' : entryNews?.sub_title?.fr || '');
    const [sub_title_en, setSubtitleEN] = useState(create ? '' : entryNews?.sub_title?.en || '');

    // event-specific
    const [location, setLocation] = useState(create ? '' : entryEvent?.location  || '');
    const [start_at, setStartDate] = useState(create ? null : entryEvent?.start_date_moment || null);
    const [end_at, setEndDate] = useState(create ? null : entryEvent?.end_date_moment || null);

    const validPost = title_fr.length > 0 && title_en.length > 0 && content_fr.length > 0 && content_en.length > 0;
    const validNews = sub_title_fr?.length > 0 && sub_title_en?.length > 0;
    const validEvent = location?.length > 0 && !!start_at && !!end_at;

    const commonChanged =
        title_fr !== entry?.title['fr'] ||
        title_en !== entry?.title['en'] ||
        content_fr !== entry?.content['fr'] ||
        content_en !== entry?.content['en'] ||
        !!imageFileFR ||
        !!imageFileEN
    ;
    const newsChanged = type === 'news' && (
        sub_title_fr !== entryNews?.sub_title?.fr ||
        sub_title_en !== entryNews?.sub_title?.en
    );
    const eventChanged = type === 'events' && (
        location !== entryEvent?.location ||
        !start_at?.isSame(entryEvent?.start_date_moment, 'minute') || 
        !end_at?.isSame(entryEvent?.end_date_moment, 'minute')
    );

    const validEdit = commonChanged || newsChanged || eventChanged;
    const validPreview = validPost && (type === 'news' ? validNews : validEvent);
    const validForm = validPreview && (create || validEdit);    

    const postEntry = () => {
        if (validForm) {

            let payload:any = { title_fr, title_en, content_fr, content_en, image_fr, image_en };

            if (type === 'news') {
                payload = {...payload, ...{ sub_title_fr, sub_title_en }};
            } else {
                payload = {...payload, ...{ location, start_at: start_at.format('YYYY-MM-DD HH:mm:ss'), end_at: end_at.format('YYYY-MM-DD HH:mm:ss') }};
            }

            if (create) {
                setAction('add');
                if (type === 'news') {
                    dispatch(createNews(payload));
                } else {
                    dispatch(createEvent(payload));
                }
            } else {
                setAction('edit');
                payload.id = entryId;

                payload.title_fr = entry.title.fr !== payload.title_fr ? payload.title_fr : undefined;
                payload.title_en = entry.title.en !== payload.title_en ? payload.title_en : undefined;
                payload.content_fr = entry.content.fr !== payload.content_fr ? payload.content_fr : undefined;
                payload.content_en = entry.content.en !== payload.content_en ? payload.content_en : undefined;
                payload.image_fr = imageFileFR ? payload.image_fr : undefined;
                payload.image_en = imageFileEN ? payload.image_en : undefined;

                if (type === 'news') {
                    payload.sub_title_fr = entryNews.sub_title.fr !== payload.sub_title_fr ? payload.sub_title_fr : undefined;
                    payload.sub_title_en = entryNews.sub_title.en !== payload.sub_title_en ? payload.sub_title_en : undefined;
                    dispatch(editNews(payload));
                } else {
                    payload.location = entryEvent.location !== payload.location ? payload.location : undefined;
                    payload.start_at = !entryEvent.start_date_moment.isSame(payload.start_at, 'minute') ? payload.start_at : undefined;
                    payload.end_at = !entryEvent.end_date_moment.isSame(payload.end_at, 'minute') ? payload.end_at : undefined;
                    dispatch(editEvent(payload));
                }
            }            
        }
    };

    const deleteEntry = () => {
        const confirmed = window.confirm(localizedContent.doYouReallyWantTo + ' ' + localizedContent.delete.toLowerCase() + ' ' + localizedContent[type === 'news' ? 'theNegociation' : 'theEvent'] + '?');

        if (confirmed) {
            setAction('delete');
            if (type === 'news') {
                dispatch(deleteNews(entryId));
            } else {
                dispatch(deleteEvent(entryId));
            }
        }
    }

    // Actions on post success

    useEffect( () => {
        if (postIsNowSuccess) {
            if (action === 'add') {
                history.push('/dashboard/' + (type === 'news' ? 'single-news' : 'event') + '/edit/' + postSuccessId);
            }
            Toast.show({
                text:
                    action === 'add' ? 
                        ( type === 'news' ? localizedContent.createNewsSuccess : localizedContent.createEventSuccess)
                    : action === 'edit' ?
                        ( type === 'news' ? localizedContent.editNewsSuccess : localizedContent.editEventSuccess)
                    :
                        ( type === 'news' ? localizedContent.deleteNewsSuccess : localizedContent.deleteEventSuccess)
            });
            dispatch(clearCmsSuccess());
        }
    }, [postIsNowSuccess, postSuccessId, action, history, localizedContent, dispatch, type]);

    // Auto-redirect on current entry deleted

    useEffect( () => {
        if (!create && !entry) {
            history.replace('/dashboard/' + type, { direction: 'back' });
        }
    }, [create, entry, history, type]);

    const handleSetDateFrom = (date: Moment) => {
        setStartDate(date);

        if (date.isSameOrAfter(end_at)) {
            setEndDate(moment(date).add(1, 'day'));
        }
    }

    const handleSetDateTo = (date: Moment) => {
        setEndDate(date);

        if (date.isSameOrBefore(start_at)) {
            setStartDate(moment(date).subtract(1, 'day'));
        }
    }

    const handleImageUpdate = (locale: Locale, file: File) => {

		if (file) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
                const base64url = reader.result.toString();
                if (locale === 'fr') {
                    setImageUrlFR(base64url);
                } else {
                    setImageUrlEN(base64url);
                }
			});
			reader.readAsDataURL(file);
		} else {
			if (locale === 'fr') {
                setImageUrlFR(create ? null : entry.images?.medium['fr'] || null);
            } else {
                setImageUrlEN(create ? null : entry.images?.medium['en'] || null);
            }
		}

        if (locale === 'fr') {
            setImageFileFR(file);
        } else {
            setImageFileEN(file);
        }
    }

    const handleLocationChange = useCallback( value => setLocation(value), [] );

    // Preview

    const now = useServerNow();
    const [openedPreviewLocale, setOpenedPreviewLocale] = useState<Locale>(null);
    const preview:Post = {
        id: null,
        title: { fr: title_fr, en: title_en },
        content: { fr: content_fr, en: content_en },
        images: { large: { fr: image_fr, en: image_en }, thumb: { fr: null, en: null }, medium: { fr: null, en: null }}
    };
    const previewSingleNews:News = {...preview, ...{
        created_at: now.format('YYYY-MM-DD'),
        created_at_moment: now,
        sub_title: { fr: sub_title_fr, en: sub_title_en },
        status: 'read'
    }};
    const previewEvent:Event = {...preview, ...{
        start_date: start_at?.format('YYYY-MM-DD'),
        start_date_moment: start_at,
        end_date: end_at?.format('YYYY-MM-DD'),
        end_date_moment: end_at,
        location
    }};

    const handlePreviewOpen = (locale: Locale) => {
        setOpenedPreviewLocale(locale);
    }

    const handlePreviewClose = () => {
        setOpenedPreviewLocale(null);
    }

	return (
       <div className={'desktop-post-editor type-' + type + ' action-' + ( create ? 'create' : 'edit' ) }>
           { canEdit ? 
                <>
                    <h1>{ pageTitle }</h1>
                    <div className="form-container">
                        <form autoComplete="new-password" onSubmit={ e => { e.preventDefault(); postEntry() }}>
                            <div className="form-inner">
                                <div className="texts-container">
                                    <h2>{ localizedContent.content }</h2>
                                    <div className="input-text-container">
                                        <TextField
                                            label={ localizedContent.title + ' (' + localizedContent.french + ')' }
                                            value={ title_fr }
                                            onChange={e => setTitleFR(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="input-text-container">
                                        <TextField
                                            label={ localizedContent.title + ' (' + localizedContent.english + ')' }
                                            value={ title_en }
                                            onChange={e => setTitleEN(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    { type === 'news' ? 
                                        <>
                                            <div className="input-text-container">
                                                <TextField
                                                    label={ localizedContent.subtitle + ' (' + localizedContent.french + ')' }
                                                    value={ sub_title_fr }
                                                    onChange={e => setSubtitleFR(e.target.value)}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="input-text-container">
                                                <TextField
                                                    label={ localizedContent.subtitle + ' (' + localizedContent.english + ')' }
                                                    value={ sub_title_en }
                                                    onChange={e => setSubtitleEN(e.target.value)}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div className="input-text-container">
                                                <LocationAutoComplete
                                                    label={ localizedContent.eventLocation }
                                                    defaultValue={ location }
                                                    onChange={ handleLocationChange }
                                                />
                                            </div>
                                            <div className="input-date-container">
                                                <DateSelector
                                                    time
                                                    label={ localizedContent.dateStart }
                                                    value={ start_at }
                                                    onChange={ handleSetDateFrom }
                                                />
                                            </div>
                                            <div className="input-date-container">
                                                <DateSelector
                                                    time
                                                    label={ localizedContent.dateEnd }
                                                    value={ end_at }
                                                    onChange={ handleSetDateTo }
                                                />
                                            </div>
                                        </>
                                    }
                                    <div className="input-textarea-container">
                                        <TextField
                                            label={ localizedContent.content + ' (' + localizedContent.french + ')' }
                                            value={ content_fr }
                                            onChange={e => setContentFR(e.target.value)}
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                        />
                                    </div>
                                    <div className="input-textarea-container">
                                        <TextField
                                            label={ localizedContent.content + ' (' + localizedContent.english + ')' }
                                            value={ content_en }
                                            onChange={e => setContentEN(e.target.value)}
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <div className="images-container">
                                    <h2>{ localizedContent.image + 's' }</h2>
                                    <div className="image-container">
                                        <div className="image-label">{ localizedContent.image + ' (' + localizedContent.french + ')' }</div>
                                        <div className="entry-image" style={ image_fr ? { backgroundImage: 'url(' + image_fr + ')'} : null } />
                                        <FileUpload file={ imageFileFR } onUpdate={ file => handleImageUpdate('fr', file) } />
                                    </div>
                                    <div className="image-container">
                                        <div className="image-label">{ localizedContent.image + ' (' + localizedContent.english + ')' }</div>
                                        <div className="entry-image" style={ image_en ? { backgroundImage: 'url(' + image_en + ')'} : null } />
                                        <FileUpload file={ imageFileEN } onUpdate={ file => handleImageUpdate('en', file) } />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-container">
                                { !create ?
                                    <FiqButton color="white" fill="outline" href={'/dashboard/' + type + '/' + entryId} icon={ arrowBack } ionIcon key="back">
                                        { localizedContent.list }
                                    </FiqButton>
                                : null }
                                <FiqButton color="white" fill="outline" onClick={ e => handlePreviewOpen('fr') } disabled={ !validPreview } icon={ eye } ionIcon key="preview-fr">
                                    { localizedContent.preview + ' FR' }
                                </FiqButton>
                                <FiqButton color="white" fill="outline" onClick={ e => handlePreviewOpen('en') } disabled={ !validPreview } icon={ eye } ionIcon key="preview-en">
                                    { localizedContent.preview + ' EN' }
                                </FiqButton>
                                <FiqButton icon={ createIcon } ionIcon key="post" disabled={ !validForm } onClick={ e => postEntry() }>
                                    { create ? localizedContent.publish : localizedContent.edit }
                                </FiqButton>                                
                                { !create ?
                                    <FiqButton color="danger" icon={ trash } ionIcon key="delete" onClick={ e => deleteEntry() }>
                                        { localizedContent.delete}
                                    </FiqButton>
                                : null }
                            </div>
                            <input type="submit" value="submit" style={{ display: 'none' }} />
                        </form>
                    </div>
                </>
           : <h1>{'You dont have the permissions to view this page'}</h1> }
           <SimpleModalController position="right" opened={ !!openedPreviewLocale } onClose={ handlePreviewClose }>
                { type === 'news' ?
                    <OpenedSingleNews singleNews={ previewSingleNews } locale={openedPreviewLocale} preview onClose={ handlePreviewClose } />
                :
                    <OpenedEvent event={ previewEvent } locale={openedPreviewLocale} preview onClose={ handlePreviewClose } />
                }                
            </SimpleModalController>
           <LoadingOverlay loading={ postLoading } />
           <ToastError error={ postError } />
		</div>
	);
};

export default DesktopPostEditor;