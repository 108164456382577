import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import DesktopHome from './dashboard/DesktopHome';
import DesktopMap from './dashboard/DesktopMap';
import DesktopNewReport from './dashboard/DesktopNewReport';
import DesktopAbleToWork from './dashboard/DesktopAbleToWork';
import DesktopNews from './dashboard/DesktopNews';
import DesktopPostEditor from './dashboard/DesktopPostEditor';
import DesktopTestimonials from './dashboard/DesktopTestimonials';
import DesktopSettings from './dashboard/DesktopSettings';
import DesktopEvents from './dashboard/DesktopEvents';
import DesktopActivateNotifications from './dashboard/DesktopActivateNotifications';
import DashboardSideMenu from '../../components/desktop/DashboardSideMenu';

import './DesktopDashboardPages.scss';

const DesktopDashboardPages:React.FC = () => {
    const location = useLocation();

    // don't animate pages when we update certain nested routes
    const pathname = location.pathname;
    const transitionKey =
        pathname.startsWith('/dashboard/news') ?
            'news'
        : pathname.startsWith('/dashboard/events') ?
            'events'
        : pathname.startsWith('/dashboard/testimonials') ?
            'testimonials'
        : (pathname.startsWith('/dashboard/map') || pathname.startsWith('/dashboard/tso-details') || pathname.startsWith('/dashboard/substitution-details') ) ?
            'map'
        :
            location.key;

    const hasSeenNotifications = useSelector((state:RootState) => state.settings.tutorialsRead.indexOf('notifications') > -1);
    const notificationsUnsupported = useSelector((state: RootState) => state.settings.notificationsUnsupported);

    const dashboardUrl = '/dashboard';
    
    return (
        <div className={'desktop-dashboard-pages' + (!hasSeenNotifications && !notificationsUnsupported ? ' notifications' : '')}>
            <div className="desktop-dashboard-side-menu">
                <DashboardSideMenu />
            </div>
            <div className="desktop-dashboard-content">
                <TransitionGroup component={ null }>
                    <CSSTransition key={ transitionKey } timeout={400}>
                        <div className="desktop-dashboard-page">
                            <Switch location={location}>
                                <Route path={ dashboardUrl } exact component={ DesktopHome } />
                                <Route path={ dashboardUrl + '/map' } exact component={ DesktopMap } />
                                <Route path={ dashboardUrl + '/tso-details/:id' } exact render={ props =>  <DesktopMap type="tso" /> } />
                                <Route path={ dashboardUrl + '/substitution-details/:id' } exact render={ props =>  <DesktopMap type="substitution" /> } />
                                <Route path={ dashboardUrl + '/report/:type' } component={ DesktopNewReport } />
                                <Route path={ dashboardUrl + '/able-to-work' } component={ DesktopAbleToWork } />
                                <Route path={[ dashboardUrl + '/news', dashboardUrl + '/news/:id' ]} exact component={ DesktopNews } />
                                <Route path={ dashboardUrl + '/single-news/create' } render={ props => <DesktopPostEditor type="news" create /> } />
                                <Route path={ dashboardUrl + '/single-news/edit/:id' } render={ props => <DesktopPostEditor type="news" /> } />
                                <Route path={[ dashboardUrl + '/testimonials', dashboardUrl + '/testimonials/:id' ]} exact component={ DesktopTestimonials } />
                                <Route path={[ dashboardUrl + '/events', dashboardUrl + '/events/:id' ]} exact component={ DesktopEvents } />
                                <Route path={ dashboardUrl + '/event/create' } render={ props => <DesktopPostEditor type="events" create /> } />
                                <Route path={ dashboardUrl + '/event/edit/:id' } render={ props => <DesktopPostEditor type="events" /> } />
                                <Route path={ dashboardUrl + '/settings' } component={ DesktopSettings } />
                                <Route path={ dashboardUrl + '/notifications' } component={ DesktopActivateNotifications } />
                            </Switch>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </div>
    );
};

export default DesktopDashboardPages;