import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { chevronDown } from 'ionicons/icons';
import CountTitle from './CountTitle';
import gsap, { Quad } from 'gsap';
import { IonIcon } from '@ionic/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import useLocalizedContent from '../../hooks/useLocalizedContent';

import './StatsCard.scss';

interface Action {
    label: string;
    href: string;
}

interface StatsCardProps {
    title: string;
    count: number;    
    comparedCount: number;
    averageHours?:number;
    comparedAverageHours?:number;
    comparedPeriod: 'week' | 'month' | 'custom';
    action?: Action;
}

const StatsCard: React.FC<StatsCardProps> = ({ title, count, comparedCount = 1, averageHours, comparedAverageHours = 0, comparedPeriod, children, action }) => {
    
    const hasComparedStats = useSelector((state:RootState) => state.settings.pageFilters.dashboard.range_type !== 2) && comparedCount;
    const isComparedStatsLoading = useSelector((state:RootState) => state.entries.entriesStatsLoading);
    const localizedContent = useLocalizedContent();
    const cardContent = useRef(null);
    const countDeltaPercent = Math.round((count - comparedCount) * 100 / comparedCount) || 0;
    const averageHoursDelta = parseFloat((averageHours - comparedAverageHours).toFixed(2)) || 0;
    const comparedPeriodLabel = localizedContent.since + ' ' + (comparedPeriod === 'week' ? localizedContent.lastWeek : comparedPeriod === 'month' ? localizedContent.lastMonth : '');
    const foldableCard = !!children;

    const [foldOpened, setFoldOpened] = useState(foldableCard);

    const handleFoldButtonClick = () => {
        const newFoldOpened = !foldOpened;
        setFoldOpened(newFoldOpened);

        const animParams = { duration: 0.25, ease: Quad.easeInOut };
        if (newFoldOpened) {
            gsap.fromTo(cardContent.current, { height: 0 }, { ...animParams, height: 'auto' });
        } else {
            gsap.to(cardContent.current, { ...animParams, height: 0 });
        }
    }

	return (
        <div className={'stats-card' + (foldOpened ? ' fold-opened' : '') + (!averageHours ? ' no-average-hours' : '') + (hasComparedStats ? ' compared' + (isComparedStatsLoading ? ' compared-loading' : '') : '')}>
            <div className="stats-card-header">
                <CountTitle count={count} title={title} />
                <div className={'card-header-compared ' + (countDeltaPercent > 0 ? 'up' : 'down')}>
                    { !!countDeltaPercent ?
                        <img src="/assets/icon/arrow-bottom.svg" alt="icon-arrow" width="19" height="23" />
                    : null }
                    <span className="compared-label">{ (countDeltaPercent >= 0 ? '+ ' : '') + countDeltaPercent + '% ' + comparedPeriodLabel }</span>
                </div>
                { action ? 
                    <div className="card-header-action">
                        <Link to={action.href}>{ action.label }</Link>
                    </div>
                : null }
                { foldableCard && count ?
                    <button type="button" className="fold-card-button" onClick={ handleFoldButtonClick }>
                        <IonIcon icon={ chevronDown } />
                    </button>
                : null }
            </div>
            { foldableCard && count ? 
                <div className="stats-card-content" ref={ cardContent }>
                    <div className="stats-card-content-inner">
                        <div className="stats-card-hours">
                            <div className="hours-total-label">{ localizedContent.numberTotalAverageHours }</div>
                            <div className="hours-total-count">{ averageHours }h</div>
                            <img className={'hours-arrow' + (averageHoursDelta < 0 ? ' down' : !averageHoursDelta ? ' hidden' : '' )} src="/assets/bg/arrow-hours.svg" width="60" alt="arrow" />
                            <div className="hours-difference">{ (averageHoursDelta > 0 ? '+' : '') + averageHoursDelta + 'h ' + comparedPeriodLabel }</div>
                        </div>
                        <div className="stats-card-children">
                            { children }
                        </div>
                    </div>
                </div>
            : null }
            
        </div>
	);
};

export default StatsCard;