import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonIcon } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { getApplicationDataAndValidate } from '../store/app-actions';
import { refresh } from 'ionicons/icons';

import './AppLoading.scss';
import useLocale from '../hooks/useLocale';
import useLocalizedContent from '../hooks/useLocalizedContent';

const AppLoading: React.FC = () => {

    const locale = useLocale();
    const localizedContent = useLocalizedContent();

    const appError = useSelector((state:RootState) => state.app.error);
    const dispatch = useDispatch();
    
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const handleRetryClick = () => {
		dispatch(getApplicationDataAndValidate());
    }

    useEffect ( () => {
        const setOffline = () => setIsOnline(false);
        const setOnline = () => {
            dispatch(getApplicationDataAndValidate());
            setIsOnline(true);
        };

        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);

        return () => {
            window.removeEventListener('offline', setOffline);
            window.removeEventListener('online', setOnline);
        };
    }, [dispatch]);	
	
	return (
		<IonPage className="app-loading">
			<IonContent>
                <div className="app-loading-content">
                    { !appError ?
                        <div key="loading" className="loading-container">
                            <img className="logo" src="/assets/logo/logo.svg" alt="fiq-logo" />
                            <div className="loading" />
                        </div>
                    : 
                        <div key="error" className="error-container">
                            <img className="offline-icon" src="/assets/icon/offline.svg" alt="offline-icon" />
                            <h1>{ isOnline ? appError[locale] : localizedContent.errorTryAgainLater}</h1>
                            <p>{ isOnline ? localizedContent.errorTryAgainLater : localizedContent.errorOffline }</p>
                            <button type="button" onClick={ handleRetryClick }>
                                <IonIcon icon={ refresh } />
                                <span className="label">{ localizedContent.errorTapToRetry }</span>                            
                            </button>			
                        </div>
                    }
                </div>
			</IonContent>
		</IonPage>
	);
};

export default AppLoading;