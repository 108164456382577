import { useState, useEffect } from 'react';
import useWindowSize from './useWindowSize';

type Hook = () => boolean

const useIsMobile:Hook = () => {

    const windowSize = useWindowSize();

    function getIsMobile(width:number) {
        return width < 1025;
    }

    const [isMobile, setIsMobile] = useState<boolean>(getIsMobile(windowSize.width));

	useEffect(() => {
        setIsMobile(getIsMobile(windowSize.width));
    }, [windowSize]);
    
    return isMobile;
}

export default useIsMobile;