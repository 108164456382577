import React from 'react';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { useDispatch } from 'react-redux';
import { loginMilitant, loginMilitantSkip } from '../../store/auth-actions';

import './MilitantActions.scss';
import FiqButton from '../buttons/FiqButton';

const MilitantActions: React.FC = () => {	

	const localizedContent = useLocalizedContent();
	const dispatch = useDispatch();

	function handleMilitantClick() {
		dispatch(loginMilitant());
	}

	function handleNotMilitantClick() {
		dispatch(loginMilitantSkip());
	}

	return (
		<div className="militant-actions">
			<FiqButton expand="block" onClick={ e => handleMilitantClick() }>
				{ localizedContent.accessMilitantDashboard }
			</FiqButton>
			<FiqButton expand="block" color="white" fill="outline" onClick={ e => handleNotMilitantClick() }>
				{ localizedContent.iAmNotMilitant }
			</FiqButton>
		</div>
	);
};

export default MilitantActions;