import React, { useState, ReactNode, useRef } from 'react';
import { IonCheckbox } from '@ionic/react';
import { close } from 'ionicons/icons';
import { Moment } from 'moment';

import SortButton from '../buttons/SortButton';
import useElementSize from '../../hooks/useElementSize';
import FiqButton from '../buttons/FiqButton';

import './SortableTable.scss';
import { useEffect } from 'react';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import useLocale from '../../hooks/useLocale';

export interface SortableTableColumn {
    type?: string;// 'read-status' | 'select' | 'sort'
    sortKey?: string;
    label?: ReactNode;
    renderTitle?(entry:any): string;
    renderContent?(entry:any): ReactNode;
}

interface Sorting {
    sort: string;
    ascending: boolean;
}

interface SelectOptions {
    entryName: string;
    actionName: string;
    actionIcon: string;
    actionIonIcon?: boolean;
    onAction(selectedEntries: any[]): void;
}

interface SortableTableProps {
    columns: SortableTableColumn[];
    entries: { id: number, read?: boolean, date?: Moment }[];
    defaultSorting?: Sorting;
    onEntryClick?(entry: any): void;
    selectOptions?: SelectOptions;
}

const SortableTable: React.FC<SortableTableProps> = ({ columns, entries, onEntryClick, selectOptions, defaultSorting = { sort: 'date', ascending: false } }) => {

    const el = useRef(null);
    const elSize = useElementSize(el);
    const locale = useLocale();
    const localizedContent = useLocalizedContent();
    const hasReadStatus = !!columns.find(column => column.type === 'read-status');

    // Handles entries sorting -----------------------------------------------------

    const sortEntries = (sorting:any):any[] => {
        const newEntries = [...entries];
        const sort = sorting.sort;
        newEntries.sort((a:any, b:any) => {
            let result;
            if (sort === 'date' && a.date) {
                result = a.date.unix() - b.date.unix();
                if (!sorting.ascending) {
                    result *= -1;
                }
            } else {
                switch (typeof a[sort]) {
                    case 'string':
                        result = a[sort].localeCompare(b[sort]);
                    break;
                    case 'number':
                        result = a[sort] - b[sort];
                    break;
                    default:
                        result = 0;
                    break;
                }
                
                if (!sorting.ascending) {
                    result *= -1;
                }

                if (a.date && result === 0 && !sorting.ascending) {
                    result = b.date.unix() - a.date.unix();
                }
            }
            
            return result;
        });
    
        return newEntries;
    }

	const [activeSorting, setActiveSorting] = useState(defaultSorting);
    const sortedEntries = sortEntries(activeSorting);

	const handleChangeSorting = (sort: string) => {
		const newActiveSorting = {...activeSorting};

		if (sort === activeSorting.sort) {
			newActiveSorting.ascending = !newActiveSorting.ascending;
		} else {
			newActiveSorting.sort = sort;
			newActiveSorting.ascending = sort !== 'date';
		}

		setActiveSorting(newActiveSorting);
	};

	// Handles selectable entries -----------------------------------------------------

	const [selectedEntriesIds, setSelectedEntriesIds] = useState([]);
	const selectedEntriesCount = selectedEntriesIds.length;

	const handleSelectEntry = (entry:any) => {
		const foundIndex = selectedEntriesIds.indexOf(entry.id);
		const selected = foundIndex > -1;
		const newSelectedEntries = [...selectedEntriesIds];
		if (selected) {
			newSelectedEntries.splice(foundIndex, 1);
		} else {
			newSelectedEntries.push(entry.id);
		}
		setSelectedEntriesIds(newSelectedEntries);
	}

	const handleToggleSelectAllEntries = () => {
		const allSelected = selectedEntriesCount === entries.length;
		setSelectedEntriesIds(allSelected ? [] : entries.map(testimonial => testimonial.id));
	}

	const handleUnselectAllEntries = () => {
		setSelectedEntriesIds([]);
	}

	const handleSelectAction = () => {
        if (selectOptions?.onAction) {
            const selectedEntries = entries.filter(entry => selectedEntriesIds.indexOf(entry.id) > -1);
            selectOptions.onAction(selectedEntries);
        }
    }

    useEffect( () => {
        setSelectedEntriesIds([]);
    }, [entries]);
    
    // Render cell content -------------------------------------------------------------

    const renderHeaderCell = (column:SortableTableColumn):ReactNode => {
        let cellContent:ReactNode;
        switch(column.type) {
            case 'read-status':
                cellContent = <span className="unread-bullet" />;
            break;
            case 'select':
                cellContent = <IonCheckbox checked={selectedEntriesCount && selectedEntriesCount === entries.length} onClick={ e => handleToggleSelectAllEntries() } />;
            break;
            case 'sort':
                cellContent = <>
                    <span className="sortable-label">{ column.label }</span>
                    <SortButton active={activeSorting.sort === column.sortKey} ascending={activeSorting.ascending} onClick={ () => handleChangeSorting(column.sortKey) }/>
                </>;
            break;
            default:
                cellContent = column.label;
            break;
        }

        return cellContent;
    }

    const renderBodyCell = (column:SortableTableColumn, entry:any):ReactNode => {
        let cellContent:ReactNode;
        switch(column.type) {
            case 'read-status':
                cellContent = <span className="unread-bullet" />;
            break;
            case 'select':
                cellContent = <IonCheckbox checked={ selectedEntriesIds.indexOf(entry.id) > -1 } onClick={ e => { e.stopPropagation(); handleSelectEntry(entry) } } />
            break;
            default:
                cellContent = column.renderContent(entry);
            break;
        }

        return cellContent;
    };

	return (
        <div className="sortable-table" ref={ el }>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            { columns.map((column, columnI) => {
                                return (
                                    <th key={'header-cell' + columnI} className={ (column.type || 'normal') + (column.type === 'sort' ? ' ' + column.sortKey + ( column.sortKey === activeSorting.sort ? ' active' : '' ) : '') }>
                                        { renderHeaderCell(column) }
                                    </th>
                                );
                            }) }                        
                        </tr>
                    </thead>
                    <tbody>
                        { sortedEntries.map((entry:any, entryI) => 
                            <tr key={'entry' + entryI} className={'entry' + (hasReadStatus && !entry.read ? ' unread' : '') + (selectedEntriesIds.indexOf(entry.id) > -1 ? ' selected' : '' )} onClick={ e => {if (onEntryClick)onEntryClick(entry)} }>
                                { columns.map((column:SortableTableColumn, columnI:number) => {
                                    return (
                                        <td key={'body-cell' + columnI} title={ column.renderTitle ? column.renderTitle(entry) : null } className={ (column.type || 'normal') + (column.type === 'sort' ? ' ' + column.sortKey : '') }>
                                            { renderBodyCell(column, entry) }
                                        </td>
                                    );
                                }) }
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className={'selected-entries-toast' + ( selectedEntriesCount ? ' active' : '' ) } style={{ right: elSize?.width / 2 || 0}}>
				<div className="selected-entries-toast-inner">
					<FiqButton color="clear" icon={ close } ionIcon onClick={ handleUnselectAllEntries } />
					<span className="selected-entries-count">{ selectedEntriesCount }</span>
					<span className="selected-entries-label">{selectOptions?.entryName + (selectedEntriesCount > 1 ? 's' : '') + ' ' + localizedContent.selected + (locale === 'fr' && selectedEntriesCount > 1 ? 's' : '')}</span>
					<FiqButton icon={ selectOptions?.actionIcon } ionIcon={ selectOptions?.actionIonIcon } onClick={ handleSelectAction }>{ selectOptions?.actionName }</FiqButton>
				</div>
			</div>
        </div>
	);
};
export default SortableTable;