import { Plugins } from '@capacitor/core';
import { UserLoginInfos, Locale } from '../models';
import { Tutorial } from '../store/settings-actions';
import { ApiSuccessPayload, environment } from './environment';
import { UserProfilePayload } from './../models/user';

const { Storage } = Plugins;

interface SettingsPayload extends ApiSuccessPayload {
	data?: any;
}

class SettingsService {

	async getLocale(): Promise<Locale | undefined> {
		const { value } = await Storage.get({ key: 'locale' });
		return value ? value as Locale : undefined;
	}
	
	async setLocale(value: Locale): Promise<void> {
		return await Storage.set({ key: 'locale', value: value.toString() });
	}

	async getUserLoginInfos(): Promise<any | undefined> {
		const { value } = await Storage.get({ key: 'user_login_infos' });
		return value ? JSON.parse(value) : undefined;
	}

	async setUserLoginInfos(value: UserLoginInfos): Promise<void> {
		const previousUserLoginInfos = await this.getUserLoginInfos();
		const userLoginInfos = { ...previousUserLoginInfos, ...value };
		if (value) {
			return await Storage.set({ key: 'user_login_infos', value: JSON.stringify(userLoginInfos) });
		} else {
			return await this.clearUserLoginInfos();
		}
	}

	async clearUserLoginInfos(): Promise<void> {
		return await Storage.remove({ key: 'user_login_infos'});
	}

	async getTutorialsRead(): Promise<Tutorial[] | undefined> {
		const { value } = await Storage.get({ key: 'read_tutorials' });
		return value ? JSON.parse(value) : [];
	}

	async setTutorialsRead(tutorials: Tutorial[]): Promise<void> {
		await Storage.set({ key: 'read_tutorials', value: JSON.stringify(tutorials)});
	}

	async syncUserProfile(token: string, payload: UserProfilePayload): Promise<SettingsPayload> {
		const response = await fetch(`${environment.api}/profile`, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },
			body: JSON.stringify(payload)
		});
		return await response.json() || { data: null };
	}
}

export const settings = new SettingsService();
