import React from 'react';

import './AbleToWorkSteps.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

const AbleToWorkSteps: React.FC = () => {

    const localizedContent = useLocalizedContent();

	return (
		<div className="able-to-work-steps">
            <h2 dangerouslySetInnerHTML={{__html: localizedContent.atwStepsTitle }} />
            <ol>
                <li>
                    <div>
                        <h3 dangerouslySetInnerHTML={{__html: localizedContent.atwStep1title }} />
                        <p dangerouslySetInnerHTML={{__html: localizedContent.atwSteps1content }} />
                    </div>
                </li>
                <li>
                    <div>
                        <h3 dangerouslySetInnerHTML={{__html: localizedContent.atwSteps2title }} />
                    </div>
                </li>
                <li>
                    <div>
                        <h3 dangerouslySetInnerHTML={{__html: localizedContent.atwSteps3title }} />
                        <p dangerouslySetInnerHTML={{__html: localizedContent.atwSteps3content }} />
                    </div>
                </li>
                <li>
                    <div>
                        <h3 dangerouslySetInnerHTML={{__html: localizedContent.atwSteps4title }} />
                    </div>
                </li>
                <li>
                    <div>
                        <h3 dangerouslySetInnerHTML={{__html: localizedContent.atwSteps5title }} />
                        <p dangerouslySetInnerHTML={{__html: localizedContent.atwSteps5content }} />
                    </div>
                </li>
            </ol>
		</div>
	);
};

export default AbleToWorkSteps;