import firebase from 'firebase/app';
import 'firebase/messaging';
import { environment } from '../services/environment';

const initializedFirebaseApp = firebase.initializeApp(environment.firebase);
let messaging:firebase.messaging.Messaging;
if (firebase.messaging.isSupported()) {
    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey(environment.firebase.vapidKey);
}

export const askPermissionsAndGetToken = async ():Promise<string> => {
    try {
        if (firebase.messaging.isSupported()) {
            await messaging.requestPermission().catch( err => { return });
            const token = await messaging.getToken();
            return token;
        }
    } catch (err) {
        if (err.code === 'messaging/token-unsubscribe-failed') {
            askPermissionsAndGetToken();
        } else {
            return;
        }
    }
}

export { messaging };