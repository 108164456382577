import './DesktopHome.scss';
import React, { useMemo, useCallback, useState } from 'react';
import { IonIcon } from '@ionic/react';
import { calendar } from 'ionicons/icons';
import DateFilter from '../../../components/filters/DateFilter';
import ExcelButton from '../../../components/buttons/ExcelButton';
import LocationFilter from '../../../components/filters/LocationFilter';
import StatsCard from '../../../components/stats/StatsCard';
import DeclarationsCircles from '../../../components/stats/DeclarationsCircles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { Tso, Substitution, Testimonial } from '../../../models';
import DeclarationsForewarnStats from '../../../components/stats/DeclarationsForewarnStats';
import ProgressBarsStats from '../../../components/stats/ProgressBarsStats';
import useDateFilteredEntries from '../../../hooks/useDateFilteredEntries';
import useLocationFilteredEntries from '../../../hooks/useLocationFilteredEntries';
import useLocale from '../../../hooks/useLocale';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { ProgressBarData } from '../../../components/stats/ProgressBarsStats';
import SimpleModalController from '../../../components/modals/SimpleModalController';
import SimpleListModal from '../../../components/modals/center-modals/SimpleListModal';
import { getSubstitutedJobOtherId } from '../../../components/declaration/SubstitutionJobs';
import DeclarationTypesLegend from '../../../components/stats/DeclarationTypesLegend';
import { parseLongDate } from '../../../lib/utils';
import useServerNow from '../../../hooks/useServerNow';

const COLORS_GRADIENT = [ '#62D0FF', '#55A1E3', '#089BE3', '#026FB1', '#004481', '#001E54' ];

const DesktopHome: React.FC= () => {

	const locale = useLocale();
	const localizedContent = useLocalizedContent();

	// get user infos
	
	const user = useSelector((state:RootState) => state.auth.user);
	const isUserAdmin = user?.is_admin || false;
	const isUserMilitant = user?.is_militant || false;

	// get region and institution for militants

	const regions = useSelector((state:RootState) => state.app.data.regions);
	const institutions = useSelector((state:RootState) => state.app.data.institutions);
	const region = !isUserAdmin && isUserMilitant ? regions.find(region => region.id === user.registration_numbers[0].region_id) : undefined;
	const institution = region && !isUserAdmin && isUserMilitant ? institutions[user.registration_numbers[0].institution_id] : undefined;

	// get entries

	const entries = useSelector((state:RootState) => state.entries.data);

	const tsos = entries.tsos;
	const substitutions = entries.substitutions;
	const testimonials = entries.testimonials;
	
	// filter entries by location

	const locationfilteredTsos = useLocationFilteredEntries('dashboard', tsos) as Tso[];
	const locationfilteredSubstitutions = useLocationFilteredEntries('dashboard', substitutions) as Substitution[];

	// filter entries by date

	const filteredTsos = useDateFilteredEntries('dashboard', locationfilteredTsos) as Tso[];
	const filteredSubstitutions = useDateFilteredEntries('dashboard', locationfilteredSubstitutions) as Substitution[];
	const filteredTestimonials = useDateFilteredEntries('dashboard', testimonials) as Testimonial[];	

	// compute data

	const urgentTsos = filteredTsos.filter(tso => tso.urgency_state);

	const tsosCount = filteredTsos.length;
	const urgentTsosCount = urgentTsos.length;
	const substitutionsCount = filteredSubstitutions.length;
	const testimonialsCount = filteredTestimonials.length;

	const comparedTsosCount = entries.compared_tsos_count;
	const tsoAverageHours = useMemo( () => {
		let count = 0;
		filteredTsos.forEach(tso => count += tso.regular_hours + tso.overtime_hours)
		return parseFloat((count / filteredTsos.length).toFixed(2));
	}, [filteredTsos]);
	const comparedTsosAverageHours = entries.compared_tsos_average_hours;

	const comparedSubstitutionsCount = entries.compared_substitutions_count;
	const substitutionAverageHours = useMemo( () => {
		let count = 0;
		filteredSubstitutions.forEach(substitution => count += substitution.hours_spent)
		return parseFloat((count / filteredSubstitutions.length).toFixed(2));
	}, [filteredSubstitutions]);
	const comparedSubstitutionsAverageHours = entries.compared_substitutions_average_hours;
	const comparedTestimonialsCount = entries.compared_testimonials_count;

	// get tsos data

	const [otherCausesModalOpened, setOtherCausesModalOpened] = useState(false);
	const tsosWithOtherCauses = filteredTsos.filter( tso => tso.cause_other?.length );
	const tsosOtherCauses = tsosWithOtherCauses.map( tso => tso.cause_other );
	tsosOtherCauses.sort((a, b) => a.localeCompare(b));

	const tsosCausesRaw = useSelector((state:RootState) => state.app.tsoCauses);
	const tsosCauses = useMemo( () => {
		
		const tsosCauses:ProgressBarData[] = tsosCausesRaw.map( (cause, i) => ({
			label: cause.name[locale],
			color: COLORS_GRADIENT[i],
			icon: cause.icon,
			count: filteredTsos.filter( tso => tso.causes?.indexOf(cause.key) > -1).length
		}));
	
		tsosCauses.push({
			label: localizedContent.other,
			color: COLORS_GRADIENT[tsosCauses.length],
			count: tsosWithOtherCauses.length,
			onMenuClick: () => { setOtherCausesModalOpened(true) }
		});
	
		tsosCauses.push({
			label: localizedContent.iDontKnow,
			count: filteredTsos.filter( tso => !tso.causes || !tso.causes.length ).length
		});

		return tsosCauses;
	}, [filteredTsos, tsosCausesRaw, localizedContent, tsosWithOtherCauses, locale]);

	// get substitutions data

	const jobs = useSelector((state:RootState) => state.app.data.jobs);
	const [otherJobsModalOpened, setOtherJobsModalOpened] = useState(false);
	const otherJobId = getSubstitutedJobOtherId();
	const otherJobIdString = otherJobId.toString();

	const substitutionWithOtherJobs = filteredSubstitutions.filter(substitution => substitution.substituted_job_id === otherJobId);
	const otherSubstitutedJobs = substitutionWithOtherJobs.map( substitution => substitution.substituted_job_other || '');
	otherSubstitutedJobs.sort((a, b) => a.localeCompare(b));

	const getTopJobs = useCallback((substitutionKey:'current_job_id' | 'substituted_job_id') => {
		const jobsCountMap:any = {};
		let topJobs:ProgressBarData[] = [];

		filteredSubstitutions.forEach((substitution, i) => {
			const jobId = substitution[substitutionKey];
			if (jobId !== null && jobId !== undefined) {// @TODO added only because of old malformated substitutions
				jobsCountMap[jobId] = jobsCountMap[jobId] ? jobsCountMap[jobId] + 1 : 1;				
			}
		});

		for (var id in jobsCountMap) {
			topJobs.push({
				label: jobs[id].title[locale],
				count: jobsCountMap[id],
				onMenuClick: id === otherJobIdString ? () => { setOtherJobsModalOpened(true) } : undefined
			});
		}

		topJobs.sort( (a, b) => b.count - a.count);
		topJobs.length = 5;
		topJobs = topJobs.map((job, i) => ({...job, color: COLORS_GRADIENT[i]}));

		return topJobs;
	}, [filteredSubstitutions, locale, jobs, otherJobIdString]);

	const topCurrentJobs = useMemo( () => getTopJobs('current_job_id'), [getTopJobs]);
	const topSubstitutedJobs = useMemo( () => getTopJobs('substituted_job_id'), [getTopJobs]);

	const now = useServerNow();

	function capitalize(word: string){
	    return word[0].toUpperCase()+word.slice(1).toLowerCase();
	}
	
	let labelDay = capitalize(now.locale(locale).format('dddd'));
	if (locale === "en") {
		labelDay = labelDay+',';
	}
	const pageFilters = useSelector((state: RootState) => state.settings.pageFilters.dashboard);
	const comparedPeriod = pageFilters.range_type === 0 ? 'week' : pageFilters.range_type === 2 ? 'custom' : 'month';

	// render home header 2 times for fixed content

	const renderHomeHeader = () => (
		<div className="home-header">
			<div className="greetings-container">
				<div className="date">
					<IonIcon icon={ calendar } />
					<span>{ labelDay + (locale === 'fr' ? ' le ' : ' ') + parseLongDate(now, locale) }</span>
				</div>
				<h2 className="greetings">{ localizedContent.greetings + ' ' + user.first_name }</h2>
				<p className="message">{ localizedContent.greetingsMessage }</p>
			</div>
			<div className="graphics" />
		</div>
	);

	return (
		!user ? <></> : 
       <div className="desktop-home">
		   <div className="home-header-fixed">
				{ renderHomeHeader() }
			</div>
			<div className="home-header-static">
				{ renderHomeHeader() }
			</div>  	
			<div className="desktop-home-content">
				{ isUserMilitant || isUserAdmin ?
					<>
						<div className="home-overview-container">
							<div className="overview-content">
								<h1 className="title">{ localizedContent.dashboard }</h1>
								{ isUserMilitant ? 
									<div className="location-container">
										<div className="location region">{ region.name }</div>
										<div className="location etablissement">{ institution.name }</div>
									</div>
								: null }
							</div>
							<div className="overview-actions">
								<ExcelButton page="dashboard" />
							</div>
						</div>
						<DateFilter page="dashboard" />				
						{ isUserAdmin ? 
							<div className="location-filter-container">
								<LocationFilter page="dashboard" hideInstallations />
							</div>
						: null }				
						<div className="declarations-circles-container">
							<div className="graphics">
								<DeclarationsCircles tsosCount={tsosCount} urgentTsosCount={urgentTsosCount} substitutionsCount={substitutionsCount} />
							</div>
							<DeclarationTypesLegend useLongNames />
						</div>
						<StatsCard
							title={ localizedContent.declarations + ' ' + localizedContent.tso }
							count={ tsosCount }
							comparedCount={ comparedTsosCount }
							averageHours={ tsoAverageHours }
							comparedAverageHours={ comparedTsosAverageHours }
							comparedPeriod={ comparedPeriod }
							action={{ href: '/dashboard/map', label: localizedContent.showDeclarations }}
						>
							<div className="tsos-card-content">
								<div className="declarations-forewarn-stats-container">
									<DeclarationsForewarnStats declarations={ filteredTsos } />
								</div>
								<div className="tsos-causes-stats-container">
									<ProgressBarsStats title={ localizedContent.requestCauses } data={ tsosCauses } totalCount={ tsosCount } />
								</div>
							</div>
						</StatsCard>
						<StatsCard
							title={ localizedContent.declarations + ' ' + localizedContent.workSubstitution }
							count={ substitutionsCount }
							comparedCount={ comparedSubstitutionsCount }
							averageHours={ substitutionAverageHours }
							comparedAverageHours={ comparedSubstitutionsAverageHours }
							comparedPeriod={ comparedPeriod }
							action={{ href: '/dashboard/map', label: localizedContent.showDeclarations }}
						>
							<div className="substitutions-card-content">
								<div className="declarations-forewarn-stats-container">
									<DeclarationsForewarnStats declarations={ filteredSubstitutions } />
								</div>
								<div className="substitution-jobs-stats-container">
									<div className="substitution-substituted-jobs-stats-container">
										<ProgressBarsStats title={ localizedContent.mostSubstitutedJobsTitles } data={ topSubstitutedJobs } totalCount={ substitutionsCount } />
									</div>
									<div className="substitution-current-jobs-stats-container">
										<ProgressBarsStats title={ localizedContent.mostCurrentJobsTitles } data={ topCurrentJobs } totalCount={ substitutionsCount } />
									</div>
								</div>
							</div>
						</StatsCard>
						<StatsCard
							title={ localizedContent.testimonials }
							count={ testimonialsCount }
							comparedCount={ comparedTestimonialsCount }
							comparedPeriod="month"
							action={{ href: '/dashboard/testimonials', label: localizedContent.showTestimonials }}
						/>
						<SimpleModalController opened={ otherCausesModalOpened } onClose={ () => setOtherCausesModalOpened(false) }>
							<div className="other-causes-container">
								<SimpleListModal title={ localizedContent.otherCauses } items={ tsosOtherCauses } onClose={ () => setOtherCausesModalOpened(false) } />
							</div>
						</SimpleModalController>
						<SimpleModalController opened={ otherJobsModalOpened } onClose={ () => setOtherJobsModalOpened(false) }>
							<div className="other-jobs-container">
								<SimpleListModal title={ localizedContent.otherSubstitutedJobTitles } items={ otherSubstitutedJobs } onClose={ () => setOtherJobsModalOpened(false) } />
							</div>
						</SimpleModalController>
					</>
				: null }
			</div>			
		</div>
	);

	
};

export default DesktopHome;