import React, { useRef, useEffect, useCallback } from 'react';
import { IonPage, IonContent, useIonViewWillEnter } from "@ionic/react";
import { useHistory } from 'react-router';
import { close } from 'ionicons/icons';
import useAbleToWorkForm from '../../../hooks/useAbleToWorkForm';
import MobileHeader from '../../../components/mobile/MobileHeader';
import TransitionableScrollableWhiteContent from '../../../components/mobile/TransitionableScrollableWhiteContent';
import MobileFooterButtons from '../../../components/mobile/MobileFooterButtons';
import { updateStatusBar } from '../../../lib/status-bar';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useBackButtonHit from '../../../hooks/useBackButtonHit';
import gsap from 'gsap';

import './MobileAbleToWork.scss';
import MobileFooter from '../../../components/mobile/MobileFooter';

const MobileAbleToWork: React.FC = () => {

    // Update tab color
    useIonViewWillEnter( () => updateStatusBar('blue'));

    const scrollContainer = useRef(null);
    const localizedContent = useLocalizedContent();
    const history = useHistory();
    const { isTutorial, currentPage, tutorialPage, isAbleToWork, setAbleToWork, goBack, goNext, backBtn, nextBtn } = useAbleToWorkForm();
    const renderedPage = isTutorial ? tutorialPage : currentPage;

    const goDashboardHome = useCallback(() => {
        if (history.length > 2) {
            history.goBack();
        } else {
            history.replace('/dashboard', { direction: 'back'});
        }
    }, [history]);

    const onTransitionStart = () => {
        scrollContainer.current.scrollTop = 0;
        gsap.set(scrollContainer.current, { overflow: 'hidden' });
    }

    const onTransitionComplete = () => {
        gsap.set(scrollContainer.current, { overflow: 'auto' });
    }

    // Back button management

    const backbuttonHit = useBackButtonHit();

    useEffect( () => {
        if (backbuttonHit) {
            if (isTutorial) {
                goDashboardHome();
            } else {
                goBack();
            }                
        }
    }, [backbuttonHit, isTutorial, goBack, goDashboardHome]);

	return (
        <IonPage className={'mobile-able-to-work' + (isTutorial ? ' is-tutorial' : '')} data-page="able-to-work">
            <MobileHeader blue backIcon={ close } title={ isTutorial ? localizedContent.ableToWorkQuestion : null } onBackClick={ goDashboardHome } />
            <IonContent>
                <div className="scroll-container" ref={ scrollContainer}>
                    <TransitionableScrollableWhiteContent transitionKey={renderedPage.key} onTransitionStart={ onTransitionStart } onTransitionComplete={ onTransitionComplete }>
                        <renderedPage.Component ableToWork={isAbleToWork} setAbleToWork={setAbleToWork} goNext={ goNext } />
                    </TransitionableScrollableWhiteContent>
                </div>
            </IonContent>
            <MobileFooter>
                <MobileFooterButtons backBtn={ backBtn} nextBtn={nextBtn} onBackClicked={ goBack } onNextClicked={ btn => btn.name === 'end' ? goDashboardHome() : goNext() } />
            </MobileFooter>
        </IonPage>
	);
};

export default MobileAbleToWork;