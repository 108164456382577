import React from 'react';
import { IonApp, isPlatform, setupConfig, iosTransitionAnimation } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import AppContent from './AppContent';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Styling */
import './styles/index.scss';

/* Redux */
import { Provider } from 'react-redux';
import { store } from './store';

/* Lock screen orientation on phones */
import { ScreenOrientation } from '@ionic-native/screen-orientation';
if (isPlatform('capacitor') && !isPlatform('tablet')) {
	ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
}

/* Ionic config */
setupConfig({
	mode: 'md',
	swipeBackEnabled: false,
	hardwareBackButton: false,
	navAnimation: iosTransitionAnimation
});

const App: React.FC = () => {	
	return (
		<Provider store={store}>
			<IonApp>
				<IonReactRouter>
					<AppContent />
				</IonReactRouter>
			</IonApp>
		</Provider>
	);
};

export default App;

