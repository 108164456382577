import React from 'react';
import { IonPage, IonContent, useIonViewWillEnter } from '@ionic/react';
import Settings from '../../../components/common-content/Settings';
import MobileHeader from '../../../components/mobile/MobileHeader';
import { updateStatusBar } from '../../../lib/status-bar';
import useLocalizedContent from '../../../hooks/useLocalizedContent';

import './MobileSettings.scss';

const MobileSettings: React.FC = () => {

    // Update tab color
    useIonViewWillEnter( () => updateStatusBar('blue'));

    const localizedContent = useLocalizedContent();

    return (
        <IonPage className="with-tabs mobile-settings" data-page="settings">
            <MobileHeader blue title={ localizedContent.settings } />
            <IonContent>
                <Settings />
            </IonContent>
        </IonPage>
    );
};

export default MobileSettings;