import React from 'react';
import { IonIcon } from '@ionic/react';
import { NavLink } from 'react-router-dom';
import { home, map, calendar, addCircle, newspaper, chatboxEllipses, settings } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import useSortableDeclarations from '../../hooks/useSortableDeclarations';
import UnreadCount from '../stats/UnreadCount';
import useLocale from '../../hooks/useLocale';
import useLocalizedContent from '../../hooks/useLocalizedContent';

import './DashboardSideMenu.scss';

const DashboardSideMenu:React.FC = () => {
    const localizedContent = useLocalizedContent();
    const user = useSelector((state:RootState) => state.auth.user);
    const isUserMilitant = user?.is_militant || false;
    const isUserAdmin = user?.is_admin || false;

    const locale = useLocale();
    const unsafeCareUrl = useSelector((state:RootState) => state.app.data.configurations.unsafe_care_url)[locale]

    // Get unread entries count

	const entries = useSelector((state: RootState) => state.entries.data);
	const tsos = entries.tsos || [];
	const substitutions = entries.substitutions || [];
    const declarations = useSortableDeclarations({ tsos, substitutions });
    
	const unreadDeclarations = declarations.filter( declaration => !declaration.read).length;
    const unreadNews = entries.news.filter( singleNews => singleNews.status !== 'read').length;
    const unreadTestimonials = entries.testimonials.filter( testimonial => testimonial.status === 'unread').length;
    
    const dashboardUrl = '/dashboard';
    
    return (
        <div className="dashboard-side-menu">
            <div className="side-menu-header">
                <img src="/assets/logo/logo.svg" alt="fiq-logo" />
            </div>
            <div className="side-menu-content">
                <NavLink className="side-menu-link" to={ dashboardUrl } exact>
                    <IonIcon icon={ home } />
                    <span className="label">{ localizedContent.dashboard }</span>
                </NavLink>
                { isUserMilitant || isUserAdmin ?
                    <NavLink className="side-menu-link" to={ dashboardUrl + '/map'}>
                        <IonIcon icon={ map } />
                        <span className="label">{ localizedContent.map }</span>
                        { !!unreadDeclarations ? 
                            <UnreadCount count={ unreadDeclarations } />
                        : null }
                    </NavLink>
                : null }                    
                { !isUserAdmin ? 
                    <>
                        <a className="side-menu-link" href={ unsafeCareUrl } target="_blank" rel="noopener noreferrer">
                            <span className="icon"><img alt="icon-bandage" src="/assets/icon/bandage.svg" /></span>
                            <span className="label" dangerouslySetInnerHTML={{__html: localizedContent.unsafeCareReportTitle }} />
                        </a>
                        <NavLink className="side-menu-link" to={ dashboardUrl + '/able-to-work' }>
                            <span className="icon"><img alt="icon-able-to-work" src="/assets/icon/able-to-work.svg" /></span>
                            <span className="label">{ localizedContent.ableToWorkQuestion }</span>
                        </NavLink>
                        <NavLink className="side-menu-link report" to={ dashboardUrl + '/report/tso' }>
                            <IonIcon icon={ addCircle } />
                            <span className="label">{ localizedContent.declarationTso }</span>
                        </NavLink>
                        <NavLink className="side-menu-link report" to={ dashboardUrl + '/report/substitution' }>
                            <IonIcon icon={ addCircle } />
                            <span className="label">{ localizedContent.workSubstitution }</span>
                        </NavLink>
                    </>
                : null }
                <NavLink className="side-menu-link" to={ dashboardUrl + '/news' }>
                    <IonIcon icon={ newspaper } />
                    <span className="label">{ localizedContent.negociations }</span>
                    { !!unreadNews ? 
                        <UnreadCount count={ unreadNews } />
                    : null }
                </NavLink>
                { isUserMilitant || isUserAdmin ?
                    <NavLink className="side-menu-link" to={ dashboardUrl + '/testimonials' }>
                        <IonIcon icon={ chatboxEllipses } />
                        <span className="label">{ localizedContent.testimonialsList }</span>
                        { !!unreadTestimonials ? 
                            <UnreadCount count={ unreadTestimonials } />
                        : null }
                    </NavLink>
                : null }
                <NavLink className="side-menu-link" to={ dashboardUrl + '/events' }>
                    <IonIcon icon={ calendar } />
                    <span className="label">{ localizedContent.events }</span>
                </NavLink>
                { isUserAdmin ?
                    <div className="cms-section">
                        <NavLink className="side-menu-link report" to={ dashboardUrl + '/single-news/create' }>
                            <IonIcon icon={ addCircle } />
                            <span className="label">{ localizedContent.create + ' ' + localizedContent.aNegociation }</span>
                        </NavLink>
                        <NavLink className="side-menu-link report" to={ dashboardUrl + '/event/create' }>
                            <IonIcon icon={ addCircle } />
                            <span className="label">{ localizedContent.create + ' ' + localizedContent.anEvent }</span>
                        </NavLink>
                    </div>
                : null}
            </div>
            <div className="side-menu-footer">
                <NavLink className="side-menu-link" to={ dashboardUrl + '/settings' }>
                    <IonIcon icon={ settings } />
                    <span className="label">{ localizedContent.settings }</span>
                </NavLink>
            </div>
        </div>
    );
};

export default DashboardSideMenu;