import React from 'react';

const QuebecRegions:React.FC = () => 
<>
	<g id="Estrie_5" className="region">
		<circle className="point" cx="370" cy="1290" r="1" fill="transparent" />
		<path id="path4992_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M340.4,1270.9h-0.6
			c-0.3,0-0.6,0.3-1.2,0.3c-0.3,0-0.3,0.3-0.3,0.6v1.5h-0.6v2.9h-1.2v5.8h-2l-0.3,5.8l-0.3,1.8l0.3,3.2l2.6-0.3l-0.3,11.4h-3.2
			l-0.3,12h43c0-0.3,0.9-0.9,1.2-0.6c0.3-0.3,0.6-0.3,0.9-0.6c0.3-0.9,0.3-1.8,0-2.6c-0.3,0-0.6-0.3-0.6-0.6c-0.3-1.2,1.5-2,2.3-2.6
			c0-0.6,1.8-2,2-2.9c-0.9-2.3,1.2-5.3,0.9-6.1l-1.5-1.2v-0.6h1.2l0.6-0.6h0.3l0.3,0.3h0.3l1.2-2.3c0.9-0.6,2.3-1.2,2.6-2h0.6
			l0.3,0.3l0.6,0.3c0,0.3,0.3,0.9,0.3,1.2l0.3,0.3c1.2,0,1.2,1.5,2,1.8h0.6c0.6,0.3,2.6,0.6,3.2,0c0.9-1.2,0.9-2.9,1.8-4.1
			s2.6-0.3,3.8-0.9l0.3-0.6c-0.2-0.6-0.3-1.2-0.3-1.8h0.6c0.6,0.9,0.9,1.5,2,1.2c0.3,0,0.6,0.6,0.6,0.9l0.9,0.6c0,0.6,0.3,1.2,0,1.8
			c0,0.2-0.1,0.4-0.3,0.6c0,0.6,0.6,1.2,0.9,1.8c0.3,0.6,0.3,1.5,0.9,1.8h0.3c0.5-0.2,1-0.5,1.5-0.9c0-0.3-0.3-0.9-0.6-0.9l-0.3-0.9
			l0.3-0.3c0.6,0,0.9-0.9,1.2-1.2l-0.3-0.9l0.3-0.3h0.6l0.3-0.3c0-0.6,0.3-1.5,0-2l-0.3-0.3l-0.3-1.2l0.6-0.6c0-0.4,0.1-0.8,0.3-1.2
			c-0.3-0.6-0.3-1.2-0.6-1.8l-0.3-0.3v-0.3c0.1-0.4,0.5-0.7,0.9-0.9c0-0.3,0.6-0.9,1.2-1.2h0.9c1.2,0.6,0.6,0.6,1.2,1.5
			c0.3,0.3,0.6,0.9,0.9,1.2l0,0c0.7-0.2,1.4-0.3,2-0.3c0.3,0.3,0.5,0.7,0.6,1.2h0.3c0.3,0,0.6-1.2,0.6-1.5c0.3,0,0.6-0.3,0.6-0.6
			v-0.3l-0.3-0.3c0-0.3,0-0.9,0.3-0.9v-0.3l-0.6-0.3l-0.3-0.3v-0.9c-0.3-0.3-0.9-0.3-1.2-0.6s-0.3-0.9-0.6-1.2
			c-0.3-0.6-0.9-0.9-1.2-1.5c-0.3-0.3-0.3-0.6-0.3-1.2c0-1.8,0-1.2,0.6-2.3c0.2-0.2,0.3-0.6,0.3-0.9s0.3-0.3,0.6-0.6v-0.6
			c0.2-0.9,0.6-1.8,1.2-2.6h0.3l0.3-0.6c2.3-0.9,3.2-4.4,4.1-4.7h1.2c0.6-0.3,0.6-0.9,0.9-1.2c0.6-0.6,1.5-0.9,2-1.5h1.5
			c1.5-1.2,2-1.8,1.8-3.8l-0.6-0.6c-0.2-0.9-0.3-1.7-0.3-2.6l-0.6-0.9l-2.3,1.5l-1.2-2.9l-6.1,3.5l-0.6-1.8l-0.9,1.5l-10.2,0.3
			l-0.3-8.2l-2.3-1.8l-0.9,1.2l-1.2-1.2l-0.3,0.3l-2.3-2l-0.6,0.9l-2.6-2.3l-1.5,2.3c0.3,0.3,0.3,0.6,0.3,1.2v2
			c0,0.3,0.3,0.6,0.3,0.9c0.2,0.3,0.3,0.6,0.6,0.9l-1.8,3.2l-2.3-2.3l-0.9,0.9l-0.6-0.3l-0.6,0.6c-0.9-0.8-1.8-1.7-2.6-2.6l-0.3-0.3
			c-0.2,0-0.3,0.1-0.3,0.3l0,0c0,0.3-0.3,0.3,0,0.3l0.6,0.6c0.2,0.2,0.3,0.4,0.3,0.6v2.9c0,0.3-0.3,0.6-0.6,1.2
			c-0.3,0.6-0.9,0.9-1.2,1.2c-0.2,0.2-0.4,0.3-0.6,0.3v0.3l-3.2-3.5l-1.5,1.8l-0.9-0.9l-1.2,1.2l-3.5-3.2l-0.9,1.2l-6.1-5.3l-4.1,6.4
			h-0.6c-0.3,0-0.9,0-0.9-0.3h-0.3c-0.2,0.2-0.4,0.4-0.6,0.6l-5-4.4l-8.2,11.7l-0.6-1.2c-0.3-0.3-0.3-0.3-0.3-0.6
			c0.2-0.2,0.4-0.3,0.6-0.3l0.3-0.3v-0.3c0-0.3-0.3-0.6-0.3-0.9c0-0.4-0.1-0.8-0.3-1.2l-3.8,5.3l3.2,2.9l-2.9,4.1L340.4,1270.9z"/>
	</g>
	<g id="Chaudiere-Appalaches_12" className="region">
		<circle className="point" cx="410" cy="1220" r="1" fill="transparent" />
		<path id="path4998_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M426,1160.2
			c-1.5,2-2.6,4.1-4.4,5.6c-1.5,1.2-2.9,0.9-4.7,1.8c-1.1,0.6-2.2,1.3-3.2,2l-1.8,0.9c-0.9,0.6-0.6,1.8-1.2,2c-0.3,0.3-1.5,0-2,0.3
			c-0.3,0.3-0.6,0.9-0.9,1.2c-1.2,0.3-2.3-0.6-3.5,0.3c-0.6,0.3-0.9,1.2-1.5,1.5c-1,1-2.2,1.7-3.5,2c-1.8,0.3-3.5-0.9-5.6,0h-0.3
			c-1.2,0.9-1.2,2.3-1.8,3.5c-1.5,1.5-3.2,2.9-5,4.1c-1.2,0.7-2.5,1.3-3.8,1.8c-0.3,0-0.6,0.3-0.9,0.3c-0.6,0-1.2,0.3-1.8,0.3
			c-1.4,0.7-2.9,1.3-4.4,1.8c-0.6,0.3-1.5,0.6-2,0.9c-1.2,0.6-1.5,1.8-3.5,2.6c-0.9,0.3-2,0.3-2.9,0.6c-0.3,0-0.9,0.3-1.2,0.3
			c-1.1-0.4-2.1-1-2.9-1.8c-0.3-0.3-0.6-1.2-1.2-1.2c-1.5,0.6-1.8,2-2.9,3.2c-0.7,0.6-1.5,1-2.3,1.5c-0.6,0.9-0.6,1.8-1.5,2.6
			c-0.6,0.6-1.5,0.6-2.3,0.9l12.9,14.9l6.4-6.7l2.3,2.9l1.5-1.5l2.3,2.9l-1.2,1.5l1.2,1.5l-1.2,1.5l6.1,5.3l-2,3.2l-0.6-0.6l-0.6,0.6
			l-0.9-0.6l-1.2,1.5l0.3,0.3l-2,3.2l0.3,0.3l-0.3,0.9l1.2,0.9l-7.9,12.6l5.5,4.7l0.6-0.9l2.3,2v-0.3l2,1.8l-0.6,4.1l3.5,3.8v-0.3
			c0.2,0,0.4-0.1,0.6-0.3l1.2-1.2c0.3-0.6,0.6-0.9,0.6-1.2v-2.9c0-0.3,0-0.3-0.3-0.6l-0.6-0.6v-0.3c0-0.2,0.1-0.3,0.3-0.3
			c0.2,0,0.3,0.1,0.3,0.3c0.8,0.9,1.7,1.8,2.6,2.6l0.6-0.6l0.6,0.3l0.9-0.9l2.3,2.3l1.8-3.2c-0.2-0.3-0.4-0.6-0.6-0.9
			c0-0.3-0.3-0.6-0.3-0.9v-2c0-0.4-0.1-0.8-0.3-1.2l1.5-2.3l2.6,2.3l0.6-0.9l2.3,2l0.3-0.3l1.2,1.2l0.9-1.2l2.3,1.8l0.3,8.2l10.2-0.3
			l0.9-1.5l0.6,1.8l6.1-3.5l1.2,2.9l2.3-1.5l0.6,0.9c1.2-0.6,1.2-2.3,2-3.2c0.6,0,1.2-0.9,1.5-1.2c0.9-0.3,2-0.9,2.3-1.8h0.3
			c0.9-0.6,1.2-2.6,0.6-3.2v-0.3l0.9-0.6v-0.3c0-0.3-0.1-0.6-0.3-0.9l-0.6-0.3l-0.3-0.3c0-0.3-0.1-0.6-0.3-0.9h-0.3l-0.3-0.6
			l-0.6,0.6h-0.9l-0.3-0.3c0.6-1.8,0.9-1.2,0.9-2c0-0.3-0.6-0.9-0.9-1.2v-1.2l0.6-0.6l0.3-0.9l0.9-0.6v-0.6l-0.3-0.3h-0.9l-0.3-0.3
			c0.6-0.9,0.6-1.8,1.2-2.6h1.2c-0.3-0.6,0.3-2.6,0.6-3.2v-0.3c-1-0.9-1.8-2-2.3-3.2l0.3-0.3c0.8-1.3,1.3-2.8,1.5-4.4v-0.6
			c0.9-1.8,0.6-2,2-3.2c0.3-0.5,0.3-1.2,0-1.8l0.9-1.8c0.3,0,0.6-0.6,0.6-0.6c0.3-0.3,0.8-0.3,1.2,0h0.3l0.3-0.6h0.3l0.3-0.6
			c0.9-0.6,0.9-1.8,1.8-2h0.9l0.9-1.2l2-14.3c0.3-1.8,0.3-4.4,1.2-5.8h0.3l1.5-2.3l-0.3-0.3l-11.1-12.9l1.5-1.8l-2-2.3l-1.2,1.5
			L426,1160.2z"/>
	</g>
	<g id="Bas-Saint-Laurent_1" className="region">
		<circle className="point" cx="550" cy="1060" r="1" fill="transparent" />
		<path id="path5000_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M585.7,1014.5
			c-0.3,0-0.3,0.3-0.6,0.3c-0.6,0.3-0.9,0.6-1.5,0.9c-2,1.2-4.1,2.9-6.4,4.1c-1.7,0.7-3.5,1.3-5.3,1.8c-1.2,0.3-2.3,1.2-3.5,1.5
			c-0.9,0.3-1.8,0.3-2.6,0.6c-1.8,0.9-2.9,2.6-4.1,3.2c-1.2,0.6-2.5,0.8-3.8,0.6h-1.2c-0.9,0.3-1.2,0.9-2,1.5
			c-1.8,1.2-4.4,1.8-5.8,3.2c-1,0.5-2,1.1-2.9,1.8l-6.1,3.5c-1.2,0.6-2,1.8-3.5,2c-2,0.9-2.3-0.3-3.5,0.3c-1.2,0.6-1.5,2.6-2.6,3.2
			h-1.8c-1.2,0.2-2.3,0.7-3.2,1.5c-0.6,0.6-1.8,1.5-2.3,2c-1,1.3-2.1,2.6-3.2,3.8c-1.5,0.9-2.3-0.3-4.1,0.9c-2,1.5-2.3,4.1-3.5,5.6
			c-1.5,1.8-3.2,1.2-4.7,2.3c-1.2,0.9-0.9,1.2-1.5,1.8c-0.6,0.9-1.2,0.6-1.8,0.9c-1.2,0.9-0.3,1.2-2,2c-0.8,0.4-1.7,0.5-2.6,0.3
			c-0.3,0-0.6-0.3-0.9-0.3l-0.3,0.3c-0.6,2.9-1.5,2.6-3.5,3.8c-0.6,0.3-1.2,0.9-1.8,1.2c-0.3,0.3-0.9,0.6-1.2,0.9
			c-2.4,2-4.6,4.4-6.4,7c-0.3,0.8-0.7,1.6-1.2,2.3v0.3c-0.2,0.5-0.5,1-0.9,1.5c-0.5,0.5-1.1,0.8-1.8,1.2c-0.8,0.6-1.6,1.3-2.3,2
			c-1.2,1.2-2.3,3.2-3.5,4.7c-0.6,0.6-0.9,1.5-1.5,2c-0.9,0.9-2.3,0.3-3.5,0.9c-1.2,0.9-5.8,8.8-6.1,9.9v0.9c0.3,0.6,0.9,1.2,0.6,1.8
			c-0.3,0.3-2,1.5-2,1.8c-1.1,1.5-2.1,3.1-2.9,4.7c-1.2,1.8-2.6,3.2-3.8,4.7c-0.6,0.9-0.9,1.8-1.5,2.6c-0.3,0.3-0.6,0.6-0.6,0.9
			c-0.3,0.6-0.3,1.2-0.9,1.8c-0.6,1.2-2.3,1.8-3.2,2.9c-0.6,0.9-0.6,1.8-1.2,2.6c-0.6,0.9-1.2,0.3-1.8,0.9c-0.6,0.3-0.6,1.2-1.2,1.5
			c-0.6,0.6-1.5,0.6-2,0.9c-0.3,0.3-0.6,0.9-0.9,1.2c-0.9,1.2-0.6,1.8-0.6,2.9c0,0.3-0.3,0.6-0.3,0.9c0,1.2,0.6,2-0.3,3.2
			c-1,1.3-2.1,2.6-3.2,3.8c-1.2,0.6-2.6,0.6-3.5,1.8c-1.5,1.8-1.5,4.4-2.6,6.1c-1.2,2.2-2.6,4.4-4.1,6.4c-1.2,1.2-2.3,2.6-3.2,4.1
			c0,0.3-0.3,0.3-0.3,0.6l8.5,10.8l1.2-1.5l2,2.3l-1.5,1.8l11.1,12.9l0.3,0.3l3.2-5.6c0.3-0.6,0.9-1.2,1.2-1.8
			c0.9-1.5,1.5-2.9,2.3-4.1c3.2-5.3,6.4-10.8,9.9-16.1l0,0c2.3-4.1,5-8.2,7.3-12.3c2.9-5,5.8-9.6,8.8-14.6c0.3,0,0.6,0.3,0.9,0.6h0.3
			l0.3-0.3h0.3l0.3-0.3h0.3l0.6,0.3h0.6l0.6,0.6h0.3c0,0.3,0.3,0.3,0.6,0.3l0.3,0.6h0.3l0.3,0.3l0.6,0.3l0.6-0.3v-0.3h0.3l0.3-0.3
			l0.3,0.3h0.3c0,0.3,0,0.6,0.3,0.6l-0.3,0.3l0.3,0.3v0.3h0.3v0.3h-0.3v0.6l-0.3,0.3l0.3,0.3v0.3h-0.3v6.4c0.9-0.3,9.1-3.2,9.6-3.2
			v-0.3l4.4-1.8c0.6-0.3,7.9-4.4,7.9-4.4c2.9-3.2,5.8-6.4,8.5-9.3v-27.2l11.7-0.3v-6.1h23.4l0.3,0.3l0.3-0.6v-0.9
			c0-0.3,0-0.3-0.3-0.6v-0.3l0.3-0.3v-0.6c0-0.2-0.1-0.4-0.3-0.6c0,0-0.3-0.6-0.6-0.6c-0.3-0.2-0.6-0.4-0.9-0.6
			c0-0.2-0.1-0.4-0.3-0.6l-0.3-0.3H551c0-0.2-0.1-0.3-0.3-0.3l0,0v-0.9c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.2-0.3-0.4-0.3-0.6l13.1-5.8
			l4.4,12.3l7.9-3.5v-0.6l9.6-3.8l-2.6-2.9l1.5-1.5l1.5,1.8l1.5,2.3h0.3l-6.1-11.4l16.7-7.3l14.9-6.1l14.9-6.4l-26.9-29.2l-7.6,4.1
			l-0.6-0.6c0,0,0.3-0.6,0.6-0.6s0.3-0.3,0.3-0.6v-0.3c0-0.3,0-0.3,0.3-0.3v-0.3c0-0.3,0-0.3-0.3-0.6v-3.2c-0.2-0.3-0.3-0.6-0.3-0.9
			l-1.2-1.2l-4.4,2.3L585.7,1014.5z"/>
	</g>
	<g id="Laval_13" className="region">
		<circle className="point" cx="277" cy="1271" r="1" fill="transparent" />
		<path id="path5004_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M283.4,1265.7h-0.3
			c0.3-0.6,0.9-1.5,1.2-2v-0.3c-0.3,0-1.5,0.6-1.8,0.6c-1.2,0.1-2.3,0.1-3.5,0c-0.6,0-0.9,0.3-1.2,0.3h-0.6c-0.6,0.3-2,2.3-2.6,2.9
			c-0.6,0.9-0.9,1.8-1.5,2.6c-0.3,0.3-0.9,0.6-0.9,0.9c-0.2,0.2-0.3,0.6-0.3,0.9c-0.6,0.9-2.6,2.9-2.3,3.8c0.6,0.6,1.5,0,2,0l0.3,0.3
			c0,0.6-0.3,0.9-0.6,0.9h0.9c0.4,0,0.8,0.1,1.2,0.3c0.2,0.2,0.6,0.3,0.9,0.3h0.6c0.3,0,0.3-0.3,0.6-0.3c0.6-0.6,1.8-1.5,2-1.8
			c0.3,0,0.3-0.3,0.6-0.3c0.3,0,0.3-0.3,0.6-0.3s0.3-0.3,0.6-0.3c0-0.3,0.3-0.6,0.3-0.9s0-0.6,0.3-0.6c0-0.3,0.3-0.3,0.3-0.6
			c0.3-0.3,0.3-0.6,0.6-1.2c0.2-0.4,0.3-0.8,0.3-1.2c0.1-0.5,0.3-1,0.6-1.5c0.2-0.4,0.5-0.8,0.9-1.2l0.9-0.9
			C283.4,1266,283.4,1265.7,283.4,1265.7L283.4,1265.7z"/>
	</g>
	<g id="Montreal_6" className="region">
		<circle className="point" cx="280" cy="1280" r="1" fill="transparent" />
		<path id="path5006_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M283.4,1265.7
			c-0.2,0.2-0.3,0.4-0.3,0.6l-0.9,0.9c-0.3,0-0.6,0.6-0.9,1.2c-0.3,0.5-0.5,0.9-0.6,1.5c0,0.3-0.3,0.6-0.3,1.2
			c-0.3,0.6-0.3,0.9-0.6,1.2s-0.3,0.3-0.3,0.6s0,0.3-0.3,0.6c0,0.3-0.1,0.6-0.3,0.9c0,0.3-0.3,0.6-0.6,0.3c-0.3,0-0.3,0-0.6,0.3
			c-0.3,0-0.3,0.3-0.6,0.3c-0.3,0.3-1.5,1.2-2,1.8c-0.3,0.3-0.3,0.3-0.6,0.3h-0.6c-0.3,0-0.6-0.1-0.9-0.3c-0.3-0.2-0.8-0.3-1.2-0.3
			h-0.9c-0.3,0-0.3,0.3-0.6,0.6c0,0.3-0.1,0.6-0.3,0.9c-0.7,0.7-1.5,1.3-2.3,1.8c-0.4,0-0.8-0.1-1.2-0.3c-0.2,0-0.4,0.1-0.6,0.3
			c0,0.5-0.1,1-0.3,1.5c-0.3,0.6-0.9,0.9-0.9,1.5c1.2,2.3,4.1,0.6,5.5,0c0.6-0.3,1.5-0.3,1.8-0.6c0.3-0.4,0.7-0.6,1.2-0.6
			c0.4,0.1,0.8,0.3,1.2,0.6c1.8,0.3,2-0.6,4.4,0.3c0.5,0.3,1.1,0.5,1.8,0.6c1.5-0.3,2-1.8,2.9-2.9c0.4-0.6,0.7-1.3,0.9-2
			c0-0.3-0.3-0.6-0.3-1.2c-0.2-0.9-0.1-1.8,0.3-2.6c0.3-1.2,1.2-2,1.5-3.2c0.3-0.6-0.3-1.5,0-2c0.3-0.7,0.7-1.4,1.2-2
			c0.2-0.5,0.1-1.1-0.3-1.5c0-0.9,0.1-1.8,0.3-2.6l-0.3-0.3C285.7,1263.1,284.9,1265.4,283.4,1265.7L283.4,1265.7z"/>
		<path id="path5012_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M270,1276.8
			c0,0.3,0.3,0.6,0.3,0.9c-0.4,1-1.3,1.8-2.3,2c-0.3,0-0.9-0.3-1.2,0l-0.3-0.3v-0.6c0.6-0.6,2-2.3,2.9-2H270z"/>
	</g>
	<g id="Monteregie_16" className="region">
		<circle className="point" cx="310" cy="1290" r="1" fill="transparent" />
		<path id="path4994_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M311.4,1234.4l-0.9,0.9
			l-0.9,0.3c-0.9,0.3-2,0-2.9,0.3c-0.6,0.3-1.5,0.6-2,0.9h-1.5c-1.2,0.6-1.2,2.9-1.8,4.1c-0.3,0.9-0.9,1.5-1.2,2.3
			c-0.3,1.5,0.3,3.5-0.6,5.5c-0.9,1.9-2.1,3.7-3.5,5.3c-0.6,0.6-1.2,0.9-1.8,1.5c-0.9,0.9-1.5,2-2.3,2.9c-0.9,1.2-2,2-2.6,3.5
			c-0.9,2-0.6,4.7-0.6,6.7v1.2c-0.3,0.6-1.8,2.6-2,3.2c-0.6,1-1,2.1-1.2,3.2c0,0.9,0.1,1.8,0.3,2.6c0.6,1.8,1.5,4.1-0.3,5.5
			c-0.6,0.3-1.2,0.6-1.5,0.3c-0.3,0-0.3-0.3-0.3-0.6c-0.6-0.6-2,0.3-2.9,0.3c-0.7-0.1-1.4-0.3-2-0.6c-0.9,0-2.6,0.3-2.9,1.2
			c-0.2,0.2-0.3,0.6-0.3,0.9s-0.6,0.3-0.9,0.6c-0.2,0.2-0.3,0.4-0.3,0.6c-0.4,0.7-1,1.3-1.8,1.8c-1.5,1.2-3.8,1.5-4.1,2
			c-0.6,0.6-0.6,1.2-0.9,2c-0.9,1.7-2.1,3.3-3.5,4.7c-0.6,0.3-1.5,0.6-2,0.9c-0.3,0-0.9,0.3-1.2,0.3c-1.5,0.3-2.3-1.2-3.8-0.9
			c-1.2,0.3-0.9,1.8-2,2.6c-1.4,0.7-2.9,1.3-4.4,1.8c-0.4,0-0.8,0.1-1.2,0.3c-0.9,0.3-0.9,1.5-1.8,1.8c-1.5,0.9-2.3,0.6-2,2.6
			c-0.3,0.3-0.6,0.9-0.9,1.2v0.3c-0.3,0.3-0.9,0.3-0.9,0.9c-0.3,0.6-0.3,1.5-0.9,1.8c-0.6,0.5-1.3,0.7-2,0.6
			c-0.9,0.3-5.3,2.9-5.8,3.2h-0.6c2.3,0,4.7,0.3,7,0.3h90.8l0.3-12h3.2l0.3-11.4l-2.6,0.3l-0.3-3.2l0.3-1.8l0.3-5.8h2v-5.8h1.2v-2.9
			h0.6v-1.5c0-0.3,0.3-0.3,0.3-0.6c0.3-0.2,0.8-0.3,1.2-0.3h0.6l-11.7-11.4l0.6-1.2l-1.8-2l-2,2.6l-1.5-1.5l-1.2,1.2l-6.7-7.9v-0.9
			l0.6-0.9h0.3l1.5-1.5l2-2.6l-2.3-2.9l0.6-0.9l-0.6-0.3v-1.2l-1.2-1.5l-0.3,0.9l-1.5-1.8l-0.6,1.2L311.4,1234.4L311.4,1234.4z"/>
		<path id="path5008_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M257.1,1296.6
			c-0.3-0.6,0-1.2-0.3-1.5c0-0.3-0.3-0.6-0.3-0.9l0,0c0-0.9,2.6-1.8,3.5-1.5c0.3,0,0.9,0.3,1.2,0.3c0.9-0.3,1.5-0.9,2.3-1.2
			c0.3-0.3,0.6,0,0.9-0.3c0.3,0,0.6-0.6,1.2-0.9h2v0.3c-0.6,0.7-1.1,1.5-1.5,2.3c-0.9,1.5-3.2,3.5-4.7,3.8c-0.8,0.2-1.6,0.1-2.3-0.3
			C258.5,1297.4,257.6,1297.3,257.1,1296.6C257.1,1296.7,257.1,1296.7,257.1,1296.6L257.1,1296.6z"/>
		<path id="path5010_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M269.1,1286.4L269.1,1286.4
			c0.6,0.2,1.2,0.3,1.8,0.3l0.3,0.3l-0.3,0.3c-0.3,0-0.6,0-0.6,0.3c-0.6,0.3-0.9,1.2-1.8,1.2c-1.5,0-3.2-0.3-3.5-2v-1.2
			c0.3-0.6,0.9-0.3,1.2-0.9c0.3,0,2.3,0.3,2.6,0.3C268.9,1285.5,269,1285.9,269.1,1286.4z"/>
		<path id="path5014_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M251,1277.7
			c-0.3,0-0.9,0.3-1.2,0.3s-0.6-0.3-0.9-0.6c-0.3-0.3-0.3-0.6-0.6-0.6c-0.3-0.5-0.6-0.9-0.9-1.5l-0.9-0.3l0,0l-3.5,16.4
			c0,0.6-0.3,1.2,0,1.8c0.3,0.6,0.7,1,1.2,1.5c0.3,0.3,0.6,0.9,0.9,1.2c1,1.4,2.1,2.8,3.2,4.1l0.3-0.3c0.3-0.2,0.8-0.3,1.2-0.3h0.3
			c0.9-1.5,0.3-0.6,1.5-1.2c0.3-0.3,0.6-0.9,1.2-1.5c0.9-0.6,2-0.6,2.6-1.5c0.6-1.2,0.6-2.6,2-3.2l2.3-0.6c0.6,0,1.5,0.3,2.3,0.3
			c0.9,0,3.8-1.5,4.1-2.3c-0.3-0.6-0.9-1.2-1.2-1.8c-0.3-0.9-0.3-1.8-0.6-2c0-0.3-0.9-0.6-0.9-1.2s0.3-0.9,0.3-1.5
			c0-0.3,0-0.6-0.3-0.6c-0.3,0.3-0.6,0.6-0.9,0.6h-0.6c-0.6-0.3-0.6-0.6-0.9-1.2l-0.3-0.3c-0.6-0.3-1.3-0.4-2-0.3
			c-1.2-0.3-1.8-1.2-2.9-1.5c-0.6-0.3-1.5,0-2-0.3c-0.9-0.6-1.2-1.2-2.3-1.2H251V1277.7L251,1277.7z"/>
	</g>
	<g id="Cote-Nord_9" className="unsupported-region">
		<circle className="point" cx="550" cy="860" r="1" fill="transparent" />
		<path id="path5016_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M562.7,568.4H445.5v155.7h-1.2
			v218.2h-0.6v15.2h0.6v59.6h0.3v47.9l-2.6,6.7c1.8,0.3,2.3,0,2.9,0.3c0.3,0,0.6,0.1,0.9,0.3c0.3,0.3,0.6,0.3,1.2,0.9l0.3,0.3
			l0.3,0.3c0.4,0,0.8,0.1,1.2,0.3c0.6,0,0.9,0.3,1.2,0.3c0.3,0.3,0.6,0.3,0.9,0.9c0,0.3,0.3,0.3,0.3,0.6v0.9c0.3,0.3,0.3,0.6,0.6,0.9
			c0,0.3,0,0.3,0.3,0.6v0.6l0,0c0.6,0.6,0.9,1.2,1.2,1.5c0.2,0.2,0.6,0.3,0.9,0.3h0.9c0.4,0,0.8,0.1,1.2,0.3c0.3,0.3,0.6,0,0.9,0.3
			l2,0.9l0.3,0.3l0.3-0.3h0.3l0.6-0.6c0.5-0.6,0.9-1.3,1.2-2c0.6-0.9,1.8-2,2.3-2.9c0.3-0.6,0.3-1.8,0.9-2c0.3-0.6,1.5-0.3,2-0.6
			c0.6-0.3,1.2-1.5,1.8-2c0.8-0.4,1.6-0.9,2.3-1.5c1.8-1.8,1.2-3.8,2-5c0.3-0.6,0.9-0.9,1.2-1.2c1.2-1.2,1.5-2.6,2-3.8
			c0.3-0.6,0.9-0.9,1.2-1.5l0.9-2.6v-2c0.4-1.4,0.8-2.8,1.5-4.1c0.3-0.6,0.3-1.5,0.9-2c0.9-0.3,1.8,0.3,2.6,0.3
			c0.6-0.3,1.1-0.8,1.5-1.5c1.8-3.2,1.5-5.3,2.3-8.5c0.3-0.9,1.2-2,1.2-2.6c0-0.3-0.1-0.6-0.3-0.9l0.3-0.3c0.6-0.3,0.9-0.6,1.5-0.9
			c1.2-0.3,2.6,0.9,3.2-1.2c0.3-0.6,0-1.2,0-1.8c0.3-0.6,1.2-0.6,1.8-0.9l1.8-1.8c0.6-0.6,2-1.2,2.6-1.8c0.9-0.9,0.9-2.3,2-2.6
			c0.4,0,0.8,0.1,1.2,0.3c0.3,0,4.4-0.3,4.7-0.3l0.3-0.3v-0.3l-0.3-0.3c-0.6,0-1.5-0.3-2-0.3l-0.3-0.3v-0.3c0.6-1.2,2.3-0.3,2.6-1.8
			c-0.4-1.3-0.3-2.6,0.3-3.8c0.3-0.6,0.6-0.9,0.9-1.5c2-3.5,4.4-2,6.7-3.8c0.9-0.7,1.6-1.5,2.3-2.3c0.3-0.3,0.3-0.6,0.6-0.6
			c0.6-1.5-0.9-1.8-0.9-2.9c0-0.4,0.1-0.8,0.3-1.2c-0.3-0.3-0.6-0.9-1.2-1.2c0.6-0.3,1.5,0.3,1.8,0.9c0,0.6-0.3,1.5,0,2
			s1.5,0.9,1.5,1.8c0.3,3.2-2.9,4.1-3.5,5.5l0.3,0.3c0.6-0.3,1.8,0.3,2.9-0.9c0.9-0.9,1.2-2.3,2.3-2.6c0.8-0.2,1.6-0.1,2.3,0.3
			c1.5,0,3.5-0.3,4.1-1.5c0.3-0.5,0.3-1.2,0-1.8c0.1-0.6-0.1-1.3-0.6-1.8c-0.6-0.3-1.5,0.3-2.3,0.3c-0.9,0-2.6-1.5-2.6-2.6
			c0.5,0,0.9,0.2,1.2,0.6c0.9,0.3,1.9,0.2,2.6-0.3c0.3-0.3,0.3-0.6,0.6-0.9c0.6-0.3,0.9,0,1.8,0h0.3c0.9-1.2,0.3-4.7,1.8-5.3h0.3
			c0.3,0.4,0.7,0.7,1.2,0.9c0.9-0.6,0.9-2,1.5-2.3c0.6,0,0.9,0.3,1.2,0.3c1.1,0.2,2.1-0.2,2.9-0.9h0.3c0.3,0.3,0.3,0.6,0.9,0.9
			c0.6,0,0.9-0.3,1.5-0.3c1.2,0,2.3,1.2,4.1,1.2c1.2,0,0.9-1.8,2-2c0.9-0.4,1.9-0.7,2.9-0.9c0.6,0,1.2,0.3,1.8,0.3s0.6-0.6,1.2-0.9
			c0.3,0,0.6,0.3,0.9,0.3h0.3c0.4-0.5,0.9-0.9,1.5-1.2c0.7-0.2,1.4-0.1,2,0.3c1.5,0,2.9,0.1,4.4,0.3c0.9,0,1.8,0.3,2.3,0.3l0,0
			c0.9-0.3,0.9-1.2,1.2-2c0.3-1.2,1.2-1.5,2-2.3c0.3-0.6,0.3-1.5,0.9-2c0.3-0.3,0.6-0.3,0.9-0.6c0-0.2,0.1-0.3,0.3-0.3l0,0l0.9-1.8
			c0.3-0.3,0.6-0.6,0.6-0.9c1.5-2.9-0.3-5.8,0-9.1c0.4-2,1.1-4,2-5.8c0.3-0.6,1.2-1.2,1.5-1.8c0.3-0.9-0.6-2.3-0.9-3.2
			c-0.3-2,0.6-6.1,2-7.6c0.6-0.9,1.6-1.5,2.6-1.8l0.3,0.3c0.3,0.6,0,1.5,0.6,1.8l0.3,0.3h0.3c0.9-1.5,1.2-3.5,2-5.3
			c0.3-0.6,1.2-1.2,1.5-1.8c0-0.6-0.6-0.9-0.6-1.8c0-2.6,2.9-5.5,5.3-6.4c0.9-0.3,2,0,2.9-0.3c0.9-0.4,1.7-0.8,2.6-1.2
			c0.3-0.6,0.6-2,0.9-2.6c0.6-1.2,2.9-5,4.4-5l0.3-0.3c-0.6-0.3-0.9-1.8-0.9-2.3c0.3,0,0.6,0.3,0.9,0.3c0.9,0.6,0.6,0.6,1.2,1.5
			c0.6,0.6,1.5,0,2,0c0.3,0,3.2,1.8,4.1,1.8l0.3-0.3v-0.3c0.3-0.5,0.9-0.9,1.5-1.2V931l-0.3-0.3c-0.6,0-1.2,0.3-1.8,0
			c-0.3,0-0.3,0-0.6-0.3c-0.3,0.1-0.6,0.1-0.9,0.3c-0.7-0.3-1.3-0.8-1.8-1.5v-2c0.3-1.2,4.7-5.8,5.8-3.5c0.3,0.6,0,1.2,0.3,1.5
			c0,0.3,0.3,0.3,0.3,0.6s-0.3,0.6-0.3,0.9l0.9,0.9c0.3,0.3,0.3,0.9,0.9,1.2c1.2,0.3,1.5-0.6,2.6-1.2c1.2-0.6,2.3-0.3,3.5-0.6
			c1.8,0.3,1.2,0.6,2,1.2c1.2,0.9,1.5,0.3,2.6,0.6c0.6,0,1.2,0.3,1.8,0.3l0.3-0.3c0-0.9-2-1.2-0.6-3.8h0.3l0.3,0.3
			c-0.3,0.6-0.6,1.5,0,2l0.3,0.3c0.4-0.1,0.8-0.3,1.2-0.6c0.3-0.6,0.6-1.5,0.9-2c0.6-0.9,2-2.6,3.2-2.9c3.5-0.6,3.8,2,5.5,2.6h0.3
			c0-0.2,0.1-0.4,0.3-0.6c0.7,0.1,1.4,0.3,2,0.6c1.2,0.3,3.5,0,4.4-0.9c1.8-0.3,3.5-0.3,5-0.6s2.6-1.2,4.4-1.5c1.2-0.3,2.6,0,3.8-0.3
			c0.9,0,1.5-0.6,2.3-0.6c0.9,0,1.5,0.3,2.3,0.3l0.9-0.9h0.6c0.3,0.3,0,0.6,0.3,0.6c0.3,0.6,1.2,0.9,1.5,1.2c2,1.2,1.8-0.3,4.1,0
			c0.6,0,1.5,0.6,2.3,0.6c1.4,0.5,2.9,0.7,4.4,0.6h1.5c0.6,0,0.6-0.9,1.2-0.9c0.3,0,0.6,0.3,0.6,0.6s-0.3,0.6-0.3,0.9
			c0.3,0.3,0.6,0.3,0.9,0.6c1.2,0.3,2.6-0.6,3.8-0.9c1.5,0,2.3,0.6,3.2,0.3c0.6,0,1.2-0.6,1.8-0.6c1.5,0,3.2,0.6,4.4-0.6
			c0.9-0.6,1.8,0,2.6-0.3c1.1-0.6,2.2-1.3,3.2-2c2-0.6,3.2,1.5,4.7,2c0.6,0.3,1.2,0,1.8,0.3c0.9,0.3,2,0.9,2.9,1.2s4.4,0.9,5,0.6
			c1.2-0.3,1.8-1.5,2.6-2c1.2-0.6,2.9,0,4.1-0.3c0.6-0.3,1.2-0.9,1.8-1.2c1.2,0,5,0.6,5.5,0.3c0.3,0,0.9-0.6,1.2-0.6h0.9
			c0.3,0.3,0.6,0.9,0.9,1.2c0.6-0.3,3.5-0.3,4.1,0.3c0.3,0.6,0.3,1.2,0.6,1.8l0.6,0.6h0.3c0-0.3,0.3-0.6,0.3-0.9c0.6,0,1.2,0,1.5,0.6
			c0,0.3,0.3,0.6,0.6,0.9l0.3,0.3c0.6,0,0.9-0.9,1.8-1.2c2-0.3,4.7,0,5.3,2.3c0,0.5,0.1,1,0.3,1.5c0.6-0.3,2,0,2.3-0.3
			c0.3-2.9,2.9-1.8,4.4-1.8h1.5c0.3,0,0.9,0.3,1.2,0.3c0.6-1.2,2-0.9,3.2-1.5v-0.3c-0.6-0.6-1.2-1.2-0.9-2c1.5-0.9,2.6-0.3,4.1,0h0.9
			c0.9-0.3,1.2-0.3,1.2-1.5c0.6-0.6,1.5-0.8,2.3-0.6c0.3,0.6,0,1.2,0.6,1.8c1.5,0.9,2.6-0.3,3.8-0.3c0.3,0,0.6,0.3,0.9,0.3h2.9
			c0.3-0.3,0.3-0.9,0.6-1.2c0,0.3,0.3,0.6,0.6,0.6s0.3-0.3,0.6-0.6c0.3,0,0.6,0.1,0.9,0.3c0.9,0,1.2-1.2,1.8-1.2l0.3,0.3L775,921
			c-0.5,0.5-1.1,0.9-1.8,1.2v0.3c0.6,0.3,1.2,0,2,0c0.6,0,1.5,0.6,2,0.6c0.3,0,1.5-0.3,1.8-0.3c0.2,0,0.4,0.1,0.6,0.3
			c0.6-0.3,1.5,0.3,2,0.3c0.3-0.4,0.7-0.7,1.2-0.9c0.9,0.3,0.6,0.6,1.2,1.2h0.3c0.3,0,0.3-0.3,0.6-0.6h0.3l0.3,0.3
			c0.3,0.3,0.6,0.9,0.9,1.2h0.3c0.9-0.6,1.2-2.3,2.3-2.6l0.6,0.3v1.2l0.3,0.3c0.9-0.3,1.8-1.2,2.9-0.6c0.3,0.3,0.3,1.2,0.6,1.2
			s0.6-0.3,1.2-0.3c0.6,0.3,2.6,2.3,3.2,2.3c0.3,0,1.8-0.3,2.3-0.3c0.9,0.2,1.8,0.5,2.6,0.9h2c0.9,0,1.8,0.3,2.3,0.3
			c1.5,0,0.9-1.8,3.5-0.6c0.2,0,0.4-0.1,0.6-0.3h0.9c0.3,0.3,0.3,0.6,0.6,0.9l0.6-0.6h0.3c0.3,0.3,0.6,0.3,0.6,0.6v0.3
			c0,0.3-0.3,0.6-0.3,0.9l0.3,0.3c1.5,0.9,2.6,1.2,2.3,3.2c0,0.6-0.3,0.9-0.3,1.5c0,0.3,0.3,0.6,0.6,0.6s1.8,0.3,2,0
			c0.6-0.3,1.5-1.2,2-1.5c1.5-0.9,2.9-0.6,4.4-1.5c0.3-0.3,0.3-0.9,0.9-1.5h0.3l0.3,0.3c1.5,2.3-2.3,2.9-3.8,3.5
			c-0.5,0.3-0.9,0.5-1.5,0.6h-0.6c-0.9,0.3-0.9,0.6-1.5,1.2c0,0.3-0.3,0.3-0.6,0.3h-1.5c0,0.6,0.9,0.6,1.2,0.9c0.9,0.1,1.8,0.1,2.6,0
			c1.5-0.6,2.6-1.8,4.1-2.3c0.6-0.3,0.9-0.3,1.5-0.6c1.5-0.6,2.6-1.2,4.1-1.8c0.8-0.4,1.7-0.7,2.6-0.9c0.8,0,1.6-0.1,2.3-0.3
			c1.2-0.6,2.5-1.2,3.8-1.8c0.3,0.1,0.6,0.2,0.9,0.3c0.6-0.6,2.3-0.6,3.2-0.6c0,0.2,0.1,0.4,0.3,0.6h0.9l0.3,0.3v0.3l0.3,0.3l0.3,0.3
			l0.3-0.3c0-0.6-0.3-1.2,0-1.5h0.6l1.2,1.2l0.3-0.3v-0.9l0.6-0.6h0.3l0.9,0.9h0.3l0.3-0.3c0-1.2,0.3-1.8,1.5-1.5
			c0.6,0.3,1.2,1.2,2,0.9c0.3,0,0.3-0.3,0.6-0.3l0.3,0.3c0.3,0.3,0,0.9,0,1.2l0.3,0.3h0.3c0-1.5,1.5-2,2.6-2.3
			c1.2-1.2,0.6-1.5,2.6-2.6c0.4-0.3,1-0.4,1.5-0.3l0.3,0.3c-0.9,0.9-2.3,1.2-2.6,2.6v0.6c0.3,0,0.6-0.3,0.9-0.6
			c0.4,0.4,0.7,0.9,0.9,1.5l0.3,0.3c0.6-1.2,1.2-0.9,1.5-2.3c0.3-0.3,0.9-0.3,1.2-0.6h0.3v0.9l0.3,0.3c0.6,0,1.2-0.6,1.8-0.6v0.9
			c0.5,0,1-0.1,1.5-0.3c0.2-0.5,0.5-1,0.9-1.5h0.3l0.3,0.3c0,0.6-0.6,1.2-0.3,1.8c0.3,0.3,1.2,1.2,1.8,0.9c1.5-1.2,1.8-0.6,2.6-1.2
			c0.2-0.9,0.9-1.7,1.8-2h1.5c0-0.3,0.3-0.6,0.6-0.6h0.3v0.3c-0.2,0.2-0.3,0.6-0.3,0.9l0.3,0.3h0.3c0.3-0.3,0.6-0.9,1.2-0.9
			c0.7,0.5,1.3,1.2,1.5,2h0.9l0.3-0.3c-0.1-0.5-0.3-1-0.6-1.5l0.3-0.3h0.3c0.9,0,2,1.2,2.6,0.6c0.3-0.3,0.3-0.9,0.6-1.5
			c0-0.3-0.1-0.6-0.3-0.9c0-0.3,0.1-0.6,0.3-0.9h0.3c-0.1,0.6,0.1,1.3,0.6,1.8c0.3,0,0.6,0,0.6-0.3h0.3v0.3c0,0.9-0.9,1.2-1.5,2
			c0.4,0.3,1,0.3,1.5,0c0.6-1.2,2.3-4.1,3.8-4.1l0.3,0.3v0.3c-0.3,0.6-0.9,0.9-0.6,1.8l0.3,0.3v0.3c-0.2,0.5-0.6,0.9-1.2,1.2v0.3
			c0.6,0,0.9-0.3,1.2-0.3l0.3,0.3c0,0.6-0.3,1.2-0.3,1.8l0.3,0.3c0.6,0,0.9-0.9,1.2-1.2l-0.3-0.9l0.3,0.3c0-0.3,0.3-0.6,0.6-0.9
			c0.3,0,0.6,0.3,0.9,0.3c0.2-0.2,0.3-0.6,0.3-0.9l0.3-0.3h0.9l0.9,0.9c0.6,0,0.9-0.9,1.2-1.2h0.9c0.3-0.3,0.3-0.9,0.9-1.5h0.3
			l0.3,0.6l0.3,0.3c0.6-0.3,0-1.2,0.6-1.5c0.6-0.7,1.4-1.2,2.3-1.5l1.2-1.2c0.3-0.3,0.9-0.3,1.2-0.6c0.7-0.5,1.1-1.5,0.9-2.3
			l-0.3-0.3l-0.3,0.3c-0.3,0.3-0.3,1.2-0.3,1.8h-0.3c-0.2,0-0.4-0.1-0.6-0.3c0-0.9-0.3-1.2,0.3-2v-0.3c-0.4,0-0.8-0.1-1.2-0.3
			c0.4-0.9,0.9-1.8,1.5-2.6l0.9-0.9c0.6-0.6,0.6-1.5,1.2-2c0.3-0.3,2.6,0,2.9,0s0.3-0.3,0.6-0.3c0-0.3,0.1-0.6,0.3-0.9
			c0.3-0.3,0.9,0,1.5,0c0.9,0,0.9-0.3,1.8-0.6s2.3-0.3,3.2-0.6c1.2-0.6,1.2-3.2,2-3.8h0.6c0.9-0.6,0-2.6,1.2-2.9c0.3,0,0.6,0.3,0.9,0
			c0.9-1.2,0.6-3.5,1.8-4.7c0.3,0,0.6,0.3,0.9,0.3s0.6-0.3,0.9-0.6l0.3-0.3c0-0.3-0.6-0.9-0.9-1.2v-0.9c0.3,0,0.9,0.3,1.2,0.3
			s0.6-0.3,1.2-0.3h1.2c1.2-0.9,0.9-2.6,1.5-3.2c0.6-0.3,1.8,0,2.3-0.6c0.6-0.9-0.3-2.9,0.6-3.5c0.3,0,0.6,0.1,0.9,0.3
			c0.3,0.9-0.3,0.9,0.9,2c0.3,0.3,0.3,0.3,0.6,0.3l0.3-0.3c0-0.3,0.3-0.6,0.3-0.9s-0.3-0.6-0.3-0.9c0.5-0.2,1-0.5,1.5-0.9
			c0.6-0.3,0.6-1.5,1.2-1.8c0.3-0.3,0.6-0.3,0.9-0.9v-1.2c0.2-0.7,0.5-1.4,0.9-2c0.3-0.9-0.6-1.5,0-2.3c0.6-0.3,0.9-0.9,1.5-0.9
			c0,0.6,0.3,0.9,0.3,1.5c0,0.3-0.3,0.6-0.3,0.9c-0.3,0.3-0.3,0.9-0.6,1.2c0,0.3-0.3,0.6-0.6,0.9c-0.5,0.9-0.6,2-0.3,2.9
			c0,0.5,0.1,1,0.3,1.5c0.6,0.3,0.6,0.9,0.9,1.2c0.6,0.6,1.8,0.6,2.3,0.9l0.3-0.3c-0.4-0.4-0.7-0.9-0.9-1.5l0.3-0.3
			c1.8,0,1.5-1.5,2.3-2.6c0.3-0.3,0.9-0.6,1.2-0.9v-0.3c0-0.3-0.6-0.6-0.9-0.9c-1.5-1.8,0.6-2,1.2-2.9v-0.3c-0.2-0.2-0.6-0.3-0.9-0.3
			l-0.3-0.3c0.3-0.6,0.3-0.9,0.6-1.2c0-0.2-0.1-0.4-0.3-0.6c0-0.3,0.3-0.6,0.3-0.9v-0.3c-0.3-0.3-0.6-0.3-0.9-0.6
			c-1.2-0.9,1.5-2.9,0.3-4.4H945c-0.2,0-0.4,0.1-0.6,0.3c-0.6,0-1.2-0.3-1.2-0.9c0-0.6,0.6-0.9,1.2-0.9l0.3,0.3h0.3
			c0.9-0.9,1.2-2.9,3.2-2.6c0.4,0.4,0.6,0.9,0.6,1.5c-0.3,1.2-2,2-1.2,3.2h0.6c0.6-0.6,0.6-1.5,0.9-2c0.6-0.6,1.5-0.9,1.8-1.8v-0.3
			l-0.3-0.3h-0.3c0.3-0.9-0.3-1.2,0.3-2.3l0.3-0.3c0,0.2,0.1,0.4,0.3,0.6l0.3,0.3c0.3,0,0.6-0.3,0.9-0.6c0-0.3,0.3-0.6,0.3-0.9
			l0.3,0.3h0.6c0.3-0.3,0.3-0.6,0.6-0.9h0.3c0.3,0,0.6,0.3,0.9,0.3l0,0c1.2-0.6,1.5-2,2.6-2.9c0.6-0.3,1.5,0,2.6-1.2
			c0.6-0.9,0.3-1.2,0.6-2h0.3l0.3-0.3c-0.5-0.1-1-0.3-1.5-0.6c-0.6-0.3-0.9-0.9-1.5-1.5c-0.9-0.9-1.2-0.9-1.5-2.3
			c0-0.4,0.1-0.8,0.3-1.2h0.3c0.4,0.6,0.6,1.3,0.6,2c0.6,0.3,1.4,0,1.8-0.6c0-0.9-1.2-1.8-0.6-2.9h0.3c2.3,0.9,0.9,1.8,1.5,2.6
			c0.9,0,3.5-2.9,4.1-3.8c0.3-0.3,0.3-0.9,0.6-1.2c0.9-0.3,1.8-0.3,2.6,0c0,0.2,0.1,0.4,0.3,0.6l0.3,0.3h0.9c0,0.3,0.3,0.3,0.6,0.6
			h0.9c1.2,0.3,1.8,1.8,2.9,1.5l0.3-0.3c-0.3-0.3-0.6-0.6-0.6-0.9l0.3-0.3c0-0.3,0.1-0.6,0.3-0.9h0.3c0.3-0.5,0.5-1.1,0.6-1.8v-0.3
			c-0.3,0-0.6-0.3-0.9-0.3l0.3-0.3c0.3,0,0.6,0.1,0.9,0.3l0.3-0.3l0.3,0.3c0.6,1.2-0.9,2.6-0.6,3.2c0,0.3,0.6,0.6,0.6,0.9
			c1.3,0.6,2.9,0.3,3.8-0.9c0.3-0.3,0.3-0.6,0.9-0.9c0,0.3,0,0.6,0.3,0.6h0.3c0.6-0.3,0.9-0.9,1.5-0.9c0.6-0.3,1.2,0,1.8-0.3l0,0
			c0.3-0.3,0.3-0.9,0.9-0.9l0.3,0.6l0.6,0.3c2.3-0.9,1.8-1.2,2.9-2.9c0.3-0.3,0.9-0.6,1.2-1.2l0,0c0.6-0.6,1.5-0.6,1.8-1.2v-0.9
			l2.6-1.8c0.3-0.6,0.9-1.8,1.8-1.5c0,0.6-1.2,1.8,0.9,2.6l0.3-0.3c0-0.3-0.3-2,0-2.3c0-0.6,0.6-0.9,0.6-1.5c0.3-0.6,0-1.2,0.3-1.8
			c1.5,0,1.5,0.9,2.9-0.3c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.9-0.1,1.8,0.3,2.6h0.3c0.6-0.3,1.8,0,2.3-0.6c0.9-0.6,0.9-2,1.8-2l0.3,0.3
			v0.3c-0.3,0.6-0.9,1.2-1.2,1.8l0.3,0.3c0.7-0.4,1.5-0.7,2.3-0.9c1.2,0,1.5,0.3,2.6-0.6c0.1-0.4,0.3-0.8,0.6-1.2h0.3
			c0,0.4,0.1,0.8,0.3,1.2l0.3,0.3c0.3,0,0.6-0.3,1.2-0.3l0.3-0.6c0-0.3-0.6-0.9-0.6-1.5c0.3-0.3,0.6-0.6,0.9-0.6l0.3,0.3
			c0,0.3,0.3,0.3,0.3,0.6l0.3,0.3c0.3-0.6,0.3-1.2,0.9-1.5h1.5c1.2,0,3.8-1.2,4.7-1.2l0,0c0.5,0,0.8,0.4,0.9,0.9
			c-0.6,2-0.9,2.9,0.3,4.7c0.3,0.6,0.6,0.6,0.6,1.2l0.3,0.3c0.3,0,0.9,0.3,1.2,0.3c0.6-0.3,1.2-1.2,1.8-1.2c0.4,0.1,0.8,0.3,1.2,0.6
			c0-8.2,0.3-17.8,0.3-22.8s-0.3-18.4-0.3-19.9h-68.6c-5.5,0-17.2,0.3-17.5,0.3c-27.8-0.6-103.4-0.6-109-0.6c-8.8,0-76,0.3-78.3,0.3
			c-10.2,0-20.2,0-30.4,0.3c0.3-0.6,0.9-2.3,0-2.9h-0.6c-0.3-0.6-0.6-3.2,0-3.8c0.3-0.2,0.8-0.3,1.2-0.3c0.6,0.9,1.5,0.9,2,1.8
			c0.9,0.3,1.5,0.9,2.3,1.2c0.5,0,1-0.1,1.5-0.3l0.6,0.6h0.9V792c-0.6-0.9-0.9-2.6-1.8-3.2h-0.3l-0.6,0.3c0-0.9,0-2.6,0.6-3.2
			c-0.3-0.9-0.3-1.8-0.6-2.6c0-0.3-0.3-0.6-0.6-1.2c-0.3-0.6,0-1.2-0.3-1.8c-0.4-0.9-0.9-1.8-1.5-2.6v-0.6l0.3-0.6v-0.3
			c-0.3-0.3-0.9-1.2-1.2-1.2l0.3-0.3v-0.3c-0.3-0.3-0.6-0.9-1.2-1.2c-0.5-0.1-1-0.3-1.5-0.6v-0.3h0.3c0.6,0.3,1.2,0,1.5,0.3
			c0.6,0,1.2,0.6,1.8,0.3l0.3-0.3v-0.3c-0.3,0-1.2-0.9-1.5-1.2c-0.8,0-1.6-0.1-2.3-0.3v0.3l0.3,0.3l-0.3,0.3H724
			c-0.3,0-0.6,0.3-0.9,0.3l-0.3-0.3c-0.7-0.4-1.2-0.9-1.8-1.5c-0.9-0.6-2-0.6-2.6-1.5c-0.6,0.3-1.2-0.3-1.8-0.3
			c-0.3-0.3-1.2-1.8-0.9-2l0.3-0.6c-0.6-2.9-4.4-2.3-2.9-7.3l0.3-0.3c0-0.9,0.9-1.8,1.2-2.6c0.3-0.6,0-1.8,0.6-2.3
			c1.5-0.3,4.4-2.9,5.3-2.6c0.8-0.7,1.7-1.3,2.6-1.8c0.6,0,0.9,0.3,1.5,0l0.3-0.3c0.9-0.3,1.8-0.3,2.6-0.6h2.3
			c1.6-0.2,3.1-0.4,4.7-0.9c1.2,0,6.7-0.3,7.9,0l0.9-0.9l0.6,0.3l0.3-0.3v-0.3l-0.3-1.2c-0.3-0.3-0.7-0.5-1.2-0.6
			c-0.6-0.6-1.5-2-2-2.3c-0.6,0-1.8-0.3-2.3-0.3c-0.9-0.6-1.6-1.4-2-2.3c-0.7-0.5-1.5-0.8-2.3-0.9c-0.3-0.3-0.3-1.2-0.3-1.5l1.2-0.9
			c-0.3-0.6,0-2.6,0.6-2.9v-0.6c-0.3-0.3-0.6-0.9-1.2-0.9l-0.3-1.5h-0.6c-0.6,0.6,0,1.8-0.3,2.6c-0.5-0.5-1.1-0.8-1.8-0.9v0.3
			c0.5,0.7,0.7,1.5,0.6,2.3c-0.6,0.6-2,0.6-2.6,0.9c-0.6,0.6-0.9,1.5-1.5,1.8v0.9H726c-2,1.8-3.8-0.3-4.7,0c-0.3,0.3-0.9,0.6-0.9,1.2
			c-2.6,0.3-1.2,3.5-5.3,2l-0.3-0.3l-0.6-0.3h-0.6l-0.6,0.6c-0.6,0-1.2-0.6-2-0.3l-0.3,0.3c-0.3,0-0.9-0.3-1.2-0.3H709l-0.6,0.6
			c0,0,0.6,2.3,0.6,2.6s-0.6,0.6-0.6,0.6h-0.3l-0.6,0.6c0,0.9,0.3,1.8,0.3,2.6c-0.3,0.8-0.4,1.5-0.3,2.3v1.2l-0.9-0.6h-0.3L706,751
			v0.6h-1.2v0.3c5.8,4.7,3.2,10.8,5.3,12.9c0.6,1.8-1.8,3.5-2,5.3h-0.9c-0.3,0.3,0.3,1.5,0.3,1.8l-0.3,0.3c-0.6,0.6-1.8,0.6-1.8,1.5
			h-0.6l-0.6,0.6c-0.3,0.9,0.3,1.5,0.6,2.3c0.3,0.3-0.3,1.2,0.3,2c0.3,0.6,0.6,1.5,0.9,2c0.2,0.4,0.3,1,0.3,1.5
			c0.3,1.2,1.2,2.3,0.9,3.5l-0.3,0.3c-0.9-1.2-1.5-0.9-2.3-1.8H704l-0.6,0.9l-0.3,3.2v0.3c-0.5,0.1-1,0.3-1.5,0.6l-0.3,0.3
			c0,0.9,0.6,1.5,0.9,2.3c0,0.5,0.1,1,0.3,1.5c0.3,1.2,1.5,2,1.5,3.5c-0.9,0.6-3.5-2.6-4.4-3.2c-0.3,0-0.6,0.1-0.9,0.3l-0.3,0.6
			c0,0.4,0.1,0.8,0.3,1.2c0.3,0.9,0.3,2.6,0.6,3.5c0,0.3-0.3,0.6-0.3,1.2c0,0.9,0.6,1.8,0.3,2.6c0,0.9-0.6,1.5-0.6,2.6
			c0.4,1.7,1.1,3.4,2,5c-0.3,0.9-0.3,2.9,0.9,3.2l0.3,0.6c-0.3,1.2-1.2,2.6-1.5,3.5c-1.2,1.8-2.3,3.8-4.4,2l-0.9,0.3
			c-0.9,0.6-0.3,2.3-0.9,3.2c-0.3,0.3-1.2,0.6-1.5,1.2c-0.9,0-1.8,1.8-2.9,1.5c-0.9-0.6-0.6-2-1.5-2.6l-0.6-0.3l-0.6,0.3
			c-0.3,0-0.3-0.6-0.3-0.6c-0.6-2.6,0.3-4.1-2.6-5.3l-0.9-2.3c-0.6-1.8-0.3-1.5-2-2.3c-0.5-0.4-1.1-0.6-1.8-0.6l-0.6,0.3h-0.3
			l-0.3-0.6c-0.3-0.3-5.8,0-6.1,0.3l-0.3,0.6l0.3,0.3l0.6,0.3c0.3,0.6,0,1.8,0,2.3l-0.3,0.3c-1.2,0-1.8-1.2-2.9-2h-0.3
			c-0.6-0.6-1.3-1.1-2-1.5c-1.8,0-1.8,1.8-4.1,0.9c-0.3,0-0.3-0.3-0.6-0.3s-0.9,0-0.9-0.3c-0.3-0.9,1.2-2.3-0.3-4.1
			c-0.9,0-2.6,0.3-2.9-0.9c0-0.6,0.3-1.8,0-2c0-0.3-0.6-0.6-0.6-0.9c-0.9,0-1.8,0.1-2.6,0.3c-0.4,0.7-0.5,1.5-0.3,2.3
			c0,0.3-0.3,0.9-0.6,0.9c-0.6-0.6-0.3-1.2-0.6-1.8c-0.3-0.9-1.2-1.5-1.5-2.3c0-1.2,0.9-1.5,1.5-2.3c0.2-1.1,0.3-2.1,0.3-3.2
			c0-0.3-0.3-0.3-0.3-0.6c-0.3-0.6,0-1.5-0.9-2h-0.3c0,0.3-0.6,0.9-0.6,1.2l-1.8-2c-0.2-0.2-0.4-0.3-0.6-0.3
			c-2.6-2.9-0.6-5.5-1.5-7.3l-0.6-0.3c-0.6,0.3-2.6,4.1-2.6,4.7c-0.4,0.3-0.9,0.5-1.5,0.6l-0.9-0.6l-0.6,0.3
			c-0.8,1.1-1.3,2.4-1.5,3.8l-0.3,0.6h-0.3c-0.3-1.3-0.3-2.7,0-4.1c0,0,0.6-0.6,0.6-0.9c0.6-1.2-0.3-3.2-0.6-4.4l-0.3-0.3
			c-0.9,0.5-1.7,1.2-2.3,2h-0.3l-0.6-0.9h-0.6c-0.3-0.2-0.8-0.3-1.2-0.3c-0.3-0.3-1.2-1.2-1.8-1.2l-0.6,0.3h-0.9
			c-1.5,0.6-1.2,1.8-2,2.6c-0.3-0.1-0.6-0.1-0.9-0.3l-1.2-0.9l-0.3,0.3c-0.9,0.9-0.3,2.3-0.9,2.9h-0.3l-0.3-0.3
			c-0.9,0.3-1.5-0.9-1.8-1.5c-0.4-0.4-0.9-0.7-1.5-0.9c-0.3-0.3-1.2,0-1.5-0.3v0.2c0.3-0.9,0.9-4.1,0.6-5c-0.3-0.6-0.6-0.9-0.9-1.5
			c-0.4-1.1-1.1-2.2-2-2.9c-0.6,0-1.2,0.9-1.5,1.5h-0.3c-0.3-0.6-0.9-1.5-1.2-2c-0.3-0.6-0.3-1.5-0.6-2h-0.3l-0.6-0.3l-0.6-2.6
			l-0.6-0.9h-0.3l-0.6,0.9v1.2l-0.6-0.6l-0.3,0.3v1.2c0,0.6,0.3,1.2,0.3,1.8v2.3c0.3,0.6,0.6,1.5,0.9,2l0.3,1.8c0.2,1-0.2,2-0.9,2.6
			h-0.6c-0.3-0.3-0.6-0.9-1.2-1.2h0.5l-0.6-0.3c-0.3-0.6,0.3-1.5,0-2c-0.3-0.6-0.6-0.9-0.9-1.5s-0.6-1.5-0.9-2l-0.6,0.9h-0.3
			c-0.9-0.6-1.8-2-1.2-3.2l0.9,0.3l0.3-0.3c0.3-0.6-0.6-1.2-0.9-1.8c-0.3-0.3-0.6-1.5-0.9-1.5c-0.7-1-0.5-2.5,0.5-3.2
			c0.2-0.1,0.4-0.2,0.6-0.3c0.6-0.6,0.6-1.5,0.9-2h0.3c0.6,0,1.8,1.2,2,1.8h0.9l0.6-0.3c0-0.9-0.1-1.8-0.3-2.6l-0.9-0.9
			c-0.6-1.2,1.5-3.5-1.2-4.4c0-0.3-0.3-0.6,0-0.9c0.3,0,0.6,0.3,0.9,0.6h0.3l0.3-0.3c0-2.6-1.2-2.6-1.5-3.2c0.3-0.3,0.6-0.9,0.9-1.2
			c0-1.5-1.8-4.1-2.3-5.3c-0.9-1.5-0.3-1.8,0.3-3.2c0.2-0.5,0.5-1,0.9-1.5h0.6c0.3,0.3,2.9,4.7,3.8,5.5h0.3c0.8-0.6,1.1-1.7,0.9-2.6
			c-0.9-0.9-2.6-4.4-2.3-5.8c0-0.6,0.6-1.2,0.6-2s-0.3-1.5-0.3-2.3c-0.6-0.6-0.6-1.2-0.9-1.8c-0.4-0.7-0.9-1.2-1.5-1.8
			c-0.8-1.1-1.3-2.4-1.5-3.8c2-0.3,4.4,1.5,4.7,1.5l0.6-0.3l0.9-1.8c0.6-2-0.9-2.6-1.5-4.7c-0.3-0.9-0.3-1.8-0.6-2.6
			c-0.4-1-0.9-2-1.5-2.9c-0.3-0.6-2.9-2.9-3.8-2.9c-0.6,0-0.6,1.5-1.2,1.5c-0.3,0,0.3,1.8,0,1.8c-0.5,0.9-0.9,1.9-1.2,2.9l-0.3,0.3
			h-0.3c-0.3-0.3-0.6-0.6-0.9-0.6c0,0.3-0.3,0.6-0.6,0.9c-1.2,1.2-2.6,0.3-3.5-0.9c-0.7,0.6-1.1,1.4-1.2,2.3c0,0.6,0.3,1.8,0.3,2.3
			l-0.6,0.3c-0.3,0.6,0,1.2,0,2c-0.3,2.6-0.9,3.5,0.3,5.8v0.3l-0.9-0.6c-0.6,0-1.5,1.8-1.5,2.3h-0.3c-0.9-1-1.9-1.8-2.9-2.6h-0.3
			c0.6,1.8,1.5,4.7,1.2,6.4c-0.3,1.2-0.9,1.5-0.3,2.9v0.3h-0.6c-1.2-0.9-1.8-4.7-2.6-5.8H589c-0.3,0.8-0.4,1.5-0.3,2.3v0.3h-0.6
			c-0.3,0.3,0.6,1.8,0.3,2.6l-0.3,0.3c-2,0-2.6-5.5-4.4-6.4c-0.9,0.2-1.8,0.5-2.6,0.9c-0.6-0.3-1.5-2.3-1.8-2.9
			c0.3-0.9,0.6-5.3-0.3-6.4l-0.9-0.9h-0.3c0,0.4,0.1,0.8,0.3,1.2l-0.3,0.3c-0.9-0.3-2.9,3.2-3.5,3.2h-0.6c-0.5-0.6-1.1-1.1-1.8-1.5
			c-0.2-0.2-0.6-0.3-0.9-0.3c-0.6-0.3-1.2-1.2-1.8-1.5c-0.1-0.4-0.3-0.8-0.6-1.2l-1.2,0.3c-1.2-0.3-1.2-3.5-1.2-4.4l0.9-0.9
			c1.5-0.3,2,0.3,3.5-1.2c0.3-0.3,0.6-0.6,0.6-0.9l-0.3-0.3c-0.6,0-1.2-0.1-1.8-0.3c-0.9,0-1.2,0.6-2,0.6c-0.6,0-1.1-0.5-1.2-1.2
			c-0.3-0.6,0-0.9,0-1.5c-0.3-1.2-1.2-2.6-1.2-3.8c0.1-0.9,0.1-1.8,0-2.6c0.2-0.6,0.3-1.2,0.3-1.8h0.6c0.3,0,0.6,1.2,0.9,1.5h0.3
			c1.5-1.5,2.6-2,2.6-4.4l0.6-0.6c1.2,0.6,1.5,2,2.3,2.3c1.1-0.2,2.2-0.1,3.2,0.3v0.3l-0.3,0.6c0.3,0.3,0.9,0.9,1.2,0.9l0.3,0.6
			l0.3,0.3c0.3-0.3,2-1.8,2.6-1.8c0.6,0.6-0.9,2.9-0.3,3.5c0.6,0.7,1.4,1.1,2.3,1.2c0.6-0.7,1.1-1.5,1.5-2.3v-0.6
			c1.5-2-1.2-2.9-0.6-4.1l0.3-0.3h0.6l0.6-0.3c0.3-0.6,0-1.5,0-2.3c0-0.9-0.3-2.3-0.3-3.2c0-1.2,1.5-3.8,0.9-4.7l-0.3-0.3
			c-0.6-0.3-2.3-0.6-2.6-1.2l0.9-0.9c0.2-0.7,0.3-1.4,0.3-2l0.3-0.3l0.9,0.9h0.3l1.5-2c1.5,0,2-1.5,2.3-2.6V691c-0.3,0-0.9,0.3-1.5,0
			c-0.6-1-0.9-2.1-0.9-3.2l-0.3-0.3c-0.9,0-1.8,0.6-2.3,0.3l-0.3-0.3v-1.8c-0.9-0.9-1.8-2.9-3.2-2.9c-1.2,0.3-2.9,0.6-4.1,0
			c-0.3-0.3-0.6-0.9-1.2-1.2h-0.6l-0.9,0.9h-0.3c-0.3,0-1.5-1.2-1.8-1.5c-0.5,0.1-1,0.2-1.5,0.3c-1.5-0.6-1.2-3.2-1.8-3.5
			c-2.3-0.6-1.8-1.8-2.6-3.5c-0.3-0.9-0.9-1.5-0.9-2.3h0.9v-0.3c0-0.3,0-0.9-0.3-0.9c-0.6-1.2,0-1.5-0.6-2.6h-0.6l-0.6,0.6
			c-0.5,0.3-1.1,0.5-1.8,0.6c-1-0.7-1.6-2-1.5-3.2c-0.9-0.2-1.7-0.3-2.6-0.3c-1.5,0-1.8-0.3-0.3-0.9c1.5-0.6,1.5-0.9,0.6-1.2
			c-1.2-0.3-1.8-1.2,0-1.2c1,0.1,1.9-0.2,2.6-0.9c0.9-0.6,1.8-2,0.9-1.8c-0.8,0.2-1.6,0.2-2.3,0h-0.3V659l-0.6-0.9
			c-1.2,0-2-1.5-2-2.3c0.3-0.6,0-2.3-0.6-2.6c-0.3-0.3-0.9-0.3-1.2-0.6h-0.3c-0.9-0.9-0.9-2.3-1.8-3.2c-0.2-0.2-0.6-0.3-0.9-0.3
			c-0.6-0.3-1.5-0.6-2-0.9c-0.9-0.6-1.2-1.5-2.3-1.5l-0.3-0.6c0.3-1.5,1.8-2.6,1.8-4.1l0.6-0.6v-0.6c-0.4-0.5-0.9-0.8-1.5-0.9
			l-0.3-0.9c0-4.1,6.4-2,7.3-3.2v-0.3c0-0.3-0.3-0.9,0-1.2c0.6-0.3,1.5-1.2,1.2-1.8l-0.3-0.3c-1.5-1.2-2.6-1.5-2.6-3.5
			c0-0.3,0.3-0.6,0-1.2l0.6-1.5c0-0.6-1.2-1.8-1.5-2c-0.9,0-1.8-1.5-1.8-2.3c0-0.6,0.3-1.5,0.3-2.3l-0.3-1.2
			c-0.7-0.7-1.2-1.5-1.8-2.3l0.3-0.6c0.3-0.3,0.6-0.6,0.9-0.6c0.7,0,1.4,0.1,2,0.3l0.9-0.3c0.9-0.9,0.9-3.2,1.8-4.1
			c0.3,0.5,0.5,1.1,0.6,1.8l0.6,0.3h1.2l0.3-0.9l0.6-0.6c0.8,0.1,1.6,0.3,2.3,0.6c0,0,1.8,0.6,1.5-0.9s0.3-1.5,0.6-1.8
			c0.6-0.3,0.3-1.8-0.6-2c-0.9-0.6-1.2-0.9-1.2-1.5c0.1-0.7,0.6-1.2,1.2-1.5c0.6-0.3,1.2-0.3,2.3,0.3c1.2,0.6,1.2,0.9,1.2,1.2
			s0.6,0.6,1.2,1.2l1.5,1.5l0.6,0.6c0.3,0.4,0.7,0.6,1.2,0.6c0.5,0.5,0.9,1.1,1.2,1.8l0.6,0.3c0.9-0.9,1.7-2,2.3-3.2v-0.3
			c-0.6-0.3-1.2-0.9-1.8-1.2c-1.5-1.5,0-2-0.6-3.5l1.2,0.6l0.3-0.3l0.9-1.8c1.2,0.3,3.2,0,3.8-0.9c0.3-0.8,0.7-1.6,1.2-2.3
			c1.2-1.2,2.3-1.2,2.3-3.2s-1.8-3.2-2.9-5c-0.6-0.9-1.8-2.3-2.9-2l-0.3-0.3c-0.3-0.6-0.3-1.5-0.6-1.8c-0.6-1.5-2-2.3-2.9-3.8
			l-0.9-1.8c-0.9-1.5-1.5-1.2-1.8-2.9c-0.6-0.9-2.3-1.5-2.6-2.6C562.7,569.9,563,569,562.7,568.4L562.7,568.4z"/>
	</g>
	<g id="Outaouais_7" className="region">
		<circle className="point" cx="140" cy="1210" r="1" fill="transparent" />
		<path id="path5018_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M138.8,1164.3l-48.8,0.3
			c-0.6,0-0.3,0.9,0,0.9c0.2,0,0.4,0.1,0.6,0.3c0.2,0,0.3,0.1,0.3,0.3l0,0v0.3c0,0.3-0.3,0.3-0.3,0.6v3.2c0,0.3,0.1,0.6,0.3,0.9
			c0.4,0.5,0.4,1.2,0,1.8c0,0.9-0.1,1.8-0.3,2.6c0,0.6,0,0.6-0.3,0.6l-0.3-0.3v-3.8c0-0.3,0-0.6-0.3-0.6c-2.3,3.2-2.6,7.6,0.3,9.1
			c0.3,0,0.3,0,0.3-0.3s0-0.3-0.3-0.9v-0.3h0.3c0.3,0.3,0.6,0.6,0.6,0.9c0,0.2,0.1,0.4,0.3,0.6c0,0.3,0,0.3-0.3,0.6s-0.3,0.3-0.3,0.6
			v1.8c0,0.6,0.3,0.9,0.3,1.2c0,0.6,0.3,2.6,0.3,3.2v0.6c-0.2,0.2-0.3,0.4-0.3,0.6c-0.3,0.3-0.3,0.3-0.3,0.6v1.2
			c0,0.3-0.3,0.3-0.6,0.9c-0.3,0.3-0.3,0.3-0.3,0.6v0.6c0,0.3,0.3,0.6,0.3,0.9c0.3,0.3,0.6,0.9,0.9,1.2c0.4,0.5,0.9,0.9,1.5,1.2
			c0.4,0.3,0.7,0.7,0.9,1.2c0,0.2,0.1,0.4,0.3,0.6c0,0.3,0,0.6-0.3,0.6c-0.3,0.3-0.3,0.3-0.3,0.6v0.6c0.1,0.4,0.3,0.8,0.6,1.2
			c0.2,0.2,0.3,0.4,0.3,0.6c0,0.3-0.3,0.3-0.3,0.6l-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3l0,0l0.6,0.6c0.2,0.2,0.3,0.6,0.3,0.9v1.2
			c-0.1,0.5-0.2,1-0.3,1.5v0.6c0,0.3,0,0.3,0.3,0.3l0.3,0.3v0.3c0,0.3,0.1,0.6,0.3,0.9c0.3,0.3,0.3,0.6,0.6,1.2
			c0,0.3,0.3,0.3,0.3,0.6v0.3c0,0.3-0.3,0.3-0.6,0.6l-0.6,0.6c0,0.3-0.3,0.6-0.3,0.9c0,0.2-0.1,0.4-0.3,0.6c-0.3,0.3-0.6,0.3-0.9,0.6
			c-0.3,0-0.3,0.3-0.3,0.6s-0.3,0.6-0.3,0.9s-0.1,0.6-0.3,0.9c0,0.3-0.3,0.6-0.6,0.6c-0.3,0.3-0.3,0.3-0.3,0.6v1.8
			c0,0.3-0.1,0.6-0.3,0.9s-0.3,0.6-0.3,0.9v0.9c0,0.3,0,0.6,0.3,0.6v0.6c-0.3,0.3-0.3,1.2-0.3,1.8v1.2l0,0c0.9,0.3,1.5,0,2.6,0.9
			l1.2-0.3c0-0.3,0.9-0.9,1.5-1.2c0.3,0.3,1.2,0.3,1.5,0.3c1.2,1,2.5,1.9,3.8,2.6c0.6,0.3,1.5,0.3,2,0.6c0.5,0.3,1,0.7,1.5,1.2
			c1.5,1.2,3,2.5,4.4,3.8c0.9,0.9,2,1.5,2.9,2.3c0.9,0.6,3.2,1.8,3.2,2.9l1.8,0.9c0.3,0.6-0.6,1.5-0.9,2v2c0.3,1.5,1.8,2,2.6,3.2
			c0.6,0.8,1,1.7,1.2,2.6c0.6,1.2,2.3,1.5,3.5,2.3v0.3c2.3,0,3.5,2,5.3,2.6l0.3,0.6c0.2,0.5,0.6,0.9,1.2,0.9c1.5,0.3,2.9-0.7,3.2-2.2
			v-0.1c0-1.5-1.2-4.7-0.9-5.8c1.8-0.9,2-0.6,3.8,0c0.9,0.3,3.8,2.3,3.5,3.5c0,0.6-0.3,2.6-0.3,2.9l-0.3,0.3c0,0.4-0.3,0.9-0.6,1.2
			c-0.6,0.6,0,0.6,0.3,1.5c0.4,0.8,0.5,1.7,0.3,2.6c-0.3,0.9,2,1.2,2.6,0.9s0.6,0.6,0.6,1.5s0.9,1.2-0.3,2c-1.2,0.9,0.6,1.8,0.6,2
			c0,0.6,0.9,1.8,0.9,2.3c-0.1,0.3-0.1,0.6-0.3,0.9c0.9,5.8,4.1,5,8.8,7l0,0c0.7,0.3,1.4,0.7,2,1.2c2,1.5,2,3.5,5,3.2l0,0
			c1.2-0.6,2.3-0.9,3.5-1.5c0.3-0.6,0.9-1.8,0.6-2.3c0.6-0.4,1.1-0.6,1.8-0.9c4.1-0.6,4.4-0.3,7.6,2l0,0c1.7,0.9,3.1,2.2,4.1,3.8
			l0.9,1.8c1,1.2,2.2,2.3,3.5,3.2c1.1,0.1,2.2-0.5,2.6-1.5c0.2-0.6,0.6-1.1,1.2-1.5c0.3-0.3,1.8-0.6,1.8-0.9c0.3-3.5,2.9-2.6,5.3-3.5
			c1.2-0.3,1.8-1.2,2.6-1.8s2-0.9,2.9-1.5c0.6-0.3,1.3-0.4,2-0.3c0.6,0,4.1-1.2,4.4-1.2c0.6-0.4,1-1.1,1.2-1.8
			c0.6-0.3,1.2-0.9,1.8-1.2c2.3-1.2,5.3,0.3,6.4,0c0.5-0.3,0.9-0.5,1.5-0.6c0.5,0,1-0.1,1.5-0.3c0.6-0.3,1.5-0.6,2-0.9
			c0.9-0.8,1.6-1.7,2.3-2.6c1.8-0.9,3.2,0,5,0h2l3.5-15.8l1.2-8.8h-2.9v2h-1.8l-0.3-2.3h-0.9v-3.2h-1.2l-0.3-3.2h-3.8v-3.2l-6.7,0.3
			l0.6-8.5h-2v2.6h-0.9v1.8h-0.9v2h-0.9v2.3h-10.2v9.3h-8.8v-1.8h-0.9v0.9h-1.8l-0.3,0.9H185l-0.6,0.6h-0.6l-0.3-0.3v-0.9
			c0-0.3,0.3-0.6,0.3-0.9c0.3-0.3,1.2-1.5,1.5-1.8c0.3-0.2,0.6-0.4,0.9-0.6l0.3,0.6l0.6-1.2h-2.9V1228h-1.8c-0.2-0.2-0.3-0.6-0.3-0.9
			s0-0.6-0.3-0.6v-0.6c0-0.3,0.3-0.6,0.3-0.9s0-0.3-0.3-0.6v-0.6c0-0.3,0-0.3,0.3-0.6c0.3-0.6,0.6-1.8,0.9-2.3c0-0.3,0.3-0.6,0.3-0.9
			s-0.1-0.6-0.3-0.9l-1.2-1.2l5-0.3v-9.6h-2l-0.3-18.7l-2-9.1l-5.3-1.8l-4.4,6.1l-7.3,6.1l-0.9,0.3h-6.4l-0.9-0.9v-3.2l-0.9-1.8
			l-1.2-0.6l-2.3-0.3l-1.5-0.9l-3.8-7l-4.7-5.5l-1.8-3.8L138.8,1164.3z"/>
	</g>
	<g id="Abitibi-Temiscamingue_8" className="region">
		<circle className="point" cx="110" cy="1100" r="1" fill="transparent" />
		<path id="path5020_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M35.1,1015.9H14.4l0.3,98.7
			c0,1.5-0.3,9.9,0,11.1c-0.9,0.9-1.5,2-1.8,3.2c0,0.4-0.1,0.8-0.3,1.2l-1.5,3.5c0,2.9,2.6,4.7,3.8,6.7c0.3,0.6,0.3,1.2,0.6,1.8
			c0.4,0.7,0.8,1.5,1.2,2.3c0.6,0.9,1.5,1.8,1.5,2.6v2.6c0,0.9,0.3,1.5,0.3,2.3c0,2-0.9,5.5-0.3,7.6c0,0.3,2,3.5,2,3.8
			c0.6,0.9,2,2,2.3,3.2c0.2,1.4,0.5,2.8,0.9,4.1c0,0.3,0.3,0.6,0.6,0.9c0.5,1,1.1,2,1.8,2.9c0.3,0.6,0.9,0.9,1.2,1.5
			c0.3,0.3,0.3,0.9,0.6,1.5c0.4,0.7,0.9,1.4,1.5,2c0.6,0.2,1.2,0.5,1.8,0.9c1.2,2.3,2,5.8,2.9,8.5c0.3,0.6,0.3,1.5,0.6,2
			c0.9,1.4,2,2.7,3.2,3.8c0.1,1.2,0.5,2.3,1.2,3.2v2c0.3,1,0.7,2,1.2,2.9c0.3,0.3,4.1,5.3,4.4,5.6c1.1,0.8,2.3,1.6,3.5,2.3
			c0.6,0.6,0.9,1.2,1.5,1.8c0.6,0.3,1.2,0.7,1.8,1.2c0.6,1.2-0.6,2.3,1.2,4.7c1.8,0.3,3.5-0.3,5.3,0.3c1.5,0.3,2.9,1.8,4.7,1.8h1.2
			c0.9,0,2.6,0.3,3.5,0.3c1.5,0.6,1.2,2.3,4.1,2.3c0.6-0.3,1.5-1.5,2.3-1.2c0.9,0,1.5,0.6,2.3,0H78c0.9,0.6,1.9,1.2,2.9,1.8
			c0.9,0.5,2,0.6,2.9,0.3c0.3,0,0.6,0.3,0.9,0.3c1.9,1.1,3.8,2.1,5.8,2.9h0.3v-1.2c0-0.6,0-1.5,0.3-1.8v-0.6c0-0.3,0-0.6-0.3-0.6
			v-0.9c0-0.3,0.1-0.6,0.3-0.9s0.3-0.6,0.3-0.9v-1.8c0-0.2,0.1-0.4,0.3-0.6c0.3-0.3,0.3-0.6,0.6-0.6c0-0.3,0.3-0.6,0.3-0.9
			s0.1-0.6,0.3-0.9c0-0.3,0.3-0.3,0.3-0.6c0.3-0.3,0.6-0.3,0.9-0.6s0.3-0.3,0.3-0.6s0.3-0.6,0.3-0.9s0.3-0.3,0.6-0.6s0.6-0.3,0.6-0.6
			v-0.3c0-0.2-0.1-0.4-0.3-0.6c-0.3-0.3-0.5-0.7-0.6-1.2c-0.2-0.2-0.3-0.6-0.3-0.9v-0.3c-0.2-0.2-0.4-0.4-0.6-0.6v-0.6
			c0.2-0.5,0.3-1,0.3-1.5v-1.2c0-0.3-0.1-0.6-0.3-0.9c-0.3-0.3-0.3-0.6-0.6-0.6l-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3l0,0
			c0.3-0.3,0.3-0.3,0.3-0.6s0-0.3-0.3-0.6s-0.3-0.6-0.6-1.2v-0.6c0-0.3,0-0.3,0.3-0.6s0.3-0.3,0.3-0.6s0-0.6-0.3-0.6
			c-0.2-0.4-0.5-0.8-0.9-1.2c-0.5-0.3-1-0.7-1.5-1.2c-0.3-0.3-0.9-0.9-0.9-1.2c0-0.3-0.3-0.6-0.3-0.9v-0.6c0-0.3,0.3-0.3,0.3-0.6
			c0.3-0.3,0.6-0.6,0.6-0.9v-1.2c0-0.2,0.1-0.4,0.3-0.6c0.3-0.3,0.3-0.3,0.3-0.6v-0.6c0-0.6-0.3-2.6-0.3-3.2c0-0.4-0.1-0.8-0.3-1.2
			v-1.8c0-0.2,0.1-0.4,0.3-0.6c0.3-0.3,0.3-0.3,0.3-0.6s0-0.3-0.3-0.6c0-0.3-0.3-0.6-0.6-0.9H90v0.3c0.2,0.2,0.3,0.6,0.3,0.9
			c-0.9-1.2-0.6-2.9-0.3-4.4c-0.1-0.9,0-1.8,0.3-2.6c0-0.3,0.1-0.6,0.3-0.9c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.2-0.3-0.6-0.3-0.9v-3.2
			c0-0.2,0.1-0.4,0.3-0.6v-0.3c0-0.2-0.1-0.3-0.3-0.3l0,0c-0.3,0-0.3,0-0.6-0.3v-0.9l38-0.3l10.8,0.3l3.8,3.8l1.8,3.8l4.7,5.6l3.8,7
			l1.5,0.9l2.3,0.3l1.2,0.6l0.9,1.8v3.2l0.9,0.9h6.4l0.9-0.3l7.3-6.1l4.4-6.1l5.3,1.8l1.2-9.6l1.2-3.5l1.5-2.6v-2.6l-1.8-2.6l0.3-2.3
			l2-1.5l2.6-3.2l0.9-0.6l2.9-1.2l0.6-2.9l0.9-1.8l2.3-0.6l-0.3-2.9v-4.4l1.8-1.2l2-3.2l0.3-2.3l1.2-2.9l1.8-0.9l0.6-2l-1.2-1.2
			c0,0.3-0.6-0.9-0.6-0.9s-1.2,0.3-0.6,0c0.3,0-0.3-0.9-0.3-0.9l-1.2-0.3c0,0.3-0.9,0-0.9,0s-0.3-2-0.6-2s0.3-1.5,0.3-1.2
			c-0.3,0.3-0.9-1.5-0.9-1.5l-0.3-0.9l-0.6-0.6h-0.9v-0.9c0,0-1.5-0.9-1.2-0.9s0.3-0.6-0.6-0.9c-0.2,0-0.4-0.1-0.6-0.3
			c0-0.6-0.3-0.6-0.3-0.9s-0.3,0-0.3-0.3v-1.2c0-0.3-0.6-0.9-0.6-0.9l0.3-0.6h0.9v-6.1l0,0v-86.2H35.1V1015.9z"/>
	</g>
	<g id="Nunavik_10" className="region">
		<circle className="point" cx="400" cy="480" r="1" fill="transparent" />
		<path id="path5022_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M563.2,567.8c-0.9-1.1-1.5-2.4-1.8-3.8c0.6-1.2,0.3-2.3,2-2.9c0.5,0,1.1,0.2,1.5,0.6
			c0.3,0,1.2-0.6,1.5-0.6c0.8,0.2,1.6,0.5,2.3,0.9c0.9,0.6,2.3,1.5,2,2c0,0.6-0.6,0.3-1.2,0.3s-0.6,0.3-0.6,0.9
			c-0.3,0.6,0.9,1.2,0.9,1.2l0.9,0.9c0.2,0.6,0.5,1.2,0.9,1.8c0.6,0.5,1.3,0.9,2,1.2c0.6,0.6,1.5,1.2,2,1.8c0.8,1,1.5,2,2,3.2
			c0,0.3,0.9,0.6,0.9,0.6l0.6,1.2c1.2,2,0.3,1.5,2.6,2.9l0.3,0.3c0,0.6,2.3,1.5,2.6,1.8c0.6,0.3,1.2,0.7,1.8,1.2
			c0.7,0.2,1.4,0.5,2,0.9c1.2,0.6,2.9,1.5,3.5,2.9l0.3,0.3c0.2-0.2,0.4-0.3,0.6-0.3h0.3c0.9,1,1.9,2,2.9,2.9l0.6-0.3
			c0-1.8-1.5-2.3-2.3-3.5c0,0-1.2-0.6-0.9-1.5s0-0.9-0.3-1.8c-0.3-0.6-0.3-1.2,1.5,0.3s2,2.3,2.9,1.5l0.6-0.3c0.6-0.6,1-1.3,1.2-2
			v-0.6c-0.7-1-1.4-2.1-2-3.2c-0.6-0.9-0.3-1.8-1.5-2.9c0.9-1.5-4.1-5.8-4.7-7.9l0.3-0.3h0.3l0.9,0.6h0.3c0.3-0.3,0.6-0.6,0.9-0.6
			c1.2,0.3,3.8,2.3,4.1,2.3h0.3c-0.5-1.3-1.2-2.5-2-3.5c-1.2-1.2-3.2-1.8-3.2-3.5l0.3-0.3c0.6,0.1,1.2,0.3,1.8,0.6
			c-0.9-0.9-1.6-1.9-2.3-2.9c-0.3-0.6-1.2-2.6-0.9-3.5h0.9v-2.3c0-0.9-0.6-1.8-0.6-2.6c0.9-0.3,2.9,2,3.8,2c0.6-1.2-1.8-3.5-2.3-4.4
			c-0.3-0.3-0.6-0.3-0.9-0.6l-0.3-0.6c-0.6-0.6-1.2-0.9-1.8-1.5c-0.6-0.9-1.5-1.8-1.5-2.9v-0.3c0.3,0.3,0.7,0.5,1.2,0.6v-0.3
			c0-0.6-0.6-2.3-0.3-2.9h0.6c0.9,0.6,1.2,1.5,2,2c1.2,1.2,2.3,1.5,2.3,3.2c0.6,0.3,1.1,0.8,1.5,1.5c0.9-1.8,3.2,0,4.1,1.2
			c0.3,0.3,0.3,0.9,0.6,1.5c0.6,0.9,1.8,1.8,2,2.6c0.6,1.5,0.6,2.6,1.5,3.8l0.3-0.6h0.3c0.6,0.3,1.5,2,2,2.6c0.3,0.4,0.7,0.7,1.2,0.9
			c1.2,1.1,2.3,2.4,3.2,3.8c0.5,0.6,0.9,1.3,1.2,2c0.3,0.6,0.9,1.2,1.2,1.8c0.6,1,1.2,2.1,1.8,3.2h0.3l0.6-0.6h0.3
			c2,0.9,1.2,0.6,3.2,0.6c0.6,0,3.5,3.5,4.4,4.1l0.3-0.3v-0.9l0.3-0.3h0.9c0.7,0.5,1.5,0.8,2.3,0.9l0,0c1.2-0.4,2.5-0.5,3.8-0.3
			c0.5,0.3,1.1,0.5,1.8,0.6l0.3,0.3c-0.6,1.5,0.6,2.9,1.2,4.1v0.6l-0.9,0.9v0.3c0.7,0.5,1.4,1.1,2,1.8c2.6,2.3,2.6,3.2,3.5,7
			c0,0.3,0.3,0.9,0.6,0.9h1.5c0.6-0.3,1.2-1.8,1.5-2.6h0.3c1.5,1.2,5.5,1.2,7.3,0.9c0.6,0,1.5-0.9,1.8-1.2c0.6-1.5-0.9-2.9-1.5-4.1
			c-0.3-0.3-0.3-0.9-0.6-1.2c0-0.3,0.3-0.9,0.3-1.2c0.3-0.3,0.9-0.6,1.2-0.3c1.2,0.3,0.3,1.8,3.8,2l0.3-0.6c0-0.5,0.1-1,0.3-1.5
			l0.3-0.3c0.3,0,2,2,2.3,2.3c-0.3-0.4-0.3-1,0-1.5c1.5-1.5,2.3-0.6,3.5-1.2l0.6-0.6c-0.3-0.6,0-1.5,0-2l0.6-0.6
			c0-1.1,0.5-2.1,1.5-2.6c0.5-0.1,1-0.3,1.5-0.6c0-0.6,0.3-1.8,0.9-2h0.9l0.3,0.3v0.9c0.3-0.1,0.6-0.1,0.9-0.3h0.3
			c1.2,1.2,0.6,1.8,2.3,2c0.4-0.4,0.9-0.6,1.5-0.6c1,0.5,1.9,1.2,2.6,2c0.7,0.5,1.2,1.1,1.8,1.8c0.6,0.9,0,2.6,0.6,3.2h0.3l0.3-0.3
			h0.3l1.5,0.9l0.9-0.3c2.9,1.8-1.2,5.3-0.6,7c0.3,0.3,2,0.6,2.3,0c0.5,0.1,1,0.3,1.5,0.6h0.3l0.3,0.3h0.3l0.9-0.6
			c0.6-0.3,4.4,1.8,6.1,0.3c-0.3-0.6-0.9-1.8-0.3-2.3c0.9,0,1.8,0.9,2.9,0.9l0.3-0.3c-0.4-0.8-0.5-1.7-0.3-2.6l0.6-0.3
			c0.6,0.3,1.2,0,1.8,0c0.5,0,1,0.1,1.5,0.3c0.5-0.1,1,0,1.5,0.3s0.9,0.5,1.5,0.6c0.6,0.3,0.6,0.9,1.5,1.2c-0.2,0.3-0.3,0.8-0.3,1.2
			c0.3,0.3,0.9,0.6,0.9,0.9c0.9,0.6,1.8-0.3,2.6,0.6h0.6c0.2-0.2,0.6-0.3,0.9-0.3c0.3,0.9,0,1.8,0.6,2.6c1.2,0.7,2.3,1.6,3.2,2.6
			c-0.1,0.8-0.1,1.6,0,2.3c1.4,0.9,3,1.5,4.7,1.8c0-0.3,0.3-0.3,0.6-0.3c0.7-0.2,1.4-0.4,2-0.6c0.6,0,1.5,0.6,2.3,0.6l1.8-1.5
			c1.2,0.3,2-1.8,2.9-1.8c1.2-0.3,2,1.8,3.5,1.8c0.2-0.2,0.3-0.4,0.3-0.6c-0.2-0.7-0.5-1.4-0.9-2c-1.2-0.6-1.2-2-1.8-2.9
			c-0.9-1.3-1.9-2.6-2.9-3.8c-0.6-0.5-1.3-0.9-2-1.2c-0.4-0.1-0.7-0.5-0.9-0.9l0.6-0.9c0-0.3-0.3-0.9-0.3-1.2
			c1.2,0.3,2.9-0.6,3.8-1.5l0.3-0.6c0-1.2-1.2-2-1.5-2.9c0.9-1.2,0.6-3.5,2-4.7v-0.3l-0.6-0.6c0-0.2,0.1-0.4,0.3-0.6
			c2.6-0.6,3.2,1.2,5,2.6c1.2,0.9,1.5,0.6,2.6,0.6c0.3,0,0.6,0.3,1.2,0.3c0.6-0.3,1.1-0.8,1.5-1.5c0-0.3-1.5-2.9-0.9-4.1
			c0.8,0.1,1.5-0.4,1.8-1.2c-0.3-0.6-0.6-0.9-0.9-1.5V566l0.6-0.9c0.3-0.9-0.9-2-1.5-2.3c-0.9-2.6,0.6-2.3,0.9-3.8
			c0-0.3-0.3-0.6-0.3-0.9c1.2-0.9,1.5-2,1.5-3.8h0.3l0.6,0.3h0.6c-0.3-0.3-0.6-0.9-0.9-1.2l0.3-0.3c0.3-0.4,0.7-0.6,1.2-0.6
			c0.5-0.2,1-0.3,1.5-0.3c0.8-0.2,1.6-0.5,2.3-0.9c0.3-0.6,1.8-2.6,1.5-3.5l-0.3-0.3c-1.8-0.3-3.2,2.9-7.3,2
			c-0.9-0.3-1.2-1.2-1.8-1.5c-0.3,0-0.3,1.2-0.3,1.5h-0.3c-0.3,0-0.6-1.2-0.9-1.5l-0.6-0.3l-0.6-0.6v-0.3c0.9-2.3,5-2,6.1-3.5V543
			c-0.7,0.1-1.5-0.1-2-0.6v-0.3l0.3-0.6c0.6-0.6,2.6-0.9,3.5-0.9c0.6,0,1.2,0.3,1.8,0.3c1.7-0.3,3.4-0.9,5-1.8
			c0.3-0.3,1.2-0.6,1.5-1.2c-0.1-0.7-0.5-1.4-1.2-1.8c-1.2,0-2.3,0.6-3.5,0.6c-2-0.3-0.9-1.2-4.1-1.5c-0.8,0-1.6,0.1-2.3,0.3h-0.9
			c-0.6,0-0.9-0.3-1.5-0.3c-1.4,0.1-2.7,0.1-4.1,0c-1.2-0.3-1.8-1.5-2.6-2c-0.3-0.3-1.8-0.9-1.5-1.8c0.9-0.4,1.7-1,2.3-1.8
			c0.3,0,1.5-1.2,1.8-1.2l0.6-1.2c1.2-2.3,0-4.4,0.3-7l0.6-0.6l0.3-0.6l-0.6-0.9c-0.8-0.3-1.6-0.3-2.3,0l-0.3-0.3l0.6-1.8l-0.3-0.6
			l-0.9-0.6v-0.3h1.2c0-1.3,0.3-2.6,0.9-3.8c0.3-0.6,0.9-0.9,1.2-1.2v-0.9l-0.3-0.6c-1.2-0.6-2.6,0.3-3.5-0.6
			c-0.3-0.3-0.5-0.7-0.6-1.2c0.5-1.1,0.6-2.3,0.3-3.5c-0.6-0.9-2-1.5-2.6-1.2h-0.3v-0.3c1.5-1.2,0.3-1.5-0.6-2.6v-0.3l0.3-0.3
			c1.5-0.6,2.9-0.9,4.4-1.5c0.7-0.3,1.4-0.7,2-1.2c0.8-0.6,1.7-1.1,2.6-1.5l3.8-1.8c1.3-0.3,2.6-0.2,3.8,0.3l-0.6-1.5l1.2-0.3v0.5
			l-0.3-0.3c-1.8-0.3-2.9,0.3-4.7,0.6c-1.5,0-2.9,0-4.4-0.3c-0.6-0.3-1.2-1.8-1.5-2.3l-1.8-0.6c-0.9,0.3-3.2,1.8-4.4,0.9l0.9-1.5V486
			c-0.8-0.3-1.7-0.5-2.6-0.6v-0.6c0.5-0.3,1-0.9,1.2-1.5l-0.6-0.6c-1.1-0.2-2.2,0.3-2.9,1.2c-0.5,0.6-0.9,1.3-1.2,2
			c-0.6,0.7-1.4,1.3-2.3,1.5H716l-0.3-1.2c0.2-0.7,0.4-1.4,0.6-2c0-0.9-0.3-2.3-0.3-3.2c0.4-0.5,0.9-0.9,1.5-1.2
			c1.2-0.6,3.5-1.8,4.7-1.5l0.6-0.6c0.6-2.3-3.8-4.4-5.5-4.7s-2-0.6-3.5,0.6l-0.6-0.3c-0.9-1.5,0.3-2.3-2.3-3.2l-0.3-0.6l0.3-0.6
			l1.8-0.3v-2.3l-0.6-0.6v-0.6l0.6-0.9v-0.6c-0.6-0.6-2-2.3-2.9-2.6v-0.3h0.3c2-0.3,1.8-0.3,3.5,0.6c0.3,0,4.7,0.9,5,0.9l1.2-0.6V461
			c0.9-0.3,2-0.6,2.9-0.9c0.4-0.5,0.5-1.2,0.3-1.8c-0.9-1.2-2.9-1.2-3.2-2.3c0.9-1.5,0.6-3.2,0.3-4.7c-1.5-1.5-2.9-2.3-4.4-3.8
			l-0.3-0.6V446c0.5-0.5,0.7-1.4,0.3-2c-0.6-0.6-2.9-0.3-3.8-2.9c-0.3-1.2,0.9-2.9,1.5-3.8s0.9-1.8,1.5-2.6l2,0.3
			c0.6-0.4,1.1-1,1.5-1.8v-0.9c-0.5-1.2-0.8-2.5-0.9-3.8l1.5-0.9c1.5,0.3,2.9-0.9,4.7-0.6l0.3-0.6v-0.6c-0.5-0.6-1.1-1.1-1.8-1.5
			c-0.3-1.5-0.3-3.1,0-4.7l0.6-0.6c0.5,0.1,1,0,1.5-0.3v-0.3c-0.7-0.7-1.2-1.5-1.5-2.3c0.2-1.3,0.3-2.7,0.3-4.1
			c0.9,0,2.3,0.6,3.2,0.3c1.2-1.2,1.5-2.6,2.3-4.7l-0.3-2l0.3-2c1.2-1.2,1.5-0.6,1.8-2.3c-0.1-0.7-0.3-1.4-0.6-2l-1.2-0.3l-1.5-0.3
			c0,0.3-0.9,0.9-0.9,0.9c0.1,1.4-0.4,2.8-1.5,3.8l-0.3-0.3c-0.4-1.8-0.7-3.7-0.9-5.5c0.3-0.7,0.7-1.4,1.2-2v-2
			c0.9,0,1.8-0.1,2.6-0.3c0.6-1.5,1.8-1.8,3.2-2c0.6-1.2,0.9-4.4,0.3-5.5c-0.6,0-1.5,0.3-1.8,0l-0.6-0.9c0-0.6,0.3-1.8,0.3-2.3
			s-0.6-1.2-0.3-2c0.3-0.6,0.6-1.5,0.9-2v-0.3c-0.4-1.2-0.5-2.5-0.3-3.8c2.3-2.3,2-1.5,4.4-2.9v-0.3c1.2-2,1.5-1.2,2.6-2.3
			c0.3-1.2,0.9-4.4,0.6-5.3c0-0.3-0.3-0.6-0.6-0.9h-0.6c-0.6,0.9-1.8,0.9-2.6,0.9v0.3c0.9,0.6,0.6,2,0.3,2.9
			c-0.3,0.6-0.9,0.6-0.9,1.5l-0.6,0.3l-0.6-0.3c-0.9-1.2,0-3.2-1.5-3.8c0,0-2,2.3-2.3,2.6l-0.9-0.6l-0.3-1.2
			c-0.3-0.3-0.6-0.9-0.9-0.9c-0.6,0.3-1.8,0.6-2,0.3l-0.9-1.2c0-0.6,0.3-4.1-0.9-4.7c-1.2-0.3-2.9-1.2-4.4-1.2
			c-0.3,0.9-0.4,1.7-0.3,2.6c-0.5,0.3-1.2,0.3-1.8,0c0-0.3-0.3-0.6-0.3-0.9c-1.5-2.6-2.9-4.7-3.2-7.6c0-0.9-1.8-3.5-2.6-4.4v-2.6
			c-0.3-0.5-0.9-1-1.5-1.2l-0.3-1.2l0.6-1.5v-0.6l-2.3-0.9l-0.6,0.3c-1.2-0.3-0.9-1.2-3.5-1.5c-0.9,0-1.8,0.3-2.3,0l-0.6-0.6
			c0.6-1.8,0.3-4.7,0.3-6.7c0.8-1.9,2.6-3.1,4.7-3.2c0.7,0,1.4-0.1,2-0.3c0.6-0.3,1.5-0.6,2-0.9h0.3c2-2.6,1.8-5,2-8.2
			c0.3-2.6,4.4-2.6,6.4-2.6l0.3-0.3c0-2.9,0.6-3.5,2.6-4.7c0.6,0,2,0.3,2.6,0l0.3,0.3l0.9,0.3c0.8,0,1.5-0.3,2-0.9l2-3.5
			c-0.6-0.9-2-0.6-2.3-1.5c-0.3-0.6,0-1.5-0.3-2s0.3-1.8,0-2l-1.5-0.3l-0.9-0.3l-0.6,0.3c-1.2,1.8-1.5,2.6-2.9,3.8
			c-0.9,0.1-1.8-0.1-2.6-0.6c-0.3-1.5-1.2-2.3-2.3-3.5c0.2-1.2,0.1-2.4-0.3-3.5c0.5-1.2,1.2-2.3,2-3.2c0.6-0.8,1-1.7,1.2-2.6l1.2,0.3
			c0.9,0.3,2,0.6,2.9,0.9c0.6-0.3,1.8-1.5,2.3-1.8c2.5,0.2,5.1,0.1,7.6-0.3c1.5-0.3,0.9-0.6,2.6-0.3h2.9c0.9-0.3,1.5-0.9,2.3-1.2
			c1.2-0.6,3.2-0.6,4.1-1.8l0.3-0.6l-0.6-0.9l-0.9-0.3c-0.8-0.9-1.3-1.9-1.8-2.9l-2.3-2.3c-1.5-1.2-2.9-0.3-4.1-1.5l-0.3-0.9
			l-0.6-0.3c-0.6,0.3-0.9,0-1.5,0l-0.9,0.3h-1.2c-1.5,0.6-1.5,2.3-2.3,2.6c-0.6,0.3-1.2,0.3-1.8,0.9c-0.6-0.2-1.2-0.3-1.8-0.3
			c-2.6,0-2.6,0.3-5,0.9c-0.4,0.6-1,1.1-1.8,1.2l-0.3,0.6l0.3,0.9c-1.8,2.3-1.8,2.6-4.1,3.5l-1.5-1.2l-0.3-1.5h-2l-0.9-0.6
			c0-0.3,0.3-1.8,0.3-1.8c-0.3-2-0.6-3.8-2.3-5.3l-1.2-0.9c-0.7-0.2-1.4-0.1-2,0.3c-1.2,0.3-3.8-1.2-5.3-1.2c-1,0.8-2.2,1.3-3.5,1.5
			c-0.3-0.3-0.9-0.6-0.9-1.2l-2.3-0.3c-0.3-0.6-1.2-1.2-1.8-1.8c-1.5-1.5-2.6-1.8-4.7-0.6c-0.6,0.9-0.9,1.8-2,2c-1.5-0.6-3-1.2-4.4-2
			V273l0.9-0.3l2.6,0.3l0.3-0.3c-0.6-0.6-2.3-3.2-2-4.1c0.6-0.8,1-1.7,1.2-2.6c1.5-1.5,3.5-1.8,5.3-2.6l1.2,0.3
			c0.6,0.9,0.9,1.9,0.9,2.9l0.6,0.3l0.9-0.3c0.6,0,0.9,0.3,1.5,0.3c0.7,0.3,1.4,0.7,2,1.2l2.3,0.9l1.2-0.9c0.6,0.8,1,1.7,1.2,2.6h0.9
			c1.4-0.7,2.9-1.3,4.4-1.8c0.6,0,1.2,0.3,1.8,0.3c0.9,0,2-0.9,2-1.8c-1.2-1.1-2.6-2-4.1-2.6l-0.9-1.5c-0.6-0.3-2.6-0.3-3.5-0.6
			c-0.4-0.3-0.8-0.6-1.2-0.9c-0.1-1.9,0.1-3.7,0.6-5.5V256c-0.3,0-1.8-0.3-1.8-0.6v-2.9c0.3-0.9,0.9-1.5,0.9-2.3l-0.9-0.3
			c-0.6-0.6-1.5-2-0.9-2.9c0.5-0.6,1.1-1.1,1.8-1.5c0.2-0.3,0.3-0.8,0.3-1.2c-0.8-0.4-1.5-1-2-1.8v-0.6l0.6-0.9l2.3-0.6v-0.6
			c-0.6-0.3-1.5-0.6-1.8-1.2l0.3-0.6l3.2-2l0.3-1.2c1.5,0,2.3-1.2,3.5-2v0.2c0-0.3-3.2-1.8-3.5-1.8H698l-0.9,1.2
			c-0.5-0.1-1,0-1.5,0.3l-0.6,1.5c-0.8,0-1.6,0.1-2.3,0.3c-0.2,0.6-0.6,1.1-1.2,1.5c-0.6,0-2.6,0-2.9,0.6v0.9
			c-0.3,0.3-0.6,0.6-0.9,0.6s-0.3,0.3-0.9,0.3s-2.9-2.6-3.5-3.2l-0.3-1.5c-0.6-0.9-1.4-1.6-2.3-2c-0.6-0.3-1.2-1.2-2-1.2l-1.2-1.5
			l-1.8-0.3v-2l0.9-0.3c0.7,0.2,1.4,0.3,2,0.3c-0.2-0.8-0.5-1.6-0.9-2.3c-0.6-1.2-1.2-2.9,0.3-3.2c1.2-0.3,2.4-0.8,3.5-1.5l0.6-0.6
			c0.8-0.4,1.6-0.9,2.3-1.5l0.3-0.9c-0.6-0.6-2-0.6-2.9-0.6c-0.1-1-0.1-2,0-2.9l-0.6-0.9l0.9-2.6l-0.9-0.6l-0.3-0.6
			c0.8-0.4,1.6-0.9,2.3-1.5h1.5v-1.8c-0.6-0.6-0.9-1.2-1.5-1.8l0.3-0.6c2.3-0.3,2.6,0.9,4.1,0.9c0.1-0.7,0.3-1.4,0.6-2
			c0-0.3,1.5-1.8,1.5-2s-1.2,0.3-1.2,0c0-0.6-0.3-1.2,0-1.5l-0.3-0.6c-1.1-0.2-2.1-0.3-3.2-0.3c-0.3-0.6-1.2-1.8-1.8-1.8
			c-1.1,0.3-2.3,0.5-3.5,0.6l-0.6-0.6V194c-0.6-0.9-2.6-3.8-2.9-4.4l0.3-0.3h0.9c1.2,0.6,2.6,1.2,4.1,1.8l1.5-1.5c1,0,2-0.1,2.9-0.3
			l0.3-0.6c0-0.6-0.3-0.9-0.3-1.2c-0.3,0-0.9-0.6-1.2-0.9V186l0.6-0.3h1.8c1.5,0,2.6-0.6,4.1-0.6c0.3-1.2-0.6-2.6-0.6-3.8l-0.3-0.3
			l-1.2,0.3c-0.6,0-1.1-0.5-1.2-1.2h-1.2l-0.6,0.6h-0.6c-0.8-1.3-2.1-2.2-3.5-2.6c-0.6-0.3-1.5-0.6-2-0.9c-0.3-0.8-0.5-1.7-0.6-2.6
			l2-1.5h1.5c1.5-0.6,2.9-1.8,4.4-2l2,0.9c1-0.2,2-0.6,2.9-1.2c0.7,0.1,1.4,0.3,2,0.6l0.3-0.3c-0.6-0.3-1.2-0.6-1.2-0.9l-0.6-0.6
			v-0.3c-0.8-0.7-1.7-1.2-2.6-1.5c-0.3-0.2-0.8-0.3-1.2-0.3H688c0.3,0.3,0.6,0.3,1.2,0.6v0.6c-0.6,0-0.9-0.3-1.5-0.3l0,0
			c-0.6,0-1.5-1.2-2-1.2c-1.5-0.6-1.8,0.3-2.6,0c-0.9-0.6-0.9-1.2-2-0.6l0,0v0.6c0.6,0.6,1.5,1.2,0.9,2.3c-0.3,0.3-0.3,0.3-0.3,0.6
			c-0.3,0.6-2,2.6-2,2.9l-0.6,0.6c-0.9,0.3-1.5,0.6-2.3,0.9v0.3c1.5,0.6,1.2,0.9,2,1.8c0.6,0.6,2.3,1.2,1.2,2
			c-1.8,0.6-2.6-0.3-3.5,1.8c-0.2,0.6-0.3,1.2-0.3,1.8v0.3H675c-0.6,1.2,0.3,1.8,0.3,2.3l-0.3,0.3c-0.3,0-0.9-0.3-1.2-0.3
			c-0.6,0-2,1.2-1.8,2c0.3,0.6,1.8,0.3,1.8,1.8c-0.3,0.3-1.8,2-2,2.3c-0.6,0-1.2-0.2-1.8-0.6h-0.6c0.1,0.7,0.1,1.4,0,2
			c0,0.9,1.8,1.5,2.3,2c0.3,0.3,0.3,0.6,0.6,0.9v0.3l-3.5,0.6c-0.9,0-1.5-0.9-2.3-0.3v0.3c0.3,0.3,0.6,0.6,0.9,0.6
			c0.3,0.3,2.6,1.8,1.5,2.3h-0.6c-0.9-0.6-0.6-1.2-2-1.8h-0.6l-0.3,0.3c0,0.3,0.3,0.9,0.3,1.2c-0.3,0.9-1.5,1.5-2,1.2
			c-0.3-0.3-0.9-0.3-0.9,0c0,0.2-0.1,0.3-0.3,0.3l0,0l0.3,0.3c0.3,0,0.6,0.3,0.9,0.3h0.6c1.2,0.3,2.1,1.4,2,2.6l-0.3,0.3
			c-0.9,0.3-1.5-0.6-2-0.9c-0.3-0.3-0.9-0.3-1.2-0.6h-0.9l-0.3,0.3c0,0.6,1.2,1.5,1.5,2.3l0,0v0.3c-0.2,0-0.4,0.1-0.6,0.3l0,0v0.3
			c0.6,0.9,1.5,1.2,2,1.8c0.9,0.6,1.5,1.8,2.6,2h1.5c0.5,0.2,0.9,0.6,1.2,1.2c0.9,0.6,1.9,0.9,2.9,0.9h0.3c-1.2,0.6-3.5,0-4.7-0.6
			c-0.6-0.3-0.9-0.6-1.5-0.6c0,0-0.6,0-0.6,0.3l0,0c-1.8-0.3-1.2-1.5-2.6-2.6s-3.2-1.2-5-2c-0.3-0.3-0.6-0.6-0.9-0.6H657v0.6
			c-0.4,0.6-1.1,0.9-1.8,0.9c-0.2,0.2-0.3,0.4-0.3,0.6c0.3,0.6,0.6,0.9,0.9,1.5c0,0.2-0.1,0.3-0.3,0.3l0,0c-0.6,0-1.2-0.9-1.8-1.2
			h-0.9c-0.3,0-0.3,0.3-0.3,0.6s0,1.2-0.3,1.5c-0.6,0.3-1.2-0.3-1.8-0.6H650c0.4,0.5,0.9,0.8,1.5,0.9c0.3,0.3,0,0.6,0,0.9
			c-0.6,1.5-1.8,1.5-3.2,1.8c-0.3,0.3-0.6,0.6-0.3,0.9l0.3,0.3c0.3,0.3,0.6,0.6,0.6,0.9c0.3,0.6,0,0.9,0.3,1.2c0,0.6,0.3,0.9,0.3,1.5
			c0.3,1.2,0.3,3.5,1.2,4.7c0.5,1.7,1.2,3.4,2,5c0,0.3,0,0.3,0.3,0.6v0.6l0,0c0.3,0.6,0.3,0.9,0.6,1.2c1.6,0.1,3.1,0.1,4.7,0
			c1.5,0,1.5,1.5,2,1.8s1.2,0,2,0c0.6,0.6,1.8,0.9,2.3,1.5l0,0c0.6,0.6,0.3,2,1.2,2.6c1.8,1.4,3.7,2.6,5.8,3.5
			c0.4,0.4,0.9,0.7,1.5,0.9h-0.3c-0.6,0.6-2,0-2.9-0.3c-0.6-0.3-1.2-0.9-1.8-1.2s-1.2-0.3-1.8-0.9c-0.9-0.6-2.6-2.3-2.6-3.5
			c0-0.3-0.3-0.3-0.6-0.3c-2.3,0-5.5-1.5-7.3-1.5c-1.5,0-1.5,0.9-2.6,0.9c-0.6,0.3-1.8-0.6-2.6-0.6h-0.9c-0.4,0-0.8-0.1-1.2-0.3l0,0
			c-0.3-0.3-0.6-0.3-0.9-0.6c-0.3,0-0.6,0.1-0.9,0.3c0,0.9,1.8,1.8,2.6,1.8c0.3-0.6,1.5,1.2,1.8,1.5v0.3c-0.3,0-0.3,0-0.6,0.3H650
			c-0.3,0.3,0,0.6,0.3,0.6c1.8,1.2,2.9,0.6,5,2.3v0.3H655c-0.6-0.3-0.9-0.3-1.5-0.6h-0.6c0,0.2-0.1,0.4-0.3,0.6
			c-0.4,1.2-0.5,2.5-0.3,3.8c0.5,0.3,1.1,0.5,1.8,0.6c0,0.2,0.1,0.4,0.3,0.6c0.4,0.9,0.9,1.8,1.5,2.6c0,0.3,0.3,0.3,0.3,0.6v0.3h-0.3
			c-1.6-1.2-3.2-2.4-4.7-3.8c-0.1-0.5-0.3-1-0.6-1.5c-1.2-0.9-2.5-1.7-3.8-2.3h-0.6c-0.3,0.6,0.3,1.8,0.3,2.3c0,1.1,0.1,2.2,0.3,3.2
			c0.3,0.3,1.2,0.9,1.5,1.2c0.5,0.8,1.2,1.4,2,1.8v0.6c-0.3,0.3-0.9,0.6-1.2,0.3c-0.3,0-0.9-0.6-1.2-0.9l-0.3-0.3
			c-0.1,0.5-0.2,1-0.3,1.5h-0.6c-0.3,0-0.9-1.2-1.5-0.9H645c-0.6,1.8,1.5,3.2,1.2,4.4c0,0.2-0.1,0.3-0.3,0.3l0,0
			c-0.6,0-1.2-1.2-1.2-1.8c-0.3-0.6-0.3-1.5-0.9-2c-0.9-1.6-2.6-2.5-4.4-2.3h-0.3l-0.3,0.3c-0.3,0.6,0.3,1.8,0.6,2v0.3
			c-0.2,0-0.4,0.1-0.6,0.3c0,0.9,0,1.8,1.2,1.8h0.6c0.4-0.1,0.8,0.2,0.9,0.7c0,0.1,0,0.1,0,0.2l-0.3,0.3c-0.3,0-0.6,0.3-0.9,0.3l0,0
			c-0.2,0.4-0.3,0.8-0.3,1.2c-0.3,1.8,0.6,1.5,1.5,2.9c0.3,0.3,0.6,0.6,0.6,0.9c0.3,0.3,0.3,0.9,0.6,1.2c0,0.3,0.3,0.6,0,1.2l0,0v0.3
			c0,0.3,0.3,0.6,0.6,0.6h1.5c0.7,0,1.4-0.2,2-0.6c0.2-0.2,0.3-0.6,0.3-0.9c-0.3-0.6,0-1.5,0.6-1.2c0.9,1.2,0.6,0.9,2,1.2
			c1.2,0.3,2,1.2,3.8,1.5c1.2,0.3,2.3-0.6,3.2,0l1.2,1.2v0.6c-0.9,0.6-1.5,0-2-0.3c-0.4,0-0.8,0.1-1.2,0.3c-0.9,0-2-0.6-2.9,0l0,0
			c-0.3,0-0.3,0-0.6-0.3c-0.6-0.3-0.9-1.8-1.8-1.8c-1.8,0-1.5,1.8-2.6,1.5h-0.6l-0.3,0.3v0.6c0.5,0.2,0.9,0.6,1.2,1.2
			c0.6,0.6,1.8,0.6,2.3,0.9l0.3,0.6c0.2,0.2,0.3,0.6,0.3,0.9v0.3h-0.9l-0.9-0.9h-1.5l-0.9-0.9c-0.4-0.4-0.9-0.7-1.5-0.9
			c-0.9-0.9-1.5-2-3.2-2l0,0c-0.3,0.3-0.3,0-0.6,0c-0.2,0-0.4,0.1-0.6,0.3c-0.9,0.3-1.9,0.5-2.9,0.6l1.8,0.9
			c-0.6,0.3-1.8-0.3-2.3,0.6v0.3c0.3,0.6,2.9,1.2,3.5,1.5c0.3,0,0.3,0.3,0.6,0.6c1.2,0.9,2,1.8,1.5,3.5l-0.3,0.3
			c-0.6,0-1.8-2-2.3-2.3c-1.6-1.1-3.4-1.9-5.3-2.3c-0.3-0.6-0.9-1.5-1.5-1.5h-0.3c-0.6,1.5,0.3,2.3,1.2,3.5c0,0.3,0.3,0.3,0.3,0.6
			c-0.3,0.3,0,0.6-0.3,0.9s-2,0.3-2.6,0.6c-0.3,0.4-0.7,0.7-1.2,0.9c-0.9,0.6-2.3,0.9-2.6,1.8c0,0.3,0.3,0.6,0.3,0.9
			c0.6,0.7,1.4,1.2,2.3,1.2h0.9c0.3,0,0.9,0.9,2,1.2c0.6,0,3.2-0.3,3.5-0.3c0.3,0.3,0,0.9-0.3,1.5c-0.2,0.2-0.6,0.3-0.9,0.3l-0.6,0.6
			l0,0c0,0.2-0.1,0.4-0.3,0.6h-0.3v0.3h-0.3c-0.3,0-0.6-0.3-0.9-0.3h-1.2c-0.9,0-2.9-0.9-3.2,0.3h-0.6c-0.3-0.3-1.2-2-1.8-2.3h-0.3
			c-0.9,0.9-0.9,2-1.5,2.9c-0.3,0.3-0.9,0.6-1.2,0.9c0,0.6,0,1.5-0.6,1.8c-0.3,0.3-0.3,0.6-0.6,0.9c0,0.2,0.1,0.3,0.3,0.3l0,0
			c1.5,0,2.3,0.3,2.6,2c0.1,1.2,0.1,2.3,0,3.5c0.9,1.8,2.9,0.3,4.1,0.9c0.3,1.8-0.6,2.6,1.8,3.5c0.6,0.3,1.2,0.3,1.5,0.6v1.5
			c0.3,0.9,2,2,2,2.3l-0.3,0.3c-0.3,0-1.5-0.9-2-0.9h-0.3c-0.9,1.2-0.9,3.5-0.9,3.8c-0.3,0.6-1.2,0.9-1.8,1.2
			c-0.3,0.3-0.3,0.9-0.6,1.2c-0.3,0.6-1.8,1.2-1.2,2c0.3,0,0.6,0.3,0.9,0.3c0.6,0.3,2,0.3,1.8,1.5c-0.3,0.6-0.3,0.6-1.2,0.6
			c-0.3,0-0.3,0.3-0.6,0.6c0,0.9-0.1,1.8-0.3,2.6c-0.9,1.5-2.9,0-4.1,2.6c-0.1,0.5-0.2,1-0.3,1.5v0.9c0,0.3-0.3,0.6-0.3,0.9h-0.3
			l-0.6-1.2c-0.3-0.6-0.6-0.9-0.6-1.5c-0.3-1.2,0.9-2.3,1.8-2.6c0.7-0.2,1.4-0.4,2-0.6l0.9-0.9c0-0.3,0.3-0.6,0.3-0.9s-0.3-2-0.3-2.3
			c0-0.6-0.6-0.9-0.9-1.2c-0.3-0.6-0.3-1.2-0.6-1.8c-0.6-0.6,1.5-3.5,1.8-4.1c0.3-0.4,0.4-1,0.3-1.5c0.3-0.3,0.6-0.3,0.9-0.6
			c0.6-0.6,1.5-3.2,0.6-3.5c-0.3,0-1.5-0.3-1.8-0.3l0,0c-0.3,0-0.6,0-0.6-0.3v-0.3c0.2-0.4,0.3-1,0.3-1.5c0-0.3-0.6-0.6-1.2-0.9
			c-1.2,0.6-0.9,1.5-2.3,0.9c-0.8-0.9-1.3-1.9-1.8-2.9c0-0.3-0.3-0.6-0.3-0.9c0-0.6,0-0.9,0.3-1.2c0-0.9-1.2-1.2-1.8-1.5
			c-1.2-0.5-2.3-1.2-3.2-2c-0.6-0.6-0.6-1.5-1.5-2c-0.6-0.6-1.5-0.3-2-0.6s-0.9-0.9-1.2-1.2c-0.9-0.9-1.8-0.6-1.8-2
			c-0.5-0.4-1.1-0.6-1.8-0.6l0,0c-0.3,0.3-0.6,0.9-0.9,1.2c-0.6,0.3-2,0.6-2.6,1.5c0,0.3,0.3,0.6,0.6,0.9c-0.2,1.1-0.4,2.2-0.9,3.2
			h-0.3c-0.2,0.2-0.3,0.4-0.3,0.6l0.3,0.3c0.3,0.3,0.9,0.3,1.5,0.9c0,0.8-0.3,1.5-0.9,2c-0.5-0.2-1-0.4-1.5-0.6
			c-0.6,0-1.2,0.3-1.8,0.3h-0.6l-0.3,0.3c0,0.3,0.3,0.6,0.3,0.9s-0.9,2-1.2,2l-0.3-0.3c0-0.3-0.3-0.3-0.6-0.3v0.3
			c-0.6,0.3,0,1.8,0.3,2l0.3,0.3c0.6,2.6-2.6,0.9-1.8,5.5c0,0.3,0.3,1.5,0,1.8c-0.6,0.3-0.9-0.3-1.8,0.6c-0.9,1.2,1.2,1.8,1.8,2.3
			l0.6,0.6c0,0.3,0.3,0.6,0,0.6c-0.9,0.3-1.5-0.3-2.3-0.3c-0.9-0.3-1.2,0-2-0.3c-0.4-0.2-1-0.3-1.5-0.3h-0.6c-0.6,0.5-1.3,0.7-2,0.6
			l-0.3,0.3c0.2,0.2,0.3,0.6,0.3,0.9c-0.1,0.8-0.5,1.6-1.2,2c-0.3,0-0.3-0.3-0.6-0.3c-0.3,0.5-0.3,1.2,0,1.8v0.3h-0.3
			c-0.9-0.6-0.6-0.9-2.3-1.5c-0.3-0.9,1.5-1.5,0.9-2.6c-0.6-0.6-2.3-2-2.9-1.8c-0.7,1-1.4,2.1-2,3.2c0,0.3-0.1,0.6-0.3,0.9
			c-0.3,1.2-1.5,2.3-2.6,2H577v0.6c0.9,0.6,1.5,1.5,0.6,2.3L577,314c-0.3,0.3-0.3,0.9-0.6,1.2h-0.3c-0.9-1.2-1.8-2-3.2-2l-0.3,0.3
			c0,0.3-0.1,0.6-0.3,0.9c-0.6,0.9-2,2.3-3.2,2v-0.3l-0.3-0.3c-0.3,0-0.9,0.9-1.2,1.2c-0.6,0.3-1.2,0-1.8,0.9c-0.6,0.6-0.6,1.5-1.2,2
			c-0.3,0.3-0.6,0.3-0.9,0.9h-0.6c-0.3-0.3-0.6-0.9-1.2-0.9l0,0c-1.5,0.3-3.2,0.9-3.8,2.3c-0.9,2.9-2.3,1.5-3.2,2.9
			c-0.6,1.2-0.9,2-1.5,3.2c-0.6,1.5-1.5,2.3-2,3.8c-1.2,2.9,1.2,3.2,0.9,5.5c0,0.3-0.1,0.6-0.3,0.9c-1.2,1.5-2.3,2-3.2,4.1
			c-0.3,1.2,0.6,3.5,0,4.4h-0.3c-0.3,0-0.6-0.6-0.6-0.9c-1.8-2.3,1.2-4.4,1.5-6.7l0,0c0.6-1.1,0.9-2.3,0.9-3.5c0-0.6-0.6-1.2-0.6-1.8
			c-0.6-1.7-0.8-3.5-0.6-5.3c0.3-2.3,1.5-2.3,2.3-3.5c0.3-0.6,0-1.5,0.3-2c0-0.3,0.3-0.6,0.3-0.9c-0.3-1.2-1.2-1.2-2-1.8
			c0-0.3-0.3-0.3-0.3-0.6l-0.3-0.3c-0.6-0.3-0.9,0.3-1.2,0.6c0-0.2-0.1-0.3-0.3-0.3l0,0v-0.3c0-0.3,0.3-0.3,0.6-0.6
			c0-0.9-1.2-0.9-1.2-1.8c0-1.5,1.5-1.5,2-2.6c0-0.6-0.9-0.9-0.6-2s1.8-2.6,1.8-3.2v-0.3c-0.3-0.3-1.2-0.3-1.5-0.6
			c-0.2-0.2-0.3-0.6-0.3-0.9c-0.3-0.6-1.8-1.2-2.3-1.5H546c-1.2,0.6-0.3,1.8-0.6,2.3c0,0.6-0.9,1.2-1.2,1.8c0,0.6-0.3,1.2-0.3,1.8v2
			c0,0.3-0.3,0.9-0.3,1.2c-0.3,2,0.3,2.3,1.2,4.1c-0.6,0.6-2.9,2.6-2.9,3.2s0.3,0.9,0.3,1.5c-0.2,0.2-0.4,0.3-0.6,0.3
			c-1.8-0.9-1.5,0.9-2.9,0.6c-0.7,0.5-1.3,1.2-1.5,2c-0.2,1.2-0.4,2.4-0.9,3.5c-0.9,1.8-2,1.8-3.2,2.9c-0.6,0.6-1.8,2.9-2.9,2.6
			c-0.9-0.9,1.5-3.2,1.2-4.7c0.6-0.8,1.3-1.6,2-2.3l4.4-4.4c0.9-0.9,2.3-1.5,2.9-2.6c0.9-1.4,1.5-3,1.8-4.7c0-1.5-1.8-2-2.3-3.2
			c-0.3-0.6,0.3-0.9,0-1.5c-0.3-0.7-0.6-1.3-0.9-2v-0.3c0-0.6,0-0.9,0.3-1.2v-1.2c0-1.5,2.3-3.8,0.6-5c-0.3-0.3-1.8-0.3-2.3-0.6
			c-0.2-0.2-0.3-0.4-0.3-0.6l0.9-0.9v-0.3c-0.9-0.3-0.3-1.2-0.6-1.5c-0.3-0.6-0.9-0.9-1.2-1.2c-0.3,0-0.6-0.3-0.9-0.3l-0.3,0.3
			c-1.2,2-0.3,2.6-0.3,4.1c-0.6,0.9-1.2,0.3-1.8,0.3h-0.9c-0.6-0.6-1.2-0.3-2-0.6c-0.2,0-0.4,0.1-0.6,0.3c-0.3,0.3-1.8,2.9-2,3.2
			c-0.3,1.8,1.2,3.2,0.3,5.3c0,0.3-0.1,0.6-0.3,0.9c0,0.3-0.3,0.9-0.3,1.2c0.1,0.8,0.1,1.6,0,2.3c-0.3,0.9-1.2,1.5-1.5,2.3
			c-0.4,0.9-0.6,1.9-0.6,2.9v0.6l0.3,0.6c0,2-1.5,2-2.3,3.5l-0.9,1.8v0.6c-0.1,1-0.1,2,0,2.9c0.1,0.5,0.2,1,0.3,1.5
			c-0.3,3.5-3.8,3.2-4.1,5c0.3,0.3,0,0.9,0,1.2c-0.3,0.3-0.6,0.9-0.9,0.9c-0.3,0.3-0.9,0.3-1.5,0.6c-1.5,0.6-3.2,1.8-4.7,2
			c-1.5,0.3-2.9-0.3-4.4,0c-0.4,0-0.8,0.1-1.2,0.3c-0.6,0-0.9,0.6-1.5,0.6c-0.5,0.2-1,0.3-1.5,0.3c-1.2,0.4-2.4,0.9-3.5,1.5
			c-0.5,0.1-1,0.3-1.5,0.6c-0.9,0-1.8,0.6-2.3,0.6v0.3c-2,0.4-3.9,1.4-5.3,2.9h-0.3c-0.3,0.6-2.9,1.5-3.8,1.8l0,0
			c-0.3,0.3-0.6,0.3-0.9,0.6c-1.2,1.2-2,2.6-3.5,3.5c-1,0.8-2.1,1.6-3.2,2.3l-2.6,1.8c-0.3,0.3-0.6,0.3-0.9,0.6
			c-0.2,0-0.3,0.1-0.3,0.3l0,0c0.3-0.3,0.3-0.6,0.6-0.6l1.8-1.8c1-0.9,2-1.9,2.9-2.9c0.6-0.6,0.9-1.2,1.5-1.8
			c1.3-1.1,2.8-2.1,4.4-2.9c0.9-0.3,1.9-0.5,2.9-0.6c0-0.3,0.3-0.3,0.6-0.3c2.6-0.6,2.9-2.9,4.1-3.5s2.9-0.3,4.1-0.9
			c0.3-0.3,0.7-0.5,1.2-0.6c0.6-0.3,1.5-0.3,2-0.6c0.3,0,0.6-0.3,0.9-0.6l3.5-1.8c0.6-0.3,1.5,0,2,0c1.8,0,3.2-1.2,5-1.2h2
			c0.8-0.3,1.5-0.8,2-1.5h0.3c0.6-1.2,1.8-2,2.3-3.2s2.6-2,2.9-2.6s-0.3-2,0-3.2c0.3-0.9,0.6-2,0.9-2.9s0.3-2,0.6-2.9
			c0.2-0.4,0.5-0.8,0.9-1.2c0.3-0.9,0-1.5,0.3-2.3c0.6-1.2,1.5-2,1.8-3.2c0.2-1,0.5-2,0.9-2.9c0.1-0.5,0.2-1,0.3-1.5
			c0.3-0.3,0-1.2,0-1.5c0.3-1.2,1.2-1.5,0.9-2.9c-0.3-0.6-0.5-1.3-0.6-2c0-1.5,0.9-2.6,1.2-4.1v-0.9l-0.3-0.3c-0.6,0-1.5,0.9-1.8,1.5
			c-0.4,0.3-1,0.4-1.5,0.3c-0.3,0-0.3,0.3-0.6,0c-0.2-0.2-0.3-0.4-0.3-0.6c0.2-0.3,0.3-0.8,0.3-1.2c0.4-0.3,0.6-0.7,0.6-1.2
			c0.3-0.9,0-2.3-0.9-2.6c-0.5,0.2-1,0.5-1.5,0.9h-0.6c-0.4-0.4-0.7-0.9-0.9-1.5c-0.3-2,1.5-2.9,0.9-5.3c-0.9-0.6-1.8-0.6-2.3-1.8
			c-0.1-0.5-0.2-1-0.3-1.5c0-0.3,0.3-0.9,0.3-1.2c0.3-0.9,2-3.2,0.6-3.8H520l-0.3-0.3v-2l-0.3-0.3c-0.7-0.2-1.4-0.3-2-0.3
			s-0.9-0.3-1.2-0.3c-3.5-0.9-1.8-1.8-3.2-2.6c-0.3-0.3-0.9-0.3-1.5-0.6s-0.9-0.9-1.5-1.5l-1.8-0.9c-1.8-0.6-3.2,0.3-4.4-0.3h-0.6
			c0-0.6-0.6-0.6-0.6-1.2l-0.3-0.3h-0.9c-1.5,1.3-3.1,2.4-5,3.2l0,0c-1.3,0.1-2.5,0.1-3.8,0c-0.3,0-0.6-0.1-0.9-0.3
			c0-0.3,0.6-0.6,0.9-1.2v-0.3H492c-0.9,0-1.5,0.6-2,0.6c-1.5,0-2.9-0.6-4.7-0.6c-0.9,0.2-1.8,0.5-2.6,0.9h-0.9
			c-0.9-0.6-2.3-1.2-2.6,0.6l-0.3,0.3c-1.7,0.1-3.3,0.7-4.7,1.8c0,0-0.6,0.3-0.6,0.6c0,0.6-0.3,0.9-0.3,1.5l-0.3,0.3
			c-0.2,0-0.4,0.1-0.6,0.3c-0.3,1.5-2.3,1.5-2.9,3.2l-0.3,0.3H468c-1.5,0.3-3.2,1.2-3.8,2.6v0.3l0,0c-0.3,0.3-0.3,2.6-0.6,3.2
			c-0.3,0.3-0.6,0.6-1.2,0.3c0-0.3-0.3-0.9-0.6-0.9c-0.3,0-0.6,0.1-0.9,0.3c-0.7,1.7-1.7,3.3-2.9,4.7c-0.9,1.2-1.5,2.3-2,3.2h-0.3
			c-0.2-0.6-0.6-1.1-1.2-1.5c-0.2-0.2-0.6-0.3-0.9-0.3v-1.2c0-0.6-0.6-0.6-0.6-1.2c0.3-0.6,0.9-0.9,1.2-1.5c0.4-0.4,0.9-0.7,1.5-0.9
			c0.6-0.6,1.8-2.6,0.3-2.6h-0.9c-0.3-0.3-0.3-0.6-0.6-0.9l0,0c-1.2,0.6-1.8,2.6-3.5,2.3c-1.5-0.9-0.6-3.5-0.3-4.7
			c0-0.5,0.1-1,0.3-1.5c0-0.2-0.1-0.3-0.3-0.3l0,0c-0.9,0-0.9,0.3-1.5,0.6H449c-0.9,1-2,1.8-3.2,2.3h-0.3c-0.6-0.3-0.6-0.9-0.9-1.5
			l-0.3-0.3c-0.6-0.6-2.9,0-3.8-0.3s-1.2-1.2-2.3-1.5c-0.9,0-1.8,0.3-2.3,0.3h-0.3c0.6,0,0.9-1.2,1.8-1.2c1.2,0,2.3,1.2,4.1,0.9
			c3.2-0.6,3.2-2.3,4.1-2.6s1.2,0.6,2,0.6s1.8-0.9,2.6-1.2c0.6-0.3,1.2,0,1.5-0.6v-0.3c-0.3-0.8-0.5-1.7-0.6-2.6l0.3-0.3
			c1.2,0,1.2,0.9,1.8,1.5l0,0c0.6,0.9,1.2,2.3,2.6,2l0.3-0.3c0-1.2-0.6-2.3-0.6-3.5v-2c0.3-1.8,0-3.5-2-3.5l-0.3-0.3
			c-0.6-0.3-0.9,0-1.5,0l-0.3-0.3l0,0c0.3-0.3,1.2-0.9,1.5-1.5c0.6-0.6-0.3-2.9-0.3-3.8v-1.8l0.3-0.3c0.2,0,0.4,0.1,0.6,0.3
			c1.2,0.6,0.6,1.5,1.2,2.6c0.3,0.6,0.9,0.9,1.2,1.5c0.3,0.7,0.5,1.3,0.6,2h0.6c0.3-0.6,0-0.9,0.3-1.5c0.2-0.2,0.4-0.3,0.6-0.3
			c0.6,0.9,1.5,0.9,2,1.2c0,0.3,0,0.3,0.3,0.6v0.9c0,0.9-0.9,1.8-0.3,2.6c0.3,0.1,0.6,0.2,0.9,0.3c0-0.3,0-1.2,0.6-1.2h0.6
			c0,0.2,0.1,0.3,0.3,0.3l0,0c-0.3,0.6,0.3,1.8-0.3,2.6l-0.3,0.3c-0.7,0.4-1.3,1-1.8,1.8l0,0c-0.3,0.6,0.9,2.9,1.2,3.5
			c0,0.3,0.3,0.9,0.3,1.2c0.3,0.6,0.6,0.6,1.2,0.9c0.3,0,0.6-0.6,1.2-0.3c1.5,0,1.5,0.3,2.6-0.6c2.9,0,0.6-3.2,2.3-4.7
			c0.3-0.6,0-1.8,0.9-1.8c0.6,0.3,0.3,1.2,1.5,1.2c1.2-0.3,0.9-2.3,0.9-3.2c0-0.6-0.6-1.2-0.6-1.8v-1.2c0.1-0.5,0-1-0.3-1.5
			c0-0.3-0.3-0.3-0.3-0.6v-1.5c0-0.6-0.3-1.5-0.3-2.3c0-0.6-0.9-1.8-0.6-2.3c0.6-0.3,0.9-0.3,1.2,0.3c0,0.3,1.5,3.2,1.8,3.2h0.3
			c0.9-0.3,1.2-2,1.5-2.6c1.2-0.9,2.3-0.9,2.9-2.3c0-0.3-0.3-0.6-0.3-1.2c-0.5-0.8-1.4-1.2-2.3-0.9c-0.3,0.3-0.9,0.6-1.2,0.9H473
			c-0.3-0.6,0-0.9-0.3-1.5c-0.4-0.3-1-0.4-1.5-0.3l-1.2,1.5l0,0v0.3c-0.4,0.2-0.9,0.1-1.1-0.3c0-0.1-0.1-0.2-0.1-0.3
			c0-0.3-0.3-0.6-0.3-0.9v-2l0,0c-0.2-0.8-0.1-1.6,0.3-2.3c0.3-0.3,0.9-0.6,1.2-0.9c0.9-0.6,2.6-2.6,3.8-2c0.6,0.6-0.3,1.8,0,2.3
			c0.6,0.3,2.3,0.3,2.6-0.3c0-0.9-1.2-2.6-0.6-3.5l0.3-0.3c1.2,0,1.5,0.9,2,1.5c0.4,0.5,0.9,0.9,1.5,1.2h0.3c0.3-0.3,0.3-0.6,0.6-0.9
			c0.6-0.6,1.5,0,1.8-1.2c0-0.6-0.3-1.5-0.3-2c-0.3-0.9-1.5-0.9-1.2-2.3c0.2-0.2,0.6-0.3,0.9-0.3c0.3-0.3-0.3-1.8,0-2.3
			c-0.8-0.4-1.7-0.5-2.6-0.3c-0.3,0.9-1.5,1.2-2,1.5h-0.6V241l0,0c-0.3-0.3,0-0.9,0.3-1.2v-0.3c0-0.3,0-0.3-0.3-0.6
			c-0.9-0.7-2.1-1.1-3.2-1.2c-1.2,0-1.8,1.2-2.6,1.5c-1.5,0.6-3.2,0.3-4.7,1.2c-0.6,0.3-0.9,1.2-1.2,1.5c-0.6,0.9-1.5,0-1.8-0.6
			c-0.9-0.3-1.8,0.3-2.6,0l0,0c-1.5,0.3-1.5-0.9-1.8-1.8c0.3-0.3,0.3-0.6,0.6-0.9c0.3-0.2,0.8-0.3,1.2-0.3c0.9-0.3,1.2-0.9,2.6-0.9
			c0.3,0,0.6-0.1,0.9-0.3c0.3-0.6,0.6-1.5,0-1.8h-1.2c-0.6-0.6,0-1.5,0.6-1.8c0-0.3,0.3-0.3,0.3-0.6c-0.2-1.1-0.6-2.2-1.2-3.2
			c-0.9-0.6-0.6,0.3-1.5-1.2c0-0.3,0.1-0.6,0.3-0.9c-0.3,0-2,0.3-2.6,0.3c-0.9-0.9,1.8-4.7,2.3-5.3l0,0c0.9-0.9,2.9-2.9,4.1-2.9
			c0.6,0,1.2,0.3,1.8-0.3c0.5-0.3,0.9-0.9,1.2-1.5l0,0c0.5-1,0.9-2.1,1.2-3.2v-0.9c0-0.3-0.3-0.3-0.6-0.6c-0.3,0-0.6,0.1-0.9,0.3
			c-0.9,0-2.3-0.9-2.3-1.8c-0.6-0.3-1.5,0.3-2.6,0v-0.6c0.4-1,1.1-1.8,2-2.3c0.5,0,1-0.1,1.5-0.3c0.3-0.6,0.3-1.5,0.6-1.8
			s0.9-0.3,0.9-0.6c-0.9-0.3-2.6-0.6-2.9-1.5c0.5-0.5,0.8-1.1,0.9-1.8c0-0.6-0.6-0.9-0.3-1.8s1.5-1.5,2-2.6v-0.3
			c-1.1-0.5-2.3-0.7-3.5-0.6h-0.9c-0.3,0-0.6-0.3-0.9-0.6h-0.9c-0.3,0-2.6-1.2-2.6-1.5c-0.2-0.2-0.3-0.4-0.3-0.6l0.3-0.3
			c0.5,0.1,1,0,1.5-0.3c0.3-0.3,0.3-2.6,0.3-3.2l-0.3-0.3c-0.5-0.1-1-0.2-1.5-0.3c-1.5,0.2-3,0.4-4.4,0.9H452c-1.3-0.1-2.5-0.1-3.8,0
			c-1.5,0.3-2.3,1.2-3.8,0.9c-1.7-0.3-3.4-0.9-5-1.8c-0.6-0.3-1.5-1.2-2-1.5c-1.2,0.2-2.4,0.5-3.5,0.9c-1.5,0.3-2.3-0.6-3.5-0.9
			c-0.3,0-4.1,1.2-4.4,1.2l0,0c-1.7,0.3-3.4,0.2-5-0.3c-0.6-0.4-1.3-0.7-2-0.9c-1.5-0.6-3.2-0.9-4.7-1.5c-0.5-0.1-1-0.3-1.5-0.6
			c-0.3,0-0.9-0.3-1.2-0.3c-1.1-0.2-2.2-0.4-3.2-0.9c-0.9-0.3-1.2-0.9-2-1.2l-2.6-0.9c-1-0.2-1.9-0.3-2.9-0.3l0.6-0.6
			c0.6,0,0.6-0.3,1.5-0.3c0.6,0.1,1.2,0.3,1.8,0.6c0.8,0.3,1.5,0.4,2.3,0.3c0.5,0,0.9,0.2,1.2,0.6c1,0.5,2.1,0.9,3.2,1.2l0,0
			c1.1,0.4,2.3,0.7,3.5,0.9c0.9,0,2.3-0.3,2.9,0.3c0.3,0,0.6,0.3,0.9,0.6c1.2,0.8,2.6,1.3,4.1,1.5c1.8,0,5.8-0.9,6.4-0.9h0.3
			c1.2-0.6,2.6,0,3.8,0l0.3-0.3c-0.3-0.6-0.6-0.6-1.2-0.9v-0.3c0.3-0.3,0.9,0,1.5,0c0.9,0.3,3.5,1.2,4.1,1.5c0.9,0,1.8-0.3,2.9-0.3
			c0.8,0.2,1.5,0.3,2.3,0.3c0.6,0,0.9-0.3,1.5-0.3s1.5,0.6,2.3,0.6c0.3,0,0.6-0.3,0.9-0.3c1.1-0.3,2.2-0.3,3.2,0c0.9,0,1.2,0.6,2,0.6
			l0,0c1.5,0.9,1.5,0.3,2.6,0.3c0.5,0.1,1,0.2,1.5,0.3c0.3,0,0.6-0.1,0.9-0.3c0-0.2,0.1-0.4,0.3-0.6c-0.3-0.9-2.3-1.2-2.9-1.5
			s-0.3-3.2,0-3.5c0.9-0.9,4.4,0.9,5,1.8h0.6v-0.9c0-0.3,1.2-1.2,1.5-1.5c0.7,0.1,1.4,0.3,2,0.6c0.2-0.2,0.4-0.3,0.6-0.3l0,0v-0.9
			c0-0.3-0.3-0.3-0.6-0.6v-0.3c0.3,0,0.6-0.3,1.2-0.6c0.6-0.9-0.3-1.5-0.3-2s0.3-1.2,0.3-2c0.3-0.3,0.6-0.6,0.6-0.9
			c-0.3-0.3,0-0.9,0-1.2l-0.3-0.3c-0.6,0.3-1.8,0.6-2.3,0v0.4c0.3-0.3,0.6-0.3,1.2-0.6c0.9-0.6,0.9-2,0.9-2.9c-0.6-0.3-0.9,0-1.5,0.3
			l-0.3-0.3l-0.9-1.8c-0.3-0.5-0.9-0.9-1.5-1.2v-0.9c0-0.6-1.8-1.2-2.3-1.2l0,0c-0.3,0-0.9-0.3-0.9-0.6c0-1.8,2.3-2.6,2-4.4
			c0-0.6-0.3-0.9-0.6-1.5c0-0.6,0.3-1.2,0-1.5c-0.6-0.6-1.8,0-2-0.6v-0.3c0.3-1.2,1.5-1.8,1.5-3.2c-0.3-0.3-0.3-0.6-0.6-0.6
			c-0.3-0.3-0.6-0.3-0.9-0.6s-0.3-1.2-0.6-1.5c-0.3-0.6-1.5-1.8-1.5-2c0.6-0.9,2.3-0.9,3.2-0.9c0.6,0,1.2-0.3,1.8-0.3h0.6
			c0.9,0,0.9-1.2,1.2-1.5s0.9-0.6,1.2-0.9c0.3-0.9,0-0.9,0-1.5c-0.6-0.3-0.9,0-1.5,0l-0.3-0.3c0-0.9,0.3-1.2,0.3-1.8
			c0.3-2-0.3-2.9-1.2-4.4c0.6-0.6,1.2-0.6,2-0.9c2-0.9,1.2-1.5,2.6-2.6c0.9-0.9,2.3-1.2,3.5-1.8c0.3-0.2,0.8-0.3,1.2-0.3
			c0.3,0,0.6-0.3,0.9-0.3c1.8-0.6,3.8-0.9,5.3-2.3c1.2-1.5-0.3-3.2-0.3-5.3c0-1.2,1.2-2.3,1.2-3.2l-0.3-0.6c-0.6-0.3-1.5-0.3-2.3-0.6
			l-0.3-0.3c0.2-0.3,0.4-0.6,0.6-0.9c-0.5-1.1-1.1-2.2-1.8-3.2c0-0.3-0.3-0.6-0.3-0.9c-0.6-0.6-0.6-1.5-0.9-2s-0.6-1.5-0.9-2
			c-0.3-0.4-0.4-1-0.3-1.5c-0.3-1.8-2.9-1.8-4.1-1.5c-0.9,0-0.9,0.9-1.2,1.2c-2.3,1.2-0.9,0.6-1.2,2.3c-0.3,0.3-0.6,0.3-1.2,0.6
			c0,0.3,0,0.3-0.3,0.6v2.3c0,0.6-0.3,1.2-0.3,1.8v0.6l0,0c0.9,1.8,2.3,2.6,1.8,4.7c0,0.3-0.3,0.6-0.6,0.9s-0.6,0.3-0.9,0.6
			c-0.6,0-2.6-0.3-3.2-1.2c0-0.3-0.3-0.6-0.3-1.2c0.3-0.3,0.5-0.7,0.6-1.2l-0.3-0.3c-0.3,0-0.6-0.3-0.6,0c-0.3,0-3.2,2-3.5,2
			c-0.9,0.6-1.8,2-2.6,2.6c-0.6,0.3-1.5-0.3-1.8,0.6l-0.3,0.6c0,0.2,0.1,0.4,0.3,0.6c1.5,0.6,2.3-1.5,3.5-1.5l0.3,0.3v0.6l-0.9,0.9
			c-0.9,0.9-1.2,1.8-2.6,1.8c-1.2-0.9-2.3-2.6-3.5-2.9c-0.3,0-1.8,0.3-2,0.3l-0.3-0.3c-0.2-0.2-0.3-0.4-0.3-0.6
			c0-0.3,0.3-0.6,0.6-0.6v-0.6c-0.3-0.4-0.7-0.6-1.2-0.6c-0.6,0-2.6,0.6-3.2-0.3v-0.3c0.3-1.2,2-0.6,2.6-1.2c0-0.9-0.6-1.5-0.6-2
			c0-0.9,1.2-1.5,0.6-2.9c-0.3-0.6-0.9-0.9-1.2-1.5c0-0.6,0.6-1.2,0.9-1.8l-0.3-0.3c-0.6,0-2,0.9-2.6,0c-0.3-1.5,1.5-2.3,2.3-3.5
			v-0.3l-0.3-0.3c-0.3,0-0.9,0.3-1.2,0.3l-0.3-0.3c0.2-0.3,0.3-0.8,0.3-1.2c-0.6-0.6-2.3-0.6-2.6,0.3c-0.3,0.3-0.7,0.5-1.2,0.6h-0.9
			c-0.9-0.3-0.9-1.2-1.2-1.8c-1.5-0.6-4.1-0.9-3.2,1.5v0.3l-0.3,0.3h-0.3c-0.6-0.9-1.8-2.3-2.9-1.8l0,0v0.3c0.3,0.6,0.6,0.9,0.9,1.5
			l-0.3,0.3c-0.3,0-0.6-0.3-1.2-0.6l-0.3,0.3l-0.3,0.3c0,0.6,1.2,1.5,0.9,2.3h-0.3c-0.7,0-1.3-0.4-1.8-0.9c-0.3-0.6,0-2-1.8-2H424
			l-0.3,0.3c0,0.3,0.3,0.6,0.3,0.9l-0.3,0.3h-1.2c0,0.3,0.3,0.6,0.3,0.9c0.5,0.2,0.9,0.6,1.2,1.2l-0.3,0.3h-0.3
			c-0.6-0.6-1.8-0.9-2.3-1.5l-0.6-0.6h-0.6c-0.6,0-1.5,1.2-2.3,0.9l-3.2,7.3c-0.3-0.9,0.6-1.2,1.2-1.5v-0.3c-0.3,0-3.2-1.8-3.8-2
			l-0.6-0.3h-0.9l-0.3,0.3v0.3c0.3,0.6,0.9,0.9,0.9,1.8l-0.3,0.3c-0.9,0-2-0.3-2.3-1.2l0,0c-0.9-0.6-1.8-0.3-2.9-0.6
			c-0.3,0-0.6-0.3-0.9-0.3c-0.5,0.1-1,0.2-1.5,0.3c-0.2,0-0.4-0.1-0.6-0.3c0.3-0.6,0.9-0.9,0.9-1.2c0.3-1.8-5.8-2.6-7-2.6
			c-0.6,0-1.2,0.6-1.5,0.6c-1.2-0.6-1.5-1.5-2.9-1.8h-0.3c0.1,0.5,0,1-0.3,1.5H391c-0.6-0.7-1-1.5-1.2-2.3c0-0.6,0.3-0.9,0.3-1.5
			l-0.3-0.3c-0.9,0-2.9,1.8-5,1.5c-0.6,0-1.5-0.3-1.8,0.3v0.3c0.2,0.4,0.3,1,0.3,1.5l-0.3,0.3c-0.6,0-1.2-2.3-1.5-2.9
			c-0.6-0.9-1.8-0.9-2.3-1.8s0-1.8-0.3-2.6c-0.9-0.9-2-0.6-3.2,0l-0.3,0.3c0,0.9-0.3,2,0.3,2.6c0.3,0.3,0.6,0.3,0.9,0.6v0.3l-0.3,0.3
			v0.3c-0.6,0.3-2.3,0.9-2.9,0.6v-0.3c0-0.6,0.3-0.9,0.9-1.2c0.3-0.9,0.3-2.6-0.6-3.2h-0.6c-0.6,0.3-0.6,0.9-1.5,1.5h-0.3
			c-0.3-0.3,0.3-2.3,0.9-2.6c0.3,0,0.6-1.2,0.6-1.5c-0.3-0.9-5-1.2-5.8-2.3c0.6-0.9,0.6-0.6,1.5-1.2c0.6-0.3,0.9-1.2,1.5-1.5v-0.6
			c-0.6-0.3-0.9-0.3-1.5-0.6c-0.3,0-0.6-0.6-0.9-0.9c-0.9-0.3-3.5,0-4.4,0c-0.2,0-0.3-0.1-0.3-0.3l0,0l0,0c0-0.6,0.9-0.3,1.2-0.6
			c0-0.6-0.9-0.6-1.5-1.2c1.2-0.6,6.7,2,7.3-1.2l0.3-0.3c1.5,0,1.2-0.3,2-0.9c0.4,0,0.8-0.1,1.2-0.3l0,0c-1-0.4-2.1-0.5-3.2-0.3
			l-0.3,0.3c-0.9,0.3-3.8-0.3-4.7-0.6c-0.3,0-0.3-0.3-0.6-0.3v-0.3c-1.2-1.2-2,1.2-4.4,0c-0.3-0.3-0.6-0.3-0.6-0.6V87
			c0.9-0.6,2-0.3,3.2-0.9c0.9-0.3,1.2-0.9,2-1.5c0.3-0.3,0.9,0,1.2-0.3V84l-0.3-0.3c-0.3-0.3-0.9-0.3-1.5-0.6l-0.6-0.6V82
			c0.3,0,0.6-0.1,0.9-0.3c-0.3-0.6-0.6-0.6-0.9-1.2V80c-0.9-0.3-1.8,0.3-2.6,0.3c-0.3,0-0.3,0-0.6-0.3v-0.6c0.6,0,1.2-0.6,1.8-0.6
			c0.9,0.1,1.8,0.1,2.6,0c0.6-0.3,0.9-0.6,1.5-0.6c0.9,0,4.1,0.6,4.7-0.3v-0.3c0-0.3-0.3-0.6,0-0.9c0.9-0.9,2.3,0.6,3.5,0.3
			c0.3-1.5,0.3-0.9-0.6-2l-0.9-0.9l0,0c0.3,0,0.3,0,0.6,0.3l0.3-0.3v-0.3l0,0c-1.7-1-3.3-2.3-4.7-3.8c-0.6-0.3-0.9,0-1.5-0.3
			c-0.8-0.3-1.7-0.5-2.6-0.6c-0.3,0-0.3-0.3-0.6-0.6s-0.9-0.3-1.2-0.6c-0.3,0-0.3-0.3-0.6-0.3c-0.9-0.3-2.3-0.3-3.2-0.6
			s-1.5-1.2-2.9-0.9h-0.3l-0.3,0.3V67l0.6,0.6c0.3,0,0.3,0.3,0.6,0.3c1.2,0.6,0.6,1.8,0.3,2.6c-1.2,0.6-1.2,0.6-1.2,2v0.9
			c0,0.3-0.3,0.3-0.6,0.6l0,0c-0.3,0-0.6-0.1-0.9-0.3c-1.2,0-2,1.5-3.5,1.5c-1.8,0.3-4.4-1.5-5.8-1.2c-0.5,0-0.9,0.2-1.2,0.6
			c-0.3,0-0.6,0.3-0.9,0.6l-0.3,0.3l-0.3,0.3h-0.6c-0.3,0.3-0.9,0.3-1.5,0.6v-0.3c0.9-0.6,1.2-1.8,1.5-2.9c2.9-2.3,4.7,0.9,7,0.9
			c2,0,2-1.8,3.5-2c0.3-0.3,0.3-0.9,0.6-1.2l1.8-2.6c-0.3,0-0.3-0.6-0.6-0.6c-0.3-0.4-0.7-0.6-1.2-0.6c-0.6-0.3-0.6-1.8-0.6-2
			l-1.5-1.5H350l-0.3-0.3c-0.6-0.9-0.6-0.9-1.8-0.9c-0.3,0.3-0.3,0.3-0.6,0.3c-0.3,0.3-0.3,0.6-0.6,0.9l0,0c-0.9,0.3-0.9,0.3-1.8-0.3
			c-0.3-0.6-0.3-0.9-0.6-1.5v-0.6c0.3-0.9,1.2-0.6,1.8-1.2c-0.3-0.3-0.6-0.3-0.9-0.6l0.3-0.3c0.9-0.3,2-0.3,2.9-0.6l0.3-0.3
			c-0.9-0.9-2-1.2-2.6-2s0-2-0.9-2.6c-1.7-0.8-3.4-1.3-5.3-1.5c-1.2-0.3-2.3,0-3.5-0.3c-0.9-0.3-1.5-0.6-2.3-0.9s-1.8-1.2-2.6-1.5
			H331c-0.9-0.2-1.8-0.7-2.3-1.5c-0.3-0.3-0.6,0-0.9,0l-0.3,0.3v0.3c-0.3,0.3-0.6,0.6-0.6,0.9v0.3l-0.3,0.3v2.3
			c0.3,1.5,1.5,3.2,2,4.7c0,0.6-0.3,0.6-0.3,1.2h-0.9c-0.3-0.3-0.6-0.6-0.6-0.9c-0.1-0.5-0.3-1-0.6-1.5c-0.3-0.3-1.2-0.9-1.5-1.2V54
			c0-0.3,0.3-1.2,0.3-1.5v-0.3c-0.6-0.3-2.6,1.8-2.6,2.6c-0.9,0.9-0.9,0.6-1.5,0.9l0,0c-0.3,0-0.6,0.3-0.9,0.3s-1.8,0,0-0.9
			c1.8-0.6,2.3-1.2,2.3-1.8s0-1.2,1.2-1.8c0.9-0.4,1.7-1.1,2-2c0.3-0.5,0.3-1.2,0-1.8c-0.3-0.3-1.2-0.9-0.3-1.8
			c0.7-0.4,1.5-0.6,2.3-0.6c0.6,0,1.5,0,1.2-1.2c0-0.8-0.1-1.6-0.3-2.3l-0.6-1.8c-0.3-0.9,0-0.6,0.9-0.9c0.9,0,0.3-0.3,0-0.9
			s-0.6-0.3-1.5,0c-0.6,0.3-0.9,0.6-1.5,0c-0.6-0.3,0-0.6,0.6-0.9s0.9-0.3,1.2-0.6s0.6-0.9,0.3-2s-2.3-1.8-2.9-2
			c-1-0.2-2-0.6-2.9-1.2c-1.2-0.6-1.8,0.6-2.3,0.9c-0.6,0,0,0.6,0,1.2c0.2,0.3,0,0.7-0.3,0.9l0,0c-0.5-0.4-0.9-0.8-1.5-1.2
			c-0.9-0.6-1.8,0.6-2,0.9s-2,0-2.9-0.3s-0.6-0.6,0-0.6s0.9-0.3,1.8-0.6s0-0.6-0.6-1.5c-0.6-0.6-0.6-0.6-1.2-0.6
			c-0.6,0.3-1.5,0.3-1.8-0.3c-0.6-0.6-0.3-0.9-1.8-1.5c-1.2-0.6-1.5,0-2,0s-0.6-1.2-0.6-1.8s-0.9-0.3-1.8-0.6
			c-0.6-0.3-0.6-1.2-1.8-2.3c-1-0.8-2.1-1.5-3.2-2c-0.6-0.3-0.3-0.3,0-0.6s0.3-0.6,0.6-1.5s0.3-0.6-0.3-1.5c-0.6-0.5-1.3-0.7-2-0.6
			c-0.6,0-1.2-0.3-2-0.3c-1.2,0-1.5-0.3-1.5-1.2c-0.3-0.9-1.8-1.2-2.9-2c-1.2-0.9-1.5,2.6-2.3,3.2c-0.6,0.6-0.9,0-0.3-1.2
			c0.6-0.9-0.6-1.2-1.5-1.5c-0.6-0.3-1.5,0.3-2,0.6c-0.9,0.3-1.8-0.3-1.8-0.9c0-0.6-0.6-0.9-1.5-0.9c-0.6,0-1.5-0.3-0.6-0.9
			c0.9-0.3,0-1.2-0.3-1.5s-1.5-1.8-2-2.3c-0.7-0.7-1.8-0.8-2.6-0.3c-0.9,0.6-1.5,0-2.6,0.3s-2,0.9-2.9,0.6c-0.9,0-2,1.5-2.6,1.8
			s-0.6,1.5-1.2,2c-0.7,0.5-1.3,1.2-1.8,2c-0.3,0.9-0.9,1.6-1.8,2c-0.6,0.3-1.2,0-2,0s-1.5,0.4-2,0.9c-0.6,0.5-1.3,0.8-2,0.9
			c-1.2,0.3-1.8,0.9-2.3-0.3s-0.9,0.3-2.6,0.9s0,1.2-0.9,1.5s-1.2,1.2-1.5,1.5c-0.3,0.6-0.3,0.3-1.2,0s0,1.8-1.8,1.8
			s-1.2,0.3-2.3,0.9c-0.9,0.6-1.2-0.3-2.3,0c-1.8,0.3-3.6,0.1-5.3-0.6c-1.8-0.6-1.8-0.9-2.3-0.6c-0.9,0.3-1.8,0.3-2.6,0
			c-0.6,0-0.9,0-0.9,0.9c-0.2,0.6-0.5,1.2-0.9,1.8c-0.2,0.3-0.3,0.6-0.6,0.9c0.5,0.3,1,0.6,1.5,0.9c0.7,0.5,1.3,1.2,1.8,2
			c0.4,0.7,0.9,1.4,1.5,2c0.9,0.3,1.8,0.6,3.2,1.2c1.5,0.3,0.6,0.3,0.6,0.9s-0.3,0.6-1.2,0.3s-1.5-0.3-2.3-0.6s-1.5-0.9-2.9-2
			c-1.5-0.9-1.2,0-1.5,0.3c-0.4,0.5-1.1,0.8-1.8,0.6c-0.6,0,0.3-0.9,0.6-1.2c0.6-0.3,0-1.5-0.6-1.8c-0.8-0.4-1.5-1-2-1.8
			c-0.6-0.9-2.3,0.3-2.3,0.3c0.1-0.4,0.3-0.8,0.6-1.2c0.3-0.3,0-0.6-0.6-1.2c-0.5-0.5-1.1-0.8-1.8-1.2c-0.6-0.7-1.4-1.2-2.3-1.5
			c-1.3-0.2-2.6-0.6-3.8-1.2c-1.8-0.3-1.2,0-1.8,0.6s-0.3,1.5-1.2,2.3s-1.2,1.2-1.5,0.9c0,0.9,0.3-0.3,0.6-0.3c0.4-0.6,0.7-1.3,0.9-2
			c0.3-0.6-0.3-1.2-1.2-1.5s-0.9,0.3-2,0c-1.1-0.4-2.3-0.7-3.5-0.9c-0.7-0.2-1.4-0.5-2-0.9c-0.6-0.6-1.5-0.6-2.6-0.6
			c-0.8,0-1.5,0.4-2,0.9c-0.6,0.3-0.6,0.6,0,1.8c0,0.9,0.3,0.3,0.6,0.9s0.6,0.9,0.3,0.9c-0.6-0.1-1.2-0.3-1.8-0.6
			c-0.6-0.6-0.9-1.5-0.9-2.3c-0.3-1.2-0.6-0.6-1.2,0c-0.6,0.4-1.1,0.9-1.5,1.5c0,0-0.6,1.5-1.2,1.8c-1,0.7-2,1.2-3.2,1.5
			c-1.5,0.3-1.5,0-1.8,0.9c-0.2,0.7-0.8,1.2-1.5,1.2c-0.6,0.1-1.2,0.3-1.8,0.6c-0.6,0.3-1.5,0.9-1.5,0.9c-0.7,0.2-1.4,0.2-2,0
			c-1.5,0-0.3,1.2-0.3,1.8s-1.2,0.3-2,0.3c-0.8,0.1-1.6,0-2.3-0.3c-0.6-0.6-0.3-0.6,0.6-0.9c0.5-0.1,1-0.3,1.5-0.6
			c0.6-0.3,0.9-0.9,1.5-1.2c0.7-0.2,1.4-0.3,2-0.3c0.4,0,0.8-0.1,1.2-0.3c0.3-0.3,1.2-0.9,1.5-1.2l1.2-1.2c0.5-0.4,1.1-0.7,1.8-0.9
			c1.1-0.2,2.2-0.5,3.2-0.9c0.9-0.3,0.3-0.6-0.3-0.9s-0.9,0-1.5-0.6s-0.6-0.6-1.8-0.9c-0.9-0.6-1.2,0.6-1.8,0
			c-0.7-0.7-1.6-1.1-2.6-1.2c-0.9,0-0.9,0.6-2.3,0.3c-1.2-0.3-1.8-0.9-2.3-0.9c-0.3,0-1.5-0.3-1.8-0.9s-0.9-0.6-2-1.5
			s-2.3,0.3-3.2,1.2c-0.9,0.6-0.9,1.2-1.2,1.8s-0.6,0-0.9-0.6c0-0.6,0.6-0.9,0.3-1.8c0-0.9-0.6-0.9-1.2-0.3c-0.6,0.3-1.5,0.9-2,1.2
			s-1.2-0.3-1.2-0.3c0.4-0.5,0.7-1.1,0.9-1.8c0.6-0.9-1.2-1.5-2-1.8s-1.8,0.6-2.3-0.3c-0.9-1.5-1.2-0.9-1.8-0.9s-0.9-0.3-1.2-1.2
			s-2-0.6-2.6-0.3s-0.9,0-1.2-0.6c-0.5-0.5-1.1-0.8-1.8-1.2c-0.6-0.6-1.2,0-1.8,0.3s-0.9,0-1.8-0.6c-0.9-0.9-1.8-0.6-2.6-0.3
			c-0.9,0.6-0.6,0-1.2-0.9c-0.6-0.9-0.9,0.3-1.5,1.5c-0.3,1.5-0.6,0-0.6-0.6s-0.6-0.9-1.5-1.2s-0.6,0.3-1.5,1.5s-0.6-0.3-0.6-0.6
			s0-0.6,0.9-1.2c0.9-0.6,1.2-0.3,0.3-1.2c-0.9-0.6-0.9-0.9-1.8-0.3c-0.6,0.6-0.9-0.6-1.5-1.5s-1.2,0-2,0.3s-0.3-0.9-0.6-1.5
			c-0.3-0.3-2-0.3-2.9-0.6c-0.6,0-1.2,0.1-1.8,0.3c-0.6,0.3-0.3,0.9-0.3,2s-0.6,0-0.9-0.3c-0.3-0.6,0-1.5,0.3-2.6s-0.9,0-1.5,0
			c-0.6-0.3-1.5,0-2.3,0c-0.9,0-1.5,0.3-2,0.3s-1.2,0-1.5,0.9c0,0.5-0.1,1-0.3,1.5c-0.3,0.6-0.6-0.6-0.6-1.2s0-0.9-0.9-1.2
			c-0.7-0.4-1.6-0.1-2,0.6c0,0.1-0.1,0.2-0.1,0.2c-0.6,1.2-0.6,1.2-0.9,0.9c-0.6-0.3-0.3-0.6,0-1.2s0.3-0.9-0.9-1.5
			c-1-0.2-1.9-0.3-2.9-0.3c-1.2,0-1.2-0.3-2-0.6s-2,0.6-2,0.6c-0.3-0.5-0.9-0.8-1.5-0.9c-0.9-0.3-0.9,0.6-0.9,1.8
			c-0.3,1.2,0,1.5-0.9,0.9s-0.3-2.6-0.3-3.2S108.4,0,107.8,0c-0.4,1.4-1.3,1.3-2.3,1.1l0,0c-0.6-0.3-1.2,0.3-1.2,0.9
			c-0.3,0.9,0,1.2-0.6,1.5c-0.8,0.5-1.4,1.2-1.8,2c-0.3,0.6-0.6,0.9-1.2,0.3s-0.3-0.3-0.9,0s-1.2,0.9-2,0.6s-1.2,0.3-1.5,0.9
			c-0.3,0.9-0.3,1.8-0.6,2c-0.5,0.6-1.3,1-2,0.9c-1.2,0-2.3,1.2-3.2,2c-1.2,0.9-1.8,0.3-2.6,0.3s-0.3,0.9,0.3,1.5
			c0.3,0.6,0,0.9-0.6,0.6c-0.6-0.3-0.9-0.3-0.9-1.5c-0.3-0.9-1.2,0.6-1.2,1.2c0,0.6,0.9,1.5,0.9,1.8c0.3,0.6-0.6,0.9-1.2,1.8
			c-0.9,0.6-0.9,0.9-1.5,1.5c-0.6,0.3-0.6-0.6-0.3-1.2l1.2-1.2c0.6-0.6,0.9-1.2,0.6-1.2c-0.6,0.1-1.2,0.3-1.8,0.6
			c-1,0.5-1.8,1.4-2.3,2.3c-0.6,0.6-0.6,0.3-0.6-0.3c0-0.9-0.6-0.6-1.2-0.6c-0.9,0-0.6,0.3-1.2,0.9c-0.3,0.7-0.7,1.4-1.2,2
			c-0.6,0.6,0,0.6,0.9,0.9c0.6,0.3,0.6,0.6,0.3,0.9s-0.6,0-1.8-0.3c-0.9-0.3-0.9,1.5-1.5,2.3c-0.3,0.7-0.3,1.4,0,2l0.6,1.8
			c0.3,0.6,0.3,1.2-0.6,2s0.3,1.8,0.3,2s-0.3,0.6-0.9,1.2c-0.6,0.6,1.2,2,1.2,2c0.2,0.7,0.2,1.4,0,2c-0.6,1.2,0,1.8,0,3.2
			c0.3,1.8,0.3,2,0,2.3c-0.3,0.6-0.3,1.8,0,1.5c0.6-0.3,1.5,0.9,1.2,1.5c0.1,0.7,0.4,1.3,0.9,1.8c0,0.3,0.6,1.2,0.9,2
			c0.3,0.9,0.3,1.8,0,2.6c-0.2,0.7-0.3,1.4-0.3,2c0.1,0.8,0.5,1.6,1.2,2c0.6,0.6,0.6,1.5,0.6,2.6c0.1,1.1,0.6,2.2,1.5,2.9
			c0.3,0.6,0.3,1.4,0,2c-0.3,0.7,0,1.6,0.6,2c0.5,0.8,1.2,1.5,2,2c0.9,0.4,1.9,0.6,2.9,0.6c1.2,0,2.4-0.1,3.5-0.3
			c0.6,0,1.5,0,1.8,0.3c0.4,0.6,0.5,1.4,0.3,2c-0.3,0.6,0.9,0.9,1.5,1.8c0.6,0.5,0.9,1.3,0.6,2c-0.3,0.6,0,0.6,0.9,0.9
			s1.5,0.9,2.9-0.3c1.8-0.9,2.3,0.3,2.9,0.9s-0.6,0.6-2,0.3c-1.2-0.3-1.2,0.3-0.9,0.6s3.2,0.6,3.8,0.9c1.1,0.1,2.1,0.5,2.9,1.2
			c0.6,0.6,0.6,0.6,0,1.5s-0.3,0.3-0.9,0s-1.1-0.8-1.5-1.5c-0.6-0.9-1.5-0.3-2.3-0.6s-1.2-0.6-1.8-0.6s-0.9,0-0.9,0.6s0,0.6-0.9,0.6
			s-1.2-0.3-1.8,0s0.9,0.6,0.3,1.5c-0.6,0.6,0.3,0.6,1.2,0.6c1,0,1.9,0.4,2.6,1.2c0.5,0.5,1.1,0.9,1.7,1.2c0.6,0.3-0.3,1.2-0.6,1.5
			c-0.6,0-1.5,0.6-2,0.3s-1.8,0-2.6-0.3c-1.2,0-0.3,0.9-0.3,1.8s0.6,1.2,1.2,1.8s1.8,0.6,2.3,0.9s0.6,1.2-0.9,1.5
			c-1.8,0.3-1.8,0.6-1.5-0.6c0.6-1.2,0-0.9-0.6-1.5S94,88.1,94,87.5s0-0.9-0.6-0.9s-1.5-0.6-1.5-1.2s-0.6-0.6-1.2-0.3
			c-0.9,0.3-0.3,1.8-0.3,2.3c0.3,0.8,0.7,1.6,1.2,2.3c0,0.6,0.3,1.5,0.9,1.5c0.3,0.4,0.3,1,0,1.5c0,0.6,0.3,1.5-0.3,2s0,0.9,0.6,0.9
			s0.3,1.8,0,2.3c-0.3,0.9,0,0.6,0.6,1.5s0.3,1.5-0.3,1.8c-0.9,0.3-0.3,0.9-0.3,0.9s1.5,0.6,2.6,1.2c0.9,0.6,0.9,1.2,0.6,2
			c-0.3,1.2-1.8,0.6-1.8,0.6c-1.2,0.7-2,1.9-2.3,3.2c0,0.6,0.3,1.5,0,2c-0.1,0.7-0.4,1.5-0.9,2c-0.3,0.9,0.6,2,0,2.6s-1.2,0-1.8,0
			s-1.8,1.2-2,1.5c0,0.3-0.9,0.6-1.2,0.9c-0.3,0-0.3,0.3-0.6,0.6c0,0.2-0.1,0.3-0.3,0.3l0,0l-0.3,0.3l0,0v0.3c-0.9,0.6-1.2,1.8-2,2.6
			c-1.2,1.5-2.9,2.3-4.1,3.5c-1.2,1.8-0.9,0.6-1.2,2.6c-0.6,0.6-1.8,0.9-2.3,1.5l-0.6,0.9c-0.3,0.3,0,0.9,0,1.5
			c-0.2,1.1-0.4,2.2-0.9,3.2v0.3c0.3,0,0.6,0.3,0.9,0c1.7-0.4,3.4-0.8,5-1.5c0.2,0,0.4,0.1,0.6,0.3c1.2-0.3,1.8-1.2,2.9-1.2
			c0.6-0.3,1.3-0.4,2-0.3c0.3,0,0.9-0.3,1.2-0.3h1.5c0,0.2-0.1,0.3-0.3,0.3l0,0c0,0-0.6,0.6-0.9,0.6c-0.9,0.4-1.8,0.9-2.6,1.5
			c0.6,0.3,3.8,0,4.7-0.3l0.3,0.3v0.3c-0.6,0.9-1.5,1.5-1.8,2.6H88c0.3,0,1.2-0.9,1.5-1.2c1.3-0.8,2.7-1.5,4.1-2
			c2.3-0.6,3.5-0.3,5.8-1.5c1.7-0.9,3.6-1.5,5.6-1.8c0,1.2-9.1,3.8-10.5,5.3v0.3c0.9,0.6,2.9-0.9,3.8-0.9c0.6,0.4,1.3,0.6,2,0.6
			l0.3,0.3c0.3,0.9-0.9,0.9-1.5,1.5l-1.2,1.2l0,0c-0.3,0.3-0.9,0.6-1.2,1.2s-0.6,0.9-0.9,1.5c-0.6,0.6-1.5,0.6-2,1.2
			c-0.6,0.3-0.9,0.9-1.5,1.5c-0.3,0.3-0.9,0.3-1.2,0.6s-0.6,0.6-0.6,0.9c0.3,0.9,1.2,1.2,1.8,1.8c0.3,0.3,0.3,0.9,0.6,1.5
			c0.8,0.2,1.6,0.5,2.3,0.9c0.9,0.6,1.8,2.3,3.2,2.6c1.8,0.3,2.9-1.2,4.4-1.5c1.8-0.6,5.8,0.6,7.3,2l-0.3,0.3c-1.8,0.3-4.7-0.6-6.1,0
			c-0.9,0.9-0.6,0.9-0.3,2c-0.6,1.5-1.5,1.5-2.6,1.8c-0.3,0-0.6,0.3-1.2,0.6c-1.4,0.7-2.6,1.9-3.5,3.2c-0.3,0.9-1.2,1.5-1.2,2.6v0.9
			c0,0.3,2,1.8,2.3,2c0.9,0.6,2.3-0.6,2.6,1.2c-0.3,0.3,0,0.6-0.3,0.9l0.3,0.3c0.3,0,0.9,0.3,1.2,0.3c0,0.3,0.3,0.9,0.3,1.2
			c0,0.9-1.2,2.6-1.2,3.5l0.3,0.3h0.3c0.8-0.8,1.8-1.4,2.9-1.8l0.3,0.3c0,0.3,0,0.3-0.3,0.6s-0.6,0.3-1.2,0.6c-0.3,0-0.3,0.3-0.3,0.6
			c0.6,0.6,1.5,0.3,2,0.6s0.6,1.5,1.5,2c0.3,0.3,0.9,0.3,0.9,0.6s-0.3,0.6-0.6,0.6h-3.5v0.3l2.6,3.2c0.2,0.2,0.4,0.3,0.6,0.3
			c0.9,0.3,2.9,0.6,3.5,1.5v0.3c-0.3,0.3-0.8,0.3-1.2,0c-2.9-0.6-2,0.3-3.5,0.3c-0.6,0-0.9-0.6-1.8-0.6c-0.6,0.3-2,0.9-2.3,1.5
			c0,0.3,0.6,0.9,0.9,1.2v0.6l-0.3,0.3c-0.6,0.3-1.2,0.3-1.8,0.6l-0.3,0.3l0.3,0.3h2c0.3,0.4,0.7,0.6,1.2,0.6c1,0.4,2.1,0.7,3.2,0.9
			c0.3,0,0.3-0.3,0.6-0.6l0.3-0.3c1.2,0,0.9,1.2,1.5,1.2h0.3c0.2-0.2,0.3-0.6,0.3-0.9c0.7-0.9,1.9-1.1,2.9-0.6c0.3,0.3-0.3,0.9,0,1.5
			c1.5,2,5.3-2.3,7-1.2v0.3c-0.9,0.9-4.4,1.5-5.3,2.3c-0.2,0.2-0.3,0.4-0.3,0.6c0.9,0.6,3.2,0.6,3.2,0.9l0,0v0.6l-0.3,0.3
			c-0.6,0-1.2,0.1-1.8,0.3c-0.6,0.3-1.5-0.3-2-0.3l-0.3,0.3c0,0.3,0.6,0.6,0.9,0.6h0.3l0.3,0.3v0.3c-1.8-0.3-2,0-3.2,1.2l-0.6,0.6
			c0,0.3,0.3,0.3,0.6,0.3s0.6,0.3,0.9,0.3v0.3c0,0.3-0.3,0.3-0.6,0.6c-1,0.1-2,0.2-2.9,0.6v0.3c0.6,0.2,1.2,0.3,1.8,0.3
			c1.5,0.6,1.5,2.3,2,3.5c0.3,0.3,0.6,0.6,0.6,0.9s0.3,0.6,0.3,0.9c0.7,0.7,1.1,1.6,1.2,2.6l0,0c-1.2,1.8-4.7-0.6-7,1.2
			c-0.3,0.3-0.6,0.6-0.6,0.9c0,0.2,0.1,0.4,0.3,0.6c0,0.3,0,0.6-0.3,0.6c-0.6,0.3-1.5,0.6-1.8,0.3l-0.3-0.3c-0.6-0.3-0.6-0.6-1.5-0.6
			v0.3l0,0c0,0.9,1.2,2,1.5,2.6h0.6v0.3c-0.3,0.3-0.3,0.6-0.6,0.6c-0.9,0.6-1.5-0.3-1.5,0.9c1.2,1.5,2.6,0,4.1,2v0.9
			c0.6,1.8,0,2.6,0.6,4.1c1.8,1.5,4.1,1.2,5.8,2.3v0.3c-0.2,0-0.3,0.1-0.3,0.3l0,0c-0.9-0.3-2.9-0.3-3.8-0.6h-0.6
			c-0.3,0-0.6-0.3-0.9-0.3s-0.6-0.1-0.9-0.3c-1.2-0.9,0-2.3-0.9-4.1c-1.2-2.3-2.6-1.8-3.8-2.9c-0.1-0.6-0.3-1.2-0.6-1.8h-0.3
			c1.5,1.8,1.5,2.3,1.5,2.6c-1.2,0.4-2.5,0.7-3.8,0.9l0,0c-0.2,0-0.4-0.1-0.6-0.3c-0.9-1.5-0.6-2-2.6-2.3c-0.6,0-0.9-0.3-1.5-0.3v0.3
			c0.3,0.3,0.7,0.5,1.2,0.6c0.2,0.2,0.3,0.4,0.3,0.6c-0.7,0.6-1.7,0.9-2.6,0.9v0.3l1.5,2.3c-0.6,2,2,0.6,2.3,1.8
			c0,0.3-0.3,0.9-0.3,1.2c-0.3,0.9,0,1.5-0.3,2c-0.3,1-0.2,2,0.3,2.9c-0.6,0.6-2.6,0.9-3.5,1.2c-0.9,0.4-1.8,0.9-2.6,1.5
			c-0.6,0.6-0.6,1.2-0.9,1.2v0.3c-0.9,0.9,0.6,2.3,1.2,2.6v0.3c-1.5,0.6-3.2,0.3-2.9,2.6c0,0.5,0.2,0.9,0.6,1.2c1.5-0.5,3-1.2,4.4-2
			c0.4,0,0.8,0.1,1.2,0.3v0.9c0.6,0.6,2,1.5,2.3,0c0.3,0.3,1.8,0.3,2,0.6v0.3c-0.3,0.3-0.3,0.6-0.6,0.6c-1.5,1.2-3.2,0.9-5,1.5
			l-0.3,0.3v0.6c0.5,0.2,1,0.5,1.5,0.9v0.6c-0.3,0.3-0.3,1.2-0.6,1.2l-0.3,0.3H92c-0.9-0.3-1.5,0.3-2.3,0.3s-2-0.3-2.6,0.3l0,0
			c0.9,0.6,2.9,0,3.2,1.5s-1.8,1.2-2.9,1.5l-0.3,0.3c-1.1-0.1-2.2,0.3-2.9,1.2c-0.3,0.3-0.3,0.6-0.6,0.9l-0.3,0.3v0.3
			c-0.6,0-1.2-0.3-1.8,0s-1.5,0.9-2,1.2c-0.2,0-0.3,0.1-0.3,0.3l0,0l-0.6,0.6c0,0.6,0.3,0.6,0.6,0.9c0.3,0.6,0,1.5,0,2
			c0.3,0.3,0.3,0.3,0.3,0.6s-0.3,0.3-0.6,0.6c-0.4-0.1-0.8-0.3-1.2-0.6c-0.3,0-0.6-0.3-0.9,0c-0.3,0-0.3,0.3-0.3,0.6
			c0,0.6,1.2,1.8,1.5,2.3c0,0.3-0.3,0.9-0.3,1.2c0,0-1.8,1.5-1.8,1.8h-0.3l-0.3-0.3c0-0.3-0.3-0.6-0.3-1.2c0-0.3-0.3-0.3-0.3-0.6
			c-0.3,0-0.6,0.1-0.9,0.3l-0.3,0.3v0.6c0.4,0.9,0.7,1.9,0.9,2.9l-0.3,0.3h-0.6c-0.3,0-1.5-0.6-1.5-0.9h-0.3
			c-0.6,0.6-1.2,0.9-1.5,1.8c0,0.6,0.9,1.5,0.9,2c-0.3,0.5-0.9,0.9-1.5,1.2c-0.3,0.3-0.6,0.6-0.6,0.9v0.3l0,0c1.5,0.3,2-0.9,2.9-0.9
			l0,0c-0.6,1.2-2.6,2.3-2.9,2.9s0.3,1.2,0.3,1.8c-0.3,0.4-0.7,0.7-1.2,0.9c-0.3,0.3-0.3,0.6-0.6,0.6l-0.3,0.3
			c-0.3,0-0.6-0.1-0.9-0.3V277c0.2-0.7,0.5-1.4,0.9-2v-0.6c-0.6-0.6-1.5-0.3-2.3-0.9c-0.5,0-0.9,0.2-1.2,0.6c-2,1.5-0.6,2-0.9,3.5
			c-0.2,0-0.3,0.1-0.3,0.3l0,0h-0.6c-0.3-0.3-0.3-1.2-0.6-1.8c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.6-0.9-1.2-1.2
			c-0.3-0.3-0.9-0.6-1.2-0.9c-0.3,0-0.3-0.3-0.6-0.3s-0.6,0.3-0.9,0.6v0.3c0.3,0.3,0.3,0.9,0.6,1.2s0.9-0.3,1.2,0l0.3,0.3
			c0,0.6-0.9,1.2-0.9,1.8c0,0,1.5,5.3,1.5,5.5c0,0.3,0.3,0.6,0.6,0.6h0.3c0.3,0.3,0.6,3.5,0.3,3.8c0,0.3-0.3,0.3-0.6,0.6h-0.6v-0.9
			l-0.3-0.3c-0.4,0-0.8,0.1-1.2,0.3c-0.3,0.4-0.3,1,0,1.5c0.9,1.5,2.9,1.8,3.5,4.1c0,0.3,0.3,0.9,0,1.2l-0.3,0.3
			c-0.4,0-0.8-0.1-1.2-0.3c-0.2,0-0.4,0.1-0.6,0.3v0.6c0,0.3-0.3,0.3-0.6,0.6c-0.6,0-1.5-0.3-2,0l-0.3,0.3c0,0.6,0.6,0.6,0.9,0.9v0.3
			c-0.3,0-0.6,0.3-0.6,0.6c-0.9,0.9-0.6,3.5,1.2,3.5c0.9-0.2,1.8-0.5,2.6-0.9c1.5,0.3,1.2,1.8,1.5,2.6c0.9,0.9,2.3,1.2,2.6,2.6
			c0.6,0.6,1.8,0.6,2.9,0.6l0.3-0.3c-0.3-0.6-4.1-3.8-4.7-5v-1.8H64c2.1,0.8,3.7,2.4,4.7,4.4c0.3,0.6,0.9,1.2,1.2,1.8
			c0.3,0.9,0.3,1.5,0.9,2c0.9,0.5,1.9,0.9,2.9,1.2c0.3,0.3,0.3,0.9,0.9,1.2c0.6,0.6,2,1.2,2.6,2c0.3,0.3,0,0.6,0.3,0.9l0.3,0.3
			c0.3,0,0.3-0.3,0.6-0.3c0.6-0.3,0.9,0,1.5-0.3l0,0c0.1,0.5,0.2,1,0.3,1.5c1.2,0.3,1.8,0.3,2.3,1.8c0,0.3-0.3,0.3-0.3,0.6
			c-0.3,0.3-0.6,0.3-0.6,0.6c0,0.9,1.5,1.5,2,1.8l1.8,0.9l0.9,0.6c0.3,0,0.3,0.3,0.6,0.3h0.9c0.6,0.3,2.6,0.9,3.5,1.2l3.5,1.8
			c0.6,0.6,1.3,1.1,2,1.5c1.2,0.3,2,0.3,3.2,0.6c2,0.9,2.6,2.9,3.5,3.5c1.2,0.9,4.4,1.8,4.7,3.5c-0.3,1.2-0.9,0.9-2,0.9l-0.3,0.3
			c0,0.3,1.2,0.6,1.5,0.9c0.9,0.3,1.8,0.9,2.6,1.2l0,0c2,1.2,1.5,2.3,2,2.9c0.9,0.9,2.6,0.9,3.8,1.8c1.4,0.9,2.7,2,3.8,3.2
			c0.4,0.4,0.9,0.7,1.5,0.9c0.3,0.3,0,0.9,0,1.2s0.6,0.9,0.9,1.2c0.3,0.6,0.3,1.2,0.6,1.5c0.6,1.2,1.5,0.6,2,1.2
			c0.2,0.2,0.3,0.6,0.3,0.9c0.3,1.1,0.9,2.1,1.8,2.9c-0.1,1.3,0.3,2.5,1.2,3.5c0,0.3,0.3,0.6,0.6,0.6c0.7,0.5,1.2,1.1,1.8,1.8
			c0.3,0.6,0.3,1.2,0.6,1.8c0.6,1.2,1.5,1.8,1.8,2.9c0.5,0.8,0.9,1.7,1.2,2.6c0.3,0.6,0.9,0.9,1.2,1.5s1.2,2,1.5,2.6
			c0,0.3,0.3,0.9,0.3,1.2c0,0.6-0.3,1.2-0.3,1.8v0.3c0.4,1.3,0.8,2.6,1.5,3.8c0.3,0.3,0.5,0.7,0.6,1.2c0.2,0.4,0.3,1,0.3,1.5
			c0.1,0.6,0.3,1.2,0.6,1.8c0.3,0.6,0.3,1.2,0.6,1.8c0.3,0.3,0.3,0.6,0.6,0.9l0.3,0.6l0.3,0.3l0.3,0.3h-0.3c0.4,0.8,0.7,1.7,0.9,2.6
			c0.4,0.7,0.9,1.2,1.5,1.8c0,0.4,0.1,0.8,0.3,1.2c0.3,1.5,1.2,2.6,1.5,4.7v0.3c0.2,0.2,0.3,0.6,0.3,0.9c0.6,1.3,1,2.7,1.2,4.1v0.9
			l0.9,2.6c0,0,0.3,1.2,0,1.5c0,0.6-0.3,1.2-0.3,1.8c0,1.8,1.8,2.6,1.8,5l0,0v0.6c-0.1,0.7-0.4,1.5-0.9,2c-0.2,1.4-0.3,2.7-0.3,4.1
			c0.1,0.8,0.3,1.6,0.6,2.3v2c0.1,0.5,0.2,1,0.3,1.5l0,0c0,1.2,0.6,2,0.6,3.2v0.9c-0.3,0.9,0.9,4.1,0.9,5.3v4.1c0,0.6,0.3,1.8,0,2.3
			c-0.3,1.2-0.3,2.3-0.6,3.5v2.3c0,1.5-0.6,2.6-0.6,4.1c0,0.6,0.3,1.2,0.3,1.8c0,0.3-0.3,3.5-0.3,3.8c-0.3,0.9,0.3,2,0.3,3.2
			c0,2,0.3,6.4-0.9,8.2v0.6c0.2,0.3,0.3,0.8,0.3,1.2c-0.1,2-0.8,4-2,5.5h-0.3v0.3l-0.3,0.3v0.3c-0.9,0.6,0.6,2.9-1.5,4.7v3.8
			c-0.3,1.2-0.6,2.4-1.2,3.5c-0.3,0.6,0,0.9-0.3,1.2c-0.3,0.9-0.9,1.5-1.2,2.3c-1.2,2-2.3,3.2-4.7,3.5h-0.6c-1.2,0.9-1.2,2.9-2,4.4
			c-0.3,0.3-0.6,0.9-0.9,1.2c-0.6,0.6-1.2,0.9-1.8,1.5c-1.2,1.2-2,2.9-3.2,4.1c-0.5,0.4-0.8,0.9-0.9,1.5c0,0-3.2,5-3.5,5.5
			c-0.6,0.9-2.9,2.6-3.5,3.5c-0.6,0.6-2.3,1.8-2,2.6c0.2,0,0.3,0.1,0.3,0.3l0,0c1.5,0.3,2.6-2.6,4.1-2.6h0.3v0.6
			c-0.3,0.3-1.8,2.9-2.3,3.5l-1.8,1.8c-0.6,0-1.8,2.6-2.3,3.2s-1.5,0.9-2,1.5s-1.2,0.9-1.8,1.5s-1.2,1.5-1.8,2
			c-1.1,0.9-2.2,1.9-3.2,2.9c-0.9,0.9-1.5,1.8-2.3,2.6c-0.6,0.6-1.8,1.5-2.6,2.3c-0.7,0.9-1.6,1.7-2.6,2.3c-2,1.2-3.5,1.5-5,3.2
			l-2.6,3.5c-0.3,0.6-0.7,1.2-1.2,1.8c0,0.3-0.9,0.9-0.9,0.9c-0.6,0.2-1.2,0.3-1.8,0.3h-0.9c-0.3,0-0.3,0.3-0.6,0.6
			c-0.4,0.3-0.7,0.7-0.9,1.2c-0.9,0.5-1.9,1-2.9,1.5c0,0.2-0.1,0.3-0.3,0.3l0,0l-2.6,1.8c-0.3,0-0.3,0-0.6,0.3
			c-0.6,0.3-0.9,0.6-1.5,0.9c-1.8,1.2-3.5,1.8-5,2.9c-0.5,0.3-1,0.7-1.5,1.2l-0.6,0.6c-0.2,0-1.6,1.4-3,2.8L563.2,567.8z"/>
	</g>
	<g id="Terres-cries-de-la-baie-James_18" className="region">
		<circle className="point" cx="240" cy="680" r="1" fill="transparent" />
		<path id="path5022_3_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M68.3,567c-1.1,1-1.1,1-1.7,1.4c-1.2,0.9-2.6,0.6-3.8,1.2c-0.9,0.3-1.8,0.9-2.6,1.2
			c-0.3,0.3-0.6,0.3-1.2,0.6c-0.3,0-0.9-0.3-1.2,0c-0.6,0.3-0.9,1.5-1.5,1.8c-0.3,0.3-5.3,2-5.8,2.3s-0.9,0.3-1.5,0.6
			c-0.2,0.6-0.8,0.8-1.4,0.6h-0.1c-0.3,0.3-0.3,0.6-0.9,0.9c-1.5,0.6-2.9-0.3-5.3,1.5l-2,2c-0.6,0.6-0.9,1.5-1.8,2s-1.5,0-2,0.3
			c-0.9,0-5,1.8-6.4,2c-1.2,0.3-2.6,0-3.5,0.3l-0.9,0.9l-3.5,2c-0.9,0.3-2,0-2.6,0.3s-2,1.5-2.6,1.5c-0.3-0.3-0.3-0.6-0.9-0.9
			c-0.6,0.3-0.6,1.2-1.2,1.5c-0.9,0.6-1.8,0-2.6,0.3c-1.2,0.3-2.3,2-3.2,1.8c-0.3-0.3-0.6-1.2-0.3-1.5c0.3,0,0.3-0.6,0.3-0.6
			l-0.3,0.3c0,0.3-0.3,0.6-0.3,0.9c0,0.6,0.6,1.2,0.6,2l0,0c-0.3,0.6-1.2,0.6-2,0.9c-0.3,0.3-0.9,0.6-1.2,0.9
			c-0.3,0.3-0.9,0.3-1.5,0.6l-0.9,0.9h-1L1.2,595l0.3,0.3c0.6,0,1.5-0.3,2-0.3c0.9,0,1.2,0.9,2,0.3V595c0-0.3-0.3-0.6-0.6-0.6v-0.3
			h0.6c0.3,0.3,0.3,0.9,0.6,1.5v0.6c-0.3,0.6-1.2,0.3-1.5,0.6L4.4,597l0.3,0.3c1,0.2,1.9,0.3,2.9,0.3c0.3,0,0.6-0.6,0.6-0.9l0.3-0.3
			c0,0.3,0.3,0.9,0.6,0.9c0,0.9-1.8,2.3-2.3,3.8v0.3c0.6,0,1.8-0.9,2.3-1.2c-0.3,0-0.6-0.3-0.9-0.3c0.2-0.4,0.5-0.8,0.9-1.2h0.6
			c0.6,0.3,0.9,0.9,1.5,1.2c0.3,0.3,0.6,0.6,0.6,0.9c0,2-2.6,1.2-3.5,1.8L8,602.9c0.9,0,1.8,0.4,2.3,1.2v0.3h0.6
			c0.4,0.4,0.6,0.9,0.6,1.5c0,0.3-0.3,0.6-0.6,0.6h-0.6l-0.3,0.3c0.4,0.3,1,0.4,1.5,0.3c-0.2,0.2-0.3,0.6-0.3,0.9
			c0.5,0.4,0.9,0.9,1.2,1.5c0,0.3,0.6,0.6,0.6,0.9c-0.2,0.8-0.5,1.6-0.9,2.3l0.3,0.3c0.9,0,1.8,0.1,2.6,0.3v0.6
			c-0.7,0.3-1.5,0.5-2.3,0.6c0,0.3,0.3,0.6,0.6,1.2c-0.3,0.3-0.6,0.9-0.9,1.2l0.3,0.3c0.3,0,0.6-0.3,0.9-0.3l0.3,0.3l0.3,0.3v1.5
			c0.3,0.6,0.9,0.6,0.9,1.8c0,0.3-0.3,0.6-0.6,1.2v0.3c0.4,0,0.8-0.1,1.2-0.3c0.2,0,0.4,0.1,0.6,0.3v0.6c-0.3,0.6-0.9,0.3-1.2,1.2
			v0.3c0.3,0,0.6,0.3,0.9,0.3c0.3,0,0.3,0.3,0.6,0.6s1.2,0,1.5,0.6c0.3,0,0.9-0.3,1.2-0.3s2.6,0.3,2.6,0.3s0.3,0.6,0.6,0.6l-0.3,0.3
			c-0.7,0.3-1.4,0.3-2,0l-0.3,0.3c0,0.3,0.3,0.6,0.6,0.6v0.3c-0.9,0.6-0.9,1.2-2,1.5c-0.2,0.2-0.3,0.6-0.3,0.9c0.5,0,1.1-0.2,1.5-0.6
			c0.5,0,0.8,0.4,0.9,0.9c0.4,0.3,1,0.4,1.5,0.3c0.2,0,0.4,0.1,0.6,0.3v0.9l0.3,0.3h0.6l0.9-0.9h0.6v0.3c-0.2,0.2-0.3,0.4-0.3,0.6
			l0.3,0.3c0.5,0,1,0.1,1.5,0.3l0.3-0.3v-0.9l0.3-0.3c1.5,0,5.5-0.9,6.4-0.9v0.3c-0.3,0.6-0.9,0.9-1.2,1.5c-0.9,0.6-2.3,0-2.9,1.2
			l0.3,0.3h0.9c0.3,0.3,0.6,0.6,0.6,0.9l-0.3,0.3c-0.3,0-0.6-0.3-0.9-0.3l-0.3,0.3l-0.3,0.3l0.3,0.3h0.9l0.3,0.3
			c-0.3,0.6-0.6,0.9-1.2,0.9c-0.9,0-1.2-0.6-1.2-1.5H28l-0.3,0.3c-0.3,0.3-0.3,0.8,0,1.2c0,0.3,0.3,0.6,0.3,0.9l-0.3,0.3
			c-0.5,0.1-1,0-1.5-0.3c-0.6,0.3-2-0.6-3.5,0l-0.3,0.3l0.3,0.3c1.5,0,4.1,0.3,5.3,0.3c0.3,0,0.6-0.3,1.2-0.3c0.9,0,1.8,0.9,2.3,1.5
			c-0.3,0-0.3,0.3-0.6,0.6l0.3,0.3h0.6l0.3,0.3c0,0.4,0.1,0.8,0.3,1.2c0.6,0,0.9-0.6,1.2-0.9h0.3l0.3,0.3c0,0.3,0.3,0.9,0.3,1.2
			c0.2,0.2,0.6,0.3,0.9,0.3c1.1,0,2,0.7,2.3,1.8l-0.3,0.3c-0.5,0-1-0.1-1.5-0.3c-0.9-0.3-1.2-0.9-2-0.6c-0.3,0-0.9,0.3-1.2,0.3
			l-0.3,0.3c0.3,0.3,0.6,0.6,0.6,0.9l-0.3,0.3c-0.3,0.3-0.9,0-1.5,0l-0.3,0.3c0.6,0,2.3,0,2.6,0.6v0.6c-0.6,0.3-1.8,0-2.3,0l-0.3,0.3
			c0.9,0.3,2,0,2.9,0.6v0.3c-1.2,0.9-3.5-0.6-4.7-0.9l-0.3,0.3c0.3,0.2,0.8,0.3,1.2,0.3c0.6,0.3,1.2,0.9,1.8,0.9
			c1.8,0.6,2.3-0.3,3.5,2l-0.3,0.3c-0.6,0.3-0.9-0.3-1.8-0.6c-0.6,0-1.2,0.3-1.8,0.3c-0.6,0-0.9-0.3-1.5,0l-0.3,0.3l0.3,0.3
			c1.8,0.3,2.6,1.2,1.8,2.9v0.3c0.8,0.1,1.6,0.1,2.3,0c0.5-0.1,1,0,1.5,0.3c0.9,0.3,3.5,1.8,3.5,2.9c-2,0.6-2.6-1.5-4.1-1.8
			c-0.6-0.3-1.2,0-1.5,0c-0.6,0-0.9,0-1.2,0.6l0.3,0.3c0.3,0.2,0.8,0.3,1.2,0.3c0,0.3-0.9,0.9-1.2,1.2c-0.3,0.2-0.8,0.3-1.2,0.3v0.3
			c0.3,0.6,0.6,0.6,1.2,0.9c-0.2,0.3-0.3,0.8-0.3,1.2c-0.2,0-0.4,0.1-0.6,0.3c0,0.6,0.1,1.2,0.3,1.8c0.3,0.3,0.9,0.3,1.2,0.6v0.3
			l-0.3,0.3l-0.3,0.3v0.6l-0.3,0.3c-0.9,0.3-1.5-0.3-2-0.6c-0.9,0-1.5,0.3-2,0.3l-0.3,0.3v0.3l0.3,0.3c0.3,0,0.9-0.3,1.2-0.3l0.9,0.9
			c1.2,0.6,3.5-0.3,4.1,1.2v0.3c-0.4,0.2-1,0.3-1.5,0.3c0,0.3,0.3,0.9,0.3,1.2l0.3,0.3h0.9l0.3,0.3v0.3c-0.3,0.9-0.9,0.9-1.8,0.6
			c-0.3,0.3-1.2,2.3-0.9,2.6c0.8-0.1,1.6-0.3,2.3-0.6c0.3-0.3,0.3-0.6,0.6-0.9h0.6c0.6,0.6,0.6,0.9,1.5,1.2l0.3,0.3
			c0,0.2-0.1,0.4-0.3,0.6v0.3c0.3,0,0.6,0.3,0.9,0.3c0.6,0.3,0.6,0.6,0.9,1.2c-0.3,0.9-1.2,1.5-1.8,1.2s-0.9-1.5-2.6-1.8
			c-0.2,0-0.4,0.1-0.6,0.3c-0.3,0.9,0.9,0.9,1.5,1.8v0.9c0.4,0.3,1,0.4,1.5,0.3c0.3,0,0.3,0.3,0.6,0.6l-0.3,0.3
			c-0.9,0.6-1.2,0-1.8,1.5H35c-0.3,0-1.2-0.9-1.5-1.2v-1.5l0.6-0.6H35c-1.8-0.3-1.8,0-3.2,0.6c-0.5,0.3-0.9,0.9-1.2,1.5v0.3l0.9,0.9
			v0.9c0.3,0.7,1,1.2,1.8,1.2v0.3c0,0.3-0.6,0.3-0.6,0.3l-0.3,0.3l0.3,0.3c0.9,0.6,1.8-0.9,2.3-0.9c1.5,0.6,1.2,2,1.8,3.2
			c0.9,0.6,1.8,2,1.2,3.2c-0.3,0.6-0.9,0.6-0.9,1.2c0.3,0.3,0.3,0.8,0,1.2c0.3,0.3,0.6,0.3,0.6,0.6c0.4,0.5,0.6,1.1,0.6,1.8
			c-0.2,0.4-0.5,0.8-0.9,1.2v0.6l0.3,0.3c0.4,0.3,1,0.4,1.5,0.3c0,0.3,0.3,0.3,0.6,0.6h0.3l0.3,0.3v0.3l-0.3,0.3
			c-0.3,0-0.6,0.3-0.9,0.6v0.3h0.9c0.6,0.3,0,1.2,0.3,1.5l-0.3,0.3c-0.3,0-0.9-0.3-1.2-0.3l-0.3,0.3c0.6,0.6,1.2,1.2,0.9,2
			c-0.6,0-1.5,0-1.8,0.6v0.3c0.3,0.3,1.2,0.6,1.2,0.9c-0.3,0.6-0.3,1.2-0.6,1.8c-0.4,0.3-0.6,0.7-0.6,1.2c-0.3,0.6,0,0.9-0.3,1.5
			c0,0.6-0.6,1.2-0.9,1.8c-0.4,0.3-0.6,0.7-0.6,1.2l0.3,0.3c0.9,0,1.2-0.6,2-0.3c0.6,0.6,0,3.2-1.5,2.3v0.3c-0.3,0.6-0.9,0.3-1.2,0.9
			l0.3,0.3c0.7,0.2,1.4,0.5,2,0.9h0.3l0.3,0.6c0.6,0,1.5,0.3,2,0.3l0.3-0.3c0-0.2-0.1-0.4-0.3-0.6l0.3-0.3c0.3,0,0.6,0.1,0.9,0.3
			c0.6,0.6,0,2.6,1.2,2.6c0.3-0.2,0.8-0.3,1.2-0.3c0.2,0.2,0.3,0.5,0.1,0.8l-0.1,0.1c-0.3,0.3-0.6,0.6-0.9,0.6s-0.9-0.3-1.5,0
			l-0.3,0.3H41c-0.2,0-0.4-0.1-0.6-0.3c-0.3,0.9,0,1.5-0.3,2.3c-0.3,0.3-0.6,0.6-0.9,0.6l-0.3,0.3c0.2,0.2,0.6,0.3,0.9,0.3
			c0,0.3,0.3,0.6,0.3,0.9s-0.3,0.6,0,0.9c0.3,0.6,0.9,0.9,1.2,1.5h0.9l0.9,0.9c0.3,0.3,0.9,0.3,1.2,0.6c0.6,0,0.9-0.6,1.2-0.6
			c1.2-0.3,1.5,0,2.6-0.9H49l0.3,0.3l-0.3,0.3c-1.5,0.3-1.5,1.2-2,2l-1.8,1.8v0.3l0.3,0.3c0.9,0.6,1.5-0.6,2-0.6c0.3,0.3,0,0.9,0,1.2
			c0,0.6,0.3,1.2,0.3,1.8c-0.3,0.3-1.2,0.3-1.5,0.3L46,737h-0.9c-0.2,0-0.4,0.1-0.6,0.3c-0.3,0-0.6-0.1-0.9-0.3H43
			c-0.3,0-0.9,0.6-1.2,0.9c-0.3,1.8,2.3,1.8,2.9,2.3c0,0.6,0.6,0.9,0.6,1.8c-0.3,0.3-0.9,0.3-1.5,0.6l-0.3-0.3H43l0.3,0.3
			c0.6,0.6,1.8-0.3,2.6,0l0.3,0.3c0.6,1.2-0.6,2-1.2,2.6v0.3c0.3,0.3,0.6,0.6,0.6,0.9c1.8,0.3,1.2-0.3,1.8-0.6v0.9
			c0.3,0.3,0.6,0.6,0.6,0.9l-0.3,0.3h-0.6l-0.3,0.3c0.7,0.3,1.3,0.8,1.8,1.5c-1.2,0.9-1.2,0.3-2,1.8h-0.9l-0.3,0.3v0.6
			c0.3,0.3,0.9,0,1.2,0.3l0.3,0.3h0.9c0,0.6-0.3,0.9-0.3,1.5l0.3,0.3c0.6-0.3,0.6-0.9,1.2-1.2c0.3,0,1.2,0,1.5,0.3l0.3,0.3
			c0,0.3-0.3,0.6-0.3,0.9c0,0.3,0.3,0.6,0.6,0.6c1.5,0,2.3-2.6,3.8-0.3c0.4,0.4,0.7,0.9,0.9,1.5c-0.3,0.6-0.6,0.6-1.2,0.9v0.6h0.3
			c0.3-0.2,0.8-0.3,1.2-0.3l0.3,0.3c0,0.6,1.2,1.2,0.9,2c-0.3,0-0.3,0.3-0.6,0.6c-0.3,0-0.9,0.3-1.2,0.3v0.6c0.3,0.6,0.8,1.1,1.5,1.2
			c0.2,0.2,0.3,0.6,0.3,0.9c-0.3,0.6-0.9,0.9-0.9,1.8c0.3,0.3,0.3,0.9,0.6,1.5c0.2,0.2,0.6,0.3,0.9,0.3v0.9c0,1.8-0.9,2.3-1.8,3.5
			c-1.2,0.6-0.6,1.5-0.9,2.3l0.3,0.3c0.3-0.3,1.2,0,1.5-0.3c3.8-0.9,2.6,0,5,2v0.3c-1.2,0.3-3.2-2-5-1.2c-0.3,0.3-0.6,0.3-0.9,0.6
			c-0.6,0.3-0.9,0.6-0.9,1.2s1.2,1.5,1.2,2.3c0.3,2-1.5,2.9-1.8,4.1c0.2,0.9,0.1,1.8-0.3,2.6l-0.9,0.9c-0.3,0.3-0.3,0.6-0.6,0.9
			s-1.2,0-1.8,0.3l-0.3,0.3c0,0.6,0.6,0.9,0.6,1.2v0.6c-2,1.2-2,0-2.9,0.3l-0.3,0.3l0.3,0.3l0.3,0.3c0.3,0.9,0.3,1.8,0,2.6H48
			c-0.2-0.2-0.4-0.3-0.6-0.3c0,2.6-1.2,3.2-3.5,3.8c-0.6,0-0.9,0.3-1.5,0.6c-0.4,0-0.8,0.1-1.2,0.3c-0.5,0.2-1,0.5-1.5,0.9v0.9
			c0.3,0.6,0.6,0.9,0.9,1.5s0.3,1.5,0.6,1.8l0.9,0.9c1.2,1.8,0,2.6-1.2,3.8s-1.8,2-3.5,2c-0.7-0.4-1.5-0.7-2.3-0.9
			c-0.6,0.6-2,1.8-1.8,2.6c0.3,0.3,0.9,0.3,1.5,1.2c0.2,1.3,0.8,2.4,1.8,3.2c0.3,0.3,0.6,0.3,0.9,0.6c0.6,0.6-0.3,2,0,2.9l0.3,0.3
			c0.9,0.3,1.5,0,2.6,0.6c0.9,0.6,1.5,2.6,2.3,2.6h1.2c0.3,0,0.3,0.3,0.6,0.6l-0.3,0.3h-0.3l-0.3,0.3c0.3,1.5-0.6,2.9-0.6,4.4
			s-0.3,1.5,0.9,2.3c0.3,0.3,0.7,0.5,1.2,0.6c1.2-0.6,3.2-0.9,4.1,0.6v0.3c-1.1,0.2-2.2,0.1-3.2-0.3l-0.3,0.3
			c-0.3,0.6-0.6,0.6-0.9,1.2l-2,2c-0.3,0.6-0.3,1.5-0.9,2s-1.8,0.9-2,1.5s-0.1,1.3,0.3,1.8c0.9,0.9,1.5,0.3,2.9,2
			c-0.3,0.3-0.8,0.3-1.2,0h-0.3c-0.3,0.3-0.3,0.9-0.6,1.2s-1.2,1.8-1.8,1.8c-0.2,0.8-0.3,1.5-0.3,2.3c-1.2,5.5,0.6,3.5,1.8,5.5
			c0.3,0.3,0,0.9,0.3,1.2s0.5,0.7,0.6,1.2l0.3,0.3l-0.3-0.3h-0.3c-0.6-0.5-1.3-0.7-2-0.6l-0.9-0.9c-0.9-1.2-1.5-2.6-1.8-4.1
			s1.5-1.5,1.2-3.5c-0.3-0.3-0.6-0.3-0.9-0.9V843c-0.1-1.7-0.2-3.3-0.6-5c-0.3-1.2-1.2-2.6-1.5-3.8s0.3-2-0.3-2.9
			c-0.6-1.2-1.5-1.5-2.3-2.3c-0.3-0.3-0.6-0.9-0.9-1.2s-1.5-2-1.8-2c-0.9-0.3-1.5,0.9-2,1.2c-1.2,0.6-2-0.3-3.2-0.6H24l2,1.8h-0.3
			c-0.2-0.4-0.3-1-0.3-1.5c0-0.6-0.6-1.2-0.3-1.8c0-0.2,0.1-0.3,0.3-0.3l0,0l0.9-1.8c0.2-0.3,0.3-0.8,0.3-1.2c0.3-0.6,1.2-1.2,0.6-2
			c-3.8-0.6-2.6-1.8-3.8-2c-0.6,0-0.9,0.3-1.5,0.6c-0.3,0.3-0.6,0.9-0.9,1.2c-0.6,1.8-0.6,1.2-1.8,2.3c-0.3,0.3-0.3,0.6-0.6,0.9
			s-0.9,0.6-1.2,0.9c-0.6,0.6-1.2,1.5-1.8,1.8c-0.8,0.5-1.7,0.8-2.6,1.2c0,0.3,0.3,0.6,0.3,1.2c0.3,0.6,0.3,1.5,0.6,2.3
			s1.2,1.8,1.2,2.6c-0.3,0.3-0.6,0.6-0.9,0.6l0.3,72.4L14.2,1016h20.7v0.9h198.4l2,2.6l0.6-0.6l0.6,0.9h0.9l0.6-0.9
			c0.2,0,0.4-0.1,0.6-0.3c0.3-0.3,0.6-0.3,0.9-0.6c0.1-0.3,0.3-0.6,0.6-0.9l0.3-0.3c0.3,0.7,0.6,1.3,0.9,2c0,0.3,2,1.2,2,1.2l0.3-0.3
			h0.3c0.2,0,0.3,0.1,0.3,0.3l0,0c0.3,0.3,0.3,0.3,0,0.6c-0.2,0.2-0.4,0.3-0.6,0.3l-0.3,0.9l0.6,1.2l0.9-0.3l0.6-0.3l0.9-0.9h0.6
			l0.6,0.6c0.2,0.3,0.5,0.5,0.9,0.6h0.3l0.3-0.3c0-0.4,0.1-0.8,0.3-1.2v-2.9c0.6-0.3,0.9-0.9,1.2-1.2v-0.6l-0.3-0.3l0.3-0.6
			c0-0.3-0.1-0.6-0.3-0.9c0-0.2-0.1-0.4-0.3-0.6h0.6v-1.8c0.3-0.3,0.5-0.7,0.6-1.2c0-0.6,0.3-0.9,0-1.5c0.2-0.2,0.3-0.6,0.3-0.9
			l-0.3-0.6v-0.9c0-0.3,0.3-0.6,0.3-0.9h0.3c0.2,0.2,0.4,0.3,0.6,0.3l0,0c0-0.3-0.3-0.6-0.3-0.9s-0.3-0.9-0.3-1.2s0.1-0.6,0.3-0.9
			c0-0.3,0.3-0.3,0.3-0.6v-2c0-0.3,0-0.3,0.3-0.3l0,0c0-0.6,0-5-0.3-5l-0.3-0.3h-0.3c-0.3,0.3-0.6,0.9-0.9,0.9
			c-0.2,0-0.3-0.1-0.3-0.3l0,0v-0.6h-0.3c-0.3,0-0.3,0.3-0.6,0.3c0,0.3-0.3,0.3-0.3,0.6c0,0.4-0.1,0.8-0.3,1.2h-0.3v-0.6
			c-0.5-0.4-0.9-0.9-1.2-1.5l-1.2-1.2V993H246l-0.3,0.3h-0.9c-0.2,0-0.4-0.1-0.6-0.3l-0.3-0.6c0-0.3,0.1-0.6,0.3-0.9
			c0-0.3,0.3-0.6,0.6-0.9c0.3-0.6,0.9-1.2,0.6-1.5v-0.3h-0.6V985c0-0.3,0.3-0.3,0.3-0.6l0.9-0.9v-0.3c0-0.3,0-0.6-0.3-0.6
			c0-0.3-0.3-0.3-0.3-0.6l-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3l0,0h0.6l0.3-0.3c0-0.6,0-0.9,0.3-1.2c0-0.3,0.3-0.6,0.6-0.9h0.3
			c0.3,0.3,0.3,0.6,0.6,0.9c0,0.3-0.1,0.6-0.3,0.9l0.3,0.3c0.3,0,0.6,0,0.6-0.3c0.3,0,0.3-0.3,0.6-0.6c0.4-0.4,0.7-0.9,0.9-1.5
			c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.9-0.3-1.2c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3,0.1-0.6,0.3-0.9v-1.8c0.4-0.3,0.8-0.6,1.2-0.9
			c0.3-0.3,0.3-0.9,0.6-1.2s0.6-0.9,0.9-1.2v-1.2c0-0.6-0.3-0.9-0.3-1.5v-0.3c0.1-0.5,0.3-1,0.6-1.5c0.3-0.3,0.3-0.6,0.6-0.6
			c0.3-0.3,0.3-0.3,0.6-0.3s0.3,0.3,0.6,0.3h0.3c0.3-0.3,0.3-0.6,0.6-0.9c0.2-0.3,0.3-0.8,0.3-1.2c0.4-0.5,0.6-1.1,0.6-1.8
			c0.3-0.6,0.5-1.1,0.6-1.8c0-0.4,0.1-0.8,0.3-1.2l0.6-1.2l1.2-0.6c0.5-0.3,0.9-0.5,1.5-0.6l0.9-0.9c0.2-0.2,0.3-0.6,0.3-0.9
			c0-0.3,0.3-0.6,0.3-0.9s0.3-0.3,0.6-0.6h0.6l0.9-1.8c0.3-0.3,0.6-0.6,0.6-0.9c0.2-0.2,0.3-0.4,0.3-0.6v-0.6l0.3-0.3
			c0.3-0.6,0-1.2,0.3-2c0-0.6,0.6-0.9,0.6-1.5v-0.6c0-0.3,0-0.3,0.3-0.6s0.3-0.6,0.6-0.6c0.2,0,0.3-0.1,0.3-0.3l0,0
			c0.2-0.2,0.3-0.4,0.3-0.6c0.1-0.5,0.2-1,0.3-1.5v-1.2h0.9l0.6-0.6c0-0.3,0.1-0.6,0.3-0.9l0,0c0-0.6,0.3-1.2,0.3-1.8
			c0.3-0.6,0.3-0.9,0.6-1.5v-0.9c0-0.3-0.3-0.6,0-0.9c0-0.2,0.1-0.4,0.3-0.6c0-0.3,0.3-0.6,0.3-0.9c0-0.3-0.1-0.6-0.3-0.9
			c0-0.3-0.3-0.3-0.3-0.6s0.3-0.6,0.3-0.9s0-0.3,0.3-0.6v-0.6c-0.2-0.2-0.4-0.3-0.6-0.3v-1.2c0-0.6,0.3-1.2,0.3-1.8
			c0.3-0.6,0.3-1.2,0.6-1.5l0.9-0.9l0,0v1.2c0.3,0,0.6,0.3,1.2,0.3c0.4,0,0.8,0.1,1.2,0.3c0.3-0.6,0.3-0.9,0.6-1.2v-1.2l0.6-0.6
			c0-0.3,0.1-0.6,0.3-0.9c0-0.3,0.6-0.6,0.6-0.9c0.4-0.8,0.7-1.7,0.9-2.6V915c0-0.3,0-0.3,0.3-0.6c0-0.3,0.3-0.3,0.6-0.6v-0.3
			c-0.2-0.9-0.3-1.7-0.3-2.6v-0.3c0.3-0.3,0.5-0.7,0.6-1.2v1.6c0-0.3,0-0.3,0.3-0.3l0,0c0.3,0.3,0.3,0.6,0.3,1.2c0,0.5-0.1,1-0.3,1.5
			c0,0.6-0.3,0.9-0.3,1.5c0,0.3,0.1,0.6,0.3,0.9h0.3c0.3-0.3,0.3-0.9,0.6-1.2c0.3,0.3,0.3,0.3,0.6,0.3s0.3,0,0.3-0.3
			c0.3-0.3,0.6-0.6,0.6-0.9c0.2-0.2,0.6-0.3,0.9-0.3s0.6,0,0.6-0.3c0.2-0.2,0.3-0.6,0.3-0.9s0.1-0.6,0.3-0.9h0.3
			c0,0.3,0.3,0.3,0.3,0.6v1.8c0,0.3,0,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3l0,0c0-0.3,0.3-0.3,0.3-0.6c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.3-0.3-0.9-0.3-1.2c0-0.4,0.1-0.8,0.3-1.2c0.3-0.3,0.3-0.6,0.6-1.2l0.9-0.9c0.3-0.3,0.6-0.6,0.6-0.9c0.3-0.3,0.3-0.6,0.6-1.2
			c0-0.3,0.3-0.6,0.3-0.9V904c0-0.3-0.3-0.6,0-0.9c0-0.3,0.3-0.6,0.6-0.9l0.9-0.9c0.1-0.5,0.2-1,0.3-1.5c0-0.4,0.1-0.8,0.3-1.2
			c0-0.3,0.3-0.6,0.3-0.9l0.3-0.6h0.3c0.3,0,0.3,0,0.3-0.3c0.2-0.6,0.3-1.2,0.3-1.8v-0.9c0-0.3,0.1-0.6,0.3-0.9
			c0.3-0.3,1.2-0.9,1.2-1.2h-0.3c0.2-0.5,0.4-1,0.6-1.5l0.6-1.2c0.3,0,0.3-0.3,0.6-0.3c0-0.3,0.3-0.3,0.3-0.6s0.3-0.3,0.3-0.6
			l0.3-0.3l0.6,0.3l0.3,0.3h0.3c0.1-0.5,0.2-1,0.3-1.5c0-0.6,0.3-1.2,0.6-1.2c0.3-0.3,0.6-0.9,1.2-0.9c0.3,0,0.6-0.3,0.9-0.3
			s0.6,0,0.6,0.3v0.9c0,0.2,0.1,0.3,0.3,0.3l0,0h0.9c0.3,0,0.3,0.3,0.6,0.6c0,0.3-0.3,0.6-0.3,1.2c0,0.3-0.3,0.6-0.3,0.9
			s0,0.6,0.3,0.6h0.9c0-0.3,0-0.3,0.3-0.6s0.3-0.3,0.6-0.3l0.3,0.3c0.2,0.2,0.3,0.6,0.3,0.9v0.6c-0.2,0.2-0.3,0.4-0.3,0.6l-0.6,0.6
			v0.6h0.3l0.3-0.3c0.3,0,0.3-0.3,0.6,0c0.3,0,0.3-0.3,0.6-0.6s0.6-0.3,0.6-0.6c0.3-0.3,0.6-0.9,1.2-1.5c0.3-0.5,0.7-1,1.2-1.5h0.3
			l0.3,0.3h0.3c0.2-0.5,0.3-1,0.3-1.5v-1.8l-0.3-0.3v-0.3h0.3c0.9-0.6,1.5-0.6,1.8-0.9s0.3-0.9,0.9-1.8v-0.3l0.6-0.3
			c1.1-0.2,2.2-0.5,3.2-0.9c0.2-0.2,0.4-0.3,0.6-0.3l0.9,0.3h0.3c0.2,0,0.3,0.1,0.3,0.3l0,0v0.6h0.3l-0.3,0.3h0.6l0.3,0.3v1.5
			c0,0.3-0.3,0.3-0.3,0.6v0.6l0.3,0.3l0.3-0.3c0.2-0.2,0.3-0.6,0.3-0.9h0.6l0.6,0.6v0.6c0,0.3-0.3,0.6,0,0.9c0.2,0.2,0.4,0.3,0.6,0.3
			c0.3-0.3,0.6-0.3,0.9-0.6s0.6-0.6,0.6-0.9c0.3-0.3,0.6-0.3,0.6-0.6c0.5-1,0.8-2.1,0.9-3.2v-1.5c0-0.3,0.3-0.9,0.3-1.2
			c0.3-0.3,0.6-0.6,0.6-0.9c0.2-0.2,0.3-0.6,0.3-0.9V875c0-0.2-0.1-0.3-0.3-0.3l0,0c0-0.3,0-0.6,0.3-0.6s0.3-0.3,0.6-0.3l0.3,0.3v1.2
			c0,0.4,0.1,0.8,0.3,1.2c0,0.3-0.3,0.9-0.3,1.2s-0.3,0.9-0.3,1.2s0,0.3,0.3,0.6l0.3,0.3l0.3,0.3v0.3c-0.2,0.5-0.5,1-0.9,1.5v1.8
			c0,0.3-0.3,0.6-0.6,1.2v0.3h0.3c0-0.3,0.3-0.6,0.6-0.9l0.3-0.3h0.3c0.2,0.2,0.3,0.4,0.3,0.6v0.6h0.3c0.3,0,0.3,0,0.3-0.3v0.1
			c0-0.3,0-0.3,0.3-0.6l0,0c0,0.2,0.1,0.3,0.3,0.3l0,0h0.3c0.3-0.3,0.6-0.3,0.6-0.6c0.3-0.3,0.3-0.3,0.3-0.6c0.3-0.6,0.6-0.9,0.9-1.5
			h0.3c0.2,0.2,0.6,0.3,0.9,0.3c0.2,0,0.3-0.1,0.3-0.3l0,0c0-0.2,0.1-0.3,0.3-0.3l0,0c0-0.3,0.1-0.6,0.3-0.9l0.9-0.6
			c0.3-0.6,0.3-1.2,0.6-1.8c0-0.3,0.3-0.6,0.6-0.9c0.2-0.4,0.3-1,0.3-1.5v-1.5h1.2l0.3-0.3v-0.3c0.4-0.3,0.7-0.7,0.9-1.2
			c0.3-0.3,0.5-0.7,0.6-1.2c0-0.4,0.1-0.8,0.3-1.2c0.3-0.3,0-0.9,0.3-1.2h0.3c0.2,0.2,0.4,0.3,0.6,0.3c0-0.6,0.1-1.2,0.3-1.8l0.6-0.3
			c0.3-0.3,0.3-0.6,0.3-1.2v-1.2h0.3c0.3,0,0.3-0.3,0.3-0.9c0-0.3,0.1-0.6,0.3-0.9c0.2,0,0.4-0.1,0.6-0.3h0.6l0.3,0.3v1.5
			c0,0.6-0.3,0.9,0,1.5l0.6,0.6c0.3,0,0.3,0.3,0.6,0.3h0.6c0-0.3,0-0.3-0.3-0.6v-0.3c0-0.3,0-0.3,0.3-0.9c-0.2-0.4-0.3-1-0.3-1.5
			c0.1-0.5,0.3-1,0.6-1.5c0-0.3,0-0.3,0.3-0.6c0-0.3,0-0.3,0.3-0.6v-0.3c-0.2-0.2-0.3-0.6-0.3-0.9c0.3-0.6,0.3-0.6,0.6-0.6
			c0.3-0.4,0.5-0.9,0.6-1.5c0-0.3,0.6-0.9,0.9-1.2v-1.2h0.3l0.3,0.3c0-0.2,0.1-0.4,0.3-0.6c0-0.3,0.3-0.6,0.6-0.9l0.3-0.6h0.3
			c0.3,0,0.3,0,0.6,0.3c0.3,0,0.3,0.3,0.6,0.3c0.3,0.3,0.7,0.5,1.2,0.6c-0.2-0.6-0.5-1.2-0.9-1.8h0.6l1.2-1.2v-0.3
			c0-0.6,0.1-1.2,0.3-1.8l0.6-0.3v-0.9c0-0.2-0.1-0.4-0.3-0.6l1.5-0.9v-0.6c0.3-0.3,0.3-0.6,0.6-0.9c0.2-0.2,0.3-0.6,0.3-0.9l0.9-0.6
			c0.3,0,0.6,0.1,0.9,0.3c0.3,0,0.3,0.3,0.6,0.3l0.6-0.3V843c-0.1-0.3-0.1-0.6-0.3-0.9c0.3-0.3,0.5-0.7,0.6-1.2
			c0-0.3-0.3-0.9-0.3-1.2l0.6-0.9c0.3,0,1.2-0.6,1.2-0.9s0-1.2,0.3-1.5h0.3l0.3,0.3v1.2h0.9l0.6-0.6h0.6c0.3,0,0.3,0.3,0.3,0.6
			s0.3,0.3,0.3,0.6l0.6-0.3l1.8-1.8c0.3-0.3,0-0.3,0-0.9c0-0.3,0-0.3,0.3-0.6s0.3-0.6,0.9-0.9v-0.3l-0.3-0.3c0-0.9,0.3-1.5,0.3-2.3
			c0.2-0.7,0.5-1.4,0.9-2c0.7-0.1,1.4-0.4,2-0.9c0.3-0.3,0.9-0.3,1.2-0.6s0.6-0.3,0.6-0.6c0.2-0.5,0.3-1,0.3-1.5
			c0-0.3-0.1-0.6-0.3-0.9c0-0.2-0.1-0.4-0.3-0.6c0-0.3-0.3-0.3-0.3-0.6c0.2-0.2,0.3-0.6,0.3-0.9v-1.8H371c-0.3-0.3-0.6-0.6-0.6-0.9
			c-0.3-0.3-0.3-0.6-0.3-1.2c0-0.9,0.3-1.5,0.3-2.3c0-0.3-0.3-0.6-0.3-0.9v-0.6c0-0.3,0.3-0.3,0.3-0.6s0.3-0.3,0.3-0.6
			s-0.1-0.6-0.3-0.9c0.6-1.3,1-2.7,1.2-4.1c-0.2-0.6-0.3-1.2-0.3-1.8s0-0.9,0.3-1.2c0-0.3,0.3-0.6,0.3-0.9v-1.2
			c0.3-0.3,1.8-1.5,1.8-1.5c0.6-0.2,1.2-0.3,1.8-0.3h0.9c0.3,0,0.6-0.3,0.9-0.3s0.6,0.3,1.2,0.3h1.2l0.9-0.9c0.3-0.3,0.3-0.9,0.6-1.2
			c0.3-0.8,0.5-1.7,0.6-2.6c0-0.3,1.2-0.9,1.2-0.9l0.6-2c0-0.3-0.1-0.6-0.3-0.9c-0.3-0.3,0-0.6,0-0.9l0,0c0.3,0,1.5-1.5,1.5-1.5
			c0.3-0.6,0.3-0.9,0.6-1.5c0.3-0.3,0.6-0.9,0.9-1.2l1.8-0.3l0.3-1.2h-1.1l-0.3,0.3h-0.3l-0.9-0.3c0-0.4,0.1-0.8,0.3-1.2l0.3-0.3
			l0.6-0.6c0.1-0.3,0.1-0.6,0.3-0.9c0.3,0,0.9-0.6,1.2-0.6l0.3-0.3h0.3c0.1-0.7,0.3-1.4,0.6-2c0.5-0.2,1-0.5,1.5-0.9
			c0.3-0.3,0.3-0.3,0.3-0.6V778c0.3-0.3,0.5-0.7,0.6-1.2c0.5-0.1,1-0.3,1.5-0.6c0.4-0.2,0.8-0.5,1.2-0.9c0-0.3-0.1-0.6-0.3-0.9
			c-0.3-0.3,0-0.6,0.3-0.9h2c0.3-0.3,0.6-0.3,0.6-0.6c0.5,0,1-0.1,1.5-0.3l1.2-0.6c0-0.2,0.1-0.3,0.3-0.3l0,0
			c0.2-0.2,0.4-0.3,0.6-0.3c0.3-0.2,0.8-0.3,1.2-0.3c0.3-0.6,0.6-0.9,0.9-1.5c0.3-0.3,0.6-0.9,0.9-1.2l0.6,0.6h1.2
			c0.3,0,0.6,0.3,0.9,0.3s0.6-0.1,0.9-0.3v0.6c0,0.3-0.3,0.3-0.6,0.3c-0.2,0.2-0.3,0.6-0.3,0.9l0.9,0.9h0.3l0.6-0.6
			c0.3,0,0.6,0,0.6-0.3h0.9c0-0.2,0.1-0.3,0.3-0.3l0,0c0.3,0,0.3-0.3,0.6-0.3c0-0.3,0.3-0.6,0.6-0.9c0.2-0.2,0.4-0.3,0.6-0.3
			c0.3,0,0.3-0.3,0.3-0.6h0.9c0.3,0,0.3,0,0.9-0.3l0.3,0.3h0.6c0.3-0.3,0.6-0.3,0.9-0.6h0.9c0.3,0,0.3-0.3,0.6-0.6h0.6
			c0.3-0.3,0.3-0.6,0.6-0.6l0.6-0.3h0.6c0.2,0,0.3,0.1,0.3,0.3l0,0c0,0.3,0,0.3,0.3,0.3h0.6c0.4,0,0.8-0.1,1.2-0.3v-0.3h0.6l0.3,0.6
			c0.3,0,0.6-0.1,0.9-0.3c0-0.3,0.3-0.3,0.6-0.9c0.4-0.3,0.8-0.5,1.2-0.6c0.5-0.1,1-0.3,1.5-0.6l0.9-0.3h1.8l2-0.6
			c0-0.3,0.3-0.3,0.6-0.6c0.3,0,0.3-0.3,0.6-0.6c0.2-0.4,0.3-0.8,0.3-1.2v-0.6c0-0.6,0.6-0.9,0.6-1.2l0.6-0.3l0.3-0.9l-1.5,0.3h-0.3
			c0,0-0.3-0.6-0.6-0.6v-0.3c0.3,0,0.3-0.3,0.6-0.3c0-0.3,0.3-0.3,0.3-0.6s0.3-0.9,0.3-1.2c0.9-0.6,1.2-0.6,1.5-0.9
			c0-0.3,0.3-0.3,0.3-0.6c0-0.4,0.1-0.8,0.3-1.2h0.3v-0.3l0.3-0.3c0-0.3,0.6-0.9,0.6-1.2l0.6-2.3l0.6-0.3l0.3-0.3v-0.3l-0.6-0.6
			l-0.3-1.2l0.3-0.3l0.3-0.6h-0.3v-0.3l0.9-0.9c0.4-0.3,0.7-0.7,0.9-1.2h0.3l0.3-0.3h-0.3c0.8-0.5,1.5-1.2,2-2l1.2-0.3l0.3-2.3
			l0.6-0.6h0.6v-16.7h1.2V567.8L68.3,567z"/>
	</g>
	<g id="Quebec_3" className="region">
		<circle className="point" cx="390" cy="1140" r="1" fill="transparent" />
		<path id="path5024_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M452,1076.7l-4.4,10.5l-9.6-5
			l-3.8,9.3l-52,0.3v1.8c0.4,0.2,0.8,0.3,1.2,0.3c0.3,0,0.3,0,0.3,0.3s-0.1,0.6-0.3,0.9c-0.3,0.9-0.3,0.6-0.3,0.6h-31.5l8.2,13.1
			l-15.5,17.8l0.3,0.6c0.2,0,0.3,0.1,0.3,0.3l0,0c0.3,0.3,0.3,0.3,0.3,0.6v0.6l0.3,0.3h0.3v0.3c0,0.3-0.3,0.6-0.3,0.9s0,0.3,0.3,0.3
			c0.2,0.2,0.4,0.3,0.6,0.3h0.3c0.2,0,0.3,0.1,0.3,0.3l0,0c0.3,0.3,0.3,0.3,0.3,0.6l-0.3,0.3h-0.3v1.8c0,0.3,0.3,0.3,0.3,0.6
			c0,0.3,0,0.3,0.3,0.3c0.2,0.2,0.4,0.3,0.6,0.3l0.3,0.3v0.3c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.3-0.4,0.6-0.6,0.9l-0.6,0.6l-0.6,0.9
			l-0.9,0.6v2c-0.2,0.6-0.5,1.2-0.9,1.8c-0.3,0.3-0.3,0.6-0.6,0.9c0,0.3,0,0.3-0.3,0.3c-0.2,0.2-0.4,0.3-0.6,0.3s-0.3,0.1-0.3,0.3
			l0,0c0.2,0.2,0.3,0.4,0.3,0.6v0.6c0,0.2-0.1,0.3-0.3,0.3l0,0l-0.6-0.3l-0.3,0.3l-2-1.5h-0.9l-3.8-1.2l1.2-3.8l-2-2.3l0.3-1.2
			l-1.8,1.8l0.3,3.2l-1.2,0.6l-2,1.5v7.9l1.5,4.4h3.2l1.2,1.2h1.2l0.9,2l-2.9,3.2l12,13.7l-2.6,2.9l-0.3-0.3l-0.6,0.6l-1.2-1.5
			l-1.5,1.5l-0.3-0.3l-1.2,1.5l-0.6-0.6l-2,2l13.1,15.5c1.2-0.3,2.6-0.3,3.8-1.2c0.5-0.3,0.9-0.9,1.2-1.5c1.5-1.5,2.9-1.5,4.1-3.8
			c0.3-0.7,0.8-1.3,1.5-1.8c1.5-0.6,2.9,1.5,4.1,1.5c0.6,0,1.2-0.3,1.8-0.3c0.3,0,0.9,0.3,1.2,0.3c1.8,0,2.6-1.5,4.1-2
			c1.2,0,1.8-0.6,2.9-0.9c0.6-0.3,1.2-0.3,1.8-0.6c0.9-0.3,1.8-0.9,2.6-1.2c1.2-0.3,3.8,0,5-0.3c0.6-0.3,0.9-0.9,1.5-1.2
			c0.3,0,0.3-0.3,0.6-0.3h0.3c0.3-0.3,0.6-0.3,0.9-0.6c0.3-0.3,0.3-0.6,0.6-0.9c0.9-0.9,3.2-3.5,3.2-4.7v-1.2c0.6-0.8,1.3-1.6,2-2.3
			c0.6-0.6,1.2-1.5,1.8-2c0.6-0.9,1.8-1.2,2.3-2c0.9-0.9,1.5-2,2.3-2.9c0.9-1.2,1.8-1.8,2.6-2.9c0.6-0.6,0.9-1.2,1.5-1.5
			c2.6-1.8,2-0.9,4.4-1.8c1.2-0.3,1.8-1.5,2.6-2c0.6-0.6,1.5-0.6,2-1.2c0.3-0.3,0.6-1.2,0.9-1.5c0.5-1,1-2.1,1.5-3.2
			c0.3-0.9,0.9-1.5,1.2-2.3c0.3-0.6,0.3-1.2,0.6-1.8c0.9-1.5,2-2.6,2.6-4.4c0.2-1.2,0.5-2.4,0.9-3.5c0.6-1.5,1.8-2.9,2.3-4.7
			c0.3-1.2,0-2.6,0.3-3.8c0.6-0.2,1.3-0.1,1.8,0.3c0.9,0,1.8-1.2,2.6-1.5c1.5-0.6,3.2-0.6,4.7-1.2c0.3-0.3,1.2-0.9,1.2-1.2
			c0.6-0.3,1.2-0.3,1.8-0.6c0.3-0.3,0.6-0.6,0.6-0.9c0.9-2.3,0.3-2.9,0.6-5c0.3-2.9,3.5-4.7,3.5-5.3s-0.6-0.9-0.3-1.5
			c0.9-0.4,1.9-0.7,2.9-0.9c0.2,0,0.3-0.1,0.3-0.3l0,0c0.9-0.9,1.5-1.2,2.3-2c1.2-0.9,1.2-2.3,2-3.5c0.6-0.6,1.5-0.6,2-1.8l0,0
			c0.3-0.4,0.4-1,0.3-1.5c0.3-1.5,0.6-3,1.2-4.4c0.6-1.8,2-3.2,2.6-5c0.3-0.9,0.3-1.8,0.6-2.6c0-0.6-0.3-0.9,0-1.2
			c0.3-0.9,1.2-0.9,1.8-2c0.3-1.5,0-3.2,0.3-4.7c0.3-1.2,2.3-3.5,2-4.1l1.8-1.8l-0.3-0.3l-2-0.9c-0.3,0-0.6-0.1-0.9-0.3
			c-0.4-0.2-0.8-0.3-1.2-0.3h-0.9c-0.3,0-0.6-0.1-0.9-0.3c-0.4-0.4-0.8-0.9-1.2-1.5l0,0v-0.6C452,1077.3,452,1077,452,1076.7
			L452,1076.7z"/>
		<path id="path5038_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M394.4,1177.2
			c-0.3-0.3,0-0.9,0-1.2c1.8-4.7,3.5-3.5,5.5-5.6c0.9-0.9,1.8-2.3,2.6-3.2c0.7-0.8,1.5-1.4,2.3-2c0.3,0,0.3-0.3,0.6-0.3
			s0.3-0.3,0.6-0.6c0.3-0.2,0.8-0.3,1.2-0.3c0.6,0.3,1.8,0.6,1.8,1.5c-0.9,2-2,4-3.2,5.8c-0.3,0.3-0.9,1.2-1.2,1.5
			c-0.6,0.3-1.5,0.9-2,1.2c-1.5,0.9-2.6,2-4.1,2.6c-0.9,0.4-1.9,0.6-2.9,0.6C395,1177.5,394.7,1177.5,394.4,1177.2z"/>
	</g>
	<g id="Mauricie-et-centre-du-Quebec_4" className="region">
		<circle className="point" cx="300" cy="1140" r="1" fill="transparent" />
		<path id="path5028_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M195.5,1016.8v92.3l28.6,0.3
			H241l25.1,27.8l-1.8,2.6l-0.3,2l-1.2,11.1l5,5.8l2.6-3.2l3.5,4.1l1.2,1.8l4.7,9.3l-0.6,0.9c0,0.8,0.1,1.6,0.3,2.3v0.6
			c0,0.3-0.3,0.3-0.3,0.6c-0.3,0-0.3,0.3-0.3,0.6c0,0.2,0.1,0.4,0.3,0.6c0,0.2,0.1,0.3,0.3,0.3l0,0h1.8c0.2,0,0.3,0.1,0.3,0.3l0,0
			v0.3h0.3c0.3,0,0.3-0.3,0.3-0.6c0-0.2,0.1-0.4,0.3-0.6c0.3,0,0.3,0,0.6,0.3s0.3,0,0.6,0c0.2-0.2,0.3-0.4,0.3-0.6v-0.3h0.3
			c0.3,0,0.3,0.3,0.6,0.6l0.6,0.6c0.3,0,0.3,0,0.3,0.3c0,0.2,0.1,0.3,0.3,0.3l0,0c0.2-0.2,0.3-0.4,0.3-0.6v-1.2
			c0-0.3,0.3-0.6,0.6-0.9c0.3,0,0.3-0.3,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6v-0.3c0.2,0,0.4,0.1,0.6,0.3c0.2,0,0.4,0.1,0.6,0.3h0.9
			l0.3,0.3c0,0.2,0.1,0.3,0.3,0.3l0,0l0.6,0.3l0.6,0.3v0.3l-0.9,3.2l0.6,0.9l-1.2,3.5c0.2,0,0.3,0.1,0.3,0.3l0,0
			c0,0.3,0.3,0.6,0.3,0.9v0.9c0,0.3,0,0.3-0.3,0.6c-0.2,0.3-0.3,0.6-0.3,0.9l-0.3,2l1.2,2.9v5.3l1.8-0.3l1.8-1.2l1.2,1.2l1.2,2.6
			c0,0.4,0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.8,0.9,1.2l-2,2.9l7.3,8.5l-0.3,0.3l0.3,0.3l-0.6,0.6l-0.3-0.3l-0.9,0.9l0.6,0.6l-0.6,0.6
			l1.2,1.5l-1.5,0.3l-0.3-0.3l-0.6,0.6l1.5,1.8l-0.9,1.2l7.3,8.5h0.9c0.7-0.2,1.4-0.5,2-0.9c0.2-0.2,0.3-0.6,0.3-0.9v-0.3
			c-0.3,0-0.3-0.3-0.6-0.6v-0.6l3.5-1.8c0.6-0.6,1.3-1.1,2-1.5c0.3,0,0.6-0.3,0.9-0.3l3.5-1.8c0.3,0,0.6-0.1,0.9-0.3
			c0.5-0.2,1-0.3,1.5-0.3c1.2,0,2,0.9,3.2,0.3c0.9-0.6,3.8-2.9,4.4-4.1c0.3-0.6,0.6-0.9,0.9-1.5l1.8-1.8c0.5-0.9,1.2-1.7,2-2.3
			c0.6-0.3,1.2-0.3,1.5-0.6c2,0.3,1.5-0.9,2.6-1.5c1.8-0.9,2.9,0.3,4.4-2.6c0.9-1.8,0-5.3,2-6.4c0.3-0.3,0.9-0.3,1.2-0.6
			c1.1-0.7,2.3-1.3,3.5-1.8l0,0l-13.1-15.5l2-2l0.6,0.6l1.2-1.5l0.3,0.3l1.5-1.5l1.2,1.5l0.6-0.6l0.3,0.3l2.6-2.9l-12-13.7l2.9-3.2
			l-0.9-2H337l-1.2-1.2h-3.2l-1.5-4.4v-7.9l2-1.5l1.2-0.6l-0.3-3.2l1.8-1.8l-0.3,1.2l2,2.3l-1.2,3.8l3.8,1.2h0.9l2,1.5l0.3-0.3
			l0.6,0.3c0.2,0,0.3-0.1,0.3-0.3l0,0v-0.6c-0.3-0.3-0.3-0.3-0.3-0.6c0-0.2,0.1-0.3,0.3-0.3l0,0c0.2,0,0.4-0.1,0.6-0.3l0.3-0.3
			c0-0.3,0.3-0.6,0.6-0.9c0.4-0.6,0.6-1.1,0.9-1.8v-2l0.9-0.6l0.6-0.9l0.6-0.6c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.2,0.3-0.4,0.3-0.6v-0.3
			c0-0.2-0.1-0.3-0.3-0.3l0,0c-0.3-0.3-0.3-0.3-0.6-0.3l-0.3-0.3c0-0.3-0.3-0.3-0.3-0.6c0-0.3-0.3-0.3,0-0.6v-1.2h0.3
			c0.3,0,0.3,0,0.3-0.3c0-0.2-0.1-0.4-0.3-0.6l-0.3-0.3h-0.3c-0.3,0-0.3-0.3-0.6-0.3l-0.3-0.3c0-0.3,0.3-0.6,0.3-0.9v-0.3h-0.3
			c-0.3,0-0.3,0-0.3-0.3v-0.6c0-0.2-0.1-0.4-0.3-0.6l-0.3-0.3l-0.3-0.6l15.5-17.8l-8.2-13.1l-2.3-3.8l-41.5-1.8l-63.1-67.2l-0.6-1.2
			v-0.3l-0.6-1.5c-0.6-0.3-1.5-0.9-1.5-0.9c-0.2-0.7-0.5-1.4-0.9-2l-0.3,0.3c-0.3,0.2-0.5,0.5-0.6,0.9c-0.3,0.3-0.6,0.6-0.9,0.6
			c-0.2,0.2-0.4,0.3-0.6,0.3l-0.6,0.9h-0.9l-0.6-0.9l-0.6,0.6l-2-2.6h-39.7v-0.5H195.5z"/>
		<path id="path4996_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M352.6,1198.8
			c-1,0-2,0.1-2.9,0.3c-0.3,0-3.2,2.6-3.8,2.9c-1.2,1.2-1.2,5-3.2,7.3c-1.8,1.8-3.2,1.2-5.3,1.8c-1.2,0.3-1.2,0.9-2,1.5
			c-0.9,0.6-1.8,0.6-2.6,1.5c-1.5,1.5-2.8,3.1-3.8,5c-0.9,0.9-2.3,1.8-2.9,2.9c-0.9,1.2,0,2.3-1.5,4.1c-0.3,0.3-1.2,0.3-1.5,0.6
			c-0.9,0.6-0.9,1.2-1.5,1.5l-3.5,1.8c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.4-0.7,0.6-1.2,0.6c-0.9,0.3-2,0.3-2.6,0.6
			c-0.7,0.9-0.8,2.2-0.3,3.2c0,0.3,0.3,0.3,0.6,0.6v0.3c-0.2,0.2-0.4,0.3-0.6,0.3c-0.6-0.3-0.9-0.9-1.5-1.2c-0.2,0-0.3,0.1-0.3,0.3
			l0,0l0,0l3.8,4.4l0.6-1.2l1.5,1.8l0.3-0.9l1.2,1.5v1.2l0.6,0.3l-0.6,0.9l2.3,2.9l-2,2.6l-1.5,1.5h-0.3l-0.6,0.9v0.9l6.7,7.9
			l1.2-1.2l1.5,1.5l2-2.6l1.8,2l-0.6,1.2l12.6,12.3l2.9-4.1l-3.2-2.9l3.8-5.3c0.2,0.4,0.3,0.8,0.3,1.2c0,0.3,0.3,0.6,0.3,0.9v0.3
			c0,0.2-0.1,0.3-0.3,0.3l0,0c-0.3,0.3-0.3,0.3-0.6,0.3c0,0.2,0.1,0.4,0.3,0.6l0.6,1.2l8.2-11.7l5,4.4c0-0.2,0.1-0.3,0.3-0.3l0,0
			c0.2,0,0.3-0.1,0.3-0.3l0,0h0.3c0.3,0,0.6,0.3,0.9,0.3h0.6l4.1-6.4l6.1,5.3l0.9-1.2l3.5,3.2l1.2-1.2l0.9,0.9l1.5-1.8l-0.3-0.3
			l0.6-4.1l-2-1.8v0.3l-2.3-2l-0.6,0.9l-5.5-4.7l7.9-12.6l-1.2-0.9l0.3-0.9l-0.3-0.3l2-3.2l-0.3-0.3l1.2-1.5l0.9,0.6l0.6-0.6l0.6,0.6
			l2-3.2l-6.1-5.3l1.2-1.5l-1.2-1.5l1.2-1.5l-2.3-2.9l-1.5,1.5l-2.3-2.9l-6.4,6.7L352.6,1198.8z"/>
	</g>
	<g id="Laurentides_15" className="region">
		<circle className="point" cx="210" cy="1180" r="1" fill="transparent" />
		<path id="path5030_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M224.1,1109.4l-28.6-0.3h-0.9
			l-0.3,0.6c0,0.2,0.1,0.4,0.3,0.6s0.3,0.6,0.3,0.9v0.6c0.3,0.3,0.3,0.6,0,1.2v0.3h0.3c0.6,0,0.6,0.3,0.6,0.9c0,0.3,0.3,0.3,0.3,0.6
			c0.3,0.3,0.6,0.3,0.9,0.6v0.6c0,0.3,0,0.3,0.3,0.3h0.6c0.3,0,0.3,0.3,0.6,0.6v0.9c0.2,0.5,0.5,1,0.9,1.5c0,0.3,0.3,0.3,0,0.3
			c-0.3,0.3-0.3,0.6-0.3,1.2v0.6c0,0.6,0.3,0.9,0.3,1.2c0,0.3,0,0.3,0.3,0.3s0.6-0.1,0.9-0.3l1.2,0.3c0,0.3,0.3,0.9,0.3,1.2
			c0.3,0.6,0.3,0.3,0.6,0h0.3c0.2,0.2,0.3,0.4,0.3,0.6c0,0.3,0.3,0.3,0.3,0.6c0.3,0,0.3,0.3,0.6,0.3l0.3,0.3c0,0.2-0.1,0.3-0.3,0.3
			l0,0c-0.3,0.3-0.6,1.5-0.9,1.8c-0.3,0.3-0.9,0.6-0.9,0.9l-0.6,0.3c-0.4,0.5-0.7,1.1-0.9,1.8c0,0.3-0.3,0.6-0.3,0.9v2
			c0,0.3-0.3,0.3-0.3,0.6l-0.9,0.9c-0.3,0.3-0.3,0.6-0.6,1.2c-0.3,0.9-0.9,1.2-1.5,1.8c-0.2,0.2-0.3,0.4-0.3,0.6v0.3
			c-0.3,0.6-0.5,1.3-0.6,2v0.9c0,0.3,0.3,0.6,0.6,0.9l0.3,0.3l0,0c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.3-0.1,0.6-0.3,0.9v0.6
			c0,0.3,0,0.3-0.3,0.6l-0.3,0.3c-0.3,0.3-0.6,0.3-0.6,0.6c0,0.3-0.3,0.3-0.3,0.6c-0.5,0.5-0.9,1.1-1.2,1.8l-0.9,1.8h-0.3
			c-0.2,0-0.4,0.1-0.6,0.3l-0.6,0.6c-0.3,0.3-0.5,0.7-0.6,1.2l-0.9,0.6l-0.9,1.5c0-0.3-1.8,2-1.8,2l-2,1.5l-0.3,2.3l1.8,2.6v2.6
			l-1.5,2.6l-1.2,3.5l-0.6,5l1.5,2.3l-2.3,5.6l2.3,5.8l0.3,18.7h2v9.6l-5,0.3l1.2,1.2c0.4,0.5,0.4,1.2,0,1.8
			c-0.3,0.6-0.3,1.8-0.9,2.3c-0.2,0.2-0.3,0.4-0.3,0.6v0.6c0,0.3,0.3,0.3,0.3,0.6c0,0.3-0.1,0.6-0.3,0.9v0.6c0,0.3,0,0.6,0.3,0.6
			c0.2,0.3,0.3,0.6,0.3,0.9h1.8v11.4h2.9l-0.6,1.2l-0.3-0.6c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.3-0.9,1.5-1.5,1.8
			c-0.9-0.9-0.9-0.9-0.9-0.3v0.9c0,0.2,0.1,0.3,0.3,0.3l0,0h0.6l0.6-0.6h3.5l0.3-0.9h1.8v-0.9h0.9v1.8h8.8v-9.9h10.2v-2.3h0.9v-2h0.9
			v-1.8h0.9v-2.6h2l-0.6,8.5l6.7-0.3v3.2h3.8l0.3,3.2h1.2v3.2h0.9l0.3,2.3h1.8v-2h2.9l-1.2,8.8l-3.5,15.8h0.3c2.9,0.6,4.1,0.9,7,0.3
			c0.2,0.2,0.4,0.3,0.6,0.3l0,0c0.9,1.2,1.5,0.9,2.6,1.8c2.3,1.2,1.2,1.5,4.1,1.2c0.6,0.3,1.2,0.9,1.8,1.2l1.5,0.6
			c0.3,0,1.2,0.3,1.2,0.6l-0.3,1.5l0.9,0.3c0.2,0.5,0.5,1,0.9,1.5l0.6,0.6c0.2,0.3,0.5,0.5,0.9,0.6c0.4,0,0.8-0.1,1.2-0.3
			c-0.3-0.9,0.3-1.5,1.2-1.8c0.6,0,1.5,0.3,2.3,0.3h1.8l0.3,0.3c0,0.3,0,1.2,0.3,1.5s0.9,0.6,1.2,0.9s0.6,0.6,0.6,0.9
			c0.6,0.6,1.8,0,2.3,0c0.6,0,1.2,0.3,1.8,0c0.9-0.3,1.5-1.2,2.3-1.2c0.3-0.3,0.9-0.6,1.2-0.9s0.6-0.9,1.2-1.2
			c0.3-0.3,1.5-0.6,1.8-0.9c0.6-0.6,0.3-2.3,0.9-3.5l1.8-1.8c0.3-0.7,0.7-1.4,1.2-2c0.4-0.4,0.9-0.7,1.5-0.9c0.3-0.3,0.3-0.6,0.6-1.2
			c0.3-0.3,0.6-0.9,0.9-1.2l-0.6-0.6l-0.6,0.3l-2.9-2.9h1.2c0.6,0,1.5,0.3,2,0.3h0.6l0.3-0.3v-0.6l0.6-0.6l-0.3-1.2l0.9-0.3l-4.1-4.7
			l0.9-0.3l-4.4-4.7l0.9-0.3l-2.9-3.2l0.6-0.9l-0.9-0.6l0.3-0.3l-1.5-1.5l0,0l-1.2-1.5l0.9-1.2l-1.2-1.5l2-2.3l-2-2.3l-1.2,1.2
			l-2.3-2.9l2-2.3l-6.7-7.3l-3.2,3.8h-0.3l-6.7-7.3l3.5-4.1l-6.4-7.6l5.8-7l-4.4-5h-1c-0.3,0-0.6,0.1-0.9,0.3
			c-0.2,0.2-0.6,0.3-0.9,0.3c-0.2,0-0.3-0.1-0.3-0.3l0,0v-0.6c0-0.3,0.3-0.6,0.3-0.9v-0.6c0-0.3-0.3-0.3-0.3-0.6
			c0-0.3-0.6-2.9-0.6-2.9l0.6-0.6l0.3-1.2l0.6-1.5l-0.6-0.9l0.3-2h0.3l-0.3-1.8l0.6-0.6l-0.3-4.4l0.3-1.8l0.6,0.6l0.6-2l0.6-0.3
			l-6.7-7.6l7.3-7.9l-2-2.3l1.2-1.5l-2.6-5l-7.9-0.9v-0.9l0.3-0.6l0.9,0.3l0.6-0.3l0.6-1.2l0.3-1.2c0.2,0,0.3-0.1,0.3-0.3l0,0
			c0.4-0.3,0.7-0.7,0.9-1.2c0.4-0.5,0.7-1.1,0.9-1.8l0,0c0.2-0.2,0.3-0.4,0.3-0.6c0.3-0.7,0.5-1.4,0.9-2v-0.6c0-0.5,0.1-1,0.3-1.5
			c0.3-0.6,0.3-1.2,0.6-1.8c0.3-0.6,0.3-0.9,0.6-1.2c0.6-0.6,1.2-1.5,1.8-2c0.3-0.3,0.6-0.6,0.6-0.9c0.3-0.3,0.3-0.3,0-0.6v-0.6
			c0-0.3,0-0.3,0.3-0.6l0.6-1.2c0-0.2,0.1-0.3,0.3-0.3l0,0v-0.3h-0.6c-0.3,0-0.3,0-0.3,0.3c-0.2,0.2-0.3,0.4-0.3,0.6h-0.3
			c-0.2,0-0.3-0.1-0.3-0.3l0,0c-0.3-0.3-0.6-0.3-0.9-0.6H242l-0.6,0.6c-0.4-0.3-0.8-0.5-1.2-0.6c-0.3,0-0.3-0.3-0.6-0.3v-0.3
			c-0.2-0.2-0.4-0.4-0.6-0.6v-0.3c0.3-0.3,0.3-0.3,0-0.3l-0.3-0.3c-0.3-0.3-0.3-0.3-0.6-0.3s-0.3,0-0.3-0.3s-0.3-0.3-0.3-0.6
			c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.6-0.9-1.2-0.9-1.8c-0.3-0.6-0.3-0.3-0.6-0.9c0-0.6-0.3-0.6-0.3-0.6s-0.6-0.6-0.6-0.9
			c0-0.2-0.1-0.4-0.3-0.6c-0.3-0.2-0.6-0.4-0.9-0.6H233v-0.3c0-0.2-0.1-0.4-0.3-0.6h-0.3L224.1,1109.4L224.1,1109.4z"/>
	</g>
	<g id="Lanaudiere_14" className="region">
		<circle className="point" cx="270" cy="1200" r="1" fill="transparent" />
		<path id="path5032_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M224.1,1109.4l8.8,9.3h0.3
			c0.2,0.2,0.3,0.4,0.3,0.6v0.3h0.6c0.3,0.2,0.6,0.3,0.9,0.6c0,0.2,0.1,0.4,0.3,0.6c0,0.3,0.6,0.9,0.6,0.9s0.3,0,0.3,0.6
			s0.3,0.3,0.6,0.9s0.9,1.2,0.9,1.8c0,0.3,0.3,0.3,0.3,0.6c0.2,0.2,0.3,0.4,0.3,0.6s0.1,0.3,0.3,0.3l0,0c0.2,0,0.4,0.1,0.6,0.3
			l0.3,0.3v0.6l0.3,0.3c0.2,0,0.3,0.1,0.3,0.3l0,0v0.3c0.2,0.2,0.4,0.3,0.6,0.3c0.4,0.1,0.9,0.3,1.2,0.6l0.6-0.6c0-0.3,0.3-0.3,0.6,0
			c0.3,0,0.6,0.3,0.9,0.6l0.3,0.3h0.3c0-0.2,0.1-0.4,0.3-0.6l0.3-0.3h0.6c0.3,0,0.3,0,0,0.3l-0.3,0.3c-0.3,0.3-0.3,0.6-0.6,1.2
			c-0.2,0.2-0.3,0.4-0.3,0.6v1.2c-0.3,0.3-0.3,0.6-0.6,0.9c-0.6,0.6-1.2,1.5-1.8,2c-0.4,0.3-0.6,0.7-0.6,1.2
			c-0.3,0.6-0.3,1.2-0.6,1.8c-0.2,0.5-0.3,1-0.3,1.5v0.6c-0.3,0.7-0.6,1.3-0.9,2c-0.2,0.2-0.3,0.4-0.3,0.6l0,0
			c-0.4,0.5-0.7,1.1-0.9,1.8c-0.2,0.4-0.5,0.8-0.9,1.2l-0.3,0.3h-0.9l-0.3,1.5l-0.6,0.3l-0.9-0.3l-0.3,0.6v0.9l7.9,0.9l2.6,5
			l-1.2,1.5l2,2.3l-7.3,7.9l6.7,7.6l-0.6,0.3l-0.6,2l-0.6-0.6l-0.3,1.8l0.3,4.4l-0.6,0.6l0.3,1.8h-0.3l-0.3,2l0.6,0.9l-0.6,1.5
			l-0.3,1.2l-0.6,0.6c0,0,0.6,2.6,0.6,2.9s0.3,0.3,0.3,0.6v0.6c0,0.3,0,0.3-0.3,0.9v0.6l0.3,0.3c0.3,0,0.6-0.3,0.9-0.3
			c0.2-0.2,0.6-0.3,0.9-0.3h0.6l4.4,5l-5.8,7l6.4,7.6l-3.5,4.1l6.7,7.3h0.3l3.2-3.8l6.7,7.3l-2,2.3l2.3,2.9l1.2-1.2l2,2.3l-2,2.3
			l1.2,1.5l-0.9,1.2l1.2,1.5l0,0l1.5,1.5l-0.3,0.3l0.9,0.6l-0.6,0.9l2.9,3.2l-0.9,0.3l4.4,4.7l-0.9,0.3l4.1,4.7l-0.9,0.3l0.3,1.2
			l-0.6,0.6v0.6c0,0.2-0.1,0.3-0.3,0.3l0,0h-0.6c-0.6-0.3-1.3-0.4-2-0.3H272l2.9,2.9l0.6-0.3l0.6,0.6c0.3-0.6,0.9-0.9,1.2-1.5
			c0.3-0.3,0.9-0.3,1.2-0.6c3.2-1.8,8.2,0,11.1-2.6c0.9-0.6,3.5-4.7,3.8-5.6c0.3-0.6,0.6-1.5,0.9-2c0.9-1.2,2-1.5,2.9-2.6
			c1.7-3.3,3.3-6.7,4.7-10.2c0.1-0.5,0.2-1,0.3-1.5c0.3-2-0.6-4.1,0.6-5.8c1.3-1.8,2.8-3.4,4.7-4.7h0.6l-7.3-8.5l0.9-1.2l-1.5-1.8
			l0.6-0.6l0.3,0.3l1.5-0.3l-1.2-1.5l0.6-0.6l-0.6-0.6l0.9-0.9l0.3,0.3l0.6-0.6l-0.3-0.3l0.3-0.3l-7.3-8.5l2-2.9
			c-0.3-0.4-0.6-0.8-0.9-1.2c-0.2-0.4-0.3-0.8-0.3-1.2l-1.2-2.6l-1.2-1.2l-1.8,1.2l-1.8,0.3v-5.3l-1.2-2.9l0.3-2
			c0-0.3,0.1-0.6,0.3-0.9c0-0.3,0.3-0.3,0.3-0.6v-0.9c0-0.3-0.1-0.6-0.3-0.9l-0.3-0.3l1.2-3.5l-0.6-0.9l0.9-3.2v-0.3l-0.6-0.3
			l-0.6-0.3c-0.2-0.2-0.4-0.4-0.6-0.6h-0.9c-0.3,0-0.3,0-0.6-0.3c-0.3,0-0.3-0.3-0.6-0.3v0.3c0,0.3,0,0.3-0.3,0.6
			c-0.2,0.2-0.4,0.3-0.6,0.3c-0.3,0-0.3,0.3-0.6,0.9v1.2c0,0.3,0,0.6-0.3,0.6c-0.2,0-0.3-0.1-0.3-0.3l0,0c0-0.2-0.1-0.3-0.3-0.3l0,0
			c-0.3,0-0.3-0.3-0.6-0.6s-0.3-0.6-0.6-0.6h-0.3v0.3c0,0.3,0,0.6-0.3,0.6c-0.2,0.2-0.4,0.2-0.6,0l0,0c-0.3-0.3-0.3-0.3-0.6-0.3
			s-0.3,0.3-0.3,0.6c0,0.2-0.1,0.4-0.3,0.6h-0.3v-0.3c0-0.2-0.1-0.3-0.3-0.3l0,0H279c-0.3,0-0.3,0-0.3-0.3s-0.3-0.3-0.3-0.6
			s0-0.6,0.3-0.6s0.3-0.3,0.3-0.6v-0.6c-0.2-0.8-0.3-1.5-0.3-2.3l0.6-0.9l-4.7-9.3l-1.2-1.8l-3.5-4.1l-2.6,3.2l-5-5.8l1.2-11.1l0.3-2
			l1.8-2.6l-25.1-27.8L224.1,1109.4z"/>
	</g>
	<g id="Gaspesie-Iles-de-la-Madeleine_11" className="unsupported-region">
		<circle className="point" cx="580" cy="1050" r="1" fill="transparent" />
		<path id="path5002_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M649.4,997.2H648
			c-0.9,0-2.9-0.3-3.5,0.3c-0.6,0.1-1.1,0.4-1.5,0.9c-0.6,0.3-1.2-0.3-1.5,0c-0.9,0.3-1.2,1.2-2,1.5c-0.9,0.3-0.9-0.3-1.5-0.6
			c-0.9-0.3-1.5,0.3-2,0.6c-0.6,0.3-1.2-0.3-1.5-0.3c-0.5,0-0.9,0.2-1.2,0.6c-1,0.4-2.1,0.6-3.2,0.6c-2.4-0.1-4.9-0.1-7.3,0
			c-0.9,0-1.5,0.6-2.3,0.9c-1.8,0.1-3.5,0.4-5.3,0.9c-2.6,0.6-4.7,1.8-7.3,2.3c-1.5,0.3-2,1.8-3.5,2.3c-0.9,0.1-1.8,0.1-2.6,0
			c-0.7,0.6-1.5,1-2.3,1.5c-0.6,0-1.2-0.3-1.8-0.3c-0.6,0.7-1.3,1.3-2,1.8c-0.7,0.2-1.4,0.4-2,0.6c-1.2,0.6-2.3,1.5-3.5,2
			c-1.5,0.6-2.9,1.5-4.4,2l0,0l3.2,3.5l4.4-2.3l1.2,1.2c0,0.3,0.1,0.6,0.3,0.9v3.2c0,0.3,0,0.3,0.3,0.6v0.3l-0.3,0.3v0.3
			c0,0.2-0.1,0.4-0.3,0.6c-0.3,0-0.6,0.6-0.6,0.6l0.6,0.6l7.6-4.1l26.9,29.2l-14.9,6.4l-14.9,6.1l-16.7,7.3l6.1,11.4h-0.3l-1.5-2.3
			l-1.5-1.8l-1.5,1.5l2.6,2.9l-9.6,3.8v0.6l-7.9,3.5l-4.4-12.3l-13.1,5.8c0.2,0.2,0.3,0.4,0.3,0.6c0.2,0.2,0.3,0.6,0.3,0.9v0.9
			c0,0.2,0.1,0.3,0.3,0.3l0,0h0.6l0.3,0.3c0,0.2,0.1,0.4,0.3,0.6c0.3,0.2,0.6,0.4,0.9,0.6l0.6,0.6c0.2,0.2,0.3,0.4,0.3,0.6v0.6
			c0,0.2-0.1,0.3-0.3,0.3l0,0v0.3c0.2,0.2,0.3,0.4,0.3,0.6v0.9l-0.3,0.6l-0.3,0.6c0.9,1.2,0.3,2,0,3.5c0.1,0.7,0.6,1.2,1.2,1.5
			c0.8-0.1,1.7,0.1,2.3,0.6c0,0.6,3.8,2.6,4.4,2.9h0.3c0.5,0.2,0.9,0.6,1.2,1.2h0.3v0.3l-0.3,0.3l0.6,0.3h0.6l0.3-0.3
			c0-0.3-0.1-0.6-0.3-0.9l0.3-0.6v-0.6l0.3,0.3h0.3c0-0.3,0.3-0.6,0.3-1.2c0-0.3-0.6-0.6-0.6-0.6v-0.3l0,0c0.3,0,0.6,0.3,0.9,0.6h1.2
			l0.3-0.3l-0.3-0.6h0.3l0.6,0.6h0.6l0.3,0.3c0.3,0,0.6-0.1,0.9-0.3h0.3l0.3,0.6h0.3c0.6-0.1,1.2-0.3,1.8-0.6l0.3-0.6l0.3-0.3
			l0.6,0.3c0.6-0.3,0.6-1.2,0.9-1.8h1.2c0.6-0.6,1.5-0.6,1.5-0.9l0.3-0.6c1.2-0.6,2.9,2.6,4.4,3.2c0.5,0,0.9-0.2,1.2-0.6v-0.6
			l-0.9-0.3c-0.3-0.3,0-2,0-2.3c1.2-0.6,0-2,2.9-3.2c0.8-0.4,1.7-0.6,2.6-0.6c0-0.2-0.1-0.3-0.3-0.3l0,0c0.6-0.3,2.3-0.3,3.2-0.6
			c0.6-0.2,1.2-0.5,1.8-0.9c0.9-0.6,2.3,0,3.2-0.9c0.6-0.3,0.6-1.2,1.2-1.5c0.3-0.3,1.2,0,1.5,0.3h0.6c0-0.3,0.3-0.6,0.6-0.9
			c0.9,0,1.2,0.6,2,0c0.3-0.6,0-1.2,0-1.8c0.5-1.2,1.5-2.2,2.6-2.9c0.2,0.2,0.6,0.3,0.9,0.3c0.3,0,0.6-0.3,0.9-0.3s1.5,0.3,1.8,0.3
			s0.3,0.3,0.6,0.6c0.6,0.3,1.5,0.3,2,0.9c0.3,0.3,0.3,0.9,0.9,1.2c0.3,0.3,0.9,0.3,1.2,0.6c0,0.2,0.1,0.4,0.3,0.6h0.3
			c0.6-0.3,0.9-2.3,0.9-2.9l-0.3-0.3c-0.3,0-0.3,0.3-0.6,0.3l-0.3-0.3v-0.3l0.3-0.3c0.9-0.3,1.2,0.9,2.9,0c0.3,0,0.9,0.3,1.2,0.3
			c1-0.1,2-0.1,2.9,0c0.9,0.3,0.9,1.5,1.8,1.5c0.7-0.3,1.4-0.7,2-1.2c1.2-0.9,1.2-2.9,2-4.1c0.3-0.6,1.2-0.6,1.8-1.2
			c0.3-0.3,0.3-0.9,0.6-1.2c0.9-0.6,1.2,0,1.8-0.3s0.6-1.5,0.9-2h0.3c0.6,0.9,0,2.3-0.3,2.9c0,0.3,0,0.6,0.3,0.6
			c1,0.7,1.9,1.6,2.6,2.6c0.2,0.6,0.6,1.1,1.2,1.5c0.6,0,5.5,1.8,6.4,2c0.9,0.3,1.5,1.2,2.3,1.5c0.9,0.3,5,1.5,5.5,2
			c0.9,0.6,0.9,2.6,1.5,3.2c0.3,0.3,0.6,0,1.2,0h1.8c0.5-0.1,1-0.2,1.5-0.3c0.6,0,1.2,0.3,2,0.3s4.1-1.5,4.7-1.8
			c0.3-0.3,0.9-0.3,1.2-0.6c0.7-0.4,1.4-0.9,2-1.5c0.9-0.6,1.2-1.5,2-2c0.9-0.6,6.7-4.7,7.3-4.7c0-0.6-1.5-1.5-0.9-3.2l0.3-0.3
			c0.3,0.3,0.3,0.6,0.6,0.9c0.3,0,0.6,0.3,0.9,0.3s3.2-0.9,3.5-0.9c0.9-0.3,2.3-1.2,3.2-0.9h0.3c0.3-0.6,0.3-1.5,0.6-2.3
			c0.3-0.9,1.2-2.9,1.5-4.1c0.3-0.9,1.8-1.8,1.5-2.6c0-0.3-1.2-0.9-1.5-1.2l0.3-0.3c0.9-0.3,1.5,0.3,2.9-0.3s1.8-1.2,2.9-1.8
			c0.8-0.3,1.5-0.6,2.3-0.9l1.8-0.9c1.8-0.9,4.1-0.9,5.8-1.5c0.9-0.3,2-0.3,2.9-0.6l0.3-0.3c-0.3-0.3-0.3-0.6-0.6-0.9v-0.9
			c0.6-1.5,1.9-2.5,3.5-2.9c0.4-0.1,0.8-0.3,1.2-0.6c0.6-0.3,0.3-1.2,1.5-2l-0.3-0.3c-0.9,0.3-2-0.9-2.3-1.5
			c-0.3-0.2-0.8-0.3-1.2-0.3c-0.9-0.6-0.3-1.5-0.3-2.3c0.3-2,0.3-3.2,2.6-3.2h1.5c0.3,0,0.9,0.3,1.2,0c-0.4-1.1-1.1-2.2-2-2.9
			c-1.2,0-2-3.2-3.2-4.1c-1.1-1.3-2.4-2.5-3.8-3.5c-0.3,0-0.3-0.3-0.6-0.6c-0.9-0.6-1.8,0.3-2.6-0.3v-0.3c0.2-0.6,0.8-0.8,1.4-0.6
			h0.1c0.2,0.2,0.4,0.3,0.6,0.3l0.3-0.3c0.3-1.2-1.5-2.3-2.3-2.9h-1.8c-1.2,0.3-1.2,1.2-2.9,0.6c-0.3,0-0.6-0.3-0.6-0.6l0.3-0.3
			c0.9-0.6,1.8,0.6,2.9-0.3c0-0.3-3.5-3.2-3.8-3.5c0.6,0.3,1.8,0.3,2.6,0.6c1,0.4,1.9,0.8,2.9,1.2c0.6,0.3,1.5,0,2,0.3l1.8,0.9
			c1.7,0.7,3.4,1.6,5,2.6c1.2,0.6,2.9,3.5,3.8,3.5l0.3-0.3c0.3-0.6-2.3-3.5-2.6-4.4c-0.3-1.2,0.9-3.2-0.3-5.5
			c-0.9-2.3-1.8-1.8-2.9-2.9c-0.6-0.6-0.6-1.5-1.2-2.3c-0.6-0.9-1.5-0.6-2-1.2c-0.7-0.7-1.2-1.5-1.8-2.3c-0.9-0.9-1.8-0.9-2.6-1.8
			c-0.5-0.5-0.9-1.1-1.2-1.8c-0.8-0.7-1.7-1.2-2.6-1.8c-0.8-0.9-1.7-1.8-2.6-2.6c-0.9-0.6-1.5-0.3-2.3-0.6c-0.9-0.3-1.5-0.9-2.3-1.2
			c-0.6-0.3-1.2-0.3-1.8-0.6c-1.1-0.4-2.1-1.3-2.6-2.3c-0.3-0.3-0.3-0.6-0.6-0.9c-0.6-0.6-1.5-0.3-2-0.6c-1.5-0.6-3.8-2-5.5-2.3
			c-1.2-0.3-2.6,0.3-3.8,0c-0.6-0.1-1.2-0.3-1.8-0.6c-1-0.2-1.9-0.3-2.9-0.3c-1.3,0.1-2.5,0.1-3.8,0c-1.5-0.6-2.9-1.2-4.1-1.8
			C654.4,997.2,652,997.2,649.4,997.2L649.4,997.2z"/>
		<path id="path5034_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M740.3,954
			c1.6,0.2,3.1,0.4,4.7,0.9c0.3,0.3,0.7,0.5,1.2,0.6h0.9c0.7,0.1,1.4,0.3,2,0.6c1.2,0.8,2.5,1.3,3.8,1.8c1.2,0.3,2.6,0.3,3.8,0.6
			c0.6,0.3,0.9,0.6,1.5,0.9c0.9,0.1,1.7,0.6,2.3,1.2c0.6,0.3,1.2,0,1.5,0.3c0.6,1.8,2.3,0.6,3.5,1.5c0.9,0.6,0.3,1.8,1.8,2.3
			c0.9,0.3,2.6,0,2.9,0.3l0.6,0.6c0.3,0,0.6,0.3,0.9,0.3c0.3,0,0.6,0.1,0.9,0.3c0.5,0.3,1,0.7,1.5,1.2c0.9,0.6,2-0.3,3.2,0.3
			c0.9,0.6,1.5,1.8,2.3,2.3c1.4,0.9,2.8,1.5,4.4,2c0.3,0.3,0.9,0.3,1.2,0.6c0.6,0.3,0.6,0.6,0.9,1.2c0.6,1.2,1.8,1.5,2.6,2.3v1.5
			c0,0.3,0.3,0.9,0.6,0.9c0.3,0.3,0.6,0.3,0.6,0.6c0.6,0.6,0.9,1.2,1.5,1.8c0.6,0,1.5-0.3,1.8,0c1.2,0.3,1.2,1.2,2,1.8
			c0.9,0.6,1.5,0,2.3,0.9c0.9,0.9,0.3,2.3,2,3.2c1.5,0.9,2.9,0.6,3.8,1.2c0.6,0.3,0.9,0.6,1.5,0.6c1.2,0,1.2-0.6,2.6,0
			c0.9,0.3,1.5,1.2,2.3,1.5c0.3,0,0.3,0.3,0.6,0.3c0.6,0.3,0.3,0.9,0.9,1.2c0.9,0.3,1.2-0.6,2-0.3c0.3,0.2,0.6,0.4,0.9,0.6
			c0.6,0.6,0.9,1.5,1.5,2c0.3,0.3,0.6,0.3,0.9,0.9c-0.1,0.5-0.2,1-0.3,1.5v0.3c2.6,1.2,1.2,0.9,1.8,2.6c0.3,0.9,1.2,0.9,1.5,1.2
			s0.3,1.5,0.9,2c0.2,0.2,0.6,0.3,0.9,0.3c0.6,0.6,0.3,2,0.9,2.6c0.6,0.6,1.8,0.6,1.8,1.5c-0.3,0.9-1.2,1.5-1.8,2
			c-0.1,0.7-0.1,1.4,0,2h-0.3c-0.3,0-0.9-0.6-1.2-0.6s-2.6,1.2-2.6,1.5c-0.3,0.3-0.3,0.6-0.6,0.6h-0.3c-1.2,0-3.2-1.5-4.4-1.2
			c-0.6,0.2-1.1,0.6-1.5,1.2H811c-1.2-1.5-2.6,0-3.5,0c-2-0.3-4.1-1.2-6.1-0.9c-1.5,0.3-2.6,1.5-3.5,1.5c-1.5-0.3-2.6-1.5-4.1-2.3
			c-0.9-0.6-2-0.3-2.9-0.9c-0.9-0.3-1.5-1.2-2.3-1.5c-0.6-0.3-0.9,0-1.5-0.3c-0.6,0-0.6-0.3-1.2-0.6c-0.6-0.3-1.3-0.4-2-0.3
			c-3.6-0.2-7.2-1-10.5-2.3c-0.9-0.3-1.8-1.2-2.6-1.5c-1.2-0.6-2.9-0.6-4.4-0.9c-0.3,0-0.3-0.3-0.3-0.6c-0.6-0.3-1.5-0.3-2-0.6
			s-1.1-0.5-1.8-0.6c-1.3-0.2-2.5-0.7-3.5-1.5c-1.8-1.5-3.8-1.8-5.3-3.2c-0.9-0.9-1.5-2.3-2.3-2.9c-0.9-0.6-1.2,0-2-0.3
			s-1.8-1.5-2.6-1.8c-0.9-0.4-1.9-0.7-2.9-0.9c-1.2-0.6-2.6-1.2-3.8-1.8c-0.6-0.3-1.5,0-2-0.6c0-0.3,0.6-0.6,0.6-1.2
			c-0.9-1.3-1.7-2.6-2.3-4.1c0-0.9,0.9-1.2,0.3-2.3c-0.3-0.6-0.9-1.2-1.2-1.8c-1.2-2.3-2.9-3.8-5-5.5c-0.6-0.6-1.2-0.9-1.8-1.5
			c-1.7-1.9-3.7-3.6-5.8-5c-0.9-0.5-1.9-0.9-2.9-1.2c-0.9-0.3-1.5-0.9-2.3-1.2c-0.9-0.3-1.8-0.3-2.6-0.6c-0.6-0.3-1.2-0.9-1.8-1.2
			c-0.3,0-0.9-0.3-1.2-0.3c-1.4-0.2-2.6-0.7-3.8-1.5c-0.6-0.3-1.5-1.5-2.3-1.8c-0.6-0.3-0.9,0-1.2-0.6c-0.9-0.9-0.9-3.5-2.9-3.5
			l-0.3,0.3c-0.3,0.9,0.6,1.5,0.6,2l-0.3,0.3h-0.3c-0.9-0.5-1.7-1.2-2.3-2c-0.3-0.6-0.3-1.2-0.9-1.8c-0.6-0.6-1.8-0.9-2.3-1.5
			c-0.3,0-0.3-0.3-0.3-0.6c0.6-1.5,1.7-2.6,3.2-3.2c0.4,0,0.8-0.1,1.2-0.3c0.9-0.4,1.9-0.7,2.9-0.9h1.5c0.3-0.3,0.7-0.5,1.2-0.6
			c0.6,0,1.2-0.1,1.8-0.3c0.6-0.3,0.9-1.2,1.2-1.2h4.4c1.2,0.3,2,1.2,3.2,1.5c1.5,0.6,1.2-0.3,2.9,0.9c0.3,0.3,2.9,2,2.9,2
			c0.3,0,2.6,0.3,2.9,0.3c0.6,0,1.2-0.3,1.8,0c0.8,0.3,1.7,0.5,2.6,0.6c0.8,0,1.6,0.1,2.3,0.3l1.8,0.9c0.9,0.3,2,0.6,2.9,0.9
			C735.6,953.4,740,953.7,740.3,954L740.3,954z"/>
		<path id="path5036_2_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M832.6,1105.9
			c-0.6,0-0.9,0.6-2,1.2v0.3c0.3,0.4,0.7,0.6,1.2,0.6c0.6-0.3,0.9-0.9,1.5-0.9s1.2-0.1,1.8-0.3c0-0.3-0.3-0.3-0.6-0.3
			C833.9,1106.1,833.2,1105.9,832.6,1105.9L832.6,1105.9z M834.3,1118.2c-0.9,0-2,0.6-2.9,0.6s-1.8-0.3-2.6,0.3c-0.8,0.5-1.5,1.2-2,2
			c-1.8,1.8-3.8,3.2-5.3,5.3c-0.8,1-1.6,2.1-2.3,3.2c-1,1.2-2.1,2.4-3.2,3.5c-0.5,0.4-0.9,0.9-1.2,1.5c-1.8,1.5-3.5,0-4.1,3.5
			c-0.3,1.2,0.3,2.6-0.3,4.1c-0.1,0.4-0.3,0.8-0.6,1.2c-0.3,0.9-0.3,1.8-0.9,2.3c-0.3,0.9-1.5,2.3-1.5,2.9c-0.3,1.8,1.2,1.5,2,1.8
			c0.3,0,0.6,0.3,0.9,0.3c0.6,0,0.9-0.6,1.5-0.9c0.3,0,0.6-0.1,0.9-0.3c0-0.3,0.3-0.6,0.9-0.9l0.3,0.3c0.3,0.3-0.3,0.9,0,1.2
			c1.5,0.6,4.4-1.5,3.8-3.2c-0.3,0.3-0.3,0.3-0.6,0.3c-0.3,0.3-0.3,0.9-0.6,1.2l-0.6,0.6h-0.3c0-0.3-0.3-0.3-0.3-0.6
			c-1.5-0.3-2.3-0.6-2.9-1.8c0-0.3-0.3-0.3-0.3-0.6h-0.3c0,0.2,0.1,0.4,0.3,0.6l-0.3,0.3h-0.3c-0.6-0.3-1.2-0.3-1.8-0.6
			c-0.2-0.2-0.3-0.6-0.3-0.9s0-0.3,0.3-0.6c0.6-1.2,1-2.5,1.2-3.8c0.1-0.4,0.3-0.8,0.6-1.2c1.2-0.9,2,0,2.6,0l0.3-0.3
			c0.3-1.2,0.6-3.8,2-3.8c0.7,0.1,1.4,0.3,2,0.6h0.3c0.6-0.6,0.9-2.6,1.2-3.5c1.2-2.7,2.9-5.2,5-7.3c0.3-0.3,0.7-0.5,1.2-0.6
			c0.3,0,0.3-0.3,0.6-0.3h-1.2c-0.8,0.5-1.7,1-2.6,1.5l-0.6,0.6c-0.3,1.2-0.9,2.3-1.8,3.2c-0.6,0.3-0.9,0.3-1.2,0.6
			c-0.6,1.2-0.3,2.3-1.2,3.2c-0.8,0.6-1.7,1.2-2.6,1.7c-0.3,0-0.9-0.3-0.9-0.6c0.6-0.6,4.4-4.1,5-4.7c0.9-1.2,1.5-2.9,2.6-4.1
			c1.2-1.2,2.9-1.8,4.1-2.9c0.9-0.9,1.5-2.6,2.6-3.2c0.6-0.3,0.9,0.3,1.5,0.3s0.9-0.3,1.2-0.3h0.3c0,1.5-2,3.5-3.5,4.4l-0.3,0.3
			c0,0.2,0.1,0.4,0.3,0.6h0.3c0.3-0.4,0.7-0.7,1.2-0.9l0.6,0.6c0.3,0,0.9-1.5,1.5-1.8h0.3c0.6-0.6,0.6-2,1.5-2.6
			c0.9-0.3,2-0.3,2.3-1.5c0-0.2-0.1-0.4-0.3-0.6C835.2,1118.4,834.9,1118.2,834.3,1118.2L834.3,1118.2z M811.3,1142.1H811
			c-0.3,0.5-0.3,1.2,0,1.8C811.1,1143.3,811.2,1142.7,811.3,1142.1z M821.5,1145h-0.3l-0.3,0.3c0,0.2,0.1,0.4,0.3,0.6
			c0.5,0,1-0.4,1.2-0.9C822.4,1145.3,821.8,1145,821.5,1145z"/>
	</g>
	<g id="Saguenay-Lac-Saint-Jean_2" className="region">
		<circle className="point" cx="360" cy="1000" r="1" fill="transparent" />
		<path id="path5026_5_" fill="#BEC6CC" stroke="#F1F9FF" strokeWidth="2" strokeMiterlimit="10" d="M444.4,740.5h-0.6l-0.6,0.6
			l-0.3,2.3l-1.2,0.3l-2,2h0.3l-0.3,0.3h-0.3c-0.3,0.3-0.6,0.9-0.9,1.2l-0.9,0.9v0.3h0.3l-0.3,0.6l-0.3,0.3l0.3,1.2l0.6,0.6v0.3
			l-0.3,0.3l-0.6,0.3l-0.6,2.3c0,0.3-0.6,0.6-0.6,1.2l-0.3,0.3v0.3h-0.3c0,0.6,0,0.9-0.3,1.2c0,0.2-0.1,0.4-0.3,0.6
			c-0.4,0.4-0.9,0.7-1.5,0.9c0,0.4-0.1,0.8-0.3,1.2c0,0.3-0.3,0.3-0.3,0.6s-0.3,0.3-0.6,0.3v0.3l0.6,0.6h0.3l1.5-0.3l-0.3,0.9
			l-0.6,0.3c0,0.6-0.6,0.9-0.6,1.2v0.6c0,0.4-0.1,0.8-0.3,1.2c0,0.3-0.3,0.3-0.6,0.6c-0.3,0-0.3,0.3-0.6,0.6c-0.7,0.2-1.4,0.4-2,0.6
			H428l-0.9,0.3c-0.5,0.2-1,0.4-1.5,0.6c-0.3,0-1.2,0.3-1.2,0.6c-0.3,0.3-0.3,0.6-0.6,0.9c0,0.3-0.3,0.3-0.9,0.3l0.3-0.6h-0.6v0.3
			c-0.3,0-0.9,0.3-1.2,0.3H421c-0.2,0-0.3-0.1-0.3-0.3l0,0c0-0.2-0.1-0.3-0.3-0.3l0,0h-0.6l-0.6,0.3c0,0.3-0.3,0.6-0.6,0.6H418
			c-0.3,0.3-0.3,0.6-0.6,0.6s-0.6,0.3-0.9,0c-0.3,0.3-0.6,0.6-0.9,0.6H415l-0.3-0.3c-0.2,0.2-0.6,0.3-0.9,0.3h-0.9
			c0,0.3-0.3,0.3-0.3,0.6c-0.3,0-0.3,0-0.6,0.3c0,0.3-0.6,0.6-0.6,0.9c-0.3,0-0.3,0-0.6,0.3c-0.2,0-0.3,0.1-0.3,0.3l0,0
			c-0.3,0-0.6,0.3-0.9,0c0,0.3-0.6,0.3-0.6,0.3l-0.6,0.6h-0.3c-0.1-0.4-0.5-0.7-0.9-0.9c0-0.3,0.1-0.6,0.3-0.9c0.2,0,0.4-0.1,0.6-0.3
			v-0.6c-0.3,0-0.6,0.3-0.9,0.3s-0.6-0.1-0.9-0.3h-1.2l-0.6-0.6c-0.2,0.4-0.5,0.8-0.9,1.2s-0.7,0.9-0.9,1.5c-0.4,0-0.8,0.1-1.2,0.3
			c-0.3,0-0.3,0.3-0.6,0.3c-0.2,0-0.3,0.1-0.3,0.3l0,0c-0.4,0.1-0.8,0.3-1.2,0.6c-0.5,0.2-1,0.3-1.5,0.3l-0.6,0.6h-2
			c-0.4,0.5-0.4,1.2,0,1.8c-0.3,0.4-0.7,0.7-1.2,0.9c-0.5,0.3-0.9,0.5-1.5,0.6c0,0.5-0.2,0.9-0.6,1.2v1.2c0,0.2-0.1,0.4-0.3,0.6
			c-0.4,0.4-0.9,0.7-1.5,0.9c-0.3,0.6-0.5,1.3-0.6,2h0.4l-0.3,0.3c-0.3,0.3-0.7,0.5-1.2,0.6c-0.2,0.2-0.3,0.6-0.3,0.9l-0.6,0.6
			l-0.3,0.3c-0.2,0.3-0.3,0.8-0.3,1.2l0.9,0.3h0.3l0.3-0.3c0.3,0,0.9-0.3,1.2,0l-0.3,1.2l-1.8,0.3c-0.5,0.2-0.8,0.7-0.9,1.2
			c-0.3,0.3-0.3,0.9-0.6,1.5l-1.5,1.5l0,0v0.9c0,0.3,0.1,0.6,0.3,0.9l-0.6,2c0,0.3-1.2,0.9-1.2,0.9c-0.3,0.6-0.3,1.8-0.6,2.6
			c-0.3,0.6-0.3,0.9-0.6,1.2L381,805h-1.2c-0.4,0-0.8-0.1-1.2-0.3c-0.2,0.2-0.6,0.3-0.9,0.3h-0.9c-0.6,0-1.2,0.1-1.8,0.3
			c-0.3,0-1.8,1.5-1.8,1.5v1.2c0,0.3-0.3,0.6-0.3,0.9s-0.3,0.6-0.3,1.2c0,0.6,0.1,1.2,0.3,1.8c-0.2,1.4-0.6,2.8-1.2,4.1
			c0.2,0.2,0.3,0.6,0.3,0.9c0,0.3,0,0.3-0.3,0.6c0,0.3-0.3,0.3-0.3,0.6v0.6c0.2,0.2,0.3,0.6,0.3,0.9c-0.2,0.8-0.3,1.5-0.3,2.3
			c0,0.4,0.1,0.8,0.3,1.2c0.3,0.3,0.3,0.6,0.6,0.9h0.3v1.8c0,0.3-0.3,0.6-0.3,0.9s0,0.3,0.3,0.6c0,0.2,0.1,0.4,0.3,0.6
			c0,0.3,0.3,0.6,0.3,0.9c0,0.5-0.1,1-0.3,1.5l-0.6,0.6c-0.3,0.3-0.6,0.3-1.2,0.6c-0.7,0.2-1.4,0.5-2,0.9c-0.3,0.6-0.6,1.5-0.9,2
			c-0.2,0.8-0.3,1.5-0.3,2.3l0.3,0.3v0.3l-0.9,0.9c-0.2,0.2-0.3,0.4-0.3,0.6v0.9l-1.8,1.8l-0.6,0.3c0-0.3,0-0.3-0.3-0.6
			c0-0.3-0.3-0.3-0.3-0.6h0l-0.6,0.6h-0.9V840l-0.3-0.3H362c-0.3,0.4-0.4,1-0.3,1.5c-0.3,0.3-0.9,0.6-1.2,0.9l-0.6,0.9
			c0,0.3,0.3,0.9,0.3,1.2c-0.1,0.4-0.3,0.8-0.6,1.2c0,0.3,0.3,0.6,0.3,0.9v1.2l-0.6,0.3c-0.3,0-0.6,0-0.6-0.3c-0.3,0-0.6-0.3-0.9-0.3
			l-0.9,0.6c0,0.3-0.1,0.6-0.3,0.9c-0.3,0.3-0.3,0.6-0.6,0.9v0.6l-1.5,0.9c0.2,0.2,0.3,0.4,0.3,0.6c0,0.3,0.3,0.6,0,0.9l-0.6,0.3
			c0,0.6-0.3,1.2-0.3,1.8v0.3c-0.5,0.2-0.9,0.6-1.2,1.2h-0.6c0.3,0.6,0.9,1.2,0.9,1.8c-0.3,0-0.6-0.3-1.2-0.6c-0.3,0-0.3-0.3-0.6-0.3
			s-0.3-0.3-0.6-0.3h-0.3l-0.3,0.6c-0.3,0.3-0.6,0.6-0.6,0.9s0,0.3-0.3,0.6l-0.3-0.3h-0.3v1.2c-0.3,0.3-0.6,0.9-0.9,1.2
			c-0.3,1.2-0.3,1.2-0.6,1.5c0,0-0.3,0-0.6,0.6c0,0.3,0.1,0.6,0.3,0.9v0.3c-0.3,0-0.3,0.3-0.3,0.6s0,0.3-0.3,0.6
			c-0.3,0.6-0.3,0.9-0.6,1.5c0,0.5,0.1,1,0.3,1.5c-0.2,0.2-0.3,0.6-0.3,0.9v0.3c0,0.3,0.3,0.3,0.3,0.6h-0.6c-0.3,0-0.3,0-0.6-0.3
			c0-0.3-0.3-0.6-0.6-0.6v-1.5c0-0.6,0.3-0.9,0-1.5l-0.3-0.3h-0.6c-0.3,0-0.6,0-0.6,0.3s-0.3,0.6-0.3,0.9s-0.3,0.6-0.3,0.9h-0.3v1.2
			c0,0.4-0.1,0.8-0.3,1.2l-0.6,0.3c-0.2,0.6-0.3,1.2-0.3,1.8c-0.2,0-0.4-0.1-0.6-0.3h-0.3c-0.3,0.3-0.3,0.6-0.3,1.2
			c0,0.4-0.1,0.8-0.3,1.2c-0.3,0.6-0.3,0.9-0.6,1.2c-0.3,0.3-0.6,0.9-0.9,1.2v0.3l-0.3,0.3c-0.3,0-0.6-0.3-0.9,0h-0.3v1.5
			c0.1,0.5,0,1-0.3,1.5c0,0.3-0.3,0.6-0.6,0.9c-0.3,0.6-0.3,1.2-0.6,1.8l-0.9,0.6c-0.2,0.2-0.3,0.6-0.3,0.9c0,0.3,0,0.3-0.3,0.3
			l-0.3,0.3c-0.3-0.1-0.6-0.1-0.9-0.3h-0.3c-0.3,0.6-0.6,0.9-0.9,1.5c0,0.2-0.1,0.4-0.3,0.6l-0.6,0.6h-0.3l-0.3-0.3l0,0
			c-0.3,0-0.3,0.3-0.3,0.6v0.3l-0.3,0.3h0.6v-0.6c0-0.2-0.1-0.4-0.3-0.6h-0.3l-0.3,0.3c-0.3,0.3-0.3,0.6-0.6,0.9h-0.3v-0.3
			c0.3-0.6,0.6-0.9,0.6-1.2v-1.8c0.1-0.6,0.4-1.1,0.9-1.5V881l-0.3-0.3l-0.3-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.4,0.1-0.8,0.3-1.2
			c0-0.3,0.3-0.9,0.3-1.2c-0.2-0.3-0.3-0.8-0.3-1.2V875l-0.3-0.3c-0.3,0-0.6,0-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6l0.3,0.3v0.3
			c0,0.3-0.3,0.6-0.3,0.9c-0.3,0.3-0.6,0.6-0.6,0.9s-0.3,0.6-0.3,1.2v1.5c-0.3,0.9-0.6,2-0.9,3.2c-0.3,0-0.6,0.3-0.6,0.6
			s-0.3,0.6-0.6,0.9s-0.6,0.6-0.9,0.6s-0.6,0-0.6-0.3v-1.5c0-0.3-0.3-0.3-0.6-0.6h-0.6c0,0.3-0.1,0.6-0.3,0.9l-0.3,0.3l-0.3-0.3v-0.6
			c0.2-0.2,0.3-0.4,0.3-0.6v-1.5l-0.3-0.3c-0.3,0-0.3,0.3-0.6,0l0,0c0-0.3,0-0.3,0.3-0.3h-0.3v-0.6c0-0.2-0.1-0.3-0.3-0.3l0,0h-0.3
			l-0.9-0.3c-0.3,0-0.3,0.3-0.6,0.3c-1.1,0.2-2.2,0.4-3.2,0.9l-0.6,0.3v0.3c-0.6,1.2-0.6,1.5-0.9,1.8c-0.5,0.5-1.1,0.8-1.8,0.9h-0.3
			v0.3l0.3,0.3v1.8c-0.1,0.5-0.2,1-0.3,1.5h-0.3l-0.3-0.3h-0.3c-0.5,0.4-0.9,0.9-1.2,1.5c-0.3,0.5-0.7,1-1.2,1.5
			c-0.3,0.3-0.6,0.3-0.6,0.6c-0.3,0.3-0.3,0.6-0.6,0.6h-0.6c-0.2,0-0.3,0.1-0.3,0.3l0,0h-0.3V892c0.3-0.3,0.6-0.3,0.6-0.6
			c0.3-0.3,0.3-0.3,0.3-0.6V890c0-0.3-0.1-0.6-0.3-0.9l-0.3-0.3c-0.3,0-0.3,0-0.6,0.3s-0.3,0.3-0.3,0.6h-0.9
			c-0.3-0.3-0.3-0.3-0.3-0.6s0.1-0.6,0.3-0.9c0.2-0.4,0.3-0.8,0.3-1.2c-0.3-0.3-0.3-0.6-0.6-0.6H303l-0.3-0.3v-0.3
			c0-0.3,0.3-0.3,0-0.6c-0.2-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.6,0.1-0.9,0.3c-0.4,0.2-0.8,0.5-1.2,0.9c-0.3,0.3-0.3,0.9-0.6,1.2
			c-0.1,0.5-0.2,1-0.3,1.5h-0.3l-0.3-0.3c0,0-0.6,0-0.6-0.3c-0.3,0-0.3,0-0.3,0.3c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2-0.1,0.4-0.3,0.6
			c0,0.3-0.3,0.3-0.6,0.3l-0.6,1.2c-0.3,0.5-0.5,0.9-0.6,1.5h0.3c0,0.3-1.2,0.9-1.2,1.2c-0.2,0.2-0.3,0.6-0.3,0.9v0.9
			c0,0.6-0.1,1.2-0.3,1.8l-0.3,0.3h-0.3l-0.3,0.6c-0.2,0.2-0.3,0.6-0.3,0.9c0,0.3-0.3,0.6-0.3,1.2c0,0.5-0.1,1-0.3,1.5l-0.9,0.9
			c-0.3,0.3-0.6,0.6-0.6,0.9v1.5c0,0.3-0.1,0.6-0.3,0.9c-0.3,0.3-0.3,0.6-0.6,1.2c-0.3,0.3-0.6,0.6-0.6,0.9l-0.9,0.9
			c-0.3,0.3-0.3,0.6-0.6,1.2c0,0.3-0.3,0.9-0.3,1.2s0.3,0.6,0.3,1.2c0,0.4-0.1,0.8-0.3,1.2c0,0.2-0.1,0.4-0.3,0.6
			c0,0.2-0.1,0.3-0.3,0.3l0,0l-0.3-0.3v-1.8c0-0.3,0-0.6-0.3-0.6h0.1c-0.2,0.6-0.4,1.2-0.6,1.8c-0.2,0.2-0.4,0.3-0.6,0.3
			c-0.3,0-0.6,0.1-0.9,0.3c0,0.3-0.3,0.6-0.6,0.9l-0.3,0.3c-0.3,0-0.3,0-0.6-0.3c-0.3,0.3-0.3,0.9-0.6,1.2h-0.3
			c-0.2-0.2-0.3-0.6-0.3-0.9c0-0.5,0.1-1,0.3-1.5c0-0.6,0.3-0.9,0.3-1.5s0-0.9-0.3-1.2l0,0l-0.3,0.3v0.6c0,0.3-0.6,0.9-0.6,1.2v0.3
			c0,0.9,0.1,1.8,0.3,2.6c0,0,0.3,0.3,0,0.3c-0.3,0.3-0.3,0.6-0.6,0.6c0,0.3,0,0.3-0.3,0.6v1.5l-0.9,2.6c-0.3,0.3-0.6,0.6-0.6,0.9
			c0,0.3-0.1,0.6-0.3,0.9c0,0.3-0.3,0.6-0.6,0.6v1.2c-0.3,0.6-0.3,0.9-0.6,1.2c-0.4,0-0.8-0.1-1.2-0.3c-0.4,0-0.8-0.1-1.2-0.3v-1.2
			l0,0c-0.3,0-0.6,0.3-0.9,0.9c-0.4,0.4-0.6,0.9-0.6,1.5c-0.2,0.6-0.3,1.2-0.3,1.8v1.2c0.3,0,0.3,0,0.6,0.3v0.6c0,0.3,0,0.3-0.3,0.6
			c-0.2,0.2-0.3,0.6-0.3,0.9c0,0.3,0,0.3,0.3,0.6c0,0.3,0.3,0.6,0.3,0.9c0,0.3-0.1,0.6-0.3,0.9c0,0.2-0.1,0.4-0.3,0.6v0.9
			c0,0.3,0.3,0.6,0,0.9c0,0.5-0.2,1.1-0.6,1.5c-0.2,0.6-0.3,1.2-0.3,1.8l0,0c0,0.3-0.1,0.6-0.3,0.9c0,0.3-0.3,0.3-0.6,0.6h-0.9v1.2
			c-0.2,0.5-0.3,1-0.3,1.5c0,0.2-0.1,0.4-0.3,0.6c-0.2,0-0.3,0.1-0.3,0.3l0,0c-0.3,0-0.3,0.3-0.6,0.6c-0.2,0.2-0.3,0.4-0.3,0.6v0.6
			c0,0.6-0.6,0.9-0.6,1.5c-0.3,0.6,0,1.2-0.3,2l-0.3,0.3v0.6c0,0.3,0,0.3-0.3,0.6s-0.3,0.6-0.6,0.9l-0.9,1.8h-0.6
			c-0.3,0.3-0.6,0.3-0.6,0.6s-0.3,0.6-0.3,0.9s-0.3,0.6-0.3,0.9s-0.6,0.6-0.9,0.9c-0.3,0.3-0.9,0.3-1.5,0.6l-1.2,0.6l-0.6,1.2
			c0,0.3-0.3,0.9-0.3,1.2c-0.1,0.6-0.3,1.2-0.6,1.8c-0.3,0.6-0.5,1.1-0.6,1.8c-0.3,0.3,0,0.9-0.3,1.2s-0.6,0.6-0.6,0.9h-0.3
			c-0.3,0-0.3,0-0.6-0.3c-0.3,0-0.6,0-0.6,0.3l-0.6,0.6c-0.3,0.5-0.5,0.9-0.6,1.5v0.3c0,0.6,0.3,0.9,0.3,1.5v1.2
			c-0.3,0.3-0.6,0.9-0.9,1.2c-0.3,0.3-0.3,0.9-0.6,1.2l-1.2,0.9v1.8c0,0.3-0.1,0.6-0.3,0.9c0,0.2-0.1,0.4-0.3,0.6
			c0,0.4,0.1,0.8,0.3,1.2c-0.3,0-0.6,0.6-0.6,0.6c-0.4,0.4-0.7,0.9-0.9,1.5l-0.6,0.6c-0.2,0-0.4,0.1-0.6,0.3l-0.3-0.3
			c0-0.3,0.3-0.6,0.3-0.9c-0.3-0.3-0.6-0.6-0.6-0.9h-0.3c-0.3,0.3-0.6,0.6-0.6,0.9s-0.3,0.6-0.3,1.2l-0.3,0.3h-0.6l-0.3,0.3l0.3,0.3
			c0,0.2,0.1,0.4,0.3,0.6c0,0.3,0.3,0.3,0.3,0.6v0.3c-0.6,0.3-0.9,0.6-0.9,0.9c-0.3,0.3-0.3,0.3-0.3,0.6v3.8h0.6v0.3
			c-0.1,0.5-0.3,1-0.6,1.5c-0.3,0.3-0.3,0.6-0.6,0.9c0,0.3-0.3,0.6-0.3,0.9l0.3,0.6c0,0.3,0.3,0.3,0.6,0.3h0.9l0.3-0.3h0.3
			c0,0.3,0.3,0.9,0,1.2c0.3,0,0.9,0.6,1.2,1.2c0.6,0.6,1.2,1.2,1.2,1.5v0.6h0.3c0.2-0.3,0.3-0.8,0.3-1.2c0-0.3,0-0.3,0.3-0.6
			c0-0.3,0.3-0.3,0.6-0.3h0.3v0.6c0,0.3,0,0.3,0.3,0.3s0.6-0.6,0.9-0.9h0.3l0.3,0.3c0,0.3,0.3,4.4,0.3,5l0,0l-0.3,0.3v2
			c0,0.3-0.3,0.3-0.3,0.6s-0.3,0.3-0.3,0.9c0,0.3,0.3,0.9,0.3,1.2s0.3,0.6,0.3,0.9l0,0c-0.3,0-0.3,0-0.6-0.3h0.6
			c-0.1,0.3-0.2,0.6-0.3,0.9v0.9l0.3,0.6c0,0.3-0.3,0.6-0.3,0.9v1.5c0,0.5-0.2,0.9-0.6,1.2c-0.3,0.5-0.3,1.2,0,1.8h-0.6
			c0,0.3,0,0.3,0.3,0.6c0,0.3,0.3,0.6,0.3,0.9l-0.3,0.6l0.3,0.3v0.6c-0.3,0.6-0.9,0.9-1.2,1.2v2.9c0,0.6,0,0.9-0.3,1.2
			c0,0.3,0,0.3-0.3,0.3H250c-0.3,0-0.6-0.3-0.9-0.6l-0.6-0.6c-0.2-0.2-0.4-0.2-0.6,0l0,0c-0.3,0-0.6,0.3-0.9,0.9l-0.6,0.3l-0.9,0.3
			l63.1,67.2l41.5,1.8l2.3,3.8h31.5l0.3-0.6c0-0.3,0.3-0.6,0.3-0.9s0-0.3-0.3-0.3c-0.4,0-0.8-0.1-1.2-0.3v-1.8l52-0.3l3.8-9.3l9.6,5
			l4.4-10.5c-0.3-0.3-0.3-0.6-0.6-0.9v-0.9c0-0.3,0-0.3-0.3-0.6l-0.9-0.9c-0.3-0.2-0.8-0.3-1.2-0.3c-0.6,0-0.9,0-1.2-0.3l-0.3-0.3
			l-0.3-0.3c-0.4-0.2-0.8-0.5-1.2-0.9c-0.2-0.2-0.6-0.3-0.9-0.3c-1,0-2-0.1-2.9-0.3l2.6-6.7v-47.9h-0.3v-59.6h-2v-15.2h0.3v-204
			H444.4z"/>
	</g>
</>

export default QuebecRegions;