import { environment } from "./environment";
import { Plugins, registerWebPlugin } from '@capacitor/core';
import { FacebookLogin } from '@rdlabo/capacitor-facebook-login';
import "@codetrix-studio/capacitor-google-auth";
import { SocialTokens } from "../models";
const { GoogleAuth, SignInWithApple } = Plugins;

export const initFacebookWeb = () => {
    if (!!(window as any).fbAsyncInit) {
        return;
    }
    registerWebPlugin(FacebookLogin as any);
    (window as any).fbAsyncInit = function () {
        FB.init(environment.facebook);
    };
    
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        (js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}

// Get social token

export const getFacebookLoginToken = async (): Promise<SocialTokens> => {
	const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
	let accessToken;
	try {
		const result = await Plugins.FacebookLogin.getCurrentAccessToken();
		accessToken = result.accessToken;
	} catch (e) {}

	if (!accessToken) {
		await Plugins.FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
		const result = await Plugins.FacebookLogin.getCurrentAccessToken();
		accessToken = result.accessToken;
	}

	return accessToken ? {
		facebook: accessToken.token,
		facebook_id: accessToken.userId
	} : null;
}

export const getGoogleLoginToken = async (): Promise<SocialTokens> => {
	const result = await GoogleAuth.signIn();
	return result ? {
		google: result.authentication?.idToken,
		google_id: result.id,
		google_email: result.email
	} : null;
}

export const getAppleLoginToken = async (): Promise<SocialTokens> => {
	const result = await SignInWithApple.Authorize();

	return result.response ? {
		apple: result.response.user,
		apple_id: result.response.identityToken
	} : null;
}