import React from 'react';

import './TestimonialSent.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

const TestimonialSent:React.FC = () => {

    const localizedContent = useLocalizedContent();

    return (
        <div className="testimonial-sent">
            <img src="/assets/icon/validation.svg" alt="icon-validation" />
            <h2 dangerouslySetInnerHTML={{__html: localizedContent.testimonialSent }} />
            <p dangerouslySetInnerHTML={{__html: localizedContent.testimonialSentContent }} />
        </div>
    );
}

export default TestimonialSent;

