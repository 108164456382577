import React from 'react';

import './MapLegendModal.scss';
import RegionStatus, { getRegionStatusLevels } from '../../stats/RegionStatus';
import DeclarationTypesLegend from '../../stats/DeclarationTypesLegend';
import useLocalizedContent from '../../../hooks/useLocalizedContent';

interface MapLegendModalProps {
    mobile?: boolean;
    onClose?(): void;
}

const MapLegendModal:React.FC<MapLegendModalProps> = ({ mobile, onClose }) => {
    
    const localizedContent = useLocalizedContent();
    const statusLevels = getRegionStatusLevels();
    const legendStatusLabels = statusLevels.map((statusLevel, i) => {
        return i === 0 ?
            localizedContent.lessThan + ' ' + (statusLevels[i+1] * 100) + '%'
        : i !== statusLevels.length - 1 ?
            localizedContent.between + ' ' + (statusLevel * 100) + '% ' + localizedContent.and + ' ' + (statusLevels[i+1] * 100) + '%'
        :
            (statusLevel * 100) + '% ' + localizedContent.and + ' ' + localizedContent.more.toLowerCase()
    });

    return (
        <div className={'map-legend-modal' + (mobile ? ' style-mobile' : '')}>
            <div className="legend-header">
                <div className="title">{ localizedContent.legend }</div>
                <button type="button" className="btn-close" onClick={ onClose }>
                    <img src="/assets/icon/close.svg" alt="close-icon" />
                </button>
            </div>
            <div className="legend-content">
                <div className="legend-entry">
                    <div className="caption" dangerouslySetInnerHTML={{ __html: localizedContent.legendUrgentTso}} />
                    <div className="content">
                        { legendStatusLabels.map( (label, i) => 
                            <div key={'item'+i} className="legend-content-item">
                                <RegionStatus level={i+1} />
                                <div className="label">{ label }</div>
                            </div>
                        ) }
                    </div>
                </div>
                <div className="legend-entry">
                    <div className="caption" dangerouslySetInnerHTML={{ __html: localizedContent.legendDeclarationTypes }} />
                    <div className="content">
                        <DeclarationTypesLegend />
                    </div>
                </div>
            </div>            
        </div>
    );
}

export default MapLegendModal;