import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { FilteredPage } from '../store/settings-actions';
import { DeclarationsFilter } from './../store/reducers/settings';

interface FilteredEntry {
    region_id: number;
    institution_id: number;
    installation_id: number;
}

type Hook = (page: FilteredPage, entries: FilteredEntry[]) => FilteredEntry[]

const useLocationFilteredEntries:Hook = (page, entries) => {
    
    const pageFilters = useSelector((state:RootState) => state.settings.pageFilters[page]) as DeclarationsFilter;    

    return useMemo( () => {   
        const isValidSingleLocation = (entry: FilteredEntry, key: 'region_id' | 'institution_id' | 'installation_id') => {
            return pageFilters[key] === undefined || pageFilters[key] === entry[key];
        }
        
        const isValidLocation = (declaration: FilteredEntry) => {
            const validRegion = isValidSingleLocation(declaration, 'region_id');
            const validInstitution = isValidSingleLocation(declaration, 'institution_id');
            const validInstallation = isValidSingleLocation(declaration, 'installation_id');
            return validRegion && validInstitution && validInstallation;
        }
        
        return entries.filter( entry => isValidLocation(entry) );
    }, [pageFilters, entries]);
}

export default useLocationFilteredEntries;