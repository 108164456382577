import React from 'react';
import SideModalCloseButton from '../../buttons/SideModalCloseButton';

import './OpenedTestimonial.scss';
import { Testimonial } from '../../../models';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useLocale from '../../../hooks/useLocale';
import { parseLongDate } from '../../../lib/utils';

interface OpenedTestimonialProps {
    testimonial: Testimonial;
    onClose?(): any
}

const OpenedTestimonial: React.FC<OpenedTestimonialProps> = ({ testimonial, onClose }) => {

    const locale = useLocale();
    const localizedContent = useLocalizedContent();
    const hasImage = !!testimonial.images;
    
    return testimonial ?
    <div className={ 'opened-testimonial' + (hasImage ? ' has-image':'') }>
        { hasImage ?
            <img className="testimonial-image" src={ testimonial.images.large } alt="testimonial" />
        : null }
        <div className="testimonial-content">
            <div className="quote-container">
                <img src="/assets/icon/quote.svg" alt="quote" />
                <img src="/assets/icon/quote.svg" alt="quote" />
            </div>
            <p className="testimonial-message">
                { testimonial.content }
            </p>
            <div className="testimonial-infos">
                <div className="label-container">
                    <img src="/assets/icon/name-pale.svg" alt="icon-name" />
                    <span className="label">{ localizedContent.name }</span>
                </div>
                <div className="testimonial-data">{ testimonial.user_fullname }</div>
            </div>
            <div className="testimonial-infos">
                <div className="label-container">
                    <img src="/assets/icon/calendar-pale.svg" alt="icon-calendar" />
                    <span className="label">{ localizedContent.addedDate }</span>
                </div>
                <div className="testimonial-data">{ parseLongDate(testimonial.created_at_moment,locale) }</div>
            </div>
        </div>
        <div className="testimonial-extra-infos">
            <div className="extra-infos-title">{ localizedContent.testimonialAllowSharing }</div>
            <div className="extra-infos-content">{ testimonial.allow_sharing ? localizedContent.yes : localizedContent.no }</div>
        </div>
        <div className="testimonial-close-button-container">
            <SideModalCloseButton onClose={ onClose } />
        </div>
    </div>
    :
    <></>
};

export default OpenedTestimonial;