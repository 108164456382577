import { environment, ApiSuccessPayload } from './environment';
import { stringify } from 'query-string';
import { EntriesSuccessPayload, EntriesStatsSuccessPayload, ChangeEntriesStatusRequestPayload, ExportEntriesRequestPayload } from '../store/entries-actions';

class EntriesService {

	async getEntries(token: string, filters?: any, types?: string[]): Promise<EntriesSuccessPayload> {
		const response = await fetch(`${environment.api}/data?${stringify({ filters: JSON.stringify(filters), types }, {
			arrayFormat: 'comma'
		})}`, {
			method: 'GET',
			headers: { Authorization: 'Bearer ' + token }
		});
		return await response.json() || { data: null };
	}

	async getEntriesStats(token: string, filters?: any, types?: string[]): Promise<EntriesStatsSuccessPayload> {
		const response = await fetch(`${environment.api}/stats?${stringify({ filters: JSON.stringify(filters), types }, {
			arrayFormat: 'comma'
		})}`, {
			method: 'GET',
			headers: { Authorization: 'Bearer ' + token }
		});
		return await response.json() || { data: null };
	}

	async readEntry(token: string, payload:ChangeEntriesStatusRequestPayload): Promise<ApiSuccessPayload> {
		const response = await fetch(`${environment.api}/read`, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify(payload)
		});
		return await response.json() || { data: null }
	}

	async archiveEntries(token: string, payload:ChangeEntriesStatusRequestPayload): Promise<ApiSuccessPayload> {
		const response = await fetch(`${environment.api}/archive`, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify(payload)
		});
		return await response.json() || { data: null }
	}

	async exportEntries(token: string, payload:ExportEntriesRequestPayload): Promise<{ data: Blob, fileName?: string }> {
		const response = await fetch(`${environment.api}/export`, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + token },
			body: JSON.stringify(payload)
		});
		return { data: await response.blob(), fileName: response.headers.get('Content-Disposition').split("filename=")[1] } || { data: null };
	}
}

export const entriesService = new EntriesService();