import { IonItem, IonLabel, IonPage, IonContent } from '@ionic/react';
import React from 'react';
import MobileHeader from '../../../components/mobile/MobileHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { DeclarationType } from '../../../models';
import useLocalizedContent from '../../../hooks/useLocalizedContent';

import { useIonViewWillEnter } from '@ionic/react';
import { updateStatusBar } from '../../../lib/status-bar';

import './MobileHome.scss';
import MobileUnsupportedSection from '../../../components/mobile/MobileUnsupportedSection';

interface MobileHomeProps {
    reportUnsafeCare?(): void
    reportNewDeclaration?(e:React.MouseEvent, type: DeclarationType): void
}

const MobileHome: React.FC<MobileHomeProps> = ({ reportNewDeclaration, reportUnsafeCare }) => {

    // Update tab color
    useIonViewWillEnter( () => updateStatusBar('blue'));

    const localizedContent = useLocalizedContent();
    const user = useSelector((state:RootState) => state.auth.user);
    const isUserAdmin = user?.is_admin || false;
    const userFirstName = user?.first_name;

    return (
        <IonPage className="with-tabs mobile-home" data-page="home">
            <MobileHeader blue hideBackButton title={ localizedContent.greetings + ' ' + userFirstName } />
            <IonContent>
                { !isUserAdmin ? 
                    <div className="ion-padding"> 
                        <IonItem className="tso-button" button onClick={ e => reportNewDeclaration(e, 'tso') } color="primary">
                            <div className="tso-btn-content">
                                <h1 dangerouslySetInnerHTML={{__html: localizedContent.tsoReportTitle }}></h1>
                                <IonLabel>
                                    <p>{ localizedContent.tsoReportDescription }</p>
                                </IonLabel>
                            </div>
                            <div className="tso-btn-image">
                                <img alt="icon-tso" src="/assets/icon/tso.svg" />
                            </div>
                        </IonItem>
                        <IonItem className="substitution-button item-gray-border" button onClick={  e => reportNewDeclaration(e, 'substitution')  }>
                            <div className="icon">
                                <img alt="icon-substitution" src="/assets/icon/substitution.svg" />
                            </div>
                            <div className="content">
                                <div className="title">{ localizedContent.substitutionReportTitle }</div>
                                <div className="description">{ localizedContent.substitutionReportDescription }</div>
                            </div>
                        </IonItem>
                        <IonItem className="report-unsafe-care-button item-gray-border" button onClick={ reportUnsafeCare }>
                            <div className="icon">
                                <img alt="icon-bandage" src="/assets/icon/bandage.svg" />
                            </div>
                            <div className="content">
                                <div className="title" dangerouslySetInnerHTML={{__html: localizedContent.unsafeCareReportTitle}} />
                            </div>
                        </IonItem>
                    </div>
                : 
                    <MobileUnsupportedSection />
                }
            </IonContent>
        </IonPage>
    );
};

export default MobileHome;