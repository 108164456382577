import React, { useMemo } from 'react';
import { FormControl } from '@material-ui/core';
import { DeclarationType } from '../../models';
import moment, { Moment } from 'moment';
import TextFieldNumber from './../TextFieldNumber';
import useStoredDeclaration from './../../hooks/useStoredDeclaration';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import DateSelector from '../stats/DateSelector';

import './DeclarationHours.scss';
import useServerNow from '../../hooks/useServerNow';

const DeclarationHours: React.FC<{ type: DeclarationType }> = ({ type }) => {

    const localizedContent = useLocalizedContent();
    const storedDeclaration = useStoredDeclaration(type);
    const declaration = storedDeclaration.declaration;
    const tso = storedDeclaration.tso;
    const substitution = storedDeclaration.substitution;

    const now = useServerNow();
    const declarationDate = declaration?.date_submitted_for;

    const date = declarationDate ? moment(declarationDate) : null;
    const hours = (type === 'tso' ? tso?.regular_hours : substitution?.hours_spent) || '';
    const overtimeHours = (type === 'tso' ? tso?.overtime_hours : undefined) || '';

    const showDateError = !useMemo( () => moment(date).isSameOrBefore(now, 'day'), [date, now]) && !!date;

    const handleDateChanged = (date: Moment) => {
        const dateString = date.format('YYYY-MM-DD');
        storedDeclaration.setData({ date_submitted_for: dateString });
    }

    const handleHoursChanged = (hours: string) => {
        storedDeclaration.setData({ [type === 'tso' ? 'regular_hours' : 'hours_spent']: hours });
    }

    const handleOvertimeHoursChanged = (overtimeHours: string) => {
        storedDeclaration.setData({ overtime_hours: overtimeHours });
    }
    
    const dateSubmittedForLabel = type === 'tso' ? localizedContent.tsoDate : localizedContent.workSubstitutionDate;

	return (
        <FormControl className="declaration-hours" fullWidth>
            <div className="date-selector-container">
                <DateSelector
                    label={ dateSubmittedForLabel }
                    value={ date }
                    max={ now }
                    onChange={ handleDateChanged }
                />
            </div>
            { showDateError ?
                <div className="date-error">
                    { localizedContent.invalidDeclarationDate }
                </div>
            : null }
            <div className="hours-block hours-regular">
                <img src="/assets/icon/hours-regular-gray.svg" alt="icon-hours-regular" />
                <span className="label">{ type === 'tso' ? localizedContent.regularHours : localizedContent.substitutionHours }</span>
                <div className="input-container">
                    <TextFieldNumber
                        value={ hours }
                        onChange={ handleHoursChanged }
                    />
                </div>
            </div>
            { type === 'tso' ? 
                <div className="hours-block hours-overtime">
                    <img src="/assets/icon/hours-overtime-gray.svg" alt="icon-hours-overtime" />
                    <span className="label">{ localizedContent.overtimeHours }</span>
                    <div className="input-container">
                        <TextFieldNumber
                            value={ overtimeHours }
                            onChange={ handleOvertimeHoursChanged }
                        />
                    </div>
                </div>
            : null }
        </FormControl>
	);
};

export default DeclarationHours;