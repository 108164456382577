import React from 'react';

import './UnreadCount.scss';

interface UnreadCountProps {
    count: number;
}

const UnreadCount:React.FC<UnreadCountProps> = ({ count }) => {

    return (
        <div className="unread-count">            
            <div className="unread-count-background" />
            <div className="unread-count-content">
                { count }
            </div>            
        </div>
    );
}

export default UnreadCount;