import React from 'react';
import moment, { Moment } from 'moment';

import './DatesSelector.scss';
import DateSelector from './DateSelector';
import RadioButtons from '../buttons/RadioButtons';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { DatesFilter } from './../../store/reducers/settings';
import useServerNow from '../../hooks/useServerNow';

interface DatesSelectorProps {
    dateFrom: Moment;
    dateTo: Moment;
    hideCustomRange?: boolean;
    onChange?(datesRange: DatesFilter): void;
}

const DatesSelector: React.FC<DatesSelectorProps> = ({ dateFrom, dateTo, onChange, hideCustomRange }) => {

    const localizedContent = useLocalizedContent();
    const now = useServerNow();
    const lastWeek = moment().subtract(7, 'days');
    const lastMonth = moment().subtract(30, 'days');

    dateFrom = dateFrom ? dateFrom : lastMonth;
    dateTo = dateTo ? dateTo : now;

    const rangeChoices = [
        {
            label: localizedContent.last7days,
            from: lastWeek
        },
        {
            label: localizedContent.last30days,
            from: lastMonth
        }
    ];

    const getActiveFilterFromDates = (dateFrom:Moment, dateTo:Moment) => {
        const dateToNow = dateTo.isSame(now, 'day');
        const dateFromLastWeek = dateFrom.isSame(lastWeek, 'day');
        const dateFromLastMonth = dateFrom.isSame(lastMonth, 'day');
        return dateToNow ? (dateFromLastWeek ? 0 : (dateFromLastMonth ? 1 : 2) ) : 2;
    }
    
    const activeFilter = getActiveFilterFromDates(dateFrom, dateTo);

    const handleDeterminedRangesChange = (index: number) => {
        onChange({
            date_from: rangeChoices[index].from, 
            date_to: now,
            range_type: index
        });
    };

    const handleDateFromChanged = (date:Moment) => {
        const newFilter:DatesFilter = {
            date_from: date,
            date_to: dateTo
        };
        if (date.isSameOrAfter(dateTo, 'day')) {
            newFilter.date_to = moment(date).add(1, 'day');
        }

        newFilter.range_type = getActiveFilterFromDates(date, newFilter.date_to);

        onChange(newFilter);
    }

    const handleDateToChanged = (date:Moment) => {
        const newFilter:DatesFilter = {
            date_from: dateFrom,
            date_to: date
        };
        if (date.isSameOrBefore(dateFrom, 'day')) {
            newFilter.date_from = moment(date).subtract(1, 'day');
        }

        newFilter.range_type = getActiveFilterFromDates(newFilter.date_from, date);

        onChange(newFilter);
    }

	return (
        <div className={'dates-selector' + ( !hideCustomRange ? ' with-custom-ranges' : '')}>
            <div className="determined-ranges">
                <RadioButtons choices={ rangeChoices } value={activeFilter} onChange={ handleDeterminedRangesChange } />
            </div>
            { !hideCustomRange ? 
                <div className={'custom-ranges' + (activeFilter === 2 ? ' active' : '')}>
                    <label>
                        <span>{ localizedContent.from }</span>
                        <DateSelector
                            max={moment(now).subtract(1, 'day')}
                            value={dateFrom}
                            onChange={handleDateFromChanged}
                        />
                    </label>
                    <label>
                        <span>{ localizedContent.to }</span>
                        <DateSelector
                            max={now}
                            value={dateTo}
                            onChange={handleDateToChanged}
                        />
                    </label>
                </div>
            : null }
        </div>
	);
};

export default DatesSelector;