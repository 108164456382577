import React from 'react';
import { ControlledIonModalContent } from '../ControlledIonModal';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import FiqButton from '../../buttons/FiqButton';

interface DeclarationExitWarningProps extends ControlledIonModalContent {    
    onExit(): void;
    onResume(): void;
}

const DeclarationExitWarning:React.FC<DeclarationExitWarningProps> = ({ onExit, onResume }) => {

    const localizedContent = useLocalizedContent();
    
    return (
        <div className="ion-padding ion-text-center">
            <div className="ion-padding-bottom">
                <img alt="icon-saved-declaration" src="/assets/icon/exit-declaration.svg" height="46" />
            </div>
            <h2>{ localizedContent.quitConfirm }</h2>
            <p>{ localizedContent.savedDeclarationWarning }</p>
            <div className="buttons-container">
                <FiqButton color="white" fill="outline" onClick={ onExit }>{ localizedContent.quit }</FiqButton>
                <FiqButton onClick={ onResume }>{ localizedContent.resume }</FiqButton>
            </div>
        </div>
    );
};

export default DeclarationExitWarning;