import React from 'react';
import { IonIcon } from '@ionic/react';
import useIsMobile from '../../hooks/useIsMobile';

import './FiqButton.scss';
import { Link } from 'react-router-dom';

interface DesktopbuttonProps {
    className?: string;
    href?: string;
    target?: string;
    rel?: string;
    expand?: 'inline' | 'block';
    fill?: 'solid' | 'outline';
    color?: 'primary' | 'success' | 'danger' | 'white' | 'clear';
    icon?: string;
    iconColor?: string;
    ionIcon?: boolean;
    hidden?: boolean;
    disabled?: boolean;
    isExternal?: boolean;
    onClick?(e:React.MouseEvent): any
}

const FiqButton: React.FC<DesktopbuttonProps> = ({ className = '', href, target, rel, fill = 'solid', expand = 'inline', iconColor, color='primary', icon, ionIcon, disabled, hidden, onClick, children, isExternal }) => {

    const isMobile = useIsMobile();

    const tagProps = {
        className: 'fiq-button' + (className ? ' ' + className : '') + ' expand-' + expand + ' color-' + color + (hidden ? ' hidden' : '') + ( ' fill-' + fill ) + (isMobile ? ' mobile' : ' desktop') + (!children ? ' no-children' : ''),
        onClick: onClick,
        disabled: disabled
    }

    const renderContent = () => (
        <>
            { icon ?
                ionIcon ? <IonIcon icon={ icon } color={ iconColor ? iconColor : undefined } /> : <img src={ icon } alt="icon" />
            :
                null
            }
            { children }
        </>
    );

	return (
        isExternal ?
            <a href={ href } target={ target ? target : undefined } rel={ rel ? rel : undefined } { ...tagProps} >{ renderContent() }</a>
            :
            href ?
                <Link to={ href } target={ target ? target : undefined } rel={ rel ? rel : undefined } { ...tagProps} >{ renderContent() }</Link>
            :
            <button type="button" { ...tagProps}>{ renderContent() }</button>
	);
};

export default FiqButton;