import React from 'react';
import { Plugins, NotificationChannel } from '@capacitor/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AuthStatus } from '../store/auth-actions';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { addFirebaseTokenToUser } from '../store/auth-actions';
import { isPlatform } from '@ionic/react';
import { addEntry, readEntry, deleteEntry, getEntries } from '../store/entries-actions';
import { useHistory } from 'react-router';
import useLocalizedContent from '../hooks/useLocalizedContent';
import { EntryTypes } from '../models';
import { FCM } from 'capacitor-fcm';
import { messaging } from '../lib/firebase-web';
import { setNotificationsRefused, setNotificationsUnsupported } from '../store/settings-actions';
import firebase from 'firebase/app';
import localeContent from '../data/locale-strings.json';
import { updateWebNotification } from '../store/app-actions';
const { PushNotifications, LocalNotifications, Toast, App } = Plugins;

const NotificationsManager:React.FC = () => {

    const localizedContent = useLocalizedContent();
    const history = useHistory();
    const authStatus = useSelector((state:RootState) => state.auth.status);    
    const dispatch = useDispatch();
    const isNative = isPlatform('capacitor');
    const isAndroid = isPlatform('android');
    const isIos = isPlatform('ios');

    // When the notification is tapped, we read the entry and navigate to it
    const handleEntryTapped = (dispatch:any, history:any, notificationData: { type: EntryTypes, object: string}) => {
        const entryType = notificationData.type;
        const entry = JSON.parse(notificationData.object);
        const entryId = entry?.id;
        const currentLocation = history.location.pathname;
        let newLocation = currentLocation;
        
        if (entryId) {
            switch (entryType) {
                case 'tsos':
                    newLocation = '/dashboard/tso-details/' + entryId;
                break;
                case 'substitutions':
                    newLocation = '/dashboard/substitution-details/' + entryId;
                break;
                case 'news':
                    newLocation = '/dashboard/news/' + entryId;
                break;            
                case 'events':
                    newLocation = '/dashboard/events/' + entryId;
                break;            
            }
        }

        // we also add the entry here in the rare case the user received the notification message and tapped the notification before receiving the data-only message
        // addEntry with the skipReplace options, because in this case, we only want to replace the entry it was not received yet
        // this solves the case when the entry was updated between the moment the user received the notification and when the notification was tapped
        // if the entry was deleted before tapping it, the entry will available until the user logs out
        dispatch(addEntry(entryType, entry, true));

        // we read the entry for tsos, substitutions and news (could add testimonials if it becomes notifyable and if we show the testimonials list for mobile)
        if (entryType !== 'events') {
            dispatch(readEntry(entryType, entryId));
        }
        
        if (currentLocation !== newLocation) {
            history.push(newLocation);
        }
    }

    // On login, we request permission and set the token
    useEffect( () => {
        
        if (authStatus === AuthStatus.LoggedIn) {
            // on native, we use Capacitor PushNotifications plugins
            if (isNative) {
                PushNotifications.requestPermission().then(result => {
                    if (result.granted) {
                        PushNotifications.register();               
                        //PushNotifications.addListener('registration', token => { setFirebaseToken(token.value) });// Using FCM token instead
                        const fcm = new FCM();
                        fcm.getToken()
                        .then( r => {
                            dispatch(addFirebaseTokenToUser(r?.token));
                        });
                    } else {
                        dispatch(setNotificationsRefused());
                    }                    
                }).catch(err => dispatch(setNotificationsRefused()));
            // on web, we use firebase lib
            } else {
                try {
                    if (Notification.permission === 'granted') {
                        messaging.getToken()
                        .then( token => {
                            dispatch(addFirebaseTokenToUser(token));
                        })
                        .catch( () => {
                            dispatch(setNotificationsRefused());
                        });
                    }
                } catch (e) {}
            }
        }
    }, [authStatus, dispatch, isNative]);

    // Method to manage data-only messages
    const parseEntry = (dispatch:any, type: EntryTypes, entry: any, options: any) => {
        if (options.delete_action) {                
            dispatch(deleteEntry(type, entry.id));
        } else {
            dispatch(addEntry(type, entry));
        }
    }

    // ANDROID ONLY - Create channels for push and local notifications, so we can hardcode the importance level 
    const pushChannelDescription = localizedContent.pushNotificationsChannelDescription;    
    useEffect( () => {
        if (isNative && isAndroid) {

            const pushChannel: NotificationChannel = {
                id: 'push-notifications',// id must match android/app/src/main/res/values/strings.xml's default_notification_channel_id
                name: 'Push notifications',
                description: pushChannelDescription,                
                importance: 5,
                visibility: 1,
                lights: true,
                lightColor: '#009FE3'
            };

            PushNotifications.createChannel(pushChannel);
            LocalNotifications.createChannel(pushChannel);

            return () => {
                PushNotifications.deleteChannel(pushChannel);
                LocalNotifications.deleteChannel(pushChannel);
            }
        }
    }, [pushChannelDescription, isNative, isAndroid]);
    
    // IOS ONLY - Actually the only way we found to receive a DATA-ONLY message on iOS - wtf @team-ionic ?
    useEffect ( () => {
        if (isNative && isIos) {
            (window as any).fiq_onIosFirebaseDataMessage = function(notification:{ type: EntryTypes, object: any, options: any }) {
                parseEntry(dispatch, notification.type, notification.object, notification.options);
            }
        }        
    }, [isIos, isNative, dispatch]);
    

    // NATIVE ONLY - On app focus, if we disabled notifications, we refresh entries
    // i know j'utilise window pcq y'a pas de App.removeListener et donc je dois ajouter les listeners une seule fois pour pas que ça se queue dans des futurs renders
    const needDataRefresh = useSelector((state: RootState) => !state.settings.notificationsAccepted || state.settings.notificationsUnsupported);
    useEffect( () => {
        if (needDataRefresh && authStatus === AuthStatus.LoggedIn) {
            (window as any).fiq_notifications_app_focus = () => {
                dispatch(getEntries());
            };

            return () => {
                (window as any).fiq_notifications_app_focus = undefined;
            }
        }
    }, [needDataRefresh, dispatch, authStatus]);

    // main listeners for receiving push in foreground + data-only message
    useEffect( () => {
        if (isNative) {

            App.addListener('appStateChange', state => {
                if (state.isActive) {
                    if ((window as any).fiq_notifications_app_focus) {
					    (window as any).fiq_notifications_app_focus();
				    }
                }                
            });

            // When we received a new message
            PushNotifications.addListener('pushNotificationReceived', notification => {

                //alert(JSON.stringify(notification));

                // When a Notification is sent, we are also receiving a Data-only message
                // Because when the app is in the background, this callback is only triggered for Data-only message
                // And we need the callback to update the redux store with the new entry
                
                const notificationData = notification.data;
                const notificationEntryType = notificationData.type;
                const notificationEntry = JSON.parse(notificationData.object);
                const isPushNotification = !!notification.title || !!notification.body;

                // if this is a push notification received when the app is in the foreground on Android
                if (isAndroid && isPushNotification) {
                    // We schedule a LocalNotification 1 second later since Capacitor for Android doesn't show anything in this case
                    const newDate = new Date();
                    LocalNotifications.schedule({
                        notifications: [
                            {
                                title: notification.title,
                                body: notification.body,
                                id: newDate.getUTCMilliseconds(),                                
                                schedule: { at: new Date(Date.now() + 1000 ) },
                                extra: notificationData,
                                channelId: 'push-notifications'
                            }
                        ]
                    });
                } else {
                    // This is a data-only notification, which means we need to update the store
                    parseEntry(dispatch, notificationEntryType, notificationEntry, JSON.parse(notificationData.options));
                }
            });
            

            // Method called when tapping on a PUSH notification
            PushNotifications.addListener('pushNotificationActionPerformed', notificationAction => {
                handleEntryTapped(dispatch, history, notificationAction.notification.data);
            });

            // Method called when tapping on a LOCAL notification (for Android only)
            LocalNotifications.addListener('localNotificationActionPerformed', notificationAction => {
                handleEntryTapped(dispatch, history, notificationAction.notification.extra);
            });

            PushNotifications.addListener('registrationError', (error: any) => {
                Toast.show({
                    text: 'Notifications registration error: ' + JSON.stringify(error),
                    duration: 'long'
                });
            });

        } else {
            if (firebase.messaging.isSupported()) {

                navigator.serviceWorker.addEventListener('message', (message) => {
                    let data, notification : any;
                    const messageData = message.data;
                    const firebaseMessaging = messageData.firebaseMessaging;

                    // Message is received while in foreground (Notifications + Data-only)
                    if (firebaseMessaging) {
                        const payload = firebaseMessaging.payload;
                        notification = payload.notification;
                        data = payload.data;
                    } else {// Data-only message received in background                        
                        data = messageData.data;
                    }                

                    // Notification message received in foreground
                    if (!!notification) {
                        dispatch(updateWebNotification({
                            title: { fr: notification.title, en: notification.title },
                            body: { fr: notification.body, en: notification.body },
                            actionLabel: localeContent.show,
                            actionClick: () => {
                                history.push(notification.click_action);
                            }
                        }))
                    } else {// Data-only message received in foreground + background
                        parseEntry(dispatch, data.type, JSON.parse(data.object), JSON.parse(data.options));
                    }
                });
                
            } else {
                dispatch(setNotificationsUnsupported());
            }
        }
    }, [dispatch, history, isNative, isAndroid]);

    return null;
}

export default NotificationsManager;