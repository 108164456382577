import React from 'react';
import { IonFooter, IonToolbar } from '@ionic/react';

import './MobileFooter.scss';

interface MobileFooterProps {
    noBorder?: boolean;
}

const MobileFooter:React.FC<MobileFooterProps> = ({ noBorder, children }) => {

    return (        
        <IonFooter className={'mobile-footer' + (noBorder ? ' no-border' : '' )}>
            <IonToolbar>
                { children }
            </IonToolbar>
        </IonFooter>
    );
}

export default MobileFooter;