import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './store/reducers';
import { init } from './store/app-actions';
import { AuthStatus } from './store/auth-actions';
import useIsMobile from './hooks/useIsMobile';
import useLocale from './hooks/useLocale';
import AuthMonitor from './components/AuthMonitor';
import LoadingOverlay from './components/LoadingOverlay';
import NotificationsManager from './components/NotificationsManager';
import WebNotifications from './components/WebNotifications';
import ToastError from './components/ToastError';
import AppLoading from './pages/AppLoading';
import MobilePages from './pages/mobile/MobilePages';
import DesktopPages from './pages/desktop/DesktopPages';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import MomentUtils from '@date-io/moment';

const MuiTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#009FE3'
		},
		secondary: {
			main: '#DADADA'
		}
	},
	typography: {
		fontFamily: 'Raleway", "Roboto", "Helvetica Neue", sans-serif'
	}
});

const AppContent: React.FC = () => {

    const history = useHistory();
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const authStatus = useSelector((state:RootState) => state.auth.status);
    const appData = useSelector((state:RootState) => state.app.data);
    const settingsReady = useSelector((state:RootState) => state.settings.ready);
    
    const locale = useLocale();

    // app is ready when AuthStatus has initialized + app data has been received + settings ready
    const appReady = authStatus !== AuthStatus.Uninitialized && appData !== undefined && settingsReady;

    // Global loading and errors
    const dataLoading = useSelector((state: RootState) => state.entries.loading);
    const dataError = useSelector((state: RootState) => state.entries.error);
    const loginError = useSelector((state: RootState) => state.auth.error);
    const settingsError = useSelector((state: RootState) => state.settings.error);    

    // Init app
    useEffect( () => {
        dispatch(init(history));
    }, [dispatch, history]);

	return (
        <div className="app-content">
            <MuiPickersUtilsProvider utils={ MomentUtils} locale={ locale }>
				<MuiThemeProvider theme={ MuiTheme }>
                    { appReady ?
                        <>
                            { isMobile ?
                                <MobilePages />
                            :
                                <DesktopPages />
                            }
                            <NotificationsManager />
                            <LoadingOverlay loading={ dataLoading } />
                            <ToastError error={ loginError } />
                            <ToastError error={ dataError } />
                            <ToastError error={ settingsError } />
                        </>
                    :
                        <AppLoading />
                    }
                    <AuthMonitor />
                    <WebNotifications />
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </div>
	);
};

export default AppContent;