import React from 'react';
import moment from 'moment';

import { FilteredPage, updatePageFilter } from '../../store/settings-actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { DatesFilter } from '../../store/reducers/settings';
import DatesSelector from '../stats/DatesSelector';
import useServerNow from '../../hooks/useServerNow';

interface DatesFilterProps {
    page: FilteredPage;
    hideCustomRange?: boolean;
}

const DateFilter: React.FC<DatesFilterProps> = ({ page, hideCustomRange }) => {

    const dispatch = useDispatch();
    const now = useServerNow();
    const lastMonth = moment().subtract(30, 'days');

    const pageFilters = useSelector((state:RootState) => state.settings.pageFilters[page]) as DatesFilter;

	const dateFrom = pageFilters?.date_from || lastMonth;
    const dateTo = pageFilters?.date_to || now;

    const onChange = (DatesFilter: DatesFilter) => {
        dispatch(updatePageFilter(page, DatesFilter, pageFilters));
    }

	return (
        <div className={'date-filter'}>
            <DatesSelector dateFrom={dateFrom} dateTo={dateTo} hideCustomRange={hideCustomRange} onChange={onChange} />
        </div>
	);
};

export default DateFilter;