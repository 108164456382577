import React from 'react';
import { DeclarationType } from '../../models';

import './DeclarationTutorial.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

const DeclarationTutorial:React.FC<{ type: DeclarationType }> = ({ type }) => {

    const localizedContent = useLocalizedContent();

    return (
        <div className="declaration-tutorial">
            <img src={'/assets/icon/' + (type === 'tso' ? 'tso' : 'substitution-blue' ) +'.svg'} alt="icon-declaration" height="136" />
            <h1>{ type === 'tso' ? localizedContent.declarationTso : localizedContent.workSubstitution }</h1>
            <h2>{ localizedContent.beforeYouStart }</h2>
            <p>{ localizedContent.privacyWarning }</p>
        </div>
    );
}

export default DeclarationTutorial;