import React from 'react';
import { IonItem, IonIcon, IonLabel, isPlatform } from '@ionic/react';
import { location, create } from 'ionicons/icons';
import { Event, Locale } from '../../models';
import useLocale from './../../hooks/useLocale';

import './EventDetails.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import FiqButton from '../buttons/FiqButton';
import useIsMobile from '../../hooks/useIsMobile';

interface EventDetailsProps {
    preview?: boolean;
    locale?: Locale;
    event: Event;
}

const EventDetails:React.FC<EventDetailsProps> = ({ preview, locale, event }) => {

    locale = useLocale(locale);
    const localizedContent = useLocalizedContent(locale);
    const user = useSelector((state:RootState) => state.auth.user);
    const isUserAdmin = user.is_admin || false;
    const isMobile = useIsMobile();

    return (
        <div className="event-details">
            <div className="dates">
                <span className="start-date">{ event?.start_date_moment.locale(locale).format('lll') }</span>
                <span className="end-date">{ event?.end_date_moment.locale(locale).format('lll') }</span>
            </div>
            <div className="title">
                { event?.title[locale] }
            </div>
            { isUserAdmin && !isMobile && !preview ?
				<div className="edit-entry">
                    <FiqButton icon={ create } ionIcon href={ '/dashboard/event/edit/' + event?.id }>
                        { localizedContent.edit + ' ' + localizedContent.theEvent }
                    </FiqButton>
				</div>
			: null }
            <IonItem className="location-button" lines="none" detail mode="ios" button target="_blank" rel="noopener noreferrer" href={'https://maps.'+(isPlatform('ios') ? 'apple' : 'google')+'.com/?q='+escape( event?.location )}>
                <IonIcon icon={location} slot="start" mode="md" />
                <IonLabel>                                
                    <div className="location-label">{ localizedContent.eventLocation }</div>
                    <div className="location-content">{ event?.location }</div>
                </IonLabel>
            </IonItem>
            <div className="details-label">{ localizedContent.details }</div>
            <p className="details-content">
                { event?.content[locale] }
            </p>
        </div>
    );
}

export default EventDetails;