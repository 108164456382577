import React from 'react';

import './AbleToWorkTutorial.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

const AbleToWorkTutorial: React.FC = () => {

	const localizedContent = useLocalizedContent();

	return (
		<div className="able-to-work-tutorial ion-text-center">
			<img className="able-to-work-icon" alt="icon-able-to-work-blue" src="/assets/icon/able-to-work-blue.svg" />
			<div className="tutorial-title">{ localizedContent.ableToWorkQuestion }</div>
            <h2>{ localizedContent.ableToWorkTutorialTitle }</h2>
            <p>{ localizedContent.ableToWorkTutorialContent }</p>
			<div className="image-banner-container">
            	<img className="image-banner" src="/assets/bg/apte-banner.svg" alt="able-to-work-banner" />
			</div>
		</div>
	);
};

export default AbleToWorkTutorial;