import React from 'react';

import './SimpleModalController.scss';
import { TransitionGroup, Transition } from 'react-transition-group';

interface SideModalContentProps {
    opened: boolean;
    onClose?():void;
    className?: string;
    position?: 'top' | 'left' | 'right' | 'bottom' | 'center';    
    keepRendered?: boolean;    
}

const SimpleModalController: React.FC<SideModalContentProps> = ({ className, position = 'center', opened, keepRendered, children, onClose }) => {

	return (
       <div className={'simple-modal-controller position-' + position + ( opened ? ' opened' : '') + (className ? ' '+className : '')}>
           <div className="simple-modal-safe" onClick={ e => { e.stopPropagation(); if (onClose) onClose()} } />
           <div className="simple-modal-content">
               { keepRendered ? children : 
                <TransitionGroup component={null}>
                    <Transition key={ opened ? 'opened' : 'closed'} timeout={250}>
                        <div className="simple-modal-content-inner">
                            { opened ? children : null }
                        </div>
                    </Transition>
                </TransitionGroup>
                 }
           </div>
		</div>
	);
};

export default SimpleModalController;