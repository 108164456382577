import React, { useState, useMemo } from 'react';

import './DesktopMapDeclarations.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { DeclarationsFilter } from '../../store/reducers/settings';
import useDateFilteredEntries from './../../hooks/useDateFilteredEntries';
import { Substitution, Tso, SortableDeclaration, DeclarationTypes, DeclarationType } from './../../models/entries';
import useLocationFilteredEntries from './../../hooks/useLocationFilteredEntries';
import useSortableDeclarations from './../../hooks/useSortableDeclarations';
import SortableTable, { SortableTableColumn } from './SortableTable';
import { updatePageFilter, readTutorial } from './../../store/settings-actions';
import MapInfosButton from '../buttons/MapInfosButton';
import QuebecMap from './../QuebecMap';
import DeclarationsCircles from './../stats/DeclarationsCircles';
import RegionStatus from '../stats/RegionStatus';
import FiqButton from '../buttons/FiqButton';
import { map } from 'ionicons/icons';
import DateFilter from './../filters/DateFilter';
import PageTitleActions from './PageTitleActions';
import ExcelButton from './../buttons/ExcelButton';
import LocationFilter from './../filters/LocationFilter';
import DeclarationsTypeFilter from '../filters/DeclarationTypeFilter';
import SimpleModalController from '../modals/SimpleModalController';
import OpenedDeclaration from './../modals/side-modals/OpenedDeclaration';
import DeclarationStatusFilter from './../filters/DeclarationStatusFilter';
import { Region } from './../../models/applicationData';
import MapLegendModal from '../modals/center-modals/MapLegendModal';
import { readEntry } from '../../store/entries-actions';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import useLocale from '../../hooks/useLocale';
import { useHistory } from 'react-router';

interface DesktopMapDeclarationsProps {
	region: Region;
	openedDeclarationType?: DeclarationType;
	openedDeclarationId?: number;
    mapWidth?: number;
}

const DesktopMapDeclarations: React.FC<DesktopMapDeclarationsProps> = ({ region, openedDeclarationType, openedDeclarationId, mapWidth }) => {

	const dispatch = useDispatch();
	const locale = useLocale();
	const localizedContent = useLocalizedContent();
	const regions = useSelector((state:RootState) => state.app.data.regions);
	const institutions = useSelector((state:RootState) => state.app.data.institutions);

	const user = useSelector((state:RootState) => state.auth.user); 
	const isUserAdmin = user.is_admin || false;
	const isUserMilitant = user.is_militant || false;

	const userInstitutionId = isUserMilitant && !isUserAdmin ? user.registration_numbers[0].institution_id : undefined;
	const pageFilters = useSelector((state:RootState) => state.settings.pageFilters.declarations) as DeclarationsFilter;	
	
	const regionId = region?.id;
	const institutionId = isUserAdmin ? undefined : userInstitutionId;

	// get declarations
	const entries = useSelector((state:RootState) => state.entries.data);
	const tsos = entries.tsos;
	const substitutions = entries.substitutions;

	// filter by region

	const regionFilteredTsos = useMemo( () => tsos.filter(tso => tso.region_id === regionId), [tsos, regionId]);
	const regionFilteredSubstitutions = useMemo( () => substitutions.filter(substitution => substitution.region_id === regionId), [substitutions, regionId]);

	// filter by date

	const dateFilteredTsos = useDateFilteredEntries('declarations', regionFilteredTsos) as Tso[];
	const dateFilteredSubstitutions = useDateFilteredEntries('declarations', regionFilteredSubstitutions) as Substitution[];

	// date-filtered counts

	const dateFilteredTsosCount = dateFilteredTsos.length;
	const dateFilteredUrgentTsosCount = dateFilteredTsos.filter(tso => tso.urgency_state).length;
	const dateFilteredSubstitutionsCount = dateFilteredTsos.length + dateFilteredSubstitutions.length;
	const dateFilteredTsosUrgentRatio = dateFilteredUrgentTsosCount / dateFilteredTsos.length;

	// filter by institution + installation

	const locationFilteredTsos = useLocationFilteredEntries('declarations', dateFilteredTsos) as Tso[];
	const locationFilteredSubstitutions = useLocationFilteredEntries('declarations', dateFilteredSubstitutions) as Substitution[];

	// counts for the declaration type filter
	const locationFilteredTsosCount = locationFilteredTsos.length;	
	const locationFilteredSubstitutionsCount = locationFilteredSubstitutions.length;

	// combine declarations in a sortable format

	const sortableDeclarations = useSortableDeclarations({ 
		tsos: locationFilteredTsos,
		substitutions: locationFilteredSubstitutions
	});

	// local filter urgencies status

	const statusesFilter = pageFilters?.statuses || [];
	const showUrgencies = statusesFilter.indexOf('urgencies') === -1;

	// local filter declaration types

	const declarationTypes = pageFilters.declaration_types;
	const isTypeUnfiltered = declarationTypes === undefined || declarationTypes.length === 0;	
	const showTsos = isTypeUnfiltered || declarationTypes.indexOf('tso') > -1;
	const showSubstitutions = isTypeUnfiltered || declarationTypes.indexOf('substitution') > -1;

	// apply local filters

	const sortableFilteredDeclarations = sortableDeclarations.filter((declaration) => {
		const validUrgencies = showUrgencies || !declaration.urgent;
		const validType = (declaration.type === 'tso' && showTsos) || (declaration.type === 'substitution' && showSubstitutions);
        return validType && validUrgencies;
	});
	
	const tableColumns:SortableTableColumn[] = [
		{
			type: 'read-status'
		},
		{
			type: 'sort',
			sortKey: 'type',
			label: localizedContent.declaration,
			renderContent: (entry:any) => entry.type === 'tso' ? localizedContent.tso : localizedContent.workSubstitution
		},
		{
			type: showUrgencies ? 'sort' : undefined,
			sortKey: 'urgent',
			label: <DeclarationStatusFilter page="declarations" />,
			renderContent: (entry:any) => entry.urgent ? <span>{ localizedContent.urgent }</span> : null
		},
		{
			type: 'sort',
			sortKey: 'hours',
			label: localizedContent.totalHoursLabel,
			renderContent: (entry:any) => entry.urgent ? <span className="urgent">{ entry.hours }h</span> : entry.hours + 'h'
		},
		{
			type: 'sort',
			sortKey: 'name',
			label: localizedContent.name,
			renderTitle: (entry:any) => entry.name,
			renderContent: (entry:any) => entry.name
		},
		{
			type: 'sort',
			sortKey: 'institution_name',
			label: localizedContent.institution,
			renderTitle: (entry:any) => entry.institution_name,
			renderContent: (entry:any) => entry.institution_name
		},
		{
			type: 'sort',
			sortKey: 'installation_name',
			label: localizedContent.installation,
			renderTitle: (entry:any) => entry.installation_name,
			renderContent: (entry:any) => entry.installation_name
		},                    
		{
			type: 'sort',
			sortKey: 'date',
			label: localizedContent.addedDate,
			renderContent: (entry:any) => entry.date.locale(locale).format('DD/MM/YYYY')
		}
	];

    // Handle opened entry ------------------------------------------------------------
	const history = useHistory();
	const openedDeclaration = openedDeclarationType ? (entries[openedDeclarationType + 's' as DeclarationTypes] as (Tso | Substitution)[]).find( declaration => declaration.id === openedDeclarationId) : null;

	const handleDeclarationOpen = (entry: SortableDeclaration) => {
		
		history.push('/dashboard/' + entry.type + '-details/' + entry.id);

		if (!entry.read) {
			dispatch(readEntry((entry.type + 's' as DeclarationTypes), entry.id));
		}		
    }
    
    const handleDeclarationClose = () => {
        history.replace('/dashboard/map');
	}

    // Handle legend map opening
	
	const seenMapTutorial = useSelector((state:RootState) => state.settings.tutorialsRead.indexOf('map') > -1);
	const [mapLegendOpened, setMapLegendOpened] = useState(!seenMapTutorial);
	const openMapLegend = () => {
		setMapLegendOpened(true);
	}
	const closeMapLegend = () => {
		if (!seenMapTutorial) {
			dispatch(readTutorial('map'));
		}
		setMapLegendOpened(false);
	}

	const handleSelectRegion = (regionNumber?: number) => {
		dispatch(updatePageFilter('declarations', { region_id: regions.find(region => region.region_number === regionNumber)?.id }) );
    }

	return (
        <div className="desktop-map-declarations">
            <MapInfosButton onClick={ openMapLegend } />
            <div className={'map-legend-container' + (mapLegendOpened ? ' opened' : '')}>
                <MapLegendModal onClose={ closeMapLegend } />
            </div>            
            <div className="map-container" style={{width: mapWidth || 0}}>
                <QuebecMap region_number={ region.region_number } />
                <div className="circles-container">
                    <DeclarationsCircles tsosCount={ dateFilteredTsosCount } urgentTsosCount={ dateFilteredUrgentTsosCount }  substitutionsCount={ dateFilteredSubstitutionsCount } triangleLayout size={80} />
                </div>
            </div>
            <div className="declarations-container">
                <div className="over-map-content">
                    <div className="current-location">
                        <RegionStatus urgentRatio={ dateFilteredTsosUrgentRatio } />
                        <span className="region-name">
                            { region.name }
                            <span className={ locale === "fr" ? "regionFrColon" : "regionEnColon" }></span>
                        </span>
                        { !isUserAdmin && institutionId ?
                            <span className="institution-name">{ institutions[institutionId].name }</span>
                        : null }
                    </div>
                    { isUserAdmin ? 
                        <div className="show-all-regions-button">
                            <FiqButton icon={ map } ionIcon color="white" fill="outline" onClick={ e => handleSelectRegion() }>{ localizedContent.showMap }</FiqButton>
                        </div>
                    : null }
                    
                </div>
                <div className="dates-filter-container">
                    <DateFilter page="declarations" />
                </div>
                <PageTitleActions count={ dateFilteredTsosCount + dateFilteredSubstitutionsCount } title={ localizedContent.declarations } dateFilters="declarations">
                    <ExcelButton page="declarations" />
                </PageTitleActions>
                <div className="locations-types-filter">
                    <LocationFilter page="declarations" regionId={ regionId } institutionId={ institutionId } />
                    <DeclarationsTypeFilter page="declarations" tsosCount={ locationFilteredTsosCount } substitutionsCount={ locationFilteredSubstitutionsCount } />
                </div>
                <SortableTable
                    entries={ sortableFilteredDeclarations }
                    defaultSorting={{ sort: 'urgent', ascending: false }}
                    onEntryClick={ handleDeclarationOpen } columns={ tableColumns }
                />
            </div>
            <SimpleModalController position="right" opened={ openedDeclaration !== null } onClose={ handleDeclarationClose }>
                <OpenedDeclaration declarationType={openedDeclarationType} declarationData={ openedDeclaration } onClose={ handleDeclarationClose } />
            </SimpleModalController>
        </div>
	);
};

export default DesktopMapDeclarations;