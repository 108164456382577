import './TsoCauses.scss';
import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { DeclarationType, TsoCauseKey } from '../../models';
import useLocale from '../../hooks/useLocale';
import { TextField } from '@material-ui/core';
import useStoredDeclaration from '../../hooks/useStoredDeclaration';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { RootState } from '../../store/reducers';

const TsoCauses: React.FC<{ type: DeclarationType }> = ({ type }) => {

    const tsoCauses = useSelector((state:RootState) => state.app.tsoCauses);
    const locale = useLocale();
    const localizedContent = useLocalizedContent();
    const storedDeclaration = useStoredDeclaration(type);
    const storedTso = storedDeclaration.tso;

    const selectedCauseKeys = storedTso?.causes;
    const otherCause = storedTso?.cause_other || '';

    const handleCauseClick = (key:TsoCauseKey) => {
        const newArray = _.xor(selectedCauseKeys, [key]);
        storedDeclaration.setData({ causes: newArray.length ? newArray : undefined });
    }

    const handleOtherChange = (e:any) => {
        const update:any = {
            cause_other: e.target.value
        };
        if (selectedCauseKeys?.length === 0) {
            update.causes = undefined;
        }
        storedDeclaration.setData(update);
    }

    const handleNoneClick = () => {
        const update:any = {
            causes: selectedCauseKeys?.length === 0 ? undefined : []
        };
        if (otherCause !== '') {
            update.cause_other = '';
        }
        storedDeclaration.setData(update);
    }

	return (
        <div className="tso-causes">
            <div className="ion-text-center">
                <div className="causes-title" dangerouslySetInnerHTML={{__html: localizedContent.whyTheseOvertimeHours }} />
                <p className="causes-caption">{ localizedContent.selectAsManyAsNecessary }</p>
            </div>
            <div className="causes-list">
                { tsoCauses.map(cause => {
                    const selected = selectedCauseKeys?.indexOf(cause.key) > -1;
                    return (
                        <div key={ 'cause-' + cause.key } className={'selectable-btn' + (selected ? ' selected':'')} onClick={ e => handleCauseClick(cause.key) }>
                            <div className="selectable-btn-icon">
                                <img src={ cause.icon } alt={'icon-'+cause.key} />
                            </div>
                            <div className="selectable-btn-content">
                                <div className="selectable-btn-title">{ cause.name[locale] }</div>
                                { cause.description ? 
                                    <div className="selectable-btn-description">{ cause.description[locale] }</div>
                                : null }
                            </div>
                        </div>
                    );
                }) }
            </div>
            <TextField
                className={ 'cause-other' + (otherCause ? ' filled' : '')}
                label={ localizedContent.other }
                variant="outlined"
                fullWidth
                value={ otherCause }
                multiline
                rows={3}
                onChange={ handleOtherChange }
            />

            <div className="none-container">
                <div className={'selectable-btn' + (selectedCauseKeys?.length === 0 ? ' selected':'')} onClick={ e => handleNoneClick() }>
                    <div className="selectable-btn-content">
                        <div className="selectable-btn-title">{ localizedContent.iDontKnow }</div>
                    </div>
                </div>
            </div>
        </div>
	);
};

export default TsoCauses;