import React from 'react';

import './GraphicsLegend.scss';

interface LegendItem {
    color: string;
    label: string;
}

interface GraphicsLegendProps {
    legendItems: LegendItem[]
}

const GraphicsLegend:React.FC<GraphicsLegendProps> = ({ legendItems }) => {
    return (
        <div className="graphics-legend">
            { legendItems.map((legend, i) =>
                <div key={ 'legend-item-' + i } className="legend-item">
                    <span className="color" style={{ backgroundColor: legend.color }}></span>
                    <span className="label">{ legend.label }</span>
                </div>
            )}
        </div>
    );
}

export default GraphicsLegend;