import React from 'react';
import { ControlledIonModalContent } from '../ControlledIonModal';
import useLocale from './../../../hooks/useLocale';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import FiqButton from '../../buttons/FiqButton';


const UnsafeCareRedirectWarning:React.FC<ControlledIonModalContent> = ({ onClose }) => {
    const locale = useLocale();
    const unsafeCareUrl = useSelector((state:RootState) => state.app.data.configurations.unsafe_care_url)[locale];
    const localizedContent = useLocalizedContent();
    const isUnsafeExternal = true;

    return (
        <div className="ion-padding ion-text-center">
            <div className="ion-padding-bottom">
                <img alt="icon-redirect-external" src="/assets/icon/redirect-external.svg" height="46" />
            </div>
            <h2>{ localizedContent.dexeroRedirectTitle }</h2>
            <p dangerouslySetInnerHTML={{ __html: localizedContent.dexeroRedirectDescription }} />
            <div className="buttons-container">
                <FiqButton color="white" fill="outline" onClick={ onClose }>{ localizedContent.back }</FiqButton>
                <FiqButton href={ unsafeCareUrl } isExternal={ isUnsafeExternal } target="_blank" rel="noopener noreferrer" onClick={ onClose }>{ localizedContent.continue }</FiqButton>
            </div>
        </div>
    );
};

export default UnsafeCareRedirectWarning;