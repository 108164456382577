import React from 'react';
import usePrevious from '../hooks/usePrevious';

import './LoadingOverlay.scss';

interface LoadingOverlayProps {
    loading?: boolean;
    loadingLabel?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ loadingLabel, loading }) => {

    const prevLoadingLabel = usePrevious(loadingLabel);
    const renderedLabel = !loadingLabel && prevLoadingLabel ? prevLoadingLabel : loadingLabel;

	return (
        <div className={'loading-overlay' + (loading ? ' loading' : '') + (loadingLabel ? ' has-label' : '')}>
            <div className="loading-animation" />
            <div className="loading-label">
                <div className="loading-label-content">
                    { renderedLabel }
                </div>                
            </div>
        </div>
	);
};

export default LoadingOverlay;