import React, { useState } from 'react';
import SimpleModalController from './../modals/SimpleModalController';
import { FilteredPage } from '../../store/settings-actions';
import ExcelExportModal from '../modals/center-modals/ExcelExportModal';

import './ExcelButton.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface ExcelButtonProps {
    page: FilteredPage;
}

const ExcelButton: React.FC<ExcelButtonProps> = ({ page }) => {

    const [modalOpened, setModalOpened] = useState(false);
    const localizedContent = useLocalizedContent();
    
	const openModal = () => {
		setModalOpened(true);
	};

	const closeModal = () => {
		setModalOpened(false);
    }

	return (
        <div className="excel-button">
            <button type="button" className="open-modal-button" onClick={ openModal }>
                <div className="content">
                    <img src="/assets/icon/excel.svg" alt="icon-excel" width="26" height="24" />
                    <span>{ localizedContent.excelExport }</span>
                </div>
                <div className="arrow" />
            </button>
            <SimpleModalController opened={ modalOpened } onClose={ closeModal } >
				<ExcelExportModal page={ page } onClose={ closeModal } />
			</SimpleModalController>
        </div>        
	);
};

export default ExcelButton;