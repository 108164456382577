import React, { useEffect, useRef, useMemo } from 'react';
import { Declaration } from '../../models';
import Chart from 'chart.js';

import './DeclarationsForewarnStats.scss';
import GraphicsLegend from './GraphicsLegend';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface DeclarationsForewarnStatsProps {
    declarations: Declaration[]
}

const DeclarationsForewarnStats:React.FC<DeclarationsForewarnStatsProps> = ({ declarations }) => {

	const canvas = useRef(null);
	const localizedContent = useLocalizedContent();

	const noneStr = localizedContent.none;
	const andMoreStr = localizedContent.andMore;
	
	const forewarnPeriods = useMemo( () => {
		const forewarnsHours = declarations.map(declaration => declaration.was_forewarn ? declaration.forewarn_hours : 0);
		return [
			{
				label: noneStr,
				color: '#4D4D4D',
				count: forewarnsHours.filter(hours => !hours).length
			},
			{
				label: '0 - 8h',
				color: '#62D0FF',
				count: forewarnsHours.filter(hours => hours > 0 && hours < 8).length
			},
			{
				label: '8h - 16h',
				color: '#089BE3',
				count: forewarnsHours.filter(hours => hours >= 8 && hours < 16).length
			},
			{
				label: '16h - 24h',
				color: '#004481',
				count: forewarnsHours.filter(hours => hours >= 16 && hours < 24).length
			},
			{
				label: '24h ' + andMoreStr,
				color: '#001E54',
				count: forewarnsHours.filter(hours => hours >= 24).length
			}
		].filter(period => period.count);
	}, [declarations, noneStr, andMoreStr]);

    useEffect(() => {
		if (canvas.current) {
			new Chart(canvas.current.getContext('2d'), {
				type: 'doughnut',
				data: {
					datasets: [{
						borderWidth: 0,
						data: forewarnPeriods.map(preNotice => preNotice.count),
						backgroundColor: forewarnPeriods.map(preNotice => preNotice.color)
					}],
					labels: forewarnPeriods.map(preNotice => preNotice.label)
				},
				options: {
					cutoutPercentage: 20,
					aspectRatio: 1,
					legend: {
						display: false
					}
				}
			});
		}		
	}, [canvas, forewarnPeriods]);

    return (
        <div className={'declarations-forewarn-stats' + (!forewarnPeriods.length ? ' no-data' :'')}>
            <div className="declarations-forewarn-title">{ localizedContent.prenoticePeriod + ' (' + localizedContent.average + ')'}</div>
            <div className="declarations-forewarn-content">
                <div className="donut-container">
                    <canvas ref={ canvas } />
                </div>
                <div className="legend-container">
                    <GraphicsLegend legendItems={ forewarnPeriods } />
                </div>
            </div>
        </div>
    );
}

export default DeclarationsForewarnStats;