import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import useLocalizedContent from '../../hooks/useLocalizedContent';

import './LoginCta.scss';

const LoginCta: React.FC = () => {	

	const localizedContent = useLocalizedContent();
	const history = useHistory();

	const loginHref = '/login';

	const handleBackClick = (e: React.MouseEvent) => {
		e.preventDefault();		
		if (history.length > 2) {
			history.goBack()
		} else if (loginHref) {
			history.replace(loginHref, { direction: 'back' });
		}
	}

	return (
		<div className="login-cta">
			<span>{ localizedContent.alreadyMember }</span>
			<Link to={loginHref} onClick={ handleBackClick }>{ localizedContent.connectCta }</Link>
		</div>
	);
};

export default LoginCta;