import { IonContent, IonPage, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import DeclarationSent from '../../../components/declaration/DeclarationSent';
import { useHistory, useRouteMatch } from 'react-router';
import { DeclarationType } from '../../../models';
import { useDispatch } from 'react-redux';
import { clearDeclarationData } from '../../../store/post-actions';
import { updateStatusBar } from '../../../lib/status-bar';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import MobileFooter from '../../../components/mobile/MobileFooter';
import FiqButton from '../../../components/buttons/FiqButton';

const MobileDeclarationSent: React.FC = () => {

    const localizedContent = useLocalizedContent();
    const history = useHistory();
    const dispatch = useDispatch();
    const routeMatch = useRouteMatch();
    const type:DeclarationType = (routeMatch.params as any)?.type || 'tso';
    
    const handleHomeClick = () => {
        history.replace('/dashboard', { direction: 'back' });
    }

    // Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));

    useIonViewDidEnter(() => dispatch(clearDeclarationData(type)));

	return (
        <IonPage className="mobile-declaration-sent" data-page="declaration-sent">
            <IonContent>
                <div className="ion-padding flex-vertical-center ion-text-center">
                    <DeclarationSent type={type} />
                </div>
            </IonContent>
            <MobileFooter>
                <FiqButton expand="block" onClick={ handleHomeClick }>{ localizedContent.backToHome }</FiqButton>
            </MobileFooter>
        </IonPage>
	);
};

export default MobileDeclarationSent;