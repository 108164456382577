import { IonPage, useIonViewWillEnter } from '@ionic/react';
import React from 'react';

import { useDispatch } from 'react-redux';
import { loginMilitantBack } from '../../../store/auth-actions';
import { RouteComponentProps } from 'react-router';
import MobileHeader from '../../../components/mobile/MobileHeader';
import { updateStatusBar } from '../../../lib/status-bar';
import MilitantNoticed from '../../../components/login/MilitantNoticed';
import MilitantActions from '../../../components/login/MilitantActions';
import MobileContent from '../../../components/mobile/MobileContent';

const MobileLoginMilitant: React.FC<RouteComponentProps> = () => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));	

	const dispatch = useDispatch();

	function handleMilitantBack() {
		dispatch(loginMilitantBack());
	}	

	return (
		<IonPage>
			<MobileHeader onBackClick={ handleMilitantBack } />
			<MobileContent renderFooterContent={ () => <MilitantActions /> }>
				<MilitantNoticed />
			</MobileContent>
		</IonPage>
	);
};

export default MobileLoginMilitant;
