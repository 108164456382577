import { environment, ApiSuccessPayload } from './environment';
import { User, UserAdminCredentialsPayload, SocialTokens } from '../models';
import { LoginAdminRequestPayload, LoginRequestPayload, ResetAdminPasswordPayload } from '../store/auth-actions';

interface AuthPayload extends ApiSuccessPayload {
	data?: {
		user: User;
		token: string;
	};
}

export class AuthenticationService {

	async validateToken(token: string): Promise<AuthPayload> {
		const response = await fetch(`${environment.api}/authentication`, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
		});
		return response.status === 200 ? await response.json() : { data: null };
	}

	async login(payload: LoginRequestPayload): Promise<AuthPayload> {
		const response = await fetch(`${environment.api}/login`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload)
		});
		return response.status === 200 ? await response.json() : { data: null };
	}	

	async loginMilitant(token: string, device_id: string, final_url: string): Promise<{ data?: { url: string }}> {
		const response = await fetch(`${environment.api}/login/militant`, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },
			body: JSON.stringify({ device_id, final_url })
		});
		return response.status === 200 ? await response.json() : { data: null };
	}

	async loginMilitantSkip(token: string): Promise<AuthPayload> {
		const response = await fetch(`${environment.api}/login/militant/skip`, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + token }
		});
		return response.status === 200 ? await response.json() : { data: null };
	}

	async loginSocial(socialTokens: SocialTokens, device_id: string):Promise<AuthPayload> {
		const response = await fetch(`${environment.api}/authentication/social`, {
			method: "POST",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({...socialTokens, device_id })
		});
		return response.status === 200 ? await response.json() : { data: false };
	}

	async loginAdmin(payload: LoginAdminRequestPayload): Promise<AuthPayload> {
		const response = await fetch(`${environment.api}/login/admin`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload)
		});
		return response.status === 200 ? await response.json() : { data: null };
	}

	async logout(token: string): Promise<AuthPayload> {
		const response = await fetch(`${environment.api}/logout`, {
			method: 'POST',
			headers: { Authorization: 'Bearer '+ token, 'Content-Type': 'application/json' }
		});
		return response.status === 200 ? await response.json() : { data: null };
	}

	async resetAdminPassword(payload: ResetAdminPasswordPayload): Promise<ApiSuccessPayload> {
		const response = await fetch(`${environment.api}/admin/reset-password`, {
			method: 'POST',
			body: JSON.stringify(payload)
		});
		return response.status === 200 ? await response.json() : { data: null };
	}

	async updateAdminCredentials(token: string, payload: UserAdminCredentialsPayload): Promise<AuthPayload> {
		const response = await fetch(`${environment.api}/profile/credentials`, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },
			body: JSON.stringify( payload )
		});
		return response.status === 200 ? await response.json() : { data: null };
	}

	async addSocialTokensToUser(token:string, socialTokens: SocialTokens):Promise<AuthPayload> {
		const response = await fetch(`${environment.api}/authentication/add/social`, {
			method: "POST",
			headers: { Authorization: 'Bearer '+ token, 'Content-Type': 'application/json' },
			body: JSON.stringify(socialTokens)
		});
		return response.status === 200 ? await response.json() : { data: false };
	}	

	async addFirebaseToken(token: string, firebaseToken: string):Promise<AuthPayload> {
		const response = await fetch(`${environment.api}/authentication/add/firebase`, {
			method: "POST",
			headers: { Authorization: 'Bearer '+ token, 'Content-Type': 'application/json' },
			body: JSON.stringify({ token: firebaseToken })
		});
		return response.status === 200 ? await response.json() : { data: false };
	}

	/*
	async removeFirebaseToken(token: string, firebaseToken: string):Promise<AuthPayload> {
		const response = await fetch(`${environment.api}/authentication/delete/firebase`, {
			method: "POST",
			headers: { Authorization: 'Bearer '+ token, 'Content-Type': 'application/json' },
			body: JSON.stringify({ token: firebaseToken })
		});
		return response.status === 200 ? await response.json() : { data: false };
	}
	*/
}

export const authentication = new AuthenticationService();
