import React from 'react';
import ScrollablePage from '../../../components/ScrollablePage';
import LoginAdminForm from '../../../components/login/LoginAdminForm';

const DesktopLoginAdmin:React.FC = () => {
    
    return (
        <ScrollablePage>
            <LoginAdminForm />
        </ScrollablePage>
    );
};

export default DesktopLoginAdmin;