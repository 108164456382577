import React from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DesktopDashboardPages from './DesktopDashboardPages';
import DesktopLoginPages from './DesktopLoginPages';

import './DesktopPages.scss';

const DesktopPages:React.FC = () => {
    
    const location = useLocation();
    const loginPages = location.pathname.startsWith('/login');
    
    return (
        <div className="desktop-pages">
            <TransitionGroup component={ null }>
                <CSSTransition key={ loginPages ? 'login' : 'dashboard' } timeout={200}>
                    <div className="desktop-view">
                        { loginPages ?
                            <DesktopLoginPages />
                        :
                            <DesktopDashboardPages />
                        }
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
};

export default DesktopPages;