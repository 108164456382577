import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilteredPage } from '../../../store/settings-actions';
import { RootState } from '../../../store/reducers';
import { DatesFilter } from './../../../store/reducers/settings';
import DatesSelector from './../../stats/DatesSelector';
import CheckboxButtons from './../../buttons/CheckboxButtons';
import FiqButton from '../../buttons/FiqButton';
import BasicModal from './BasicModal';

import './ExcelExportModal.scss';
import { exportEntries } from '../../../store/entries-actions';
import { TestimonialStatus, ExportableEntryType } from '../../../models';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useLocale from '../../../hooks/useLocale';

interface ExcelExportModal {
    page: FilteredPage;
    onClose?():void;
}

const ExcelExportModal: React.FC<ExcelExportModal> = ({ page, onClose }) => {

    const locale = useLocale();
    const localizedContent = useLocalizedContent();
    const dispatch = useDispatch();
    const pageFilter = useSelector((state:RootState) => state.settings.pageFilters[page]);
    const datesFilter = pageFilter as DatesFilter;
    const [dates, setDates] = useState({ from: datesFilter?.date_from, to: datesFilter?.date_to });
    const [declarationTypeIndexes, setDeclarationTypeIndexes] = useState([0,1]);
    const [testimonialStatusIndexes, setTestimonialStatusIndexes] = useState([0,1]);     

    const declarationTypeChoices: { type: ExportableEntryType, label: string }[] = [
        {
            type: 'tsos',
            label: localizedContent.tso
        },
        {
            type: 'substitutions',
            label: localizedContent.workSubstitution
        }
    ];

    const testimonialStatusChoices: { type: string, label: string, statuses: TestimonialStatus[] }[] = [
        {
            type: 'active',
            label: localizedContent.actives,
            statuses: ['read', 'unread']
        },
        {
            type: 'archived',
            label: localizedContent.archived,
            statuses: ['archived']
        }
    ];

    const validDeclarationTypes = !!declarationTypeIndexes.length;
    const validTestimonialStatuses = !!testimonialStatusIndexes.length;
    const validForm = 
        page === 'declarations' ?
            validDeclarationTypes
        : page === 'testimonials' ?
            validTestimonialStatuses
        :
            validDeclarationTypes || validTestimonialStatuses;

    

    const handleDatesChange = (datesRange: DatesFilter) => {
        setDates({
            from: datesRange.date_from,
            to: datesRange.date_to
        });
    }

    const handleDeclarationTypeChange = (selectedIndexes: number[]) => {
        setDeclarationTypeIndexes(selectedIndexes);
    }

    const handleTestimonialStatusChange = (selectedIndexes: number[]) => {
        setTestimonialStatusIndexes(selectedIndexes);
    }

    const handleExportClick = () => {

        const filters = {
            date_from: dates.from?.format('YYYY-MM-DD'),
            date_to: dates.to?.format('YYYY-MM-DD'),
            status: page !== 'declarations' ? testimonialStatusIndexes.map( index => testimonialStatusChoices[index].statuses).reduce((acc, val) => acc.concat(val), []) : []
        };

        const types = page === 'dashboard' || page === 'declarations' ? declarationTypeIndexes.map( index => declarationTypeChoices[index].type) : [];
        
        if ((page === 'dashboard' || page === 'testimonials') && testimonialStatusIndexes.length) {
            types.push('testimonials');
        }

        dispatch(exportEntries({
            filters, types
        }));
    }
    
    const headerString = localizedContent.export + ' ' + (
        page === 'dashboard' ?
            (locale === 'fr' ? 'le ' : '') + localizedContent.dashboard
        : page === 'declarations' ?
            (locale === 'fr' ? 'les ' : '') + localizedContent.declarations.toLowerCase()
        :
            (locale === 'fr' ? 'les ' : '') + localizedContent.testimonials.toLowerCase()
    ); 

	return (
        <div className="excel-export-modal">
            <BasicModal title={headerString} onClose={onClose}>
                <div className="date-filter-container">
                    <div className="filter-name">{ localizedContent.period }</div>
                    <DatesSelector dateFrom={ dates.from } dateTo={ dates.to } onChange={ handleDatesChange } />
                </div>
                { page === 'dashboard' || page === 'declarations' ?
                    <div className="page-filter">
                        <div className="filter-name">{ localizedContent.declarationTypes }</div>
                        <CheckboxButtons theme="standard" value={declarationTypeIndexes} choices={ declarationTypeChoices } onChange={ handleDeclarationTypeChange } />
                    </div>
                : null }
                { page === 'dashboard' || page === 'testimonials' ?
                    <div className="page-filter">
                        <div className="filter-name">{ localizedContent.testimonialStatuses }</div>
                        <CheckboxButtons theme="standard" value={testimonialStatusIndexes} choices={ testimonialStatusChoices} onChange={ handleTestimonialStatusChange } />
                    </div>
                : null }
                <div className="submit-container">
                    <FiqButton onClick={ e => handleExportClick() } disabled={ !validForm }>{ localizedContent.export }</FiqButton>
                </div>
            </BasicModal>
        </div>
	);
};

export default ExcelExportModal;