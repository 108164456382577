import { IonPage, useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import { updateStatusBar } from '../../../lib/status-bar';
import ForgotPassword from '../../../components/login/ForgotPassword';
import MobileContent from '../../../components/mobile/MobileContent';

const MobileForgotPassword: React.FC = () => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));
	
	return (
		<IonPage>
			<MobileContent>
				<ForgotPassword />
			</MobileContent>
		</IonPage>
	);
};

export default MobileForgotPassword;