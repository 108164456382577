import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { isPlatform } from '@ionic/react';
import { isNumberValid } from '../hooks/useNumberValidation';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';

interface TextFieldNumberProps {
    value?: string;
    onChange?(value: string): void;
}

const TextFieldNumber: React.FC<TextFieldNumberProps> = ({ value, onChange }) => {

    const deviceInfo = useSelector((state: RootState) => state.app.deviceInfo);
    const isLGphone = deviceInfo?.model?.includes('LG-');
    const isNative = isPlatform('capacitor') && !isLGphone;

    const [controlledValue, setControlledValue] = useState(value);

    const onBeforeChange = (newValue: string) => {
        newValue = isNative ? newValue : newValue.replace(',', '.');
        const valid = isNumberValid(newValue);
        newValue = valid ? newValue : value;
        onChange(newValue);
        setControlledValue(newValue);
    } 

	return (
        <TextField
            fullWidth
            type={ isNative ? 'number' : 'text'}
            inputProps={ isNative ? {
                step: 0.01,
                min: 0.0,
                inputMode: 'decimal'
            } : undefined }
            variant="outlined"
            value={ controlledValue }
            onChange={ e => onBeforeChange(e.target.value) }
        />
	);
};

export default TextFieldNumber;