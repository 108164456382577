import { useRef, useEffect } from 'react';

type Hook = (value:any) => any

const usePrevious:Hook = (value) => {

    const ref = useRef(null);

	useEffect(() => {
		ref.current = value;
    }, [value]);
    
	return ref.current;
}

export default usePrevious;