import { useMemo } from 'react';
import useLocale from './useLocale';
import localeStrings from '../data/locale-strings.json';
import { LocalizedContent, LocaleStrings, Locale } from '../models';

type Hook = (locale?: Locale) => LocalizedContent

const useLocalizedContent:Hook = (locale) => {
    const userLocale = useLocale();
    locale = locale ? locale : userLocale;
    const localizedContent = useMemo( () => {
        const content:LocalizedContent = {};
        for (let key in localeStrings) {
            content[key] = (localeStrings as LocaleStrings)[key][locale];
        }
        return content;
    }, [locale]);
    return localizedContent;
}

export default useLocalizedContent;