import React from 'react';
import { close } from 'ionicons/icons';

import './FixedBackButton.scss';
import TrueBackButton from './TrueBackButton';

interface FixedBackButtonProps {
    hideBackground?: boolean;
    defaultHref?: string;
}

const FixedBackButton: React.FC<FixedBackButtonProps> = ({ hideBackground, defaultHref = '/dashboard' }) => {
    return (
        <div className={'fixed-back-button' + (hideBackground ? ' hide-background':'')}>
            <TrueBackButton icon={close} defaultHref={ defaultHref } buttonProps={{ fill: 'clear' }} />
        </div>
    )
};

export default FixedBackButton;