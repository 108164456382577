import React from 'react';
import { AbleToWorkPageProps } from '../../hooks/useAbleToWorkForm';
import FiqButton from '../buttons/FiqButton';

import './AbleToWorkChoice.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

const AbleToWorkChoice: React.FC<AbleToWorkPageProps> = ({ setAbleToWork }) => {

    const localizedContent = useLocalizedContent();

	return (
		<div className="able-to-work-choice">
            <div className="choice-container">
                <h2 className="choice-title" dangerouslySetInnerHTML={{__html: localizedContent.iAmAbleToWorkTitle }} />
                <div className="choice-description">{ localizedContent.iAmAbleToWorkSubtitle }</div>
                <FiqButton expand="block" onClick={ e => setAbleToWork(true)}>{ localizedContent.whatAreTheConsequences }</FiqButton>
            </div>
            <div className="choice-container">
                <h2 className="choice-title" dangerouslySetInnerHTML={{ __html: localizedContent.iAmNotAbleToWorkTitle }} />
                <FiqButton expand="block" onClick={ e => setAbleToWork(false)}>{ localizedContent.whatAreTheConsequences }</FiqButton>
            </div>
		</div>
	);
};

export default AbleToWorkChoice;