import React from 'react';
import ScrollablePage from '../../../components/ScrollablePage';
import CreateAccount from '../../../components/login/CreateAccount';
import LoginCta from '../../../components/login/LoginCta';

const DesktopCreateAccount:React.FC = () => {
    
    return (
        <ScrollablePage
            headerBackUrl="/login"
            renderFooter={ () => <LoginCta /> }
        >
            <CreateAccount />
        </ScrollablePage>
    );
};

export default DesktopCreateAccount;