import React from 'react';
import './CountTitle.scss';

interface CountTitleProps {
    count: number;
    title: string;
    titleSize?: number;
}

const CountTitle: React.FC<CountTitleProps> = ({ count, title, titleSize = 20 }) => {
	return (
        <div className="count-title">
            <div className="count-title-count">{ count }</div>
            <div className="count-title-title" style={{ fontSize: titleSize }}>{ title }</div>
        </div>
	);
};

export default CountTitle;