import React, { useRef } from 'react';
import FiqButton from '../../buttons/FiqButton';
import gsap, { Quad } from 'gsap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import TestimonialForm from '../../common-content/TestimonialForm';
import TestimonialSent from '../../common-content/TestimonialSent';

import './NewTestimonialModal.scss';
import useLocalizedContent from '../../../hooks/useLocalizedContent';

interface NewTestimonialButtonProps {
    testimonialSent: boolean;
    onClose?(): void;
}

const NewTestimonialModal: React.FC<NewTestimonialButtonProps> = ({ testimonialSent, onClose }) => {
    
    const localizedContent = useLocalizedContent();
	const el = useRef(null);
	const newTestimonialSentTransitionDuration = 250;
	const handleTestimonialSentTransition = (node:HTMLElement) => {
        gsap.from(el.current, {
            height: node.offsetHeight,
            duration: newTestimonialSentTransitionDuration / 1000,
            ease: Quad.easeInOut
        });
	}

    return (
        <div className="new-testimonial-modal" ref={el}>
            <TransitionGroup component={null}>
                <CSSTransition
                    key={ 'testimonial' + (testimonialSent ? '-sent' : '') }
                    timeout={newTestimonialSentTransitionDuration}
                    onExit={ handleTestimonialSentTransition
                }>
                    <div className="testimonial-view">
                        {!testimonialSent ?
                            <div className="testimonial-form-container">
                                <div className="testimonial-form-title">{ localizedContent.yourTestimonial }</div>
                                <TestimonialForm rows={7} />
                            </div>
                        :
                            <div className="testimonial-sent-container">
                                <TestimonialSent />
                                <FiqButton onClick={ onClose }>{ localizedContent.back }</FiqButton>
                            </div>
                        }
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
};

export default NewTestimonialModal;



