import React, { useRef, useEffect, useState } from 'react';
import { IonModal } from '@ionic/react';
import usePrevious from '../../hooks/usePrevious';

import './ControlledIonModal.scss';

export interface ControlledIonModalContent {
    onClose?(): void;
}

interface ControlledIonModalProps {
    opened: boolean;
    type?: 'bottom' | string;
    onClose(): void;
}

const ControlledIonModal:React.FC<ControlledIonModalProps> = ({ opened, type, onClose, children }) => {

    // All of this because Ionic is not thinking in react
    // When we simply set isOpen to false, it does not trigger an animation
    // We MUST use IonModal's dismiss method
    const el = useRef(null);
    const previousOpened = usePrevious(opened);
    const [modalOpened, setModalOpened] = useState(opened);
    const [mounted, setMounted] = useState(true);

    const handleModalDismissed = () => {
        if (mounted) {
            setModalOpened(false);
            onClose();
        }        
    }

    useEffect( () => {
        return () => {
            setMounted(false);
        };
    }, []); 

    useEffect( () => {
        if (previousOpened && !opened && modalOpened) {
            (el as any).current?.dismiss();
        }

        if (!previousOpened && opened) {
            setModalOpened(true);
        }
    }, [opened, previousOpened, modalOpened]);

    return (
        <IonModal ref={ el } isOpen={ modalOpened } cssClass={ 'controlled-ion-modal type-' + (type ? type : 'center') } onDidDismiss={ handleModalDismissed }>
            { children }
        </IonModal>
    );
};

export default ControlledIonModal;