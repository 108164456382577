import React, { useEffect, useRef, useState } from 'react';
import QuebecRegions from '../assets/QuebecRegions';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import './QuebecMap.scss';
import { Storage } from '@capacitor/core';
import gsap from 'gsap';
import useElementSize from './../hooks/useElementSize';
import useIsMobile from './../hooks/useIsMobile';

interface QuebecMapProps {
    region_number?: number;
    selectable?: boolean;
    onZoomChange?(data:any): void;
    onPanning?(data:any): void;
    onPanningStop?(data:any): void;
    onSelectableRegionEnter?(regionNumber: number): void;
    onSelectableRegionLeave?(regionNumber: number): void;
    onSeletableRegionClick?(regionNumber: number): void;
}

const REGION_PADDING = 10;

const QuebecMap: React.FC<QuebecMapProps> = ({ region_number, selectable, onZoomChange, onPanning, onPanningStop, onSelectableRegionEnter, onSelectableRegionLeave, onSeletableRegionClick }) => {
    const el = useRef(null);
    const elSize = useElementSize(el);
    const hasSize = !!elSize?.width;
    const svgWidth = 1029.3;
    const svgHeight = 1316.9;
    const [viewBox, setViewBox] = useState('0 0 '+svgWidth+' '+svgHeight);
    const [defaultPanzoomData, setDefaultPanzoomData] = useState({ scale: null, x: null, y: null })
    const [selectableMapReady, setSelectableMapReady] = useState(false);
    const [exiting, setExiting] = useState(false);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (!isMobile || hasSize) {
            const currentEl = el.current;
            const regions:NodeListOf<SVGGElement> = currentEl.querySelectorAll('g.region');        

            const getRegionNumber = (region:SVGGElement):number => {
                return parseInt(region.id.split('_')[1]);
            };

            // if we have a region_number, we show it as selected on the map
            if (region_number !== undefined) {
                let selectedRegion:SVGGElement;
                regions.forEach(region => {
                    if (getRegionNumber(region) === region_number) {
                        selectedRegion = region;
                        const rbox = region.getBBox();
                        setViewBox([(rbox.x - REGION_PADDING), (rbox.y - REGION_PADDING), (rbox.width + REGION_PADDING * 2), (rbox.height + REGION_PADDING * 2)].join(' '));
                        region.classList.add('selected');
                        updatePathStrokes(el.current, rbox.width > 150 || rbox.height > 150 ? 2 : 1);
                    }
                });
                return () => {
                    selectedRegion?.classList.remove('selected');
                };
            // if not and the map is selectable, we listen clicks on the regions
            } else if (selectable && selectableMapReady) {

                updatePathStrokes(el.current, defaultPanzoomData.scale > 7 ? 1 : 2);

                // preventing region clicks when are moving the map
                let mouseMoved = false;
                const handleRegionDown = (e:any) => {
                    mouseMoved = false;
                }

                const handleRegionMove = (e:any) => {
                    if (!mouseMoved) {
                        mouseMoved = true;
                    }                
                }

                const handleRegionClick = (e:MouseEvent) => {
                    if (!mouseMoved) {
                        const target = e.currentTarget as SVGGElement;
                        if (onSeletableRegionClick) {
                            setExiting(true);
                            onSeletableRegionClick(getRegionNumber(target));
                        }
                    }
                };

                const handleRegionEnter = (e:MouseEvent) => {                
                    const target = e.currentTarget as SVGGElement;
                    if (onSelectableRegionEnter) {
                        onSelectableRegionEnter(getRegionNumber(target));
                    }
                }

                const handleRegionLeave = (e:MouseEvent) => {
                    const target = e.currentTarget as SVGGElement;
                    if (onSelectableRegionLeave) {
                        onSelectableRegionLeave(getRegionNumber(target));
                    }
                }

                regions.forEach(region => {
                    currentEl.addEventListener('touchstart', handleRegionDown);
                    currentEl.addEventListener('mousedown', handleRegionDown);
                    currentEl.addEventListener('mousemove', handleRegionMove);
                    currentEl.addEventListener('touchmove', handleRegionMove);
                    region.addEventListener('mouseenter', handleRegionEnter);
                    region.addEventListener('mouseleave', handleRegionLeave);
                    region.addEventListener('click', handleRegionClick);
                });

                return () => {
                    regions.forEach(region => {
                        currentEl.removeEventListener('touchstart', handleRegionDown);
                        currentEl.removeEventListener('mousedown', handleRegionDown);
                        currentEl.removeEventListener('mousemove', handleRegionMove);
                        currentEl.removeEventListener('touchmove', handleRegionMove);
                        region.removeEventListener('mouseenter', handleRegionEnter);
                        region.removeEventListener('mouseleave', handleRegionLeave);
                        region.removeEventListener('click', handleRegionClick);
                    });
                };
            }
        }
    }, [el, region_number, selectable, onSelectableRegionEnter, onSelectableRegionLeave, onSeletableRegionClick, selectableMapReady, defaultPanzoomData, hasSize, isMobile]);

    const renderSvg = () => (
        <svg width={ svgWidth + 'px' } height={ svgHeight + 'px' } viewBox={ viewBox } >
            <QuebecRegions />
        </svg>
    )

    const updatePathStrokes = (el:HTMLDivElement, width: number) => {
        const paths:NodeListOf<SVGGElement> = el.querySelectorAll('svg path');
        gsap.set(paths, { strokeWidth: width });
    }

    useEffect( () => {
        const getPanZoomData = async () => {
            const { value } = await Storage.get({ key: 'panzoom'});
            const panzoomData = JSON.parse(value);
            setDefaultPanzoomData({ scale: panzoomData?.scale || 1, x: panzoomData?.positionX || undefined, y: panzoomData?.positionY || undefined });
            setSelectableMapReady(true);
        }
       getPanZoomData();
    }, []);

    const updatePanzoomData = async (data: any) => {
        if (!exiting) {
            const { scale, positionX, positionY } = data;
            const panzoomData = { scale, positionX, positionY };
            await Storage.set({ key: 'panzoom', value: JSON.stringify(panzoomData)});
        }        
    }

    const handleZoomChange = (data:any) => {
        updatePanzoomData(data);

        updatePathStrokes(el.current, data.scale > 7 ? 1 : 2);

        if (onZoomChange) {
            onZoomChange(data);
        }
    }

    const handlePanning = (data:any) => {
        updatePanzoomData(data);

        if (onPanning) {
            onPanning(data);
        }
    }

    const updateZoom = (direction: number) => {
        var evt = document.createEvent('MouseEvents') as any;
        evt.initEvent('wheel', true, true); 
        evt.deltaY = -direction;
        [...Array(5)].forEach(i => {
            el.current.querySelector('svg').dispatchEvent(evt);
        })
    }

    return (
        <div className={'quebec-map' + (selectable ? ' selectable':'') } ref={el}>
            { selectable ? 
                ( selectableMapReady ? 
                    <>
                        <TransformWrapper
                            defaultScale={ defaultPanzoomData.scale }
                            defaultPositionX={ defaultPanzoomData.x }
                            defaultPositionY={ defaultPanzoomData.y }
                            onZoomChange={ handleZoomChange }
                            onPanning={ handlePanning }
                            onPanningStop={ onPanningStop }
                            options={{ maxScale: 30, limitToBounds: false }}
                            scalePadding={{ animationTime: 0 }}
                            pan={{ velocitySensitivity: 0, velocityBaseTime: 0, animationTime: 0 }}
                            zoomIn={{ animationTime: 0 }}
                            zoomOut={{ animationTime: 0 }}
                            doubleClick={{ animationTime: 0 }}
                            reset={{ animationTime: 0 }}
                        >
                            <TransformComponent>
                                { renderSvg() }
                            </TransformComponent>
                        </TransformWrapper>
                        <div className="zoom-buttons">
                            <button className="plus" onClick={ e => updateZoom(1)}>
                                <span className="icon" />
                            </button>
                            <button className="minus" onClick={ e => updateZoom(-1)}>
                                <span className="icon" />
                            </button>
                        </div>
                    </>
                : null)               
            : !isMobile || hasSize ? renderSvg() : null }
        </div>
    );
};
export default QuebecMap;