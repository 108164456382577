import React from 'react';

import './ProgressBarsStats.scss';
import { IonIcon } from '@ionic/react';
import { list } from 'ionicons/icons';

export interface ProgressBarData {
    label: string;
    count: number;
    color?: string;    
    icon?: string;
    onMenuClick?(): void;
}

interface ProgressBarsStatsProps {
    title: string;
    data: ProgressBarData[];
    totalCount?: number;
}

const ProgressBarsStats:React.FC<ProgressBarsStatsProps> = ({ title, data, totalCount }) => {

    if (!totalCount) {
        totalCount = 0;
        data.forEach(singleData => totalCount += singleData.count);
    } else {
        totalCount = Math.max(0, totalCount);
    }

    return (
        <div className="progress-bars-stats">
            <div className="progress-bars-stats-title">{ title }</div>
            <div className="progress-bars-stats-content">
                { data.map((singleData, i) => {
                    const percent = Math.round(Math.min(singleData.count, totalCount) / totalCount * 100 || 0) + '%';
                    return (
                        <div key={ 'data-' + i } className="single-data">
                            <div className="type" style={{ backgroundColor: singleData.color}}>
                                { singleData.icon ?
                                    <div className="type-icon" style={{backgroundImage: singleData.icon ? 'url(' + singleData.icon + ')': undefined }}/>
                                :
                                    <div className="type-count">{ i + 1 }</div>
                                }
                            </div>
                            <div className="content">
                                <div className="progress" style={{ backgroundColor: singleData.color, width: percent }} />
                                <div className="text">
                                    <div className="label">{ singleData.label }</div>
                                    <div className="value">{ percent }</div>
                                </div>
                                { singleData.onMenuClick ? 
                                    <button type="button" className="menu" onClick={ singleData.onMenuClick }>
                                        <IonIcon icon={ list } />
                                    </button>
                                : null }
                            </div>
                        </div>
                    );
                }) }
            </div>
        </div>
    );
}

export default ProgressBarsStats;