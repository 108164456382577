import React from 'react';
import { IonPage, IonContent, useIonViewWillEnter } from '@ionic/react';
import MobileHeader from '../../../components/mobile/MobileHeader';
import { useHistory } from 'react-router';
import TestimonialForm from '../../../components/common-content/TestimonialForm';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import usePrevious from '../../../hooks/usePrevious';
import { updateStatusBar } from '../../../lib/status-bar';
import useLocalizedContent from '../../../hooks/useLocalizedContent';

import "./MobileTestimonials.scss";
import MobileUnsupportedSection from '../../../components/mobile/MobileUnsupportedSection';

const MobileTestimonials: React.FC = () => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('blue'));
	const localizedContent = useLocalizedContent();
	const user = useSelector((state:RootState) => state.auth.user);
	const isUserAdmin = user.is_admin || false;
	const history = useHistory();
	const testimonial = useSelector((state:RootState) => state.post.testimonial);
	const testimonialSent = testimonial?.sent || false;
	const testimonialJustSent = !usePrevious(testimonialSent) && testimonialSent;

	useEffect( () => {
		if (testimonialJustSent) {
			history.push('/dashboard/testimonial-sent');
		}
	}, [history, testimonialJustSent]);

	return (
		<IonPage className="with-tabs mobile-testimonials" data-page="testimonials">
			<MobileHeader blue title={ localizedContent.testimonial } />
			<IonContent>
				{ !isUserAdmin ?
					<TestimonialForm />
				: 
					<MobileUnsupportedSection />
				}
			</IonContent>
		</IonPage>		
	);
};

export default MobileTestimonials;