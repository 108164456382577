import React from 'react';
import { ReactComponent as SortIconBottom } from '../../assets/sort-icon-bottom.svg';

import './SortButton.scss';

interface SortButtonProps {
    active: boolean;
    ascending?: boolean;
    onClick?():void;
}

const SortButton: React.FC<SortButtonProps> = ({ active, ascending, onClick }) => {

    return (
        <button type="button" className={'sort-button' + (active ? ' active' + (ascending ? ' ascending' : ' descending') : '')} onClick={ onClick }>
            <SortIconBottom className="up" />
            <SortIconBottom className="down" />
        </button>
    );
};

export default SortButton;