import React, { useState } from 'react';
import { chevronBack } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';

import './TrueBackButton.scss';

interface TrueBackButtonProps {
    icon?: any;
    defaultHref?: string;
    onClick?(): void;
    buttonProps?: any;
    iconProps?:any;
    disabled?: boolean;
}

const TrueBackButton:React.FC<TrueBackButtonProps> = ({ icon = chevronBack, defaultHref, onClick, buttonProps, iconProps, disabled }) => {

    const history = useHistory();
    const [locallyDisabled, setLocallyDisabled] = useState(false);

    const handleClick = () => {        
        if (onClick) {
            onClick();
        } else {
            setLocallyDisabled(true);
            if (history.length > 2) {
                history.goBack()
            } else if (defaultHref) {
                history.replace(defaultHref, { direction: 'back' });
            }
        }
    }

    return (
        <IonButton disabled={ disabled || locallyDisabled } className="true-back-button" onClick={ handleClick } { ...buttonProps } >
            <IonIcon icon={ icon } { ...iconProps } />
        </IonButton>
    );
}

export default TrueBackButton;