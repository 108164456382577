import React from 'react';

import FiqButton from '../buttons/FiqButton';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers';
import { login } from '../../store/auth-actions';

import './LoginFormSubmit.scss';

interface LoginFormSubmitProps {
    create?: boolean;
}

const LoginFormSubmit: React.FC<LoginFormSubmitProps> = ({ create }) => {

    const localizedContent = useLocalizedContent();
	const userLoginInfos = useSelector((state:RootState) => state.settings.userLoginInfos);
	const dispatch = useDispatch();
	
    const socialTokens = useSelector((state:RootState) => state.auth.socialTokens);
    const canSubmit = userLoginInfos.first_name && userLoginInfos.last_name && userLoginInfos.registration_number && userLoginInfos.institution_id;

    const logIn = async() => {
		if (canSubmit) {
			dispatch(login(userLoginInfos, socialTokens));
		}
	};

	return (
		<div className="login-form-submit">
			<FiqButton expand="block" disabled={ !canSubmit } onClick={ e => { e.preventDefault(); logIn(); }}>
                { create ? localizedContent.createAccount : localizedContent.loginCta }
            </FiqButton>
		</div>
	);
};

export default LoginFormSubmit;