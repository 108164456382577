import { SettingsActionTypes, Tutorial } from '../settings-actions';
import { UserLoginInfos, DeclarationType, DeclarationStatus, TestimonialStatus, LocaleString } from '../../models';
import { Moment } from 'moment';

export type DeclarationFilterStatus = DeclarationStatus | 'urgencies';

export interface LocationsFilter {
	region_id?: number;
	institution_id?: number;
	installation_id?: number;
}

export interface DatesFilter {
	date_from?: Moment;
	date_to?: Moment;
	range_type?: number;// 0 = last week, 1 = last month, 2 = custom range ( when the app is opened, it starts undefined but the assumed default is 1 = last month)
}

export interface DashboardFilters extends LocationsFilter, DatesFilter {}

export interface DeclarationsFilter extends LocationsFilter, DatesFilter {
	declaration_types?: DeclarationType[];
	statuses?: DeclarationFilterStatus[];// inverted filter (values inside are filtered out)
}

export interface TestimonialsFilter extends DatesFilter {
	status?: TestimonialStatus;
}

interface SettingsState {
	updating: boolean;	
	ready: boolean;
	tutorialsRead: Tutorial[];
	pageFilters: {
		dashboard: DashboardFilters;
		declarations: DeclarationsFilter;
		testimonials: TestimonialsFilter;
	};
	error?: LocaleString;
	locale?: string;
	email?: string;
	userLoginInfos? : UserLoginInfos;
	notificationsAccepted?: boolean;
	notificationsUnsupported?: boolean;
}

const settings = (
	state: SettingsState = {
		updating: false,
		ready: false,		
		tutorialsRead: [],
		pageFilters: {
			dashboard: {},
			declarations: {},
			testimonials: {}
		}
	},
	action: any
) => {
	switch (action.type) {
		case SettingsActionTypes.updating:
			return {
				...state,
				updating: true,
				error: undefined
			};
		case SettingsActionTypes.loadSuccess:
			return {
				...state,
				updating: false,
				ready: true
			};
		case SettingsActionTypes.updateFailure:
			return {
				...state,
				updating: false,
				error: action.error as LocaleString
			};
		case SettingsActionTypes.updateSuccess:
			return {
				...state,
				updating: false
			};
		case SettingsActionTypes.updateLocale:
			return {
				...state,
				locale: action.payload
			};
		case SettingsActionTypes.updateEmail:
			return {
				...state,
				email: action.payload
			};
		case SettingsActionTypes.updateUserLoginInfos:
			return {
				...state,
				userLoginInfos: {...state.userLoginInfos, ...action.payload } as UserLoginInfos
			};
		case SettingsActionTypes.updateReadTutorials:
			return {
				...state,
				tutorialsRead: action.payload
			};
		case SettingsActionTypes.updatePageFilters:
			return {
				...state,
				pageFilters: {...state.pageFilters, [action.payload.page] : {...(state.pageFilters as any)[action.payload.page], ...action.payload.filter } }
			};
		case SettingsActionTypes.setNotificationsAccepted:
			return {
				...state,
				notificationsAccepted: true
			};
		case SettingsActionTypes.setNotificationsRefused:
			return {
				...state,
				notificationsAccepted: false
			};
		case SettingsActionTypes.setNotificationsUnsupported:
			return {
				...state,
				notificationsUnsupported: true
			};
		default:
			return state;
	}
};
export default settings;
