import { IonContent, IonPage, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import TestimonialSent from '../../../components/common-content/TestimonialSent';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { clearTestimonialData } from '../../../store/post-actions';
import { updateStatusBar } from '../../../lib/status-bar';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import MobileFooter from '../../../components/mobile/MobileFooter';
import FiqButton from '../../../components/buttons/FiqButton';

const MobileTestimonialSent: React.FC = () => {

	const history = useHistory();
	const dispatch = useDispatch();
	const localizedContent= useLocalizedContent();

	const handleHomeClick = () => {
        history.replace('/dashboard', { direction: 'back' });
	}

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));	
	useIonViewDidEnter(() => dispatch(clearTestimonialData()));

	return (
		<IonPage className="mobile-testimonial-sent" data-page="testimonial-sent">
			<IonContent>
				<div className="ion-padding flex-vertical-center">
                    <TestimonialSent />
                </div>
     		</IonContent>
			<MobileFooter>
				<FiqButton expand="block" onClick={ handleHomeClick }>{ localizedContent.backToHome }</FiqButton>
			</MobileFooter>
		</IonPage>
	);
};

export default MobileTestimonialSent;