import React from 'react';

import './OpenedDeclaration.scss';
import DeclarationDetails from '../../common-content/DeclarationDetails';
import SideModalCloseButton from '../../buttons/SideModalCloseButton';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { DeclarationType, Substitution, Tso } from '../../../models';



interface OpenedDeclarationProps {
    declarationType?: DeclarationType;
    declarationData?: Tso | Substitution;
    onClose?(): any
}

const OpenedDeclaration: React.FC<OpenedDeclarationProps> = ({ declarationType, declarationData, onClose }) => {

    const localizedContent = useLocalizedContent();
    
    return (
        <div className={ 'opened-declaration' + (declarationType ? ' ' + declarationType : '') }>
            <div className="declaration-content">
                <div className="close-button-container">
                    <SideModalCloseButton onClose={ onClose } label={ localizedContent.backToList } />
                </div>
                <div className="declaration-type">
                    { declarationType === 'tso' ? localizedContent.declarationTso : localizedContent.workSubstitution }
                </div>
                <DeclarationDetails type={ declarationType } declaration={ declarationData } />
            </div>            
        </div>
    );
};

export default OpenedDeclaration;