import React, { useEffect } from 'react';
import usePrevious from '../hooks/usePrevious';
import useLocale from '../hooks/useLocale';
import { LocaleString } from '../models';
import { Plugins } from '@capacitor/core';

const { Toast } = Plugins;

interface ToastErrorProps {
    error?: LocaleString;
}

const ToastError: React.FC<ToastErrorProps> = ({ error }) => {

    const locale = useLocale();
    const previousError = usePrevious(error);

    useEffect( () => {
        if (previousError !== null && previousError !== error && error) {
            Toast.show({
                text: error[locale],
                duration: 'long'
            });
        }
    }, [previousError, error, locale]);

	return null;
};

export default ToastError;