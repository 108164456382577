import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import usePrevious from "./usePrevious";
import { RootState } from "../store/reducers";
import { useEffect } from "react";

type Hook = () => void

const useSocialTokenRedirect:Hook = () => {    
    
    const history = useHistory();

    // Navigate to the form if we received a new social token	
	const socialTokens = useSelector((state:RootState) => state.auth.socialTokens);
    const previousSocialTokens = usePrevious(socialTokens);
    const justReceivedSocialToken = previousSocialTokens === undefined && previousSocialTokens !== socialTokens && !!socialTokens;

    useEffect( () => {
		if (justReceivedSocialToken) {
			history.push('/login/form');
		}
	}, [justReceivedSocialToken, history]);

    return;
}

export default useSocialTokenRedirect;