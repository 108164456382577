import React from 'react';
import PushNotificationsSettings from '../PushNotificationsSettings';
import useLocalizedContent from '../../hooks/useLocalizedContent';

import './ActivateNotifications.scss';

const ActivateNotifications:React.FC = () => {

	const localizedContent = useLocalizedContent();

    return (
        <div className="activate-notifications">
            <div className="notifications-icon-container">
                <img alt="icon-notifications" src="/assets/icon/notifications.svg" height="40" />
            </div>
            <h2>{ localizedContent.activateNotificationsQuestion }</h2>
            <PushNotificationsSettings />
        </div>
    )
}

export default ActivateNotifications;