import { postService, TsoPayload } from '../services/post.service';
import { Plugins } from '@capacitor/core';
import { TsoCauseKey, DeclarationType, LocaleString } from '../models';
import { SubstitutionPayload } from './../services/post.service';
import localeStrings from '../data/locale-strings.json';
import { addEntry, deleteEntry } from './entries-actions';

const { Storage } = Plugins;

// Stored post data

export interface PostableData {
	posting: boolean;
	error?: LocaleString;
	sent?: boolean;
}

export interface StoredDeclaration extends PostableData {
	current_page: number;
	institution_id?: number;
	installation_id?: number;	
	was_forewarn?: boolean;
	forewarn_period_count?: string;
	forewarn_period_index?: number;
	forewarn_hours?: number;
	date_submitted_for: string;
}

export interface StoredTso extends StoredDeclaration {
	regular_hours?: string;
	overtime_hours?: string;
	causes?: TsoCauseKey[];// empty array means "iDontKnow", undefined means cause_other must be set
	cause_other?: string;
}

export interface StoredSubstitution extends StoredDeclaration {
	current_job_id?: number;
	substituted_job_id?: number;
	substituted_job_other?: string;
	description?: string;
	hours_spent?: string;
}

export interface StoredTestimonial extends PostableData {
	content?: string;
	allow_sharing?: boolean;
	file?: File;
}

export type PostType = 'tso' | 'substitution' | 'testimonial';

export interface CreatePostRequestPayload {
	title_fr: string;
	title_en: string;
	content_fr: string;
	content_en: string;
	image_fr?: string;
	image_en?: string;
}

export interface EditPostRequestPayload {
	id: number;
	title_fr?: string;
	title_en?: string;
	content_fr?: string;
	content_en?: string;
	image_fr?: string;
	image_en?: string;
}

export interface CreateNewsRequestPayload extends CreatePostRequestPayload {
	sub_title_fr: string;
	sub_title_en: string;
}

export interface CreateEventRequestPayload extends CreatePostRequestPayload {
	location: string;
	start_at: string;
	end_at: string;
}

export interface EditNewsRequestPayload extends EditPostRequestPayload {
	subtitle_fr?: string;
	subtitle_en?: string;
}

export interface EditEventRequestPayload extends EditPostRequestPayload {
	location?: string;
	start_at?: string;
	end_at?: string;
}

export enum PostStatus {
	Ready,
	Sending,
	Sent,
	Invalid
}

export enum PostActionTypes {
	posting = '[Post API] Posting data',
	postSuccess = '[Post API] post success',
	postFailure = '[Post API] post failure',
	setPostData = '[Post API] set Post data',
	clearPostData = '[Post API] clear Post data',
	cmsLoading = '[Post API] CMS loading',
	cmsSuccess = '[Post API] CMS success',
	cmsFailure = '[Post API] CMS failure',
	clearCmsSuccess = '[Post API] clear CMS success'
}

async function getToken(): Promise<string> {	
	const { value } = await Storage.get({ key: 'token' });
	return value ? value.toString() : undefined;
}

export const getSavedDeclaration = async (type: DeclarationType) => {
	const storedKey = 'declaration_' + type;
	const { value } = await Storage.get({ key: storedKey });
	return JSON.parse(value);		
}

export const initSavedDeclarations = () => {
	return async (dispatch: any) => {
		const savedTso = await getSavedDeclaration('tso');
		const savedSubstitution = await getSavedDeclaration('substitution');

		if (savedTso) {
			dispatch({
				type: PostActionTypes.setPostData,
				payload: { type: 'tso', data: savedTso }
			});
		}

		if (savedSubstitution) {
			dispatch({
				type: PostActionTypes.setPostData,
				payload: { type: 'substitution', data: savedSubstitution }
			});
		}
	}
}

export const setDeclarationData = (type: DeclarationType, data: any) => {
	return async (dispatch: any) => {

		const storedKey = 'declaration_' + type;
		const currentSavedDeclaration = await getSavedDeclaration(type);
		const newSavedDeclaration = {...currentSavedDeclaration, ...data};

		await Storage.set({ key: storedKey, value: JSON.stringify(newSavedDeclaration)});

		return dispatch({
			type: PostActionTypes.setPostData,
			payload: { type, data }
		});
	}
};

export const setTestimonialData = (data: any) => ({
	type: PostActionTypes.setPostData,
	payload: { type: 'testimonial', data }
})

export const clearDeclarationData = (type: DeclarationType) => {
	return async (dispatch: any) => {
		dispatch({
			type: PostActionTypes.clearPostData,
			payload: { type }
		});
		await Storage.remove({ key: 'declaration_' + type });
	}
}

export const clearTestimonialData = () => {
	return async (dispatch: any) => {
		dispatch({
			type: PostActionTypes.clearPostData,
			payload: { type: 'testimonial' }
		});
	}
}

export const createTso = (tso: StoredTso) => {
	return async (dispatch: any) => {

		if (tso.sent) {
			return dispatch(postFailure(localeStrings.errorTsoAlreadySent, 'tso'));
	   	}

		dispatch(posting('tso'));
		const token = await getToken();

		const tsoPayload:TsoPayload = {
			institution_id: tso.institution_id,
			installation_id: tso.installation_id,
			was_forewarn: !!tso.was_forewarn,
			forewarn_hours: tso.forewarn_hours,
			date_submitted_for: tso.date_submitted_for,
			regular_hours: parseFloat(tso.regular_hours),
			overtime_hours: parseFloat(tso.overtime_hours),
			causes: tso.causes,
			cause_other: tso.cause_other
		};

		try {
			const res = await postService.createTso(token, tsoPayload);
			if (res.data) {
				await dispatch(addEntry('tsos', res.data.tso));
				return dispatch(postSuccess('tso'));
			} else {
				return dispatch(postFailure(localeStrings.errorSendingTso, 'tso'));
			}
		} catch(e) {
			return dispatch(postFailure(localeStrings.errorAPI, 'tso'));
		}
	};
}

export const createSubstitution = (substitution: StoredSubstitution) => {
	return async (dispatch: any) => {

		if (substitution.sent) {
			return dispatch(postFailure(localeStrings.errorSubstitutionAlreadySent, 'substitution'));
	   	}

		dispatch(posting('substitution'));
		const token = await getToken();

		const substitutionPayload:SubstitutionPayload = {
			institution_id: substitution.institution_id,
			installation_id: substitution.installation_id,
			was_forewarn: !!substitution.was_forewarn,
			forewarn_hours: substitution.forewarn_hours,
			date_submitted_for: substitution.date_submitted_for,
			current_job_id: substitution.current_job_id,
			substituted_job_id: substitution.substituted_job_id,
			substituted_job_other: substitution.substituted_job_other ? substitution.substituted_job_other : null,
			description: substitution.description,
			hours_spent: parseFloat(substitution.hours_spent)
		}

		try {
			const res = await postService.createSubstitution(token, substitutionPayload);
			
			if (res.data) {
				await dispatch(addEntry('substitutions', res.data.substitution));
				return dispatch(postSuccess('substitution'));
			} else {
				return dispatch(postFailure(localeStrings.errorSendingSubstitution, 'substitution'));
			}
		} catch(e) {
			return dispatch(postFailure(localeStrings.errorAPI, 'substitution'));
		}
	};
}

export const createTestimonial = (testimonial: StoredTestimonial) => {
	return async (dispatch: any) => {

		if (testimonial.sent) {
			 return dispatch(postFailure(localeStrings.errorTestimonialAlreadySent, 'testimonial'));
		}

		dispatch(posting('testimonial'));

		const postTestimonial = async (imageBase64?:string) => {
			try {
				const token = await getToken();
				const res = await postService.createTestimonial(token, {
					content: testimonial.content,
					allow_sharing: testimonial.allow_sharing || false,
					image: imageBase64
				});
				if (res.data) {
					await dispatch(addEntry('testimonials', res.data.testimonial));
					return dispatch(postSuccess('testimonial'));
				} else {
					return dispatch(postFailure(localeStrings.errorSendingTestimonial, 'testimonial'));
				}
			} catch(e) {
				return dispatch(postFailure(localeStrings.errorAPI, 'testimonial'));
			}
		};

		if (testimonial.file) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				postTestimonial(reader.result.toString());
			});
			reader.readAsDataURL(testimonial.file);
		} else {
			postTestimonial();
		}
	};
};

export const posting = (type: PostType) => ({
	type: PostActionTypes.posting,
	payload: { type }
});

export const postSuccess = (type: PostType) => ({
	type: PostActionTypes.postSuccess,
	payload: { type }
});

export const postFailure = (error: LocaleString, type: PostType) => ({
	type: PostActionTypes.postFailure,
	payload: { type },
	error
});

export const createNews = (payload: CreateNewsRequestPayload) => {
	return async (dispatch: any) => {
		dispatch(cmsLoading());
		try {
			const token = await getToken();
			const res = await postService.createNews(token, payload);
			if (res.data) {
				dispatch(addEntry('news', res.data.news));
				return dispatch(cmsSuccess(res.data.news.id));
			} else {
				return dispatch(cmsFailure(localeStrings.errorCreatingNews));
			}
		} catch(e) {
			return dispatch(cmsFailure(localeStrings.errorAPI));
		}
	};
}

export const createEvent = (payload: CreateEventRequestPayload) => {
	return async (dispatch: any) => {
		dispatch(cmsLoading());
		try {
			const token = await getToken();
			const res = await postService.createEvent(token, payload);
			if (res.data) {
				dispatch(addEntry('events', res.data.events));
				return dispatch(cmsSuccess(res.data.events.id));
			} else {
				return dispatch(cmsFailure(localeStrings.errorCreatingEvent));
			}
		} catch(e) {
			return dispatch(cmsFailure(localeStrings.errorAPI));
		}
	};
}

export const editNews = (payload: EditNewsRequestPayload) => {
	return async (dispatch: any) => {
		dispatch(cmsLoading());
		try {
			const token = await getToken();
			const res = await postService.editNews(token, payload);
			if (res.data?.news) {
				dispatch(addEntry('news', res.data.news));
				return dispatch(cmsSuccess(res.data.news.id));
			} else {
				return dispatch(cmsFailure(localeStrings.errorEditingNews));
			}
		} catch(e) {
			return dispatch(cmsFailure(localeStrings.errorAPI));
		}
	};
}

export const editEvent = (payload: EditEventRequestPayload) => {
	return async (dispatch: any) => {
		dispatch(cmsLoading());
		try {
			const token = await getToken();
			const res = await postService.editEvent(token, payload);
			if (res.data?.events) {
				dispatch(addEntry('events', res.data.events));
				return dispatch(cmsSuccess(res.data.events.id));
			} else {
				return dispatch(cmsFailure(localeStrings.errorEditingEvent));
			}
		} catch(e) {
			return dispatch(cmsFailure(localeStrings.errorAPI));
		}
	};
}

export const deleteNews = (id: number) => {
	return async (dispatch: any) => {
		dispatch(cmsLoading());
		try {
			const token = await getToken();
			const res = await postService.deleteNews(token, id);
			if (res.data?.status === 'ok') {
				dispatch(deleteEntry('news', id));
				return dispatch(cmsSuccess(id));
			} else {
				return dispatch(cmsFailure(localeStrings.errorDeletingNews));
			}
		} catch(e) {
			return dispatch(cmsFailure(localeStrings.errorAPI));
		}
	};
}

export const deleteEvent = (id: number) => {
	return async (dispatch: any) => {
		dispatch(cmsLoading());
		try {
			const token = await getToken();
			const res = await postService.deleteEvent(token, id);
			if (res.data?.status === 'ok') {
				dispatch(deleteEntry('events', id));
				return dispatch(cmsSuccess(id));
			} else {
				return dispatch(cmsFailure(localeStrings.errorDeletingEvent));
			}
		} catch(e) {
			return dispatch(cmsFailure(localeStrings.errorAPI));
		}
	};
}

export const cmsLoading = () => ({
	type: PostActionTypes.cmsLoading
});

export const cmsSuccess = (id:number) => ({
	type: PostActionTypes.cmsSuccess,
	payload: { id }
});

export const cmsFailure = (error: LocaleString) => ({
	type: PostActionTypes.cmsFailure,
	payload: { error }	
});

export const clearCmsSuccess = () => ({
	type: PostActionTypes.clearCmsSuccess
});