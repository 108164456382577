import React from 'react';
import { IonPage, IonContent, useIonViewWillEnter, isPlatform } from '@ionic/react';
import MobileHeader from '../../../components/mobile/MobileHeader';
import { updateStatusBar } from '../../../lib/status-bar';
import ActivateNotifications from '../../../components/common-content/ActivateNotifications';
import { useDispatch } from 'react-redux';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { useHistory } from 'react-router';
import { readTutorial } from '../../../store/settings-actions';

import './MobileActivateNotifications.scss';
import FiqButton from '../../../components/buttons/FiqButton';
import AuthorizeNotificationsButtons from '../../../components/buttons/AuthorizeNotificationsButtons';

const MobileActivateNotifications: React.FC = () => {

	// Update tab color
	useIonViewWillEnter( () => updateStatusBar('white'));
	
	const dispatch = useDispatch();
	const localizedContent = useLocalizedContent();
	const history = useHistory();

	const isNative = isPlatform('capacitor');

	const handleNextClick = () => {
		dispatch(readTutorial('notifications'));
		history.replace('/dashboard', { direction: 'forward' });
	};

	return (
		<IonPage className="mobile-activate-notifications">
			<MobileHeader hideBackButton />
			<IonContent className="ion-padding">				
				<div className="activate-notifications-container">
					<div className="content-main">
						<ActivateNotifications />
					</div>
					<div className="content-footer">
						{ !isNative ?
							<AuthorizeNotificationsButtons showCloseButton />
						:
							<FiqButton expand="block" onClick={ e => handleNextClick() }>{ localizedContent.next }</FiqButton>
						}
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default MobileActivateNotifications;
