import React from 'react';
import { News, Locale } from '../../models';

import './SingleNewsDetails.scss';
import useLocale from '../../hooks/useLocale';
import { parseLongDate } from '../../lib/utils';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import FiqButton from '../buttons/FiqButton';
import { create } from 'ionicons/icons';
import useIsMobile from '../../hooks/useIsMobile';

interface SingleNewsDetailsProps {
    preview?: boolean;
    locale?: Locale;
    singleNews: News;
}

const SingleNewsDetails:React.FC<SingleNewsDetailsProps> = ({ preview, locale, singleNews }) => {

    locale = useLocale(locale);
    const localizedContent = useLocalizedContent(locale);
    const user = useSelector((state:RootState) => state.auth.user);
    const isUserAdmin = user.is_admin || false;
    const isMobile = useIsMobile();

    return (
        <div className="single-news-details">
            <div className="title">{ singleNews?.title[locale] }</div>
            { isUserAdmin && !isMobile && !preview ?
				<div className="edit-entry">
                    <FiqButton icon={ create } ionIcon href={ '/dashboard/single-news/edit/' + singleNews?.id }>
                        { localizedContent.edit + ' ' + localizedContent.theNegociation }
                    </FiqButton>
				</div>
			: null }
            <div className="date">{ singleNews? parseLongDate(singleNews.created_at_moment,locale) : null }</div>
            <div className="subtitle-container">
                <div className="subtitle-separator top" />
                <div className="subtitle-content">
                    { singleNews?.sub_title[locale] }
                </div>
                <div className="subtitle-separator bottom" />
            </div>
            <div className="content" dangerouslySetInnerHTML={{__html: singleNews?.content[locale] }} />
        </div>
    );
}

export default SingleNewsDetails;