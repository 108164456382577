import React from 'react';

import './RegionStatus.scss';

interface RegionStatusProps {
    level?: number;
    urgentRatio?: number;// computes level from urgentRatio if level is undefined
}

export const getRegionStatusLevels = () => [0, 0.3, 0.5];

const RegionStatus:React.FC<RegionStatusProps> = ({ level = 0, urgentRatio }) => {

    if (!level) {
        getRegionStatusLevels().forEach((statusLevel, i) => urgentRatio >= statusLevel ? level++ : undefined );
    }

    return (
        <span className={'region-status level-' + level} />
    );
}

export default RegionStatus;