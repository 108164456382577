import React from 'react';

import './SimpleListModal.scss';
import BasicModal from './BasicModal';

interface SimpleListModalProps {
    title?: string;
    items: any[];
    onClose?(): void;
}

const SimpleListModal: React.FC<SimpleListModalProps> = ({ title, items, onClose }) => {

    return (
        <div className="simple-list-modal">
            <BasicModal title={title} onClose={ onClose }>
                <div className="list-items">
                    { items.map( (cause, i) =>
                        <div key={'item-' + i} className="list-item">
                            { cause }
                        </div>
                    )}
                </div>
            </BasicModal>            
        </div>
    );
};

export default SimpleListModal;



