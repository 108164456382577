import { combineReducers } from 'redux';
import auth from './auth';
import settings from './settings';
import entries from './entries';
import post from './post';
import app from './app';

const rootReducer = combineReducers({
	auth,
	settings,
	entries,
	post,
	app
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;