import React from 'react';
import LoginHome from '../../../components/login/LoginHome';
import CreateAccountCta from '../../../components/login/CreateAccountCta';
import ScrollablePage from '../../../components/ScrollablePage';

const DesktopLoginHome:React.FC = () => {
    
    return (
        <ScrollablePage
            renderFooter={ () => <CreateAccountCta /> }
        >
            <LoginHome />
        </ScrollablePage>
    );
};

export default DesktopLoginHome;