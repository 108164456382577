import React from 'react';
import { IonIcon } from '@ionic/react';
import { location as mapMarker } from 'ionicons/icons';

import './DesktopCard.scss';
import { LocaleString, PostImages } from '../../models';
import useLocale from '../../hooks/useLocale';
import { Moment } from 'moment';
import { parseLongDate } from '../../lib/utils';


//@TODO envoyer les clés event et news au lieu d'un mélange
interface DesktopCardProps {
    title: LocaleString;
    images?: PostImages;
    sub_title?: LocaleString;// nego
    location?: string;// event
    start_date_moment?: Moment;
    created_at_moment?: Moment;
    event?: boolean;// event
    status?: string;// news
    onClick?(): void;
}

const DesktopCard:React.FC<DesktopCardProps> = ({ title, images, sub_title, location, start_date_moment, created_at_moment, event, status, onClick }) => {

    const momentDate = event ? start_date_moment : created_at_moment;
    const locale = useLocale();

    return (
        <div className={'desktop-card' + (event ? ' event' : '') + (!event ? (status === 'read' ? ' read' : ' unread') : '')} onClick={ onClick }>
            <div className="card-image" style={images?.medium[locale] ? { backgroundImage: 'url(' + images.medium[locale] + ')' } : null }>
                { event ?
                    <div className="card-event-date">
                        <div className="card-event-month">{ momentDate.locale(locale).format('MMM') }</div>
                        <div className="card-event-day">{ momentDate.locale(locale).format('DD') }</div>
                    </div>
                : null }
            </div>
            <div className="card-content">
                <div className="card-title">{ title[locale] }</div>
                { !event ?
                    <div className="card-subtitle">
                        { sub_title[locale] }
                    </div>
                : null }
                <div className="card-bottom">
                    { !event ?
                        <>
                            <div className="card-date">
                                { parseLongDate(momentDate,locale) }
                            </div>
                            <div className="read-status" />
                        </>
                    : null }
                    { location ?
                        <div className="card-location">
                            <IonIcon icon={ mapMarker } />
                            <span className="label" title={ location }>{ location }</span>
                        </div>
                    : null }
                </div>
            </div>
        </div>
    );
};

export default DesktopCard