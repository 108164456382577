import React from 'react';
import { FilteredPage, updatePageFilter } from '../../store/settings-actions';
import RadioButtons from '../buttons/RadioButtons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { TestimonialsFilter } from '../../store/reducers/settings';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface FilterChoice {
	key: string;
	label: string;
	count: number;
}

interface TestimonialsFilterProps {
	page?: FilteredPage;
	activesCount: number;
	archivedsCount: number;
}

const TestimonialStatusFilter:React.FC<TestimonialsFilterProps> = ({ page = "testimonials", activesCount, archivedsCount }) => {

	const dispatch = useDispatch();
	const localizedContent = useLocalizedContent();
    const pageFilters = useSelector((state:RootState) => state.settings.pageFilters[page]) as TestimonialsFilter;
    
	const filtersChoices:FilterChoice[] = [
		{ key: 'active', label: localizedContent.actives, count: activesCount },
		{ key: 'archived', label: localizedContent.archived, count: archivedsCount }
	];

	const choiceIndex = pageFilters?.status ? filtersChoices.findIndex(choice => choice.key === pageFilters.status) : 0;

	const handleArchiveStatusFilterChange = (index:number, choice:any) => {
		dispatch(updatePageFilter('testimonials', { status: choice.key }));
	}
    
    return (
        <RadioButtons value={ choiceIndex } choices={ filtersChoices } onChange={ handleArchiveStatusFilterChange } />
    );
}

export default TestimonialStatusFilter;