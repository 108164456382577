import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import useLocale from '../hooks/useLocale';
import useLocalizedContent from '../hooks/useLocalizedContent';

import './NewsletterSubscribe.scss';

const NewsletterSubscribe:React.FC = () => {

    const locale = useLocale();

    const localizedContent = useLocalizedContent(locale);
    
    const newsletterUrl = useSelector((state:RootState) => state.app.data.configurations.newsletter_subscribe_url)[locale];

    return (
        <div className="newsletter-subscribe">
            <h2>{ localizedContent.newsletterSubscribeTitle }</h2>
            <p>{ localizedContent.newsletterSubscribeContent }</p>

            <div className="items-container subscribe-container">
                <a className="button-newsletter" href={ newsletterUrl } target="_blank" rel="noopener noreferrer">
                    { localizedContent.newsletterSubscribeCta }
                </a>
            </div>

   
        </div>
    );
}

export default NewsletterSubscribe;