import React, { useRef } from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useSocialTokenRedirect from '../../hooks/useSocialTokenRedirect';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import DesktopLoginAdmin from './login/DesktopLoginAdmin';
import DesktopLoginHome from './login/DesktopLoginHome';
import DesktopLoginForm from './login/DesktopLoginForm';
import DesktopCreateAccount from './login/DesktopCreateAccount';
import DesktopForgotPassword from './login/DesktopForgotPassword';
import DesktopLoginMilitant from './login/DesktopLoginMilitant';

import gsap, { Quad } from 'gsap';

import './DesktopLoginPages.scss';
import useLoginLoading from '../../hooks/useLoginLoading';
import LoadingOverlay from '../../components/LoadingOverlay';

const DesktopLoginPages:React.FC = () => {
    const location = useLocation();
    useSocialTokenRedirect();
    const adminEnvironment = useSelector((state: RootState) => state.app.environment.admin);

    const transitionContainer = useRef(null);
    const duration = 500;

    const loginLoading = useLoginLoading();

    const handlePageTransition = (node:HTMLElement) => {
        if (transitionContainer.current.offsetHeight) {
            gsap.from(transitionContainer.current, {
                height: node.offsetHeight,
                duration: duration / 1000,
                ease: Quad.easeInOut,
                clearProps: 'height'
            });
        } else {
            gsap.fromTo(transitionContainer.current, {
                height: node.offsetHeight
            },{
                height: node.offsetHeight,
                duration: duration / 1000,
                clearProps: 'height'
            });
        }
    }

    return (
        <div className="desktop-login-pages">
            <div className="login-pages-container">
                <div className="desktop-login-page-container" ref={ transitionContainer }>
                    <TransitionGroup component={ null }>
                        <CSSTransition key={ location.key } timeout={ duration } onExit={ handlePageTransition }>
                            <div className="desktop-login-page">
                                <Switch location={location}>
                                    <Route path="/login" exact render={ props => adminEnvironment ? <DesktopLoginAdmin /> : <DesktopLoginHome /> } />
                                    <Route path="/login/home" component={ DesktopLoginHome } />
                                    <Route path="/login/admin" component={ DesktopLoginAdmin } />
                                    <Route path="/login/form" component={ DesktopLoginForm } />
                                    <Route path="/login/create" exact component={ DesktopCreateAccount } />
                                    <Route path="/login/create/form" render={ props => <DesktopLoginForm create /> } />
                                    <Route path="/login/forgot-password" component={ DesktopForgotPassword } />
                                    <Route path="/login/militant" component={ DesktopLoginMilitant } />
                                </Switch>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                    <LoadingOverlay loading={ loginLoading.loading } loadingLabel={ loginLoading.label } />
                </div>
            </div>
        </div>
    );
};

export default DesktopLoginPages;