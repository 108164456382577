import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useMemo, useEffect, useRef } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import FixedBackButton from '../../../components/buttons/FixedBackButton';
import EventDetails from '../../../components/common-content/EventDetails';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import useLocale from '../../../hooks/useLocale';
import FixedContent from '../../../components/mobile/FixedContent';
import ScrollableWhiteContent from '../../../components/mobile/ScrollableWhiteContent';
import { updateStatusBar } from '../../../lib/status-bar';

import './MobileEventDetails.scss';
import useRouteIdParam from '../../../hooks/useRouteIdParam';
import useElementSize from '../../../hooks/useElementSize';

const MobileEventDetails: React.FC<RouteComponentProps> = () => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));

	const locale = useLocale();
	const history = useHistory();
	const entries = useSelector((state:RootState) => state.entries.data);
	const eventId = useRouteIdParam();
	const event = useMemo(() => entries.events.find(event => event.id === eventId) || null, [entries, eventId]);

	useEffect( () => {
        if (!event) {
            history.replace('/dashboard/events', { direction: 'back' });
        }
	}, [history, event]);
	
	const scrollableContent = useRef(null);
    const scrollableContentSize = useElementSize(scrollableContent);

	return (
		<IonPage className="mobile-event-details" data-page="event-details">
			<IonContent>
				<FixedContent image={ event?.images?.large[locale] ? event.images.large[locale] : null } width={ scrollableContentSize?.width } />
				<ScrollableWhiteContent>
					<div className="ion-padding" ref={ scrollableContent }>
						<EventDetails event={ event } />
					</div>
				</ScrollableWhiteContent>
                <FixedBackButton defaultHref="/dashboard/events" />
     		</IonContent>
		</IonPage>
	);
};

export default MobileEventDetails;
