import React from 'react';
import { IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { Locale } from '../../models';

import './SideModalCloseButton.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface SideModalBackButtonProps {
    locale?: Locale;
    label?: string;
    onClose?(): void;
}

const SideModalCloseButton:React.FC<SideModalBackButtonProps> = ({ locale, label, onClose }) => {

    const localizedContent = useLocalizedContent(locale);
    label = label ? label : localizedContent.back;

    return (
        <button className="side-modal-close-btn" onClick={ e => { if (onClose) onClose() }}>
            <IonIcon icon={ arrowBack } />
            <span className="label">{ label }</span>
        </button>
    );
}

export default SideModalCloseButton;