import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonTextarea } from '@ionic/react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { createTestimonial, setTestimonialData } from '../../store/post-actions';
import { RootState } from '../../store/reducers';
import LoadingOverlay from '../LoadingOverlay';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import FileUpload from '../FileUpload';

import './TestimonialForm.scss';
import ToastError from '../ToastError';
import FiqButton from '../buttons/FiqButton';

interface TestimonialFormProps {
    rows?: number;
} 

const TestimonialForm:React.FC<TestimonialFormProps> = ({ rows = 10 }) => {

    const testimonial = useSelector((state:RootState) => state.post.testimonial);
    const localizedContent = useLocalizedContent();
    const dispatch = useDispatch();
	const content = testimonial?.content || '';
	const file = testimonial?.file || null;
    const allowSharing = testimonial?.allow_sharing || false;
    const posting = testimonial?.posting || false;
    const error = useSelector((state:RootState) => state.post.testimonial?.error);

    return (
        <div className="testimonial-form">
            <div className="message-container">
                <IonTextarea
                    placeholder={ localizedContent.yourMessage }
                    rows={rows}
                    value={ content }
                    onIonChange={ e => {
                        dispatch(setTestimonialData({ content: e.detail.value }));
                    }}
                />
            </div>
            <div className="file-upload-container">
                <FileUpload file={file} onUpdate={ file => dispatch(setTestimonialData({ file }) )} />
            </div>
            <div className="public-sharing-container">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={ allowSharing }
                            onChange={ e => {
                                dispatch(setTestimonialData( { allow_sharing: e.target.checked }));
                            } }
                            color="primary"
                        />
                    }
                    label={ localizedContent.testimonialIAllowSharing }
                />
            </div>
            <div className="submit-container">                
                <FiqButton disabled={ !content.length } color="primary" expand="block" onClick={ e => dispatch(createTestimonial(testimonial)) }>
                    { localizedContent.sendTestimonial }
                </FiqButton>
            </div>
            <LoadingOverlay loading={ posting } />
            <ToastError error={ error } />
        </div>
    );
}

export default TestimonialForm;