import { Plugins, StatusBarStyle } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
const { StatusBar } = Plugins;

export const updateStatusBar = (color: 'white' | 'blue' | 'dark-blue') => {
    if (isPlatform('capacitor')) {

        if (!isPlatform('ios')) {
            StatusBar.setBackgroundColor({
                color:
                    color === 'white' ?
                        '#FFFFFF'
                    : color === 'blue' ?
                        '#009FE3'
                    :
                        '#00202D'
            });
        }
        
        StatusBar.setStyle({
            style: color === 'white' ? StatusBarStyle.Light : StatusBarStyle.Dark
        });  
    }
};