import React from 'react';

import './AbleToWorkEvaluation.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

const AbleToWorkEvaluation: React.FC = () => {

    const localizedContent = useLocalizedContent();

	return (
		<div className="able-to-work-evaluation">
            <h2 className="page-title" dangerouslySetInnerHTML={{__html: localizedContent.ableToWorkEvaluationTitle }} />
            <div className="image-evaluation" style={{backgroundImage: 'url(/assets/bg/apte-evaluation.svg)'}} />
            <div className="items-container">
                <div className="item-container item-task">
                    <div className="item-icon">
                        <img src="/assets/icon/tache.svg" alt="icon-task" />
                    </div>
                    <div className="item-content">
                        <div className="item-title">{ localizedContent.myTask }</div>
                        <div className="item-description" dangerouslySetInnerHTML={{__html: localizedContent.myTaskDescription}} />
                    </div>
                </div>
                <div className="item-container item-state">
                    <div className="item-icon">
                        <img src="/assets/icon/etat.svg" alt="icon-state" />
                    </div>
                    <div className="item-content">
                    <div className="item-title">{ localizedContent.myState }</div>
                        <div className="item-description" dangerouslySetInnerHTML={{__html: localizedContent.myStateDescription}} />
                    </div>
                </div>
                <div className="item-container item-environment">
                    <div className="item-icon">
                        <img src="/assets/icon/environment.svg" alt="icon-environment" />
                    </div>
                    <div className="item-content">
                    <div className="item-title">{ localizedContent.theEnvironment }</div>
                        <div className="item-description" dangerouslySetInnerHTML={{__html: localizedContent.theEnvironmentDescription}} />
                    </div>
                </div>
            </div>
            <div className="image-banner-container">
            	<img className="image-banner" src="/assets/bg/apte-banner.svg" alt="able-to-work-banner" />
			</div>
		</div>
	);
};

export default AbleToWorkEvaluation;