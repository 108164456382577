import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';

interface AutoCompleteSelectProps {
    autoComplete?: boolean;
    label: string;
    disabled?: boolean;
    options: { name: string }[];
    value: any;
    onChange(e:any, v:any):void;
}

const AutoCompleteSelect: React.FC<AutoCompleteSelectProps> = ({ label, disabled, options, value, onChange, autoComplete }) => {

	return (
        autoComplete ?
            <Autocomplete
                options={ options }
                getOptionLabel={ o => o.name ? o.name : '' }
                value={ value }
                onChange={ onChange }
                renderInput={params => (
                    <TextField {...params} label={ label } variant="outlined" fullWidth />
                )}
            />
        : 
            <FormControl fullWidth variant="outlined">
                <InputLabel>{ label }</InputLabel>
                <Select
                    label={ label }
                    disabled={disabled}
                    value={ options.find(option => option.name === value?.name)?.name || '' }
                    onChange={ e => onChange(e, options.find(option => option.name === e.target.value)) }
                > 
                    { options.map( (option, i) => 
                        <MenuItem key={ 'option-' + i } value={ option.name }>
                            { option.name }
                        </MenuItem>
                    ) }
                </Select>
            </FormControl>
	);
};

export default AutoCompleteSelect;