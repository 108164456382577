export interface ApiSuccessPayload {
	data?: any;
	timestamp: string;
}

export const environment = {
	api: 'https://app-api.fiqsante.qc.ca/api/v1',
	// api: 'https://apifiq.winkstrategies.com/api/v1',
	facebook: {
		appId: '848814158895697',
		cookie: true,
		xfbml: true,
		version: 'v2.8'
	},
	firebase: {
		apiKey: 'AIzaSyDIeMlEbbO1Q7WZasmLBG2dmSlrP2LCOcA',
		authDomain: 'fiq-sante-dev.firebaseapp.com',
		databaseURL: 'https://fiq-sante-dev.firebaseio.com',
		projectId: 'fiq-sante-dev',
		storageBucket: 'fiq-sante-dev.appspot.com',
		messagingSenderId: '387063317536',
		appId: '1:387063317536:web:51477e5cd9646a45a92845',
		measurementId: 'G-8WPYEMFSQG',
		vapidKey: 'BC1mkb0LDtO4paFxlHAZZB8ws0GgDBOalUSgViPqSBXwoieTMuEpAFCBYiTPGhcQfAVLAjZlIVgyiLreLtc_2xI'
	},
	androidHost: 'com.fiqsante.app://',
	iosHost: 'fiqsanteapp://',
	webHost: window.location.protocol + '//' + window.location.host + '/'
};