import React from 'react';
import { Event, Locale } from '../../../models';

import SideModalCloseButton from '../../buttons/SideModalCloseButton';
import EventDetails from '../../common-content/EventDetails';

import './OpenedEvent.scss';
import useLocale from './../../../hooks/useLocale';

interface OpenedSingleNewsProps {
    preview?: boolean;
    locale?: Locale;
    event?: Event;
    onClose?(): any
}

const OpenedSingleNews: React.FC<OpenedSingleNewsProps> = ({ preview, locale, event, onClose }) => {
    locale = useLocale(locale);
    
    return (
        <div className="opened-event">
            <div className="event-image" style={ event.images?.large[locale] ? { backgroundImage: 'url(' + event.images.large[locale] + ')' } : null } />
            <div className="close-button-container">
                <SideModalCloseButton locale={ locale } onClose={ onClose } />
            </div>
            <div className="event-content">
                <EventDetails locale={ locale } event={ event } preview={ preview } />
            </div>
        </div>
    );
};

export default OpenedSingleNews;