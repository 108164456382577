import React, { useRef, useEffect, useState } from 'react';
import { IonIcon, IonText } from '@ionic/react';
import { image, closeCircle, cloudUpload } from 'ionicons/icons';
import useLocalizedContent from '../hooks/useLocalizedContent';

import './FileUpload.scss';
import useIsMobile from '../hooks/useIsMobile';

interface FileUploadProps {
    file: File;
    onUpdate(file:File): void;
} 

const FileUpload:React.FC<FileUploadProps> = ({ file, onUpdate }) => {

    const localizedContent = useLocalizedContent();
    const el = useRef(null);
    const fileInput = useRef(null);

    const isMobile = useIsMobile();
    const [dragOver, setDragOver] = useState(false);
    
    useEffect( () => {
        const elCurrent = el.current;
        const onDrop = (e: DragEvent) => {
            e.stopPropagation();
            e.preventDefault();
            onUpdate(e.dataTransfer.files[0]);
            setDragOver(false);
        };

        const onDragOver = (e: DragEvent) => {
            e.stopPropagation();
            e.preventDefault();    
            e.dataTransfer.dropEffect = 'copy';
            setDragOver(true);
        };

        const onDragOut = (e: DragEvent) => {
            e.stopPropagation();
            e.preventDefault();    
            setDragOver(false);
        };

        elCurrent.addEventListener('drop', onDrop);    
        elCurrent.addEventListener('dragover', onDragOver);
        elCurrent.addEventListener('dragleave', onDragOut);

        return () => {
            elCurrent.removeEventListener('drop', onDrop);
            elCurrent.removeEventListener('dragover', onDragOver);
            elCurrent.removeEventListener('dragleave', onDragOut);
        }
    }, [onUpdate]);

    return (
        <div className={'file-upload' + ( dragOver ? ' drag-over': '' )} ref={ el }>
            { file && 
                <div className="selected-file-container">
                    <div className="selected-file">
                        <IonIcon icon={ image } />
                        <IonText>{ file.name }</IonText>
                    </div>
                    <button
                        type="button"
                        onClick={ e => {
                            e.preventDefault();
                            fileInput.current.value = null;
                            onUpdate(null);
                        } }
                    >
                        <IonIcon icon={ closeCircle } color="medium" />
                    </button>
                </div>
            }
            <label className="file-input-container">
                <input
                    ref={fileInput}
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    capture="camera"
                    onChange={ e => {
                        e.preventDefault();
                        if (e.target.files.length) {
                            onUpdate(e.target.files[0])
                        }    
                    }}
                />
                <div className="custom-file-input">
                    <div className="custom-file-input-content">
                        <IonIcon color="primary" icon={ cloudUpload } mode="md" />
                        <IonText color="primary">{ localizedContent.selectFile }</IonText>
                    </div>
                    { !isMobile ?
                        <div className="drag-cta">{ localizedContent.dragCta }</div>
                    : null }
                </div>
            </label>
        </div>
    );
}

export default FileUpload;