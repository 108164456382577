import React from 'react';
import { News, Locale } from '../../../models';

import SideModalCloseButton from '../../buttons/SideModalCloseButton';
import SingleNewsDetails from '../../common-content/SingleNewsDetails';

import './OpenedSingleNews.scss';
import NewsletterSubscribe from '../../NewsletterSubscribe';
import useLocale from './../../../hooks/useLocale';


interface OpenedSingleNewsProps {
    preview?: boolean;
    locale?: Locale;
    singleNews?: News;
    onClose?(): any
}

const OpenedSingleNews: React.FC<OpenedSingleNewsProps> = ({ preview, locale, singleNews, onClose }) => {
    locale = useLocale(locale);

    return (
        <div className="opened-single-news">
            <div className="single-news-image" style={ singleNews.images?.large[locale] ? { backgroundImage: 'url(' + singleNews.images.large[locale] + ')' } : null } />
            <div className="close-button-container">
                <SideModalCloseButton locale={ locale } onClose={ onClose } />
            </div>
            <div className="single-news-content">
                <SingleNewsDetails locale={ locale } singleNews={ singleNews } preview={ preview } />
            </div>
            <div className="single-news-subscribe">
                <NewsletterSubscribe />
            </div>
        </div>
    );
};

export default OpenedSingleNews;