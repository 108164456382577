import { IonIcon } from '@ionic/react';
import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { eye, eyeOff } from 'ionicons/icons';

import './VisiblePasswordInput.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface VisiblePasswordInputProps {
    value: string;
    label?: string;
    disabled?: boolean;
    autoComplete?: string;
    name?: string;    
    onChange?(password: string): void;
}

const VisiblePasswordInput: React.FC<VisiblePasswordInputProps> = ({ value, label, name = 'password', autoComplete, disabled, onChange }) => {

    const [showPassword, setShowPassword] = useState(false);
    const localizedContent = useLocalizedContent();
    
    const handleChange = (password:string) => {
        if (onChange){
            onChange(password);
        }

        if (!password.length) {
            setShowPassword(false);
        }
    }
	
	return (
		<div className={'visible-password-input' + (showPassword ? ' showing-password' : '')}>
            <TextField
                type={ showPassword ? 'text' : 'password'}
                name={ name }
                label={ label || localizedContent.password }
                variant="outlined"
                disabled={ disabled }
                autoComplete={ autoComplete }
                fullWidth
                value={ value }
                onChange={e => handleChange(e.target.value) }
            />
            { !disabled && value.length ?
                <button className="show-password-button" type="button" tabIndex={-1} onClick={ e => setShowPassword(!showPassword) }>
                    <IonIcon icon={ showPassword ? eyeOff : eye } />
                </button>
            : null }            
        </div>
	);
};

export default VisiblePasswordInput;