import { IonHeader,IonButtons, IonToolbar, IonTitle } from '@ionic/react';
import React from 'react';
import { chevronBack } from 'ionicons/icons';

import './MobileHeader.scss';
import TrueBackButton from '../buttons/TrueBackButton';

interface MobileHeaderProps {
    title?: string;
    titleHidden?: boolean;
    blue?: boolean;
    defaultBackHref?: string;
    hideBackButton? :boolean;
    backIcon?: string;
    onBackClick?(): void;
    alignLeft?: boolean;
    backDisabled?: boolean;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ title, titleHidden, blue, hideBackButton, defaultBackHref = '/dashboard', backIcon = chevronBack, onBackClick, children, alignLeft, backDisabled }) => {
    return (
        <IonHeader className={'mobile-header ' + (blue ? 'blue':'white') + (hideBackButton ? ' back-hidden' :'')}>
            <IonToolbar color={ blue ? 'primary' : undefined } >
                { !hideBackButton ? 
                    <IonButtons slot="start">
                        <TrueBackButton defaultHref={ defaultBackHref } icon={ backIcon } onClick={ onBackClick } disabled={ backDisabled } />
                    </IonButtons>
                : null}
                <IonTitle className={'title' + (titleHidden ? ' hidden' : '')} style={{ textAlign: hideBackButton || children || alignLeft ? 'left' : 'center' }}>{ title }</IonTitle>
                { !hideBackButton && !children ? 
                    <IonButtons slot="end">
                        <TrueBackButton icon={ backIcon } buttonProps={{ style: {visibility: 'hidden' }}} />
                    </IonButtons>
                : null}
            </IonToolbar>
            { children }
        </IonHeader>
	);
};

export default MobileHeader;