import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { FilteredPage } from '../store/settings-actions';
import moment, { Moment } from 'moment';
import useServerNow from './useServerNow';

interface FilteredEntries {
    created_at_moment: Moment;
}

type Hook = (page: FilteredPage, entries: FilteredEntries[]) => FilteredEntries[]

const useDateFilteredEntries:Hook = (page, entries) => {
    
    const pageFilters = useSelector((state:RootState) => state.settings.pageFilters[page]);
    const now = useServerNow();

    const filteredEntries = useMemo( () => {
        const dateFrom = pageFilters?.date_from || moment(now).subtract(30, 'days');
        const dateTo = pageFilters?.date_to || now;
        const isBetweenDates = (date: Moment) => date.isSameOrAfter(dateFrom, 'day') && date.isSameOrBefore(dateTo, 'day');
        return entries.filter( entry => isBetweenDates(entry.created_at_moment) );
    }, [pageFilters, entries, now]);

    return filteredEntries;
}

export default useDateFilteredEntries;