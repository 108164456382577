import { useSelector } from 'react-redux';
import { SortableDeclaration, Tso, Substitution } from '../models';
import { RootState } from '../store/reducers';
import { useMemo } from 'react';

type Hook = (entries: { tsos: Tso[], substitutions: Substitution[] }) => SortableDeclaration[]

const useSortableDeclarations:Hook = (entries) => {

    const appData = useSelector((state:RootState) => state.app.data);
    const { institutions, installations } = appData;

    const declarations:SortableDeclaration[] = useMemo( () => 
        entries.tsos.map((tso:any) => ({ type: 'tso', data: tso}))// get tsos
        .concat(entries.substitutions.map(substitution => ({ type: 'substitution', data: substitution})))// combine with substitutions
        .map((declaration:any) => ({...declaration,
            id: declaration.data.id,
            read: declaration.data.status !== 'unread',
            urgent: declaration.type === 'tso' ? (declaration.data.urgency_state ? 1 : 0) : 0,
            hours: declaration.type === 'tso' ? declaration.data.regular_hours + declaration.data.overtime_hours : declaration.data.hours_spent,
            name: declaration.data.first_name + ' ' + declaration.data.last_name,
            institution_name: institutions[declaration.data.institution_id]?.name,
            installation_name: installations[declaration.data.installation_id]?.name,
            date: declaration.data.created_at_moment
        }))
    , [entries, institutions, installations]);

    return declarations;
}

export default useSortableDeclarations;