import React, { useState } from 'react';
import BasicModal from './BasicModal';
import VisiblePasswordInput from '../../login/VisiblePasswordInput';
import usePasswordValidation from '../../../hooks/usePasswordValidation';
import FiqButton from '../../buttons/FiqButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateAdminUser } from './../../../store/auth-actions';
import { RootState } from '../../../store/reducers';
import usePrevious from './../../../hooks/usePrevious';
import useLocale from './../../../hooks/useLocale';
import useLocalizedContent from './../../../hooks/useLocalizedContent';
import LoadingOverlay from '../../LoadingOverlay';

import './EditAdminPasswordModal.scss';

interface EditAdminPasswordModalProps {
    onClose?(): void;
}

const EditAdminPasswordModal: React.FC<EditAdminPasswordModalProps> = ({ onClose }) => {

    const localizedContent = useLocalizedContent();
    const title = localizedContent.editPassword;

    const locale = useLocale();
    
    const dispatch = useDispatch();
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const passwordsMatch = password === passwordConfirm;
    const validForm = usePasswordValidation(password) && currentPassword.length && passwordsMatch && currentPassword !== password;

    const loading = useSelector((state: RootState) => state.auth.loading);
    const error = useSelector((state: RootState) => state.auth.error);
    const success = useSelector((state: RootState) => state.auth.adminUserUpdateSuccess);
    const previousError = usePrevious(error);
    const previousSuccess = usePrevious(success);
    const nowErrored = previousError !== null && previousError !== error && !!error;
    const nowSuccess = previousSuccess !== null && previousSuccess !== success && !!success;

    const handleSubmit = () => {
        if (nowSuccess) {
            onClose();
        } else if (validForm) {
            dispatch(updateAdminUser({ current_password: currentPassword, password }));
            setCurrentPassword('');
            setPassword('');
            setPasswordConfirm('');
        }
    }

    return (
        <div className="edit-admin-password-modal">
            <BasicModal title={title} onClose={ onClose }>
                <form className="edit-admin-modal-content" onSubmit={ e => { e.preventDefault(); handleSubmit(); }}>
                    <div className="edit-admin-modal-field-container">
                        <VisiblePasswordInput
                            name="current-password"
                            label={ localizedContent.currentPassword }
                            autoComplete={'none'}
                            value={ currentPassword }
                            disabled={ nowSuccess }
                            onChange={ password => { setCurrentPassword(password); if (!password.length) setPassword('') } }
                        />
                    </div>
                    <div className="edit-admin-modal-field-container">
                        <VisiblePasswordInput
                            name="password"
                            label={ localizedContent.newPassword }
                            autoComplete={'new-password'}
                            value={ password }
                            onChange={ password => { setPassword(password); if (!password.length) setPasswordConfirm('') } }
                            disabled= { !currentPassword.length || nowSuccess }
                        />
                    </div>
                    <div className="edit-admin-modal-field-container">
                        <VisiblePasswordInput
                            name="confirmpassword"
                            label={ localizedContent.newPassword + ' (' + localizedContent.confirm + ')'}
                            autoComplete={'new-password'}
                            value={ passwordConfirm }
                            onChange={ password => setPasswordConfirm(password) }
                            disabled= { !password.length || nowSuccess }
                        />
                    </div>
                    <div className="password-hint">
                        { localizedContent.passwordHint }
                    </div>
                    <input type="submit" value="submit" style={{ display: 'none' }} />
                </form>                
                <div className="edit-admin-modal-submit-container">
                    <div className="feedback-container">
                        { nowErrored ? 
                            <span className="feedback-error">{ error[locale] }</span>
                        : nowSuccess ?
                            <span className="feedback-success">{ localizedContent.successUpdatePassword }</span>
                        : null }
                    </div>
                    <FiqButton disabled={ !nowSuccess && !validForm } onClick={ handleSubmit }>
                        { nowSuccess ? localizedContent.close : localizedContent.save }
                    </FiqButton>
                </div>
                <LoadingOverlay loading={ loading } />
            </BasicModal>
        </div>
    );
};

export default EditAdminPasswordModal;