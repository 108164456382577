import React from 'react';
import { FormControl } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { DeclarationType } from '../../models';
import useStoredDeclaration from './../../hooks/useStoredDeclaration';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import AutoCompleteSelect from '../AutocompleteSelect';

const DeclarationLocation: React.FC<{ type: DeclarationType }> = ({ type }) => {

    const localizedContent = useLocalizedContent();
    const user = useSelector((state:RootState) => state.auth.user);
    const appData = useSelector((state:RootState) => state.app.data);
    const storedDeclaration = useStoredDeclaration(type);
    const declaration = storedDeclaration.declaration;
    
    let institutionsList = user?.registration_numbers.map(registrationNumber => appData.institutions[registrationNumber.institution_id]) || [];
    institutionsList = institutionsList.filter((institution, index) => institutionsList.indexOf(institution) === index);
    const institution = declaration?.institution_id ? appData.institutions[declaration.institution_id] : institutionsList.length === 1 ? institutionsList[0] : null;
        
    const installationsList = institution?.installations.map(installationId => appData.installations[installationId]) || [];
    const installation = declaration?.installation_id ? appData.installations[declaration.installation_id] : null;

	return (
        <FormControl fullWidth>
            <div className="ion-margin-bottom">
                <AutoCompleteSelect
                    options={ institutionsList }
                    value={ institution }
                    disabled={ institutionsList.length < 2 }
                    onChange={ (e:any, v:any) => {
                        storedDeclaration.setData({
                            institution_id: v?.id,
                            installation_id: undefined
                        })}
                    }
                    label={ localizedContent.institution }
                />
            </div>
            <AutoCompleteSelect
                options={ installationsList }
                value={ installation }
                onChange={(e:any, v:any) => storedDeclaration.setData({
                    institution_id: institution.id,
                    installation_id: v?.id
                })}
                label={ localizedContent.installation }
            />
        </FormControl>
	);
};

export default DeclarationLocation;