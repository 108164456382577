import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { DeclarationsFilter } from '../../../store/reducers/settings';
import useElementSize from '../../../hooks/useElementSize';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import DesktopMapRegions from '../../../components/desktop/DesktopMapRegions';
import DesktopMapDeclarations from '../../../components/desktop/DesktopMapDeclarations';

import './DesktopMap.scss';
import useRouteIdParam from '../../../hooks/useRouteIdParam';
import { DeclarationType, Declaration, DeclarationTypes } from '../../../models';
import { updatePageFilter } from '../../../store/settings-actions';

interface DesktopMapProps {
	type?: DeclarationType;
}

const DesktopMap: React.FC<DesktopMapProps> = ({ type }) => {
	
	const dispatch = useDispatch();
	const user = useSelector((state:RootState) => state.auth.user); 
	const isUserAdmin = user.is_admin || false;
	const isUserMilitant = user.is_militant || false;
	const pageFilters = useSelector((state:RootState) => state.settings.pageFilters.declarations) as DeclarationsFilter;
	const regions = useSelector((state:RootState) => state.app.data.regions);
	const entries = useSelector((state:RootState) => state.entries.data);

	const openedDeclarationId = useRouteIdParam();
	const declarationRegionId = openedDeclarationId ? (entries[(type+'s' as DeclarationTypes)] as Declaration[]).find(entry => entry.id === openedDeclarationId)?.region_id : undefined;
	const userRegionId = isUserMilitant && !isUserAdmin ? user.registration_numbers[0].region_id : undefined;
	const storedRegionId = pageFilters?.region_id;
	const currentRegionId = isUserAdmin ? ( declarationRegionId ? declarationRegionId : storedRegionId ) : userRegionId;

	useEffect( () => {
		if (!!declarationRegionId && declarationRegionId !== storedRegionId) {
			dispatch(updatePageFilter('declarations', { region_id: declarationRegionId}));
		}
	}, [dispatch, declarationRegionId, storedRegionId])

	const region = regions.find(region => region.id === currentRegionId);
	const selectableMap = isUserAdmin && region === undefined;

	// Get element size for the fixed map width without scrollbar

	const el = useRef(null);
	const elSize = useElementSize(el);	

	return (
		<div className="desktop-map" ref={ el }>
			<TransitionGroup component={null}>
				<CSSTransition key={selectableMap ? 'selectable' : 'declarations'} timeout={400}>
					<div className="desktop-map-content">
						{ selectableMap ?
							<DesktopMapRegions />
						:
							<DesktopMapDeclarations region={ region } openedDeclarationType={ type } openedDeclarationId={openedDeclarationId} mapWidth={ elSize?.width || 0 } />
						}
					</div>
				</CSSTransition>
			</TransitionGroup>			
		</div>
	);
};

export default DesktopMap;