import React from 'react';

import './BasicModal.scss';
import { close } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

interface BasicModalProps {
    title?: string;
    onClose?(): void;
}

const BasicModal: React.FC<BasicModalProps> = ({ title, onClose, children }) => {
	return (
        <div className="basic-modal">
            <div className="basic-modal-header">
                <div className="basic-modal-title">{ title }</div>
                <button type="button" className="basic-modal-close-btn" onClick={ onClose }>
                    <IonIcon icon={ close } />
                </button>
            </div>
            <div className="basic-modal-content">
                { children }
            </div>
        </div>
	);
};

export default BasicModal;