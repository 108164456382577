import React from 'react';
import { AbleToWorkPageProps } from './../../hooks/useAbleToWorkForm';
import FiqButton from '../buttons/FiqButton';

import './AbleToWorkConsequences.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

const AbleToWorkConsequences: React.FC<AbleToWorkPageProps> = ({ ableToWork, goNext }) => {

    const localizedContent = useLocalizedContent();

	return (
		<div className="able-to-work-consequences ion-text-center">
            <div className="choice-container">
                <h2 className="choice-title" dangerouslySetInnerHTML={{__html: localizedContent.employerAcceptsIQuit }} />
            </div>
            <div className="choice-container">
                { ableToWork ?
                    <div>
                        <h2 className="choice-title" dangerouslySetInnerHTML={{__html: localizedContent.employerRefusesInotify }} />
                    </div>
                :
                    <div>
                        <h2 className="choice-title" dangerouslySetInnerHTML={{__html: localizedContent.employerEnforcesWorking }} />
                        <p dangerouslySetInnerHTML={{__html: localizedContent.iGiveReasonableNotice }} />
                    </div>
                }
                <FiqButton expand="block" onClick={ goNext }>{ localizedContent.andThen }</FiqButton>
            </div>
		</div>
	);
};

export default AbleToWorkConsequences;