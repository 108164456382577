import React from 'react';
import { Link } from 'react-router-dom';

import LoginGreetings from './LoginGreetings';
import useLocalizedContent from '../../hooks/useLocalizedContent';

import './LoginHome.scss';

const LoginHome: React.FC = () => {	

	const localizedContent = useLocalizedContent();

	return (
		<div className="login-home">
			<LoginGreetings />
			<div className="ignore-third-party-logins-link">
				<Link to="/login/form">{ localizedContent.loginCta }</Link>
			</div>
		</div>
	);
};

export default LoginHome;