import { settings } from '../services/settings.service';
import { UserLoginInfos, Locale, User, UserProfilePayload, EntryTypes, LocaleString } from '../models';
import { Plugins } from '@capacitor/core';
import { entriesService } from '../services/entries.service';
import { EntriesActionType, getEntries } from './entries-actions';
import { updateUser } from './auth-actions';

import localeStrings from '../data/locale-strings.json';
import moment from 'moment';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';


const { Storage } = Plugins;

export type Tutorial = 'tso' | 'substitution' | 'map' | 'notifications';
export type FilteredPage = 'dashboard' | 'declarations' | 'testimonials';

export enum SettingsActionTypes {
	loadSuccess = '[Settings] load success',
	updating = '[Settings] updating',
	updateSuccess = '[Settings] update success',
	updateFailure = '[Settings] update failure',
	updateLocale = '[Settings] update locale',
	updateEmail = '[Settings] update email',
	updateUserLoginInfos = '[Settings] update user login infos',
	updateReadTutorials = '[Settings] update read tutorials',
	updatePageFilters = '[Settings] update page filters',
	setNotificationsAccepted = '[Settings] notifications accepted', 
	setNotificationsRefused = '[Settings] notifications refused',
	setNotificationsUnsupported = '[Settings] notifications unsupported'
};

async function getToken(): Promise<string> {	
	const { value } = await Storage.get({ key: 'token' });
	return value ? value.toString() : undefined;
}

export const load = (user?: User) => {
	return async (dispatch: any) => {
		dispatch(updating());
		// Get email from user
		const email = user?.email || '';
		dispatch(updateEmail(email));

		// Get locale from user || storage || device 
		let locale:string = user?.locale;
		if (!locale) {
			const storedLocale = await settings.getLocale();
			if (!storedLocale) {
				// getting the locale from the device has mixed results (en instead of fr for some users)
				//const languageCode = await Device.getLanguageCode();
				//locale = languageCode.value.substr(0, 2);
			} else {
				locale = storedLocale;
			}			
		}

		// set locale to store and re-update storage
		await dispatch(updateLocale(locale as Locale));
		return dispatch(loadSuccess());
	};
};

//------------------------------------

export const initSavedSettings = () => {
	return async (dispatch: any) => {
		// Set saved settings from storage to store
		const userLoginInfos = await settings.getUserLoginInfos();
		const tutorialsRead = await settings.getTutorialsRead();

		dispatch(setUserLoginInfos(userLoginInfos));
		return dispatch(setReadTutorials(tutorialsRead));
	}
}

export const updateLocale = (payload: Locale, syncToUser?: boolean) => {
	return async (dispatch: any) => {

		// only support fr + en, defaults to fr
		const locale = ['fr', 'en'].indexOf(payload) > -1 ? payload : 'fr';

		moment.locale(locale+'-ca');
		await settings.setLocale(locale);
		dispatch(setLocale(locale));

		if (syncToUser) {
			await dispatch(updateUserProfile({ locale }));
		}
	}    
};

export type PushNotificationType = 'emergencies' | 'news';

export const updateUserProfile = (payload: UserProfilePayload) => {
	return async (dispatch: any) => {
		dispatch(updating());
		try {
			const token = await getToken();
			const res = await settings.syncUserProfile(token, payload);
			if (res.data) {
				dispatch(updateUser(payload));// update with our own data
				dispatch(updateSuccess());
			} else {
				dispatch(updateUser({} as UserProfilePayload));// dont overwrite anything in the store since it failed
				dispatch(updateFailure( localeStrings.errorUpdateUser ));
			}
		} catch (e) {
			dispatch(updateFailure( localeStrings.errorAPI ));
		}
	}
};

export const updateEmail = (payload: string) => ({
	type: SettingsActionTypes.updateEmail,
	payload
});

export const readTutorial = (tutorial: Tutorial) => {
	return async (dispatch: any) => {
		const tutorials = await settings.getTutorialsRead();
		if (tutorials.indexOf(tutorial) === -1) {
			tutorials.push(tutorial);
			await settings.setTutorialsRead(tutorials);
			return dispatch(setReadTutorials(tutorials));
		}		
	}	
}

export const updateUserLoginInfos = (payload: UserLoginInfos) => {
	return async (dispatch: any) => {
		if (payload.save_infos) {
			await settings.setUserLoginInfos(payload);
		} else {
			await settings.clearUserLoginInfos();
		}
		return dispatch(setUserLoginInfos(payload));
	}
};

const setLocale = (payload: Locale) => ({
	type: SettingsActionTypes.updateLocale,
	payload
});

const setUserLoginInfos = (payload: UserLoginInfos) => ({
	type: SettingsActionTypes.updateUserLoginInfos,
	payload
});

const setReadTutorials = (payload: Tutorial[]) => ({
	type: SettingsActionTypes.updateReadTutorials,
	payload
});

export const updatePageFilter = (page: FilteredPage, filter: any, previousFilter?: any) => {
	return async (dispatch: any) => {

		// check filters status, we might need an entries update or a stats update

		const isNewDateFilter = filter.date_from && filter.date_to;
		const isDashboardPage = page === 'dashboard';

		const setPageFilter = () => {
			dispatch({
				type: SettingsActionTypes.updatePageFilters,
				payload: {
					page, filter
				}
			});
		}

		if (isNewDateFilter && filter.range_type === 2) {
			// get new entries from page (all location)
			const entryTypes:EntryTypes[] = [];

			switch(page) {
				case 'dashboard':
					entryTypes.push('tsos', 'substitutions', 'testimonials');
				break;
				case 'declarations':
					entryTypes.push('tsos', 'substitutions');
				break;
				case 'testimonials':
					entryTypes.push('testimonials');
				break;			
			}

			await dispatch(getEntries({
				date_from: filter.date_from.format('YYYY-MM-DD'),
				date_to: filter.date_to.format('YYYY-MM-DD')
			}, entryTypes));

			// update page filters

			setPageFilter();

		} else if (isDashboardPage) {

			// update page filters

			setPageFilter();

			dispatch({
				type: EntriesActionType.clearEntriesStats
			});

			// if the dates changed and are not custom

			if ((isNewDateFilter && filter.range_type !== 2) || previousFilter.range_type !== 2){
				
				// get new stats
				
				dispatch({
					type: EntriesActionType.loadingEntriesStats
				});

				const newDateFrom = isNewDateFilter ? filter.date_from : previousFilter?.date_from;
				const newDateTo = isNewDateFilter ? filter.date_to : previousFilter?.date_to;
				const newRegionId = isNewDateFilter ? previousFilter.region_id : filter.region_id;
				const newInstitutionId = isNewDateFilter ? previousFilter.institution_id : filter.institution_id;
				const newInstallationId = isNewDateFilter ? previousFilter.installation_id : filter.installation_id;

				try {

					const token = await getToken();

					const res = await entriesService.getEntriesStats(token, {
						date_from: newDateFrom?.format('YYYY-MM-DD'),
						date_to: newDateTo?.format('YYYY-MM-DD'),
						region_id: newRegionId,
						institution_id: newInstitutionId,
						installation_id: newInstallationId
					});

					dispatch({
						type: EntriesActionType.setEntriesStats,
						payload: res
					});
				} catch (e) {
					dispatch({
						type: EntriesActionType.entriesStatsFailure,
						error: localeStrings.errorAPI
					})
				}
			}			
		} else {
			setPageFilter();
		}		
	}
};

//------------------------------------

export const updating = () => ({
	type: SettingsActionTypes.updating
});

export const loadSuccess = () => ({
	type: SettingsActionTypes.loadSuccess
});

export const updateFailure = (error: LocaleString) => ({
	type: SettingsActionTypes.updateFailure,
	error
});

export const updateSuccess = () => ({
	type: SettingsActionTypes.updateSuccess
});

// ----

export const setNotificationsAccepted = () => ({
	type: SettingsActionTypes.setNotificationsAccepted
});

export const setNotificationsRefused = () => ({
	type: SettingsActionTypes.setNotificationsRefused
});

export const setNotificationsUnsupported = () => ({
	type: SettingsActionTypes.setNotificationsUnsupported
});