import React from 'react';
import useLocalizedContent from './useLocalizedContent';
import { useState } from 'react';

import AbleToWorkTutorial from '../components/able-to-work/AbleToWorkTutorial';
import AbleToWorkEvaluation from '../components/able-to-work/AbleToWorkEvaluation';
import AbleToWorkConsequences from '../components/able-to-work/AbleToWorkConsequences';
import AbleToWorkChoice from '../components/able-to-work/AbleToWorkChoice';
import AbleToWorkSteps from '../components/able-to-work/AbleToWorkSteps';
import { TutorialPageAction, TutorialPagesStatus, TutorialPage, TutorialPageProps } from '../models';

export interface AbleToWorkPageProps extends TutorialPageProps {
    ableToWork?: boolean;
    setAbleToWork?(ableToWork: boolean): void;
}

interface AbleToWorkPage extends TutorialPage {
    Component: React.FC<AbleToWorkPageProps>;
}

interface AbleToWorkStatus extends TutorialPagesStatus {
    tutorialPage: AbleToWorkPage;
    currentPage: AbleToWorkPage;
    isAbleToWork?: boolean;
    setAbleToWork?(ableToWork: boolean): void;
}

type Hook = () => AbleToWorkStatus

const useAbleToWorkForm:Hook = () => {

    //const dispatch = useDispatch();
    const localizedContent = useLocalizedContent();

    const [pageNumber, setPageNumber] = useState(0);
    const [isAbleToWork, setIsAbleToWork] = useState<boolean>(undefined);
    const [isTutorial, setIsTutorial] = useState(true);

    const goBack = () => {
        const newPageNumber = Math.max(0, pageNumber - 1);
        if (!pageNumber && !isTutorial) {
            setIsTutorial(true);
        } else if (pageNumber !== newPageNumber) {
            if (newPageNumber === 1) {
                setIsAbleToWork(undefined);
            }
            setPageNumber(newPageNumber);
        }        
    }

    const goNext = () => {
        const newPageNumber = Math.min(totalPages, pageNumber + 1);
        if (isTutorial) {
            setIsTutorial(false);
        } else if (pageNumber !== newPageNumber) {
            setPageNumber(newPageNumber);
        }
    }

    const setAbleToWork = (ableToWork: boolean) => {
        if (isAbleToWork === undefined && ableToWork !== undefined) {
            goNext();
        }
        setIsAbleToWork(ableToWork);
    }

    const tutorialPage:AbleToWorkPage = {
        key: 'tutorial',
        Component: AbleToWorkTutorial
    }

    const pages:AbleToWorkPage[] = [
        {
            key: 'evaluation',
            Component: AbleToWorkEvaluation
        },
        {
            key: 'choice',
            Component: AbleToWorkChoice
        },
        {
            key: 'consequences',
            Component: AbleToWorkConsequences
        },
        {
            key: 'steps',
            Component: AbleToWorkSteps
        }
    ];

    const currentPage = pages[pageNumber];
    const totalPages = pages.length;
    const isLastPage = pageNumber === totalPages -1;

    const backBtn:TutorialPageAction = {
        name: 'back',
        label: localizedContent.back,
        hidden: isTutorial
    }

    const nextBtn:TutorialPageAction = {
        name: isLastPage ? 'end' : 'next',
        label: pageNumber > 1 ? localizedContent.iUnderstood : localizedContent.continue,
        hidden: pageNumber > 0 && pageNumber < 3
    }

    return {
        isTutorial,
        tutorialPage,
        currentPage,
        isAbleToWork,
        setAbleToWork,
        goBack,
        goNext,
        backBtn,
        nextBtn
    };
}

export default useAbleToWorkForm;