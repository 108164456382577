import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginGreetings from './LoginGreetings';
import AdminUserInputs from './AdminUserInputs';
import FiqButton from '../buttons/FiqButton';
import { useDispatch } from 'react-redux';
import { loginAdmin } from '../../store/auth-actions';
import useEmailValidation from '../../hooks/useEmailValidation';
import useLocalizedContent from '../../hooks/useLocalizedContent';

import './LoginAdminForm.scss';

const LoginAdminForm: React.FC = () => {
    
    const localizedContent = useLocalizedContent();
    const dispatch = useDispatch();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const emailValid = useEmailValidation(email);
	const passwordValid = password.length;
	const formValid = emailValid && passwordValid;

	const handleSubmit = () => {
		dispatch(loginAdmin({ email, password }));
	}

	return (
		<div className="login-admin-form">
            <div className="greetings-container">
                <LoginGreetings />
            </div>
            <div className="admin-user-inputs-container">
                <form onSubmit={ e => { e.preventDefault(); handleSubmit() }}>
                    <AdminUserInputs email={email} password={password} onEmailChange={ setEmail } onPasswordChange={ setPassword } />
                    <input type="submit" value="submit" tabIndex={-1} style={{ display: 'none' }} />
                </form>
            </div>
            <div className="admin-user-forgot-password">
                <Link to="/login/forgot-password">{ localizedContent.forgotPassword }</Link>
            </div>
            <div className="admin-user-submit">
                <FiqButton disabled={ !formValid } expand="block" onClick={ handleSubmit }>{ localizedContent.loginCta }</FiqButton>
            </div>					
        </div>
	);
};

export default LoginAdminForm;