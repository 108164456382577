import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './MobileFooterButtons.scss';
import FiqButton from '../buttons/FiqButton';

export type MobileFooterButtonName = 'back' | 'next' | 'submit' | 'end';

export interface MobileFooterButton {
    name: MobileFooterButtonName;
    label: string;
    hidden?: boolean;
    disabled?: boolean;
}

interface MobileFooterButtonsProps {
    backBtn: MobileFooterButton;
    nextBtn: MobileFooterButton;
    onBackClicked?(btn: MobileFooterButton): void;
    onNextClicked?(btn: MobileFooterButton): void;
}

const MobileFooterButtons:React.FC<MobileFooterButtonsProps> = ({ backBtn, nextBtn, onBackClicked, onNextClicked}) => {

    const hasTwoButtons = !backBtn.hidden && !nextBtn.hidden;
    const largeButton = !backBtn.hidden ? backBtn : nextBtn;
    const largeButtonClick = !backBtn.hidden ? onBackClicked : onNextClicked;

    return (       
        <div className="mobile-footer-buttons">
            <div className="btns-container">
                <TransitionGroup component={null}>
                    <CSSTransition key={ !hasTwoButtons ? 'one-btn' : 'two-btns' } timeout={ 500 }>
                        <div className="btns-content">
                            { hasTwoButtons ? 
                                <>
                                    <FiqButton className="prev-btn" key="prev-btn" expand="block" disabled={ backBtn.disabled } fill="outline" onClick= { e => onBackClicked(backBtn) }>
                                        { backBtn.label }
                                    </FiqButton>
                                    <FiqButton className="next-btn" key="next-btn" expand="block" disabled={ nextBtn.disabled } fill="solid" onClick= { e => onNextClicked(nextBtn) }>
                                        { nextBtn.label }
                                    </FiqButton>
                                </>
                            : 
                                <FiqButton className={ 'large-btn ' + largeButton.name } key="large-btn" expand="block" disabled={ largeButton.disabled } fill={ largeButton?.name === 'back' ? 'outline' : 'solid' } onClick= { e => largeButtonClick(largeButton) }>
                                    { largeButton.label }
                                </FiqButton>
                            }
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </div>
    );
}

export default MobileFooterButtons;