import { appService } from '../services/app.service';
import { Plugins, DeviceInfo } from '@capacitor/core';
import { ApplicationData, Environment, LocaleString, WebNotification, Locale } from '../models';
import { validate, initAuth } from './auth-actions';
import { initSavedSettings, updateLocale } from './settings-actions';
import { initSavedDeclarations } from './post-actions';
import localeStrings from '../data/locale-strings.json';
import { ApiSuccessPayload } from '../services/environment';
import { isPlatform } from '@ionic/react';

const { Device, SplashScreen, App } = Plugins;

export interface GetApplicationDataSuccessPayload extends ApiSuccessPayload {
	data?: ApplicationData;
	hash: string;
}

export interface GetApplicationDataHashSuccessPayload extends ApiSuccessPayload {
	data?: {
		hash: string;
	};
}

export enum AppActionTypes {
	loading = '[App] Loading app',	
	setData = '[App] set app data',	
	dataFailure = '[App] data failure',
	updateEnvironment = '[App] update environment',
	setWebNotification = '[App] set web notification',
	hitBackButton = '[App] hit back button',
	setDeviceInfo = '[App] set device infos'
}

export const init = (history:any) => {
	return async (dispatch: any) => {

		// Hide splash screen
		SplashScreen.hide();
		
		// Init the other stores
		initAuth();
		await dispatch(updateEnvironment())
		await dispatch(initSavedSettings());
		await dispatch(initSavedDeclarations());

		// Set locale from device
		Device.getLanguageCode()
		.then( languageCode => {
			const locale = languageCode.value.substr(0, 2) as Locale;
			dispatch(updateLocale(locale));
		})
		// then continue the init process
		.finally( () => {
			dispatch(getApplicationDataAndValidate());
		});

		// get device info
		Device.getInfo()
		.then ( value => {
			dispatch(setDeviceInfo(value))
		});

		if (!(window as any).fiq_app_init) {

			// ANDROID-ONLY: Catch hardware back button: close the app, navigate manually, or navigate normally depending on the current page
			App.addListener('backButton', () => {
				const pathname = history.location.pathname;
				if (pathname === '/login' || pathname === '/dashboard' ) {
					App.exitApp();
				} else if (pathname === '/dashboard/able-to-work' || pathname.startsWith('/dashboard/report/')) {
					// components on these pages will listen the dispatch below to navigate instead
				} else {
					history.goBack();
				}

				dispatch(hitBackButton());
			});

			// On app focus, refresh application data
			App.addListener('appStateChange', state => {
				if (state.isActive) {
					dispatch(refreshApplicationData());
				}
			});

			// WEB-ONLY: Catch a PWA update and show a notification with a CTA to reload
			const updateNotification = {
				title: localeStrings.appUpdateAvailable,
				actionLabel: localeStrings.refresh,
				actionClick: () => window.location.reload()
			}
	
			if (!!(window as any).fiq_app_update_available) {
				dispatch(updateWebNotification(updateNotification));
			}
			(window as any).fiq_show_app_update = () => {
				dispatch(updateWebNotification(updateNotification));
			};
		}
        
		(window as any).fiq_app_init = true;
	};
}


export const getApplicationDataAndValidate = () => {
    return async (dispatch: any) => {
		dispatch(loading());
		try {
			const res = await appService.getApplicationData();
			if (res.data) {				
				dispatch(setData(res));
				dispatch(validate());
			} else {
				return dispatch(dataFailure(localeStrings.errorAppData));
			}
		} catch(e) {
			return dispatch(dataFailure(localeStrings.errorAPI));
		}		
    };
}

// check if application data changed (comparing hashes to save some processing server-side)
export const refreshApplicationData = () => {
    return async (dispatch: any) => {
		const currentApplicationDataHash = appService.applicationDataHash;
		const appDataHashRes = await appService.getApplicationDataHash();
		if (appDataHashRes.data?.hash !== currentApplicationDataHash) {
			dispatch(loading());
			const res = await appService.getApplicationData();
			dispatch(setData(res));
		}		
	}
}

export const updateWebNotification = (webNotification: WebNotification) => {
	return async (dispatch: any) => {
		const notification = webNotification ? {...webNotification, ...{id: new Date().getTime()}} : webNotification;
		dispatch(setWebNotification(notification));
	}
}

export const hitBackButton = () => ({
	type: AppActionTypes.hitBackButton
});

const updateEnvironment = () => {
	return async (dispatch:any) => {
		let environment: Environment = { admin: false };
		if (!isPlatform('capacitor')) {
			environment.admin = window.location.hostname.includes('admin');
		}
		
		// environment from json file
		/*
		try {
			const jsonEnvironment = await (await fetch('/environment.json')).json();
			environment = {...environment, ...jsonEnvironment};
		} catch(e) {}
		*/
		
		dispatch({
			type: AppActionTypes.updateEnvironment,
			payload: environment
		});
	}
};

const setWebNotification = (payload: WebNotification) => ({
	type: AppActionTypes.setWebNotification,
	payload
})

const loading = () => ({
	type: AppActionTypes.loading
});

const setData = (payload: GetApplicationDataSuccessPayload) => ({
	type: AppActionTypes.setData,
	payload
});

const dataFailure = (error: LocaleString) => ({
	type: AppActionTypes.dataFailure,
	error
});

const setDeviceInfo = (payload: DeviceInfo) => ({
	type: AppActionTypes.setDeviceInfo,
	payload
});