import React from 'react';
import ScrollablePage from '../../../components/ScrollablePage';
import ForgotPassword from '../../../components/login/ForgotPassword';

const DesktopForgotPassword:React.FC = () => {
    
    return (
        <ScrollablePage>
            <ForgotPassword />
        </ScrollablePage>
    );
};

export default DesktopForgotPassword;