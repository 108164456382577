import React from 'react';
import { Select, FormControl, MenuItem } from '@material-ui/core';

import './LocationFilter.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers';
import { FilteredPage, updatePageFilter } from '../../store/settings-actions';
import { LocationsFilter } from '../../store/reducers/settings';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface LocationFilterProps {
    page:FilteredPage;
    defaultRegionId?: number;
    regionId?: number;
    institutionId?: number;
    hideAllRegions?: boolean;
    hideInstallations?: boolean;
    vertical?: boolean;
}

const LocationFilter: React.FC<LocationFilterProps> = ({ page, defaultRegionId, regionId, institutionId, hideAllRegions, hideInstallations, vertical }) => {

    const localizedContent = useLocalizedContent();
    const dispatch = useDispatch();
    const regions = useSelector((state:RootState) => state.app.data.regions);
    const institutions = useSelector((state:RootState) => state.app.data.institutions);
    const installations = useSelector((state:RootState) => state.app.data.installations);

    const pageFilters = useSelector((state:RootState) => state.settings.pageFilters[page]) as LocationsFilter;

    const renderedRegionId = regionId !== undefined ? regionId : pageFilters?.region_id || defaultRegionId;
    const renderedInstitutionId = institutionId !== undefined ? institutionId : pageFilters?.institution_id;
    const installationId = pageFilters?.installation_id;

    const region = renderedRegionId ? regions.find(region => region.id === renderedRegionId) : undefined;    

    const institution = renderedInstitutionId ? institutions[renderedInstitutionId] : undefined;
    const installation = installationId !== undefined ? installations[installationId] : undefined;

    const institutionsList = region ? region.institutions.map(institutionId => institutions[institutionId]) : [];
    const installationsList = institution && !hideInstallations ? institution.installations.map(installationId => installations[installationId]) : [];

    const handleRegionChange = (e:any) => {
        dispatch(updatePageFilter(page, {
            region_id: e.target.value ? parseInt(e.target.value) : undefined,
            institution_id: undefined,
            installation_id: undefined
        }, pageFilters));
    }
    
    const handleInstitutionChange = (e:any) => {
        dispatch(updatePageFilter(page, {
            institution_id: e.target.value ? parseInt(e.target.value) : undefined,
            installation_id: undefined
        }, pageFilters));
    }

    const handleInstallationChange = (e:any) => {
        dispatch(updatePageFilter(page, {
            installation_id: e.target.value ? parseInt(e.target.value) : undefined
        }, pageFilters));
    }

	return (
        <div className={ 'location-filter' + (vertical ? ' vertical' : '') }>
            { !regionId ?
                <FormControl variant="outlined">
                    <Select
                        displayEmpty={ !hideAllRegions }
                        value={ region ? region.id : '' }
                        onChange={ handleRegionChange }
                    >
                        { !hideAllRegions ? <MenuItem value="">{ localizedContent.allRegions }</MenuItem> : null }
                        { regions.map(region => 
                            <MenuItem key={ 'region-' + region.id } value={ region.id }>{ region.name }</MenuItem>
                        )}
                    </Select>
                </FormControl>
            : null }
            { !institutionId ?
                <FormControl variant="outlined">
                    <Select
                        displayEmpty
                        value={ institution ? institution.id : '' }
                        onChange={ handleInstitutionChange }
                        disabled={ regions && region === undefined }
                    >
                        <MenuItem value="">{ localizedContent.allInstitutions }</MenuItem>
                        { institutionsList && institutionsList.map(institution => 
                            <MenuItem key={ 'institution-' + institution.id } value={ institution.id }>{ institution.name }</MenuItem>
                        )}
                    </Select>
                </FormControl>
            : null }
            { !hideInstallations ?
                <FormControl variant="outlined">
                    <Select
                        displayEmpty
                        value={ installation ? installation.id : '' }
                        onChange={ handleInstallationChange }
                        disabled={ institution === undefined }
                    >
                        <MenuItem value="">{ localizedContent.allInstallations }</MenuItem>
                        { installationsList.map(installation => 
                            <MenuItem key={ 'installation-' + installation.id } value={ installation.id }>{ installation.name }</MenuItem>
                        )}
                    </Select>
                </FormControl>
            : null }
        </div>
	);
};

export default LocationFilter;