import React, { useEffect, useRef } from 'react';
import { TextField } from '@material-ui/core';

import places from 'places.js';

import './LocationAutocomplete.scss';

interface LocationAutoCompleteProps {
    label: string;
    defaultValue: string;
    onChange(value: string): void;
}

const LocationAutoComplete: React.FC<LocationAutoCompleteProps> = ({ label, defaultValue, onChange }) => {

    const el = useRef(null);

    useEffect( () => {
        const placeAutocomplete = places({
            appId: 'plWE2LOA01QA',
            apiKey: 'fe97e2a23c5f0bae1945eab5f16cf122',
            container: el.current.querySelector('input'),
            language: 'fr',
            countries: ['ca']
        });

        placeAutocomplete.on('change', e => onChange(e.suggestion.value));
        placeAutocomplete.on('clear', () => onChange(''));

        return () => {
            placeAutocomplete.removeAllListeners('change');
            placeAutocomplete.removeAllListeners('clear');
        }
    }, [el, onChange]);

	return (
        <div ref={ el } className="location-autocomplete">
            <TextField
                label={ label }
                defaultValue={ defaultValue }
                variant="outlined"
                onChange={ e => onChange(e.target.value) }
                fullWidth
            />
        </div>        
	);
};

export default LocationAutoComplete;

