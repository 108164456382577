import React from 'react';
import './DonutProgress.scss';
import useIsMobile from '../../hooks/useIsMobile';

interface DonutProgressProps {
    current?:number;
    total?:number;
    size?:number;
    backgroundColor?:string;
    borderColor?:string;
    progressColor?:string;
    borderWidth?:number;
}

const DonutProgress: React.FC<DonutProgressProps> = ({
    current = 1,
    total = 5,
    size = 72,
    borderWidth = 5,
    backgroundColor = 'white',
    borderColor = '#EBEBEB',
    progressColor = '#4EBBEB'
}) => {

    const isMobile = useIsMobile();

    const progress = current / total;
    const strokeDasharray = 251.2;
    const strokeDashoffset = strokeDasharray - strokeDasharray * progress;
    const pixelSize = size + 'px';    

    return (
        <div className="donut-progress" style={{ width: pixelSize, height: pixelSize }}>
            <svg width={ pixelSize } height={ pixelSize } viewBox="0 0 100 100">
                { !isMobile ?
                    <defs>
                        <filter id="dropshadow" x="-60%" y="-60%" width="180%" height="180%" filterUnits="userSpaceOnUse">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="2"/>
                        <feOffset dx="2" dy="2" result="offsetblur"/> 
                        <feOffset dx="-2" dy="-2" result="offsetblur"/>
                        <feComponentTransfer>
                            <feFuncA type="linear" slope="0.3"/>
                        </feComponentTransfer>
                        <feMerge> 
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                        </filter>
                    </defs>
                : null }
                
                <circle cx="50" cy="50" r="40" fill={ backgroundColor } />
                <circle style={ !isMobile ? {filter: 'url(#dropshadow)' } : null } cx="50" cy="50" r="40" fill="transparent" strokeWidth={ borderWidth } stroke={ borderColor } />
                <circle cx="50" cy="50" r="40" fill="transparent" strokeWidth={ borderWidth } stroke={ progressColor } {...{ strokeDasharray, strokeDashoffset }} />
            </svg>
            <div className="progress-text">{ current }/{ total }</div>
        </div>
    );
}

export default DonutProgress;