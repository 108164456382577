import { useState, useEffect, RefObject } from "react";

interface Size {
    width: number;
    height: number;
}

type Hook = (ref: RefObject<HTMLElement>) => Size

const useElementSize:Hook = (ref) => {

    const [size, setSize] = useState<Size>(null);

    useEffect(() => {
        const getSize = () => {
            const width = ref.current?.offsetWidth || 0;
            const height = ref.current?.offsetHeight || 0;
            if (width && height) {
                return {width, height};
            } else {
                return null;
            }
        }

        const onResize = () => {
            const size = getSize();
            if (size) {
                setSize(size);
            }
        }        
        
        // Dirty way to wait for the content to be hydrated (Ionic ready)
        const int = setInterval(() => {
            const size = getSize();
            if (size) {
                setSize(size);
                clearInterval(int);
                window.addEventListener('resize', onResize);

                return () => {
                    window.removeEventListener('resize', onResize);
                }
            }
        },1);
    }, [ref]);

    return size;
}

export default useElementSize;