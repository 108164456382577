import React from 'react';

import './TooltipSelector.scss';
import { arrowDown } from 'ionicons/icons';
import { IonIcon, IonCheckbox } from '@ionic/react';

interface Choice {
    name: string;
    label: string;
}

interface TooltipSelectorProps {
    label: string;
    selectedIndexes: number[];
    choices: Choice[];
    onChange?(selectedIndexes: number[]): void;
}

const TooltipSelector: React.FC<TooltipSelectorProps> = ({ label, selectedIndexes, choices, onChange }) => {
    const handleSelectionChange = (index: number) => {
        if (onChange) {
            let newSelectedIndexes = [...selectedIndexes];
            if (newSelectedIndexes.indexOf(index) === -1) {
                newSelectedIndexes.push(index);
            } else {
                newSelectedIndexes = newSelectedIndexes.filter(selectedIndex => selectedIndex !== index);
            }
            onChange(newSelectedIndexes);
        }
    };

	return (
        <div className="tooltip-selector">
            <div className="static-content">
                <span className="label">{ label }</span>
                <span className="icon">
                    <IonIcon icon={ arrowDown } mode="ios" />
                </span>
            </div>
            <div className="tooltip-selections">
                { choices.map((choice, i) => 
                    <label key={'selection-'+i} className="selection" onClick={ e => handleSelectionChange(i) }>
                        <IonCheckbox checked={ selectedIndexes.indexOf(i) > -1 } />
                        <span className="label">{ choice.label }</span>               
                    </label>
                )}
            </div>
        </div>
	);
};

export default TooltipSelector;