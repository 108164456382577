import React from 'react';
import { TextField } from '@material-ui/core';
import FiqButton from '../buttons/FiqButton';
import VisiblePasswordInput from './VisiblePasswordInput';
import useLocalizedContent from '../../hooks/useLocalizedContent';

import './AdminUserInputs.scss';

interface AdminUserInputsProps {
    email: string;
    password?: string;
    onEmailChange?(email: string): void;
    onPasswordChange?(password: string): void;
    editBtn?: boolean;
    onEditEmail?(): void;
    onEditPassword?(): void;
}

const AdminUserInputs: React.FC<AdminUserInputsProps> = ({ email, password, onEmailChange, onPasswordChange, editBtn, onEditEmail, onEditPassword }) => {
	const localizedContent = useLocalizedContent();
	return (
		<div className="admin-user-inputs">
            <div className="input-container">
                <TextField
                    type="email"
                    name="email"
                    label={ localizedContent.email }
                    variant="outlined"
                    disabled={ editBtn }
                    fullWidth
                    value={ email }
                    onChange={e => onEmailChange ? onEmailChange(e.target.value) : undefined }
                />
                { editBtn ? 
                    <div className="input-action">
                        <FiqButton color="white" fill="outline" onClick={ onEditEmail }>{ localizedContent.edit }</FiqButton>
                    </div>
                : null }
            </div>
            <div className="input-container">
                <div className="input-content">
                    <VisiblePasswordInput
                        name="password"
                        disabled={ editBtn }
                        value={ editBtn ? '123456789' : password }
                        onChange={password => onPasswordChange ? onPasswordChange(password) : undefined }
                    />
                </div>
                { editBtn ? 
                    <div className="input-action">
                        <FiqButton color="white" fill="outline" onClick={ onEditPassword }>{ localizedContent.edit }</FiqButton>
                    </div>
                : null}
            </div>
        </div>
	);
};

export default AdminUserInputs;