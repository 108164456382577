import React, { useState } from 'react';
import BasicModal from './BasicModal';
import { TextField } from '@material-ui/core';
import useEmailValidation from '../../../hooks/useEmailValidation';
import FiqButton from '../../buttons/FiqButton';

import './EditAdminEmailModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateAdminUser } from './../../../store/auth-actions';
import { RootState } from '../../../store/reducers';
import usePrevious from './../../../hooks/usePrevious';
import useLocale from './../../../hooks/useLocale';
import useLocalizedContent from './../../../hooks/useLocalizedContent';
import LoadingOverlay from '../../LoadingOverlay';

interface EditAdminEmailModalProps {
    currentEmail: string;
    onClose?(): void;
}

const EditAdminEmailModal: React.FC<EditAdminEmailModalProps> = ({ currentEmail, onClose }) => {

    const locale = useLocale();
    const localizedContent = useLocalizedContent();
    const title = localizedContent.editEmail;

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [emailConfirm, setEmailConfirm] = useState('');

    const emailsMatch = email === emailConfirm;
    const validForm = useEmailValidation(email) && emailsMatch && currentEmail !== email;

    const loading = useSelector((state: RootState) => state.auth.loading);
    const error = useSelector((state: RootState) => state.auth.error);
    const success = useSelector((state: RootState) => state.auth.adminUserUpdateSuccess);
    const previousError = usePrevious(error);
    const previousSuccess = usePrevious(success);
    const nowErrored = previousError !== null && previousError !== error && !!error;
    const nowSuccess = previousSuccess !== null && previousSuccess !== success && !!success;

    const handleSubmit = () => {
        if (nowSuccess) {
            onClose();
        } else if (validForm) {
            dispatch(updateAdminUser({ email }));
            setEmail('');
            setEmailConfirm('');
        }
    }

    return (
        <div className="edit-admin-email-modal">
            <BasicModal title={title} onClose={ onClose }>
                <form className="edit-admin-modal-content" onSubmit={ e => { e.preventDefault(); handleSubmit(); }}>
                    <div className="edit-admin-modal-field-container">
                        <TextField
                            type="email"
                            name={ localizedContent.currentEmail }
                            label="Courriel actuel"
                            variant="outlined"
                            disabled={ true }
                            fullWidth
                            value={ currentEmail }
                        />
                    </div>
                    <div className="edit-admin-modal-field-container">
                        <TextField
                            type="email"
                            name="email"
                            label={ localizedContent.newEmail }
                            autoComplete={'none'}
                            variant="outlined"
                            disabled={ nowSuccess }
                            fullWidth
                            value={ email }
                            onChange={ e => { setEmail(e.target.value); if (!e.target.value) setEmailConfirm('') } }
                        />
                    </div>
                    <div className="edit-admin-modal-field-container">
                        <TextField
                            type="email"
                            name="confirmail"
                            label={ localizedContent.newEmail + ' (' + localizedContent.confirm + ')'}
                            variant="outlined"
                            autoComplete={'none'}
                            disabled={ !email.length || nowSuccess }
                            fullWidth
                            value={ emailConfirm }
                            onChange={ e => setEmailConfirm(e.target.value) }
                        />
                    </div>
                    <input type="submit" value="submit" style={{ display: 'none' }} />
                </form>      
                <div className="edit-admin-modal-submit-container">
                    <div className="feedback-container">
                        { nowErrored ? 
                            <span className="feedback-error">{ error[locale] }</span>
                        : nowSuccess ?
                            <span className="feedback-success">{ localizedContent.successUpdateEmail }</span>
                        : null }
                    </div>
                    <FiqButton disabled={ !nowSuccess && (!validForm ) } onClick={ handleSubmit }>
                        { nowSuccess ? localizedContent.close : localizedContent.save }
                    </FiqButton>
                </div>
                <LoadingOverlay loading={ loading } />
            </BasicModal>            
        </div>
    );
};

export default EditAdminEmailModal;