import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { initFacebookWeb } from './services/third-party-login.service';

ReactDOM.render(<App />, document.getElementById('root'));

// Init facebook web
initFacebookWeb();

// Enables Capacitor's Toast for web
defineCustomElements(window);

// Cache the web-app offline
serviceWorker.register({
	onUpdate: async registration => {
		// Clear the cache on update and notify the user
		if (registration && registration.waiting) {
			await registration.unregister();
			// catched in AppController
			(window as any).fiq_app_update_available = true;
			if ((window as any).fiq_show_app_update) {
				(window as any).fiq_show_app_update();
			}
		}
	}
});