import React from 'react';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import useLocale from '../../hooks/useLocale';
import { isPlatform, IonIcon } from '@ionic/react';
import { TextField } from '@material-ui/core';

import './DateSelector.scss';
import { calendar } from 'ionicons/icons';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface DateSelectorProps {
    label?: String;
    value?: Moment;
    min?: Moment;
    max?: Moment;
    time?: boolean;
    onChange?(date: Moment): void;
}

const DateSelector: React.FC<DateSelectorProps> = ({ value, label, min, max, time, onChange }) => {

    const locale = useLocale();
    const localizedContent = useLocalizedContent();
    const isNative = isPlatform('capacitor');

	return (
        <div className="date-selector">
            <div className="input-container">
                { isNative ? 
                    <TextField
                        fullWidth
                        label={ label }
                        variant="outlined"
                        value={ value?.format('YYYY-MM-DD') || '' }
                        type="date"
                        inputProps={{
                            min: min ? min.format('YYYY-MM-DD') : undefined,
                            max: max ? max.format('YYYY-MM-DD') : undefined
                        }}
                        onChange={e => { onChange(moment(e.target.value)) }}
                    />
                : 
                    ( time ? 
                        <DateTimePicker
                            fullWidth
                            label={ label }
                            inputVariant="outlined"
                            value={ value || null }
                            format={ locale === 'fr' ? 'DD/MM/YYYY - HH:mm' : 'YYYY/MM/DD - h:mm a'}
                            minDate={ min }
                            maxDate={ max }                        
                            onChange={date => { onChange(date) }}
                            okLabel={ localizedContent.ok }
                            cancelLabel={ localizedContent.cancel }
                        />
                    : 
                        <DatePicker
                            fullWidth
                            label={ label }
                            inputVariant="outlined"
                            value={ value || null }
                            format={ locale === 'fr' ? 'DD/MM/YYYY' : 'YYYY/MM/DD'}
                            minDate={ min }
                            maxDate={ max }                        
                            onChange={date => { onChange(date) }}
                            okLabel={ localizedContent.ok }
                            cancelLabel={ localizedContent.cancel }
                        />
                    )                    
                }
            </div>
            <div className="calendar-icon">
                <IonIcon icon={ calendar } />
            </div>
        </div>
	);
};

export default DateSelector;