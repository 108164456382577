import React from 'react';
import useLocalizedContent from '../../hooks/useLocalizedContent';

import './MobileUnsupportedSection.scss';

const MobileUnsupportedSection:React.FC = () => {

    const localizedContent = useLocalizedContent();

    return (
        <div className="mobile-unsupported-section flex-vertical-center">
            { localizedContent.unsupportedSizeWarning }
        </div>
    );
}

export default MobileUnsupportedSection;