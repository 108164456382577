import { PostActionTypes, StoredTso, StoredSubstitution} from '../post-actions';
import { StoredTestimonial } from './../post-actions';
import { LocaleString } from '../../models';

interface PostState {
	tso?: StoredTso;
	substitution?: StoredSubstitution;
	testimonial?: StoredTestimonial;
	cmsLoading: boolean;
	cmsSuccessPostId: number;
	cmsError?: LocaleString;
}

const data = (
	state: PostState = { cmsLoading: false, cmsSuccessPostId: null },
	action: any
) => {
	switch (action.type) {
		case PostActionTypes.setPostData:
			return {
				...state,
				[action.payload.type] : action.payload.type === 'tso' ?
					{...state.tso, ...action.payload.data }
				: action.payload.type === 'substitution' ?
					{...state.substitution, ...action.payload.data }
				:
					{...state.testimonial, ...action.payload.data}
			};
		case PostActionTypes.clearPostData:
			return {
				...state,
				[action.payload.type]: undefined
			};
		case PostActionTypes.posting:
			const postingStateUpdate:any = { posting: true, error: undefined };
			return {
				...state,
				[action.payload.type] :  action.payload.type === 'tso' ?
					{ ...state.tso, ...postingStateUpdate }
				: action.payload.type === 'substitution' ?
					{ ...state.substitution, ...postingStateUpdate }
				: 
					{ ...state.testimonial, ...postingStateUpdate }
			};

		case PostActionTypes.postSuccess:
			const successStateUpdate:any = { sent: true, posting: false, error: undefined };
			return {
				...state,
				[action.payload.type] :  action.payload.type === 'tso' ?
					{ ...state.tso, ...successStateUpdate }
				: action.payload.type === 'substitution' ?
					{ ...state.substitution, ...successStateUpdate }
				: 
					{ ...state.testimonial, ...successStateUpdate }
			};

		case PostActionTypes.postFailure:
			const failureStateUpdate = { error: action.error as LocaleString, posting: false };
			return {
				...state,
				[action.payload.type] :  action.payload.type === 'tso' ?
					{ ...state.tso, ...failureStateUpdate }
				: action.payload.type === 'substitution' ?
					{ ...state.substitution, ...failureStateUpdate }
				: 
					{ ...state.testimonial, ...failureStateUpdate }
			};
		case PostActionTypes.cmsLoading:
			return {
				...state,
				cmsLoading : true,
				cmsError: undefined
			};
		case PostActionTypes.cmsSuccess:
			return {
				...state,
				cmsSuccessPostId: action.payload.id as number,
				cmsLoading : false
		};
		case PostActionTypes.clearCmsSuccess:
			return {
				...state,
				cmsSuccessPostId: null
		};
		case PostActionTypes.cmsFailure:
			return {
				...state,
				cmsLoading : false,
				cmsError : action.payload.error as LocaleString
		};
		default:
			return state;
	}
};
export default data;
