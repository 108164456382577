import React, { useState } from 'react';
import FiqButton from './FiqButton';
import { addCircle } from 'ionicons/icons';
import { clearTestimonialData } from './../../store/post-actions';
import SimpleModalController from './../modals/SimpleModalController';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';

import './NewTestimonialButton.scss';
import NewTestimonialModal from '../modals/center-modals/NewTestimonialModal';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface NewTestimonialButtonProps {
}

const NewTestimonialButton: React.FC<NewTestimonialButtonProps> = () => {

	const dispatch = useDispatch();
	const localizedContent = useLocalizedContent();

	const [newTestimonialModalOpened, setNewTestimonialModalOpened] = useState(false);
	const handlePostTestimonialClick = (e:React.MouseEvent) => {
		setNewTestimonialModalOpened(true);
	};
	
	const testimonial = useSelector((state:RootState) => state.post.testimonial);
	const testimonialSent = testimonial?.sent || false;

	const handlePostTestimonialClose = () => {
		if (newTestimonialModalOpened && testimonialSent) {
			dispatch(clearTestimonialData());
		}
		setNewTestimonialModalOpened(false);
	}

    return (
        <div className="new-testimonial-button">
            <FiqButton icon={ addCircle } ionIcon onClick={ handlePostTestimonialClick }>{ localizedContent.postTestimonial }</FiqButton>
            <SimpleModalController opened={ newTestimonialModalOpened } onClose={ handlePostTestimonialClose }>
				<NewTestimonialModal testimonialSent={ testimonialSent } onClose={ handlePostTestimonialClose } />
			</SimpleModalController>
        </div>
    )
};

export default NewTestimonialButton;

