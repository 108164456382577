import React from 'react';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserLoginInfos } from '../../store/settings-actions';
import { Region, Institution } from '../../models';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { RootState } from '../../store/reducers';
import AutoCompleteSelect from '../../components/AutocompleteSelect';

import './LoginForm.scss';
import { login } from '../../store/auth-actions';

const LoginForm: React.FC = () => {

	const localizedContent = useLocalizedContent();
	const appData = useSelector((state:RootState) => state.app.data);
	const userLoginInfos = useSelector((state:RootState) => state.settings.userLoginInfos);
	const dispatch = useDispatch();

	const regions = appData.regions;
	const institutions = appData.institutions;

	// Login fields saved values
	const firstName = userLoginInfos?.first_name || '';
	const lastName = userLoginInfos?.last_name || '';
	const registrationNumber = userLoginInfos?.registration_number || '';
	const regionId = userLoginInfos?.region_id || null;
	const institutionId = userLoginInfos?.institution_id || null;

	const region = regionId ? (regions.find(region => region.id === regionId) || null): null;
	const institution = region && institutionId ? (institutions[institutionId] || null) : null;
	const saveInfos = userLoginInfos?.save_infos !== undefined ? userLoginInfos.save_infos : false;

	const regionInstitutions = region?.institutions.map(institutionId => institutions[institutionId]) || [];
	
	const socialTokens = useSelector((state:RootState) => state.auth.socialTokens);
	const canSubmit = userLoginInfos.first_name && userLoginInfos.last_name && userLoginInfos.registration_number && userLoginInfos.institution_id;

	// Login submit
	
	const logIn = async() => {
		if (canSubmit) {
			dispatch(login(userLoginInfos, socialTokens));
		}
	};

	// Save user login infos

	const setFirstName = (first_name: string) => {
		dispatch(updateUserLoginInfos({ ...userLoginInfos, ...{ first_name }}))
	}

	const setLastName = (last_name: string) => {
		dispatch(updateUserLoginInfos({ ...userLoginInfos, ...{ last_name }}));
	}

	const setRegistrationNumber = (registration_number: string) => {
		dispatch(updateUserLoginInfos({ ...userLoginInfos, ...{ registration_number }}))
	}

	const setRegion = (region_id: number) => {
		if (regionId !== region_id) {
			dispatch(updateUserLoginInfos({ ...userLoginInfos, ...{ region_id, institution_id: null }}));
		}		
	}

	const setInstitution = (institution_id: number) => {
		if (institutionId !== institution_id) {
			dispatch(updateUserLoginInfos({ ...userLoginInfos, ...{ institution_id }}));
		}		
	}

	const setSaveInfos = (save_infos: boolean) => {
		dispatch(updateUserLoginInfos({ ...userLoginInfos, ...{ save_infos }}));
	}

	// Login submit

	return (
		<form className="login-form" autoComplete="new-password" onSubmit={ e => { e.preventDefault(); logIn() }}>
			<div className="ion-padding-top">
				<TextField  label={ localizedContent.firstName } variant="outlined" fullWidth defaultValue={firstName} onChange={e => setFirstName(e.target.value)} />
			</div>
			<div className="ion-padding-top">
				<TextField label={ localizedContent.lastName } variant="outlined" fullWidth defaultValue={lastName} onChange={e => setLastName(e.target.value)} />
			</div>
			<div className="ion-padding-top">
				<TextField label={ localizedContent.registrationNumber } variant="outlined" fullWidth defaultValue={registrationNumber} onChange={e => setRegistrationNumber(e.target.value)} />
			</div>
			<div className="ion-padding-top">
				<AutoCompleteSelect
					options={ regions }
					value={ region }
					onChange={ (e:any, v:Region) => setRegion(v.id) }
					label={ localizedContent.region }
				/>
			</div>
			<div className="ion-padding-top">
				<AutoCompleteSelect
					options={ regionInstitutions }
					disabled={ !regionInstitutions.length }
					value={ institution }
					onChange={ (e:any, v:Institution) => setInstitution(v.id) }
					label={ localizedContent.institution }
				/>
			</div>
			<div className="ion-padding-top">
				<FormControlLabel
					control={
						<Checkbox
							checked={ saveInfos }
							onChange={ e => setSaveInfos(e.target.checked) }
							color="primary"
						/>
					}
					label={ localizedContent.saveInfosForNextTime }
				/>
			</div>
			<input type="submit" value="submit" tabIndex={-1} style={{ display: 'none' }} />
		</form>
	);
};

export default LoginForm;