import React, { useState } from 'react';

import './CheckboxButtons.scss';
import { IonCheckbox } from '@ionic/react';

interface Choice {
    label: string;
    count?: number;
}

interface CheckboxButtonsProps {
    theme?: 'fiq' | 'standard';
    choices: Choice[];
    value?: number[];// controls the component
    defaultSelectedIndexes?: number[];
    onChange?(selectedIndexes: number[]):void;
}

const CheckboxButtons: React.FC<CheckboxButtonsProps> = ({ choices, value, defaultSelectedIndexes = [], theme = 'fiq', onChange }) => {
    
    const controlled = value !== undefined;
    const [selectedIndexes, setSelectedIndexes] = useState(defaultSelectedIndexes);
    const renderedSelectedIndexes = controlled ? value : selectedIndexes;

    const handleButtonClick = (index:number) => {

        const selectedIndexesCopy = [...renderedSelectedIndexes];
        const selectedIndex = renderedSelectedIndexes.indexOf(index);        

        if (selectedIndex > -1) {
            selectedIndexesCopy.splice(selectedIndex, 1);
        } else {
            selectedIndexesCopy.push(index);
        }

        selectedIndexesCopy.sort();

        if (!controlled) {
            setSelectedIndexes(selectedIndexesCopy);
        }
        
        if (onChange) {
            onChange(selectedIndexesCopy);
        }
    }

	return (
        <div className={'checkbox-buttons theme-' + theme}>
            { choices.map((choice, i) => {
                const checked = renderedSelectedIndexes.indexOf(i) > -1;
                return (
                    <button key={ 'choice' + i } type="button" className={'checkbox-button' + (checked ? ' selected' : '')} onClick={ e => handleButtonClick(i) }>
                        { theme === 'fiq' ? 
                            <>
                                <span className="label">{ choice.label }</span>
                                { choice.count !== undefined &&
                                    <span className="count">{ choice.count }</span>
                                }
                            </>
                        :
                            <>
                                <IonCheckbox checked={ checked } />
                                <span className="label">{ choice.label }</span>
                            </>
                        }                    
                    </button>
                );
            }) }
        </div>
	);
};

export default CheckboxButtons;