import React from 'react';
import { useSelector } from 'react-redux';
import DesktopCard from '../../../components/desktop/DesktopCard';
import SimpleModalController from '../../../components/modals/SimpleModalController';
import OpenedEvent from '../../../components/modals/side-modals/OpenedEvent';
import { RootState } from '../../../store/reducers';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import useRouteIdParam from '../../../hooks/useRouteIdParam';
import { useHistory } from 'react-router';
import { Event } from '../../../models';

import './DesktopEvents.scss';

const DesktopEvents: React.FC= () => {

	const history = useHistory();
	const localizedContent = useLocalizedContent();
	const entries = useSelector((state:RootState) => state.entries.data);

	const events = entries.events;
	const eventId = useRouteIdParam();
	const openedEvent = eventId ? events.find(event => event.id === eventId) || null : null;

	const handleEventOpen = (event: Event) => {		
		history.push('/dashboard/events/' + event.id);
	}

	const handleEventClose = () => {
		history.replace('/dashboard/events');
	}

	return (
		<div className="desktop-events">
			<h1>{ localizedContent.events }</h1>
			<div className="cards-list">
				{ events.map((event, i) => <DesktopCard key={ 'card' + i } { ...event } event onClick={ () => handleEventOpen(event) } /> ) }
			</div>
			<SimpleModalController position="right" opened={ openedEvent !== null } onClose={ handleEventClose }>
				<OpenedEvent event={ openedEvent } onClose={ handleEventClose } />
			</SimpleModalController>
		</div>
	);
};

export default DesktopEvents;