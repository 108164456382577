import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { useMemo } from "react";

type Hook = () => Moment

const useServerNow:Hook = () => {
    const nowDelta = useSelector((state:RootState) => state.app.nowDelta);
    return useMemo( () => moment().subtract(nowDelta, 'milliseconds'),[nowDelta]);
}

export default useServerNow;