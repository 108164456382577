import React from 'react';

import './DeclarationsCircles.scss';

interface DeclarationsCirclesProps {
    tsosCount: number;
    urgentTsosCount: number;
    substitutionsCount: number;
    size?: number;
    triangleLayout?: boolean;
}

const DEFAULT_SIZE = 144;
const MIN_SCALE = 0.8;
const MAX_SCALE = 1.5;
const EMPTY_CIRCLE_SCALE = 0.5;
const CIRCLE_PADDING = 0.2;

const DeclarationsCircles:React.FC<DeclarationsCirclesProps> = ({ tsosCount, urgentTsosCount, substitutionsCount, size = DEFAULT_SIZE, triangleLayout = false }) => {

    const min = Math.min(tsosCount, urgentTsosCount, substitutionsCount);
    const max = Math.max(tsosCount, urgentTsosCount, substitutionsCount);
    const average = (min + max) / 2;    

    const getRatioSize = (value:number) => {
    
        const underAverage = Boolean(value < average);
    
        const oldMin = underAverage ? min : average;
        const oldMax = underAverage ? average : max;
        const newMin = underAverage ? MIN_SCALE : 1;
        const newMax = underAverage ? 1 : MAX_SCALE;
    
        const scale = value === average ? 1 : (value - oldMin) * (newMax - newMin) / (oldMax - oldMin) + newMin;
    
        return scale * size;
    }

    const noCount = !tsosCount && !urgentTsosCount && !substitutionsCount;

    const circles = [
        {
            type: 'tso',
            value: tsosCount,
            ratioSize: getRatioSize(tsosCount)
        },
        {
            type: 'tso-urgent',
            value: urgentTsosCount,
            ratioSize: getRatioSize(urgentTsosCount)
        },
        {
            type: 'substitution',
            value: substitutionsCount,
            ratioSize: getRatioSize(substitutionsCount)
        }
    ].map( circle => ({...circle, circleSize: circle.value > 0 || noCount ? circle.ratioSize : size * EMPTY_CIRCLE_SCALE}));

    const circlesStyles = circles.map((circle, i) => {
        const circleSize = circle.circleSize;
        const circlePadding = size * CIRCLE_PADDING;

        const style: React.CSSProperties = {
            width: circleSize,
            height: circleSize
        };

        if (triangleLayout) {
            switch (i) {
                case 0:
                    style.left = Math.max(0, circles[1].circleSize / 2 + circles[2].circleSize / 2 - circleSize / 2 - circlePadding);
                break;
                case 1:
                    style.marginTop = - circlePadding;
                break;
                case 2:
                    style.position = 'absolute';
                    style.top = circles[0].circleSize - circlePadding;
                    style.left = circles[1].circleSize - circlePadding;
                break;
            }
        } else {
            if (i > 0) {
                style.marginLeft = size * -CIRCLE_PADDING;
            }
        }
        return style;
    });

    const style = triangleLayout ? {
        width: circles[1].circleSize - size * CIRCLE_PADDING + circles[2].circleSize,
        height: circles[0].circleSize - size * CIRCLE_PADDING + (Math.max(circles[1].circleSize, circles[2].circleSize)),
    } : undefined;

    return (
        <div className={'declarations-circles' + (triangleLayout ? ' triangle-layout' : '')} style={ style }>
            { circles.map((circle, i) => {
                return (
                    <div key={ 'circle-' + circle.type } className={ 'declarations-circle status-' + circle.type + (circle.value === 0 ? ' empty':'')} style={circlesStyles[i]}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">{ circle.value }</text>
                        </svg>
                    </div>
                );
            })}
        </div>
    );
}

export default DeclarationsCircles;