import React, { useMemo, useEffect } from 'react';
import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import DeclarationDetails from '../../../components/common-content/DeclarationDetails';

import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { DeclarationType } from '../../../models';

import './MobileDeclarationDetails.scss';
import MobileHeader from '../../../components/mobile/MobileHeader';
import { updateStatusBar } from '../../../lib/status-bar';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import { RootState } from '../../../store/reducers';
import useRouteIdParam from '../../../hooks/useRouteIdParam';

interface MobileDeclarationDetailsProps {
	type: DeclarationType;
}

const MobileDeclarationDetails: React.FC<MobileDeclarationDetailsProps> = ({ type }) => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));

	const entries = useSelector((state:RootState) => state.entries.data);
	const history = useHistory();
	const localizedContent = useLocalizedContent();
	const declarationId = useRouteIdParam();

	const declaration = useMemo(() => type === 'tso' ? entries.tsos.find(tso => tso.id === declarationId) : entries.substitutions.find(substitution => substitution.id === declarationId), [type, entries, declarationId]);
	const declarationTypeName = type === 'tso' ? localizedContent.declarationTso : localizedContent.workSubstitution;

	useEffect( () => {
        if (!declaration) {
            history.replace('/dashboard/map', { direction: 'back' });
        }
    }, [history, declaration]);

	return (
		<IonPage className="mobile-declaration-details" data-page="declaration-details">
			<MobileHeader title={declarationTypeName} defaultBackHref="/dashboard/map" />
			<IonContent>
                <DeclarationDetails type={ type } declaration={ declaration } />
     		</IonContent>
		</IonPage>
	);
};

export default MobileDeclarationDetails;