import React from 'react';
import DeclarationTutorial from '../components/declaration/DeclarationTutorial';
import useLocalizedContent from './useLocalizedContent';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/reducers';
import { DeclarationType, TutorialPageAction, TutorialPagesStatus, TutorialPage, LocaleString } from '../models';

import DeclarationLocation from '../components/declaration/DeclarationLocation';
import DeclarationHours from '../components/declaration/DeclarationHours';
import DeclarationForewarn from '../components/declaration/DeclarationForewarn';
import useStoredDeclaration from './useStoredDeclaration';
import TsoCauses from '../components/declaration/TsoCauses';
import SubstitutionJobs, { getSubstitutedJobOtherId } from '../components/declaration/SubstitutionJobs';
import { createTso, createSubstitution } from '../store/post-actions';
import { readTutorial } from '../store/settings-actions';
import moment from 'moment';
import useServerNow from './useServerNow';

interface DeclarationPage extends TutorialPage {
    title: string;
    Component: React.FC<{ type: DeclarationType }>;
}

interface DeclarationStatus extends TutorialPagesStatus {
    canSubmit: boolean;
    posting: boolean;
    isSuccess: boolean;
    error?: LocaleString;
    tutorialPage: DeclarationPage;
    currentPage: DeclarationPage;
}

type Hook = (type: DeclarationType) => DeclarationStatus

const useDeclarationForm:Hook = (type) => {

    const dispatch = useDispatch();
    const localizedContent = useLocalizedContent();
    const storedDeclaration = useStoredDeclaration(type);
    const now = useServerNow();
    const declaration = storedDeclaration.declaration;
    const tso = storedDeclaration.tso;
    const substitution = storedDeclaration.substitution;

    const hasSeenTutorial = useSelector((state:RootState) => state.settings.tutorialsRead.indexOf(type) > -1);

    const pageNumber = declaration?.current_page || 0;
    const [isTutorial, setIsTutorial] = useState(!declaration && !hasSeenTutorial);

    const getDeclarationPages = () => {    
        const pages = {
            location: {
                key: 'location',
                title: localizedContent.institutionAndInstallation,
                Component: DeclarationLocation,
                canGoNext: declaration?.installation_id !== undefined && declaration?.installation_id !== undefined
            },
            hours: {
                key: 'hours',
                title: localizedContent.numberWorkedHours,
                Component: DeclarationHours,
                canGoNext:
                    declaration?.date_submitted_for !== undefined && moment(declaration.date_submitted_for).isSameOrBefore(now, 'day') && ( type === 'tso' ?
                        parseFloat(tso?.regular_hours) > 0 && parseFloat(tso?.overtime_hours) > 0
                    :
                        parseFloat(substitution?.hours_spent) > 0
                    )
            },
            forewarn: {
                key: 'forewarn',
                title: localizedContent.prenoticePeriod,
                Component: DeclarationForewarn,
                canGoNext: declaration?.was_forewarn === false || declaration?.forewarn_hours > 0
            },
            causes: {
                key: 'causes',
                title: localizedContent.requestCauses,
                Component: TsoCauses,
                canGoNext: tso?.causes !== undefined || !!tso?.cause_other
            },
            jobs: {
                key: 'jobs',
                title: localizedContent.substitutedJobTitle,
                Component: SubstitutionJobs,
                canGoNext: substitution?.current_job_id !== undefined && 
                    ( substitution?.substituted_job_id === getSubstitutedJobOtherId() ?
                        !!substitution?.substituted_job_other
                    :
                        substitution?.substituted_job_id !== undefined
                    )
            }
        };
    
        return (
            type === 'tso' ?
                [pages.location, pages.hours, pages.forewarn, pages.causes]
            :
                [pages.jobs, pages.forewarn, pages.hours, pages.location]
        );
    };

    const tutorialPage = {
        key: 'tutorial',
        title: type === 'tso' ? localizedContent.declarationTso : localizedContent.workSubstitution,
        Component: DeclarationTutorial,
        canGoNext: true
    }

    const declarationsPages = getDeclarationPages();
    const currentValidatedPage = declarationsPages[pageNumber];
    const pagesCount = declarationsPages.length;

    const canSubmit = pageNumber === pagesCount - 1;
    const posting = declaration?.posting;
    const isSuccess = declaration?.sent;
    const error = declaration?.error;

    const goBack = () => {
        const newPageNumber = Math.max(0, pageNumber - 1);
        if (pageNumber === 0 && !isTutorial) {
            setIsTutorial(true);
        } else if (pageNumber !== newPageNumber) {
            storedDeclaration.setData({ current_page: newPageNumber });
        }        
    }

    const goNext = () => {
        const newPageNumber = pageNumber + 1;

        if (isTutorial) {
            dispatch(readTutorial(type));
            setIsTutorial(false);
        } else if (canSubmit) {
            if (type === 'tso') {
                dispatch(createTso(tso));
            } else {
                dispatch(createSubstitution(substitution));
            }            
        } else if (pageNumber !== newPageNumber) {
            storedDeclaration.setData({ current_page: newPageNumber });
        }        
    }

    const backBtn:TutorialPageAction = {
        name: 'back',
        label: localizedContent.back,
        hidden: isTutorial
    }

    const nextBtn:TutorialPageAction = {
        name: canSubmit ? 'submit' : 'next',
        label: canSubmit ? localizedContent.send : localizedContent.continue,
        disabled: isTutorial ? false : !currentValidatedPage.canGoNext
    }

    const currentPage:DeclarationPage = {
        key: currentValidatedPage.key,
        title: currentValidatedPage.title,
        Component: currentValidatedPage.Component
    }

    return {
        pageNumber,
        isTutorial,
        canSubmit,
        posting,
        isSuccess,
        error,
        currentPage,
        pagesCount,
        tutorialPage,
        goBack,
        goNext,
        backBtn,
        nextBtn
    };
}

export default useDeclarationForm;