import React, { useState } from 'react';
import localeStrings from '../../data/locale-strings.json';
import FiqButton from './FiqButton';
import { useDispatch, useSelector } from 'react-redux';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { useHistory } from 'react-router';
import { readTutorial, setNotificationsRefused } from '../../store/settings-actions';
import { askPermissionsAndGetToken } from '../../lib/firebase-web';
import { addFirebaseTokenToUser } from '../../store/auth-actions';
import { RootState } from '../../store/reducers';
import LoadingOverlay from '../LoadingOverlay';
import { LocaleStrings } from '../../models';

import './AuthorizeNotificationsButtons.scss';
import ToastError from '../ToastError';

interface AuthorizeNotificationsButtonsProps {
    showCloseButton?: boolean;
}

const AuthorizeNotificationsButtons: React.FC<AuthorizeNotificationsButtonsProps> = ({ showCloseButton }) => {

	const dispatch = useDispatch();
	const localizedContent = useLocalizedContent();
	const history = useHistory();

	const [showingNativePermission, setShowingNativePermission] = useState<boolean>(undefined);
	const notificationsAccepted = useSelector((state: RootState) => state.settings.notificationsAccepted);
	const notificationError = notificationsAccepted === false ? (localeStrings as LocaleStrings).errorRefusedNotificationsPermissions : undefined;

	const showAuthorizationButton = showingNativePermission === undefined && notificationsAccepted === undefined;

	const handleAuthorizePermissions = () => {
		setShowingNativePermission(true);
		askPermissionsAndGetToken()
		.then( token => {
			if (token) {
				dispatch(addFirebaseTokenToUser(token));
			} else {
				dispatch(setNotificationsRefused());
			}
		})
		.finally( () => {
			setShowingNativePermission(false);
		});
	}

	const handleNextClick = () => {		
		dispatch(readTutorial('notifications'));
		history.replace('/dashboard', { direction: 'forward' });
	};

	return (
		<div className="authorize-notifications-buttons">
            { showAuthorizationButton ? 
                <FiqButton onClick={ handleAuthorizePermissions }>{ localizedContent.authorize }</FiqButton>
            : null }
            { showCloseButton ? 
                <FiqButton color="white" fill="outline" onClick={ handleNextClick }>{ showAuthorizationButton ? localizedContent.refuse : localizedContent.close }</FiqButton>
            : null}            
			<LoadingOverlay loading={ showingNativePermission } loadingLabel={ localizedContent.waitingNotificationsPermissions } />
			<ToastError error={ notificationError } />
		</div>
	);
};

export default AuthorizeNotificationsButtons;