import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers';
import { IonRefresher, IonRefresherContent } from '@ionic/react';
import { refreshEntries, setEntries } from '../../store/entries-actions';

const PullToRefreshEntries: React.FC = () => {

    const dispatch = useDispatch();
    const needDataRefresh = useSelector((state: RootState) => !state.settings.notificationsAccepted || state.settings.notificationsUnsupported);

    return needDataRefresh ?
        <IonRefresher slot="fixed" onIonRefresh={ async e => {
            const entries = await refreshEntries();
            dispatch(setEntries(entries));
            e.detail.complete();
        }} >
            <IonRefresherContent />
        </IonRefresher>
    : null
};

export default PullToRefreshEntries;