import { ApplicationData, WebNotification } from './../../models/applicationData';
import { AppActionTypes } from '../app-actions';
import { Environment, LocaleString, TsoCause } from '../../models';
import { DeviceInfo } from '@capacitor/core';
import tsoCausesData from '../../data/tso-causes.json';
import moment from 'moment';

interface PostState {
    loading: boolean;
    data?: ApplicationData;
    tsoCauses: TsoCause[];
    error?: LocaleString;
    backButtonHitCount: number;
    webNotification?: WebNotification;
    environment?: Environment;
    deviceInfo?: DeviceInfo;
    nowDelta?: number;
}

const app = (
	state: PostState = {
        loading: false,
        backButtonHitCount: 0,
        tsoCauses: (tsoCausesData as TsoCause[])
    },
	action: any
) => {
	switch (action.type) {
		case AppActionTypes.loading:
			return {
				...state,
				loading: true,
				error: undefined
            };
        case AppActionTypes.setData:

            const serverNow = moment(action.payload.timestamp);
			const clientNow = moment();
            const nowDelta = clientNow.diff(serverNow, 'milliseconds');
            
            return {
                ...state,
                loading: false,
                data: action.payload.data as ApplicationData,
                nowDelta
            };
        case AppActionTypes.dataFailure:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case AppActionTypes.hitBackButton:
            return {
                ...state,
                backButtonHitCount: state.backButtonHitCount + 1
            }
        case AppActionTypes.setWebNotification:
            return {
                ...state,
                webNotification: action.payload as WebNotification
            }
        case AppActionTypes.updateEnvironment:
            return {
                ...state,
                environment: action.payload as Environment
            }
        case AppActionTypes.setDeviceInfo:
            return {
                ...state,
                deviceInfo: action.payload as DeviceInfo
            }
		default:
			return state;
	}
};
export default app;