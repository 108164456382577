import { Testimonial, SortableTestimonial } from '../models/entries';

type Hook = (testimonials: Testimonial[]) => SortableTestimonial[]

const useSortableTestimonials:Hook = (testimonials) => {

    return testimonials.map(testimonial => ({
            id: testimonial.id,
            data: testimonial,
            read: testimonial.status === 'read' || testimonial.status === 'archived',// @TODO ah noel
            name: testimonial.user_fullname,
            content: testimonial.content,
            date: testimonial.created_at_moment
        })
    );
}

export default useSortableTestimonials;