import React from 'react';
import { FilteredPage, updatePageFilter } from '../../store/settings-actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers';
import { DeclarationsFilter } from '../../store/reducers/settings';
import CheckboxButtons from './../buttons/CheckboxButtons';
import { DeclarationType } from '../../models';

import './DeclarationTypeFilter.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface TypeChoice {
    type: DeclarationType;
    label: string;
    count: number;
}

interface DeclarationTypeFilterProps {
    page?: FilteredPage;
    tsosCount: number;
    substitutionsCount: number;
}

const DeclarationsTypeFilter:React.FC<DeclarationTypeFilterProps> = ({ page = "declarations", tsosCount, substitutionsCount }) => {
    const dispatch = useDispatch();
    const localizedContent = useLocalizedContent();
    const pageFilters = useSelector((state:RootState) => state.settings.pageFilters[page]) as DeclarationsFilter;

    const declarationTypesChoices:TypeChoice[] = [
        {
            type: 'tso',
            label: localizedContent.tso,
            count: tsosCount
        },
        {
            type: 'substitution',
            label: localizedContent.workSubstitution,
            count: substitutionsCount
        }
    ];

    const typesSelected = pageFilters?.declaration_types || ['tso', 'substitution'];
    const typesSelectedIndexes = typesSelected.map((type, i) => declarationTypesChoices.findIndex(choice => choice.type === type));

    const handleOnChange = (selectedIndexes: number[]) => {
        dispatch(updatePageFilter(page, { declaration_types: selectedIndexes.map(i => declarationTypesChoices[i].type) }));
    }

    return (
        <div className="declaration-type-filter">
            <CheckboxButtons choices={ declarationTypesChoices } value={ typesSelectedIndexes } onChange={ handleOnChange } />
        </div>
    );
};

export default DeclarationsTypeFilter;