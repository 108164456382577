import { authentication } from '../services/authentication.service';
import { Plugins } from '@capacitor/core';
import { getEntries, clearEntries } from './entries-actions';
import { isPlatform } from '@ionic/react';

import queryString from 'query-string';
import localeStrings from '../data/locale-strings.json';
import { User, UserUpdatableKeys, UserAdminCredentialsPayload, SocialTokens, ThirdPartyLoginType, LocaleString, UserLoginInfos } from '../models';
import { openPopupCenterWindow } from './../lib/utils';
import { setNotificationsAccepted } from './settings-actions';
import { environment } from '../services/environment';
import { getFacebookLoginToken, getGoogleLoginToken, getAppleLoginToken } from '../services/third-party-login.service';

const { Storage, Device, Browser, App } = Plugins;

const militantLoginAndroidCallbackUrl = environment.androidHost + 'militant/callback';
const militantLoginIosCallbackUrl = environment.iosHost + 'militant/callback';
const militantLoginWebInitUrl = environment.webHost + 'militant-init.html';
const militantLoginWebSuccessUrl = environment.webHost + 'militant-success.html';


// Payloads

export interface ValidateSuccessPayload {
	success: boolean;
	user?: User;
}

export interface LoginRequestPayload {
	first_name: string;
	last_name: string;
	registration_number: string;
	institution_id: number;
	facebook?: string;
	google?: string;
	facebook_id?: string;
	google_id?: string;
	google_email?: string;
	device_id?: string;
}

export interface LoginSuccessPayload {
	data?: {
		token?: string;
		token_expiration_date?: string;
		user?: User
	}	
}

export interface LoginAdminRequestPayload {
	email: string;
	password: string;
	device_id?: string;
}

export interface ResetAdminPasswordPayload {
	email: string;
	redirect_url: string;
}

export interface SessionPayload {
	token?: string;
	token_expiration_date?: string;
}

export enum AuthStatus {
	Uninitialized,
	LoggedIn,
	MilitantNeedsLogin,
	LoggedOut,
	Invalid
}

export enum AuthActionTypes {
	updateFirebaseToken = '[Authentication] Update firebase token',
	updateSocialTokens = '[Authentication] Update social tokens',
	updateUser = '[Authentication] Update user',
	loading = '[Authentication] Loading',
	loginSocialInit = '[Authentication] login social init',
	loginMilitantInit = '[Authentication] login militant init',
	loginSuccess = '[Authentication] login success',
	logoutSuccess = '[Authentication] logout success',
	loginMilitantBack = '[Authentication] login militant back',
	loginFailure = '[Authentication] login failure',
	logoutFailure = '[Authentication] logout failure',
	otherFailure = '[Authentication] other failure',// militant login failure, linking social to a logged-in account
	
	adminPasswordResetLoading = '[Authentication] admin password reset loading',
	adminPasswordResetSuccess = '[Authentication] admin password reset success',
	adminUserUpdateLoading = '[Authentication] admin user update loading',
	adminUserUpdateSuccess = '[Authentication] admin user update success',

	sessionSet = '[Authentication] session set',
	sessionCleared = '[Authentication] session cleared'
}

async function getToken(): Promise<string> {
	// get the auth token from storage
	const { value } = await Storage.get({ key: 'token' });
	return value ? value.toString() : undefined;
}

export const initAuth = () => {
	// on mobile, we catch when the App is opened from an URL
	// jfais ce morceau là icitte pcq le App.removeListener est private et je ne veux pas qu'ils s'accumulent	
	
	if (isPlatform('capacitor')) {
		if (isPlatform('ios')) {
			Browser.addListener('browserFinished', () => {
				if ((window as any).fiq_militantLoginActive) {
					(window as any).fiq_militantLoginActive = false;
					(window as any).fiq_onMobileMilitantRemoveLoading();
				}
			});
		}
		App.addListener('appStateChange', state => {
			if (state.isActive) {
				// i know
				if ((window as any).fiq_militantLoginActive) {
					(window as any).fiq_militantLoginActive = false;
					(window as any).fiq_onMobileMilitantRemoveLoading();
				}		
			}
		});
		App.addListener('appUrlOpen', data => {
			// if the URL is the militant login callback, we finalize the mobile militant login
			const callbackUrl = isPlatform('ios') ? militantLoginIosCallbackUrl : militantLoginAndroidCallbackUrl;
			if (data.url.indexOf(callbackUrl) >= 0 && (window as any).fiq_onMobileMilitantLogin) {
				(window as any).fiq_onMobileMilitantLogin(data.url);
			}
		});
	}	
}

export const loading = () => ({
	type: AuthActionTypes.loading
});

export const validate = () => {
	return async (dispatch: any) => {
		dispatch(loading());

		const token = await getToken();

		if (token) {
			try {
				const res = await authentication.validateToken(token);
				return dispatch(loginSuccess(res));
			} catch (e) {
				return dispatch(loginFailure(localeStrings.errorAPI));
			}
		} else {
			return dispatch(logoutSuccess());
		}
	};
};

export const login = (userLoginInfos: UserLoginInfos, socialTokens: SocialTokens) => {
	return async (dispatch: any) => {
		dispatch(loading());

		const payload: LoginRequestPayload = {
			...{
				first_name: userLoginInfos.first_name,
				last_name: userLoginInfos.last_name,
				registration_number: userLoginInfos.registration_number,
				institution_id: userLoginInfos.institution_id
			},
			...socialTokens
		};

		try {
			const { uuid } = await Device.getInfo();
			payload.device_id = uuid;
			const res = await authentication.login(payload);
			return dispatch(loginSuccess(res));
		} catch (e) {
			return dispatch(loginFailure(localeStrings.errorAPI));
		}
	}
}

export const loginAdmin = (payload: LoginAdminRequestPayload) => {
	return async (dispatch: any) => {
		dispatch(loading());
		try {
			const { uuid } = await Device.getInfo();
			payload.device_id = uuid;
			const res = await authentication.loginAdmin(payload);
			return dispatch(loginSuccess(res));
		} catch (e) {
			return dispatch(loginFailure(localeStrings.errorAPI));
		}		
	}
}

export const loginMilitant = () => {

	// gotta open a local popup outside of async functions for safari and firefox
	let popup:Window;
	if (!isPlatform('capacitor')) {
		popup = openPopupCenterWindow(militantLoginWebInitUrl, 'militant-login', window, 400, 600);
	}

	return async (dispatch: any) => {
		dispatch({ type: AuthActionTypes.loginMilitantInit });
		
		try {
			const token = await getToken();
			const { uuid } = await Device.getInfo();
			const finalUrl =
				isPlatform('capacitor') ? 				
					( isPlatform('ios') ?
						militantLoginIosCallbackUrl
					: isPlatform('android') ?
						militantLoginAndroidCallbackUrl
					:
						militantLoginWebSuccessUrl
					)					
				:
					militantLoginWebSuccessUrl;

			const res = await authentication.loginMilitant(token, uuid, finalUrl);
			if (res.data) {

				const onLoginMilitantCompleted = (location: string) => {

					if (!(window as any).fiq_militantLoginActive) {
						return;
					}

					(window as any).fiq_militantLoginActive = false;

					if (!location){
						return dispatch(otherFailure(localeStrings.errorInitLoginMilitant));
					}

					const locationParams = queryString.parseUrl(location, {
						parseBooleans: true, parseNumbers: true
					}).query;
					if (!!locationParams.success) {
						return dispatch(validate());
					} else if (locationParams.error) {
						return dispatch(otherFailure(localeStrings.errorWrongLoginMilitant));
					} else {
						return dispatch(otherFailure(localeStrings.errorInitLoginMilitant));
					}
				}
				// pour savoir qu'on est en train de se logger militant quand le App.appStateChange est triggered
				(window as any).fiq_militantLoginActive = true;

				// on mobile, we open a browser from Capacitor, then listen when the app is opened again from the specific url above
				if (isPlatform('capacitor')){
					await Browser.open({url: res.data.url });
					(window as any).fiq_onMobileMilitantLogin = function(location: string) {
						onLoginMilitantCompleted(location);
						if (isPlatform('ios')) {
							Browser.close();
						}
					};
					(window as any).fiq_onMobileMilitantRemoveLoading = function() {
						dispatch(otherFailure());
					};

				// on desktop, we open a popup window, then listen for a callback from the opened window
				} else {
					let locationUrl = '';
					const timer = setInterval(() => { 
						if(popup.closed) {
							clearInterval(timer);
							onLoginMilitantCompleted(locationUrl);
						}
					}, 250);
					popup.location.replace(res.data.url);					
					(window as any).fiq_onDesktopMilitantLogin = function(popupUrl: string) {
						locationUrl = popupUrl;
						if (popup) {
							popup.close();
						}						
					};
				}
			} else {
				return dispatch(otherFailure(localeStrings.errorInitLoginMilitant));
			}
		} catch (e) {
			return dispatch(otherFailure(localeStrings.errorAPI));
		}		
	};
};

export const loginMilitantSkip = () => {
	return async (dispatch: any) => {
		dispatch(loading());
		try {
			const token = await getToken();
			const res = await authentication.loginMilitantSkip(token);
			if (res.data) {
				return dispatch(loginSuccess(res));
			} else {
				return dispatch(otherFailure(localeStrings.errorAPI));
			}
		} catch (e) {
			return dispatch(otherFailure(localeStrings.errorAPI));
		}		
	};
};

export const loginMilitantBack = () => ({
	type: AuthActionTypes.loginMilitantBack
});

const loginSuccess = (payload: LoginSuccessPayload) => {
	return async (dispatch: any) => {
		let user;
		if (payload.data) {
			dispatch(sessionSet({token: payload.data.token, token_expiration_date: payload.data.token_expiration_date}));			
			user = payload.data.user;		
			if (!user.is_militant || user.is_militant_logged_in) { 	
				// the login is now successfull for all types of user
				dispatch(clearEntries());
				await dispatch(getEntries());
			}
		}

		return dispatch({
			type: AuthActionTypes.loginSuccess,
			payload: {
				user,
				error: user ? undefined : localeStrings.errorAuthenticating,
				status: user ? (user.is_militant && !user.is_militant_logged_in ? AuthStatus.MilitantNeedsLogin : AuthStatus.LoggedIn) : AuthStatus.LoggedOut
			}
		});
	}
}

export const loginFailure = (error: LocaleString) => ({
	type: AuthActionTypes.loginFailure,
	error
});

export const logoutFailure = (error: LocaleString) => ({
	type: AuthActionTypes.logoutFailure,
	error
});

export const otherFailure = (error?: LocaleString) => ({
	type: AuthActionTypes.otherFailure,
	error
});

export const logout = () => {
	return async (dispatch: any) => {
		dispatch(loading());
		try {
			const token = await getToken();
			const res = await authentication.logout(token);
			if (res.data) {
				dispatch(sessionCleared());
				return dispatch(logoutSuccess());
			} else {
				return dispatch(logoutFailure(localeStrings.errorAPI));
			}			
		} catch(e) {
			return dispatch(logoutFailure(localeStrings.errorAPI));
		}
	};
};

export const logoutSuccess = () => ({
	type: AuthActionTypes.logoutSuccess
});

export const socialLogin = (type: ThirdPartyLoginType) => {
	return async (dispatch: any) => {
		dispatch({ type: AuthActionTypes.loginSocialInit, payload: type });
		let socialToken: SocialTokens;

		try {
			switch(type) {
				case 'facebook':
					socialToken = await getFacebookLoginToken();
				break;
				case 'google':
					socialToken = await getGoogleLoginToken(); 
				break;
				case 'apple':
					socialToken = await getAppleLoginToken(); 
				break;
			}
		} catch(e) { }

		if (!socialToken) {
			dispatch(loginFailure( localeStrings[type === 'facebook' ? 'errorLoginFacebook' : type === 'google' ? 'errorLoginGoogle' : 'errorLoginApple'] ));
		} else {
			try {
				const { uuid } = await Device.getInfo();
				const res = await authentication.loginSocial(socialToken, uuid);
				// if we got a user, we continue the login process
				if (res.data?.user) {
					dispatch(loginSuccess(res));
				} else {
					// if the social login failed, we keep the token in the store, so we can provide it to the /login
										
					// sets the token to the store - Login components will listen this change and will redirect to the form
					dispatch({
						type: AuthActionTypes.updateSocialTokens,
						payload: socialToken
					});					
				}
			} catch(e) {
				dispatch(loginFailure( localeStrings.errorAPI ));
			}
		}
	}
}

export const linkSocialToUser = (type: ThirdPartyLoginType, user: User) => {
	return async (dispatch: any) => {
		const socialLinked = type === 'facebook' ? user.facebook_linked : type === 'google' ? user.google_linked : user.apple_linked;
		if (!socialLinked) {
			let socialToken: SocialTokens;
			try {
				switch(type) {
					case 'facebook':
						socialToken = await getFacebookLoginToken();
					break;
					case 'google':
						socialToken = await getGoogleLoginToken(); 
					break;
					case 'apple':
						socialToken = await getAppleLoginToken(); 
					break;
				}
			} catch(e) {}

			if (!socialToken) {
				dispatch(otherFailure( localeStrings[type === 'facebook' ? 'errorLoginFacebook' : type === 'google' ? 'errorLoginGoogle' : 'errorLoginApple'] ));
			} else {
				try {
					// we ask the api to link them
					const token = await getToken();
					const res = await authentication.addSocialTokensToUser(token, socialToken);
					if (res.data) {
						// and update the store with the new status
						dispatch(updateUser({ [type+'_linked']: true }));
					} else {
						dispatch(otherFailure( localeStrings.errorLinkingSocialAccount ));
					}
				} catch(e) {
					dispatch(otherFailure( localeStrings.errorLinkingSocialAccount ));
				}
			}
		}
	}	
}

// Firebase token

export const addFirebaseTokenToUser = (firebaseToken: string) => {
	return async (dispatch:any) => {
		if (firebaseToken) {
			const token = await getToken();
			await authentication.addFirebaseToken(token, firebaseToken);
		}
		return dispatch(setNotificationsAccepted());
	}	
}

// Session Token storage

export const sessionSet = (payload: SessionPayload) => {
	Storage.set({ key: 'token', value: payload.token.toString() });
	Storage.set({ key: 'token_expiration_date', value: payload.token_expiration_date?.toString() });
	return {
		type: AuthActionTypes.sessionSet,
		payload
	};	
};

export const sessionCleared = () => {
	Storage.remove({ key: 'token' });
	Storage.remove({ key: 'token_expiration_date' });
	return {
		type: AuthActionTypes.sessionCleared
	}	
};

// Update user

export const updateUser = (payload: UserUpdatableKeys) => ({
	type: AuthActionTypes.updateUser,
	payload
})

// Update admin user

export const updateAdminUser = (payload: UserAdminCredentialsPayload) => {
	return async (dispatch: any) => {
		dispatch({ type: AuthActionTypes.adminUserUpdateLoading });
		try {
			const token = await getToken();
			const res = await authentication.updateAdminCredentials(token, payload);
			if (res.data) {
				if (payload.email) {
					dispatch(updateUser({ email: payload.email }));
				}
				dispatch({ type: AuthActionTypes.adminUserUpdateSuccess });
			} else {
				dispatch(otherFailure( payload.email ? localeStrings.errorUpdateEmail : localeStrings.errorUpdatePassword ));
			}
		} catch(e) {
			dispatch(otherFailure( localeStrings.errorAPI ));
		}
	};
}

export const resetAdminPassword = (email: string) => {
	return async (dispatch: any) => {
		dispatch({ type: AuthActionTypes.adminPasswordResetLoading });	
		try {			
			const redirect_url = (
				isPlatform('android') ? 
					environment.androidHost
				: isPlatform('ios') ?
					environment.iosHost
				:
					environment.webHost
			) + 'admin-password-reset.html';
			const res = await authentication.resetAdminPassword({email, redirect_url});

			if (res.data) {
				dispatch({ type: AuthActionTypes.adminPasswordResetSuccess });
			} else {
				dispatch(otherFailure( localeStrings.errorAPI ));
			}			
		} catch (e) {
			dispatch(otherFailure( localeStrings.errorAPI ));
		}
	};
}