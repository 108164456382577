import React from 'react';
import { IonToggle } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { updateUserProfile } from '../store/settings-actions';
import useLocalizedContent from '../hooks/useLocalizedContent';

import './PushNotificationsSettings.scss';

const PushNotificationsSettings: React.FC = () => {

    const localizedContent = useLocalizedContent();
    const user = useSelector((state:RootState) => state.auth.user);
    const updating = useSelector((state:RootState) => state.settings.updating);

    const isUserMilitant = user?.is_militant || false;

    const dispatch = useDispatch();

    const notificationsAccepted = useSelector((state: RootState) => state.settings.notificationsAccepted);

    const emergenciesNotificationsEnabled = notificationsAccepted && user?.enabled_emergencies;
    const newsNotificationsEnabled = notificationsAccepted && user?.enabled_negociations;

    const handleTogglePushEnabled = (key:'enabled_negociations'|'enabled_emergencies') => {
        if (notificationsAccepted) {// @TODO will no longer be need needed when the settings become per-device instead of per-user
            dispatch(updateUserProfile({ [key] : !user[key] }));
        }        
    }

    return (
        <div className={'push-notifications-settings' + (!notificationsAccepted ? ' permissions-refused' : '')}>
            { isUserMilitant ?
                <div className="item">    
                    <div className="item-content">
                        <div className="item-title">{ localizedContent.pushNotificationsEmergenciesTitle }</div>
                        <div className="item-label">{ localizedContent.pushNotificationsEmergenciesDescription }</div>
                    </div>                    
                    <div className="item-action">
                        <IonToggle
                            mode="ios"
                            className={ 'updating-toggle' + (updating ? ' updating' : '') }
                            checked={ emergenciesNotificationsEnabled }
                            onClick={ e => handleTogglePushEnabled('enabled_emergencies') }
                        />
                    </div>
                </div>
            : null }
            <div className="item">    
                <div className="item-content">
                    <div className="item-title">{ localizedContent.pushNotificationsNegociationsTitle }</div>
                    <div className="item-label">{ localizedContent.pushNotificationsNegociationsContent }</div>
                </div>                    
                <div className="item-action">
                    <IonToggle
                        mode="ios"
                        className={ 'updating-toggle' + (updating ? ' updating' : '') }
                        checked={ newsNotificationsEnabled }
                        onClick={ e => handleTogglePushEnabled('enabled_negociations') }
                    />
                </div>
            </div>
        </div>
    );
};

export default PushNotificationsSettings;