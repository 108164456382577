import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/reducers';
import { DeclarationType } from '../models';

import { StoredDeclaration, StoredTso, StoredSubstitution, setDeclarationData } from '../store/post-actions';

type Hook = (type: DeclarationType) => {
    declaration: StoredDeclaration,
    tso: StoredTso,
    substitution: StoredSubstitution,
    setData(data: any): void
}

const useStoredDeclaration:Hook = (type) => {

    const dispatch = useDispatch();
    const declaration:StoredDeclaration = useSelector((state:RootState) => state.post[type]);
    const tso = declaration as StoredTso;
    const substitution = declaration as StoredSubstitution;

    return {
        declaration,
        tso,
        substitution,
        setData: (data:any) => { dispatch(setDeclarationData(type, data))}
    };
}

export default useStoredDeclaration;