import React from 'react';
import useLocalizedContent from './../../hooks/useLocalizedContent';

import './LoginGreetings.scss';

const LoginGreetings: React.FC = () => {

    const localizedContent = useLocalizedContent();

    return (
        <div className="login-greetings">
            <img src="/assets/logo/logo.svg" alt="fiq-logo" />
            <h1>{ localizedContent.loginHomeTitle }</h1>
            <p>{ localizedContent.loginHomeSubtitle }</p>
        </div>
	);
};

export default LoginGreetings;