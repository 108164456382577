import React, { useState } from 'react';

import './RadioButtons.scss';
import { IonIcon } from '@ionic/react';

interface Choice {
    label: string;
    count?: number;
    icon?: string;
    ionIcon?: boolean;
}

interface RadioButtonsProps {
    choices: Choice[];    
    value?: number;// setting this controls the Component
    defaultSelected?: number;// only used when uncontrolled
    onChange?(index: number, choice: Choice):void;
}

const RadioButtons: React.FC<RadioButtonsProps> = ({ choices, defaultSelected, value, onChange }) => {

    const controlled = value !== undefined;
    const [selectedIndex, setSelectedIndex] = useState(defaultSelected);

    const renderedIndex = controlled ? value : selectedIndex;

    const handleButtonClick = (index:number) => {
        if (!controlled) {
            setSelectedIndex(index);
        }
        
        if (onChange) {
            onChange(index, choices[index]);
        }
    }

	return (
        <div className="radio-buttons">
            { choices.map((choice, i) => 
                <button key={ 'choice' + i } type="button" className={'radio-button' + (renderedIndex === i ? ' selected' : '')} onClick={ e => handleButtonClick(i) }>
                    { choice.icon ?
                        choice.ionIcon ? <IonIcon icon={ choice.icon } /> : <img src={ choice.icon } alt="icon" />
                    : null }
                    <span className="label">{ choice.label }</span>
                    { choice.count !== undefined ?
                        <span className="count">{ choice.count }</span>
                    : null }
                </button>
            ) }
        </div>
	);
};

export default RadioButtons;