import React, { useEffect } from 'react';
import useDeclarationForm from '../../../hooks/useDeclarationForm';
import { useRouteMatch, useHistory } from 'react-router';
import { DeclarationType } from '../../../models';

import DeclarationSent from '../../../components/declaration/DeclarationSent';
import { useDispatch } from 'react-redux';
import { clearDeclarationData } from '../../../store/post-actions';
import { useState } from 'react';

import './DesktopNewReport.scss';
import DesktopTutorialPages from '../../../components/desktop/DesktopTutorialPages';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ToastError from '../../../components/ToastError';

const DesktopNewReport: React.FC = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const type:DeclarationType = (routeMatch.params as any)?.type || 'tso';

    const declarationProcess = useDeclarationForm(type);
    const { goBack, goNext, currentPage, pageNumber, pagesCount, isTutorial, tutorialPage, isSuccess, backBtn, nextBtn, error, posting } = declarationProcess;

    const [wasSuccess, setWasSuccess] = useState(isSuccess);
    const [animatingPage, setAnimatingPage] = useState(false);

    useEffect( () => {
        if (isSuccess) {
            setWasSuccess(isSuccess);            
        }

        if (isSuccess && !animatingPage) {
            dispatch(clearDeclarationData(type));
        }
    }, [isSuccess, animatingPage, type, dispatch]);

    const pageProps:any = {
        type
    };

    const tutorialPagesProps = {
        TutorialComponent: tutorialPage.Component,
		pageProps: pageProps,
		pageKey: currentPage ? currentPage.key : wasSuccess ? 'success' : 'other',
		isTutorial,
		backBtn,
		nextBtn,
		goBack,
        goNext,
        pageNumber,
        pagesCount,
        onTransitionStart: () => setAnimatingPage(true),
        onTransitionComplete: () => setAnimatingPage(false)
    }

    const onEnd = () => {
        history.replace('/dashboard', { direction: 'back'} );
    }

	return (
        <div className={'desktop-new-report type-' + type + (wasSuccess ? ' is-success' : '')}>
            <DesktopTutorialPages {...tutorialPagesProps }>
                { wasSuccess ? (
                    <DeclarationSent { ...tutorialPagesProps.pageProps } onEnd={ onEnd } />
                ): (
                    <>
                        <div className="page-title">{ currentPage?.title }</div>
                        <currentPage.Component { ...tutorialPagesProps.pageProps} />
                    </>
                )}
            </DesktopTutorialPages>
            <LoadingOverlay loading={ posting } />
            <ToastError error={ error } />
        </div>
    );
};

export default DesktopNewReport;