import React, { useState, useRef } from 'react';
import { IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from "@ionic/react";
import { Route, useLocation, useHistory } from 'react-router';
import MobileSingleNewsDetails from './dashboard/MobileSingleNewsDetails';
import MobileEventDetails from './dashboard/MobileEventDetails';
import MobileTestimonialSent from './dashboard/MobileTestimonialSent';
import MobileNewReport from './dashboard/MobileNewReport';
import MobileDeclarationSent from './dashboard/MobileDeclarationSent';
import MobileDeclarationsMap from './dashboard/MobileMap';
import MobileDeclarationDetails from './dashboard/MobileDeclarationDetails';
import MobileAbleToWork from './dashboard/MobileAbleToWork';
import MobilePostEditor from './dashboard/MobilePostEditor';
import MobileHome from './dashboard/MobileHome';
import MobileNews from './dashboard/MobileNews';
import MobileTestimonials from './dashboard/MobileTestimonials';
import MobileEvents from './dashboard/MobileEvents';
import MobileSettings from './dashboard/MobileSettings';
import MobileActivateNotifications from './dashboard/MobileActivateNotifications';
import { Link, NavLink } from 'react-router-dom';
import ControlledIonModal from '../../components/modals/ControlledIonModal';
import UnsafeCareRedirectWarning from '../../components/modals/bottom-modals/UnsafeCareRedirectWarning';
import SavedDeclarationWarning from '../../components/modals/bottom-modals/SavedDeclarationWarning';
import UnreadCount from '../../components/stats/UnreadCount';
import { useDispatch, useSelector } from 'react-redux';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { RootState } from '../../store/reducers';
import useSortableDeclarations from '../../hooks/useSortableDeclarations';
import { StoredTso, StoredSubstitution, clearDeclarationData } from '../../store/post-actions';
import { DeclarationType } from '../../models';
import { newspaper, close, calendar, addCircle, create, settings, map } from 'ionicons/icons';
import useSocialTokenRedirect from '../../hooks/useSocialTokenRedirect';
import useLoginLoading from '../../hooks/useLoginLoading';
import MobileLoginAdmin from './login/MobileLoginAdmin';
import MobileLoginHome from './login/MobileLoginHome';
import MobileLoginForm from './login/MobileLoginForm';
import MobileCreateAccount from './login/MobileCreateAccount';
import MobileForgotPassword from './login/MobileForgotPassword';
import MobileLoginMilitant from './login/MobileLoginMilitant';
import LoadingOverlay from '../../components/LoadingOverlay';

import './MobilePages.scss';
import { AuthStatus } from '../../store/auth-actions';
import { updateStatusBar } from '../../lib/status-bar';

const MobilePages: React.FC = () => {

    // Login

    useSocialTokenRedirect();
    const adminEnvironment = useSelector((state: RootState) => state.app.environment.admin);
    const loginLoading = useLoginLoading();

    // Dashboard

    const dashboardUrl = '/dashboard';

	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();	
	const localizedContent = useLocalizedContent();

	const user = useSelector((state:RootState) => state.auth.user);
	const isUserMilitant = user?.is_militant || false;
	const isUserAdmin = user?.is_admin || false;

	// Get unread entries count

	const entries = useSelector((state: RootState) => state.entries.data);
	const tsos = entries.tsos || [];
	const substitutions = entries.substitutions || [];
	const declarations = useSortableDeclarations({ tsos, substitutions });
	const unreadDeclarations = declarations.filter( declaration => !declaration.read).length;
	const unreadNews = entries.news.filter( singleNews => singleNews.status !== 'read').length;

	// Handles the warning modal before redirecting the user to report unsafe care

	const [redirectWarningOpened, setRedirectWarningOpened] = useState(false);

	const openRedirectWarning = () => {
		setRedirectWarningOpened(true);
	}
	const closeRedirectWarning = () => {
		setRedirectWarningOpened(false);
	}

	// Handles the stored declaration modal warning when the user starts a new report

	const storedTso:StoredTso = useSelector((state:RootState) => state.post.tso);
	const storedSubstitution:StoredSubstitution = useSelector((state:RootState) => state.post.substitution);
	const [storedDeclarationWarningOpened, setDeclarationWarningOpened] = useState<DeclarationType>(null);

	const navigateToDeclaration = (type:DeclarationType) => {
		history.push('/dashboard/report/' + type);
	}

	const handleNewDeclarationClick = (e:React.MouseEvent, type: DeclarationType) => {
		e.preventDefault();

		if (isPageTransitionning('new-declaration')) {
			return;
		}

		closeTabBar();

		const storedDeclaration = type === 'tso' ? storedTso : storedSubstitution;

		if (storedDeclaration) {
			setDeclarationWarningOpened(type);
		} else {
			navigateToDeclaration(type);
		}
	}
	
	const startNewDeclaration = () => {
		dispatch(clearDeclarationData(storedDeclarationWarningOpened));
		navigateToDeclaration(storedDeclarationWarningOpened);
		closeDeclarationWarning();
	}

	const resumeDeclaration = () => {
		navigateToDeclaration(storedDeclarationWarningOpened);
		closeDeclarationWarning();
	}

	const closeDeclarationWarning = () => {
		setDeclarationWarningOpened(null);
	}

	// Handles opened tab bar state

	const [tabsBarOpened, setTabsBarOpened] = useState(false);

	const toggleTabsBarOpened = (e: React.MouseEvent) => {
		e.preventDefault();
		const nextTabsBarOpened = !tabsBarOpened;
		setTabsBarOpened(nextTabsBarOpened);
		setTimeout( () => {
			updateStatusBar(nextTabsBarOpened ? 'dark-blue' : 'blue');
		}, 75);
	}

	const closeTabBar = () => {
		setTabsBarOpened(false);
	}

	// fix ionic tab navigation
	const isPageTransitionning = (page: string) => el.current.querySelectorAll('ion-router-outlet div.ion-page[data-page="'+page+'"]:last-child:not(.ion-page-hidden)').length > 0;

	const el = useRef(null);
	const goToPage = (e: React.MouseEvent, href: string, page?: string) => {
		e.preventDefault();
		// prevents navigating to the same pages we are currently transitionning from (ah ionic...)
		const valid = !page || !isPageTransitionning(page);
		if (valid) {
			history.push(href);
			closeTabBar();
		}
    }
    
    const tabPages = ['', '/news', '/testimonials', '/events', '/settings'].map( tabPage => dashboardUrl + tabPage );
    const tabPagesSlash = tabPages.map( tabPage => tabPage + '/' );

    const authStatus = useSelector((state: RootState) => state.auth.status);
    const loggedIn = authStatus === AuthStatus.LoggedIn;
    const inTabPage = tabPages.indexOf(location.pathname) > -1 || tabPagesSlash.indexOf(location.pathname) > -1;
    const tabBarVisible = loggedIn && inTabPage;

	return (
        <div className={'mobile-pages' + ( tabBarVisible ? ' tab-bar-visible' : '' ) + ( tabsBarOpened ? ' opened' : '' ) } ref={ el }>
            <IonRouterOutlet>
                <Route path="/login" exact render={ props => adminEnvironment ? <MobileLoginAdmin /> : <MobileLoginHome /> } />
                <Route path="/login/home" component={ MobileLoginHome } />
                <Route path="/login/admin" component={ MobileLoginAdmin } />
                <Route path="/login/form" component={ MobileLoginForm } />
                <Route path="/login/create" exact component={ MobileCreateAccount } />
                <Route path="/login/create/form" render={ props => <MobileLoginForm create /> } />
                <Route path="/login/forgot-password" component={ MobileForgotPassword } />
                <Route path="/login/militant" component={ MobileLoginMilitant } />
                <Route path="/dashboard" exact render={ () => <MobileHome reportNewDeclaration={ handleNewDeclarationClick } reportUnsafeCare={ openRedirectWarning } /> } />
                <Route path="/dashboard/news" exact component={MobileNews} />
				<Route path="/dashboard/news/:id" component={ MobileSingleNewsDetails } />
                <Route path="/dashboard/events" exact component={MobileEvents} />
				<Route path="/dashboard/events/:id" component={ MobileEventDetails } />
				<Route path="/dashboard/testimonials" component={MobileTestimonials} />
                <Route path="/dashboard/settings" component={MobileSettings} />
                <Route path="/dashboard/notifications" component={MobileActivateNotifications} />                
                <Route path="/dashboard/testimonial-sent" component={ MobileTestimonialSent } />
                <Route path="/dashboard/report/:type" component={ MobileNewReport } />
                <Route path="/dashboard/report-sent/:type" component={ MobileDeclarationSent } />
                <Route path="/dashboard/map" exact component={ MobileDeclarationsMap } />
                <Route path="/dashboard/tso-details/:id" render={ props => <MobileDeclarationDetails type="tso" {...props} /> } />
                <Route path="/dashboard/substitution-details/:id" render={ props => <MobileDeclarationDetails type="substitution" {...props} /> } />
                <Route path="/dashboard/able-to-work" exact component={ MobileAbleToWork } />
                <Route path="/dashboard/single-news/create" render={ props => <MobilePostEditor type="news" create /> } />
                <Route path="/dashboard/single-news/edit/:id" render={ props => <MobilePostEditor type="news" /> } />
                <Route path="/dashboard/event/create" render={ props => <MobilePostEditor type="events" create /> } />
                <Route path="/dashboard/event/edit/:id" render={ props => <MobilePostEditor type="events" /> } />
            </IonRouterOutlet>
            <IonTabBar mode="md">
                { !isUserAdmin ?
                    <IonTabButton key="btn-declaration" className="declaration" onClick={ e => handleNewDeclarationClick(e, 'tso') } layout="icon-start">
                        <img src="/assets/icon/add.svg" alt="add" />
                        <span>{ isUserMilitant ? localizedContent.declarationTso : localizedContent.tso}</span>
                    </IonTabButton>
                : null}					
                { isUserMilitant || isUserAdmin ?
                    <IonTabButton key="btn-map" onClick={ e => goToPage(e, '/dashboard/map', 'map') }>
                        <IonIcon icon={ map } />
                        <IonLabel>{ localizedContent.map }</IonLabel>
                        { !!unreadDeclarations ?
                            <UnreadCount count={ unreadDeclarations } />
                        : null }							
                    </IonTabButton>
                : 
                    <IonTabButton key="btn-substitution" className="declaration" onClick={ e => handleNewDeclarationClick(e, 'substitution') } layout="icon-start">
                        <img src="/assets/icon/add.svg" alt="add" />
                        <span>{ localizedContent.workSubstitution }</span>
                    </IonTabButton>
                }
                <IonTabButton key="btn-news" selected={ location.pathname.startsWith(dashboardUrl + '/news') } onClick={ e => goToPage(e, dashboardUrl + '/news', 'news') }>
                    <IonIcon icon={newspaper} />
                    <IonLabel>{ localizedContent.negos }</IonLabel>
                    { !!unreadNews ?
                        <UnreadCount count={ unreadNews } />
                    : null }
                </IonTabButton>
                { !isUserAdmin ?
                    <IonTabButton key="btn-bar-open-toggle" className={'toggle' + (tabsBarOpened ? ' opened' : '')} onClick={ e => toggleTabsBarOpened(e) }>
                        <img src="/assets/icon/more.svg" alt="more" />
                        <IonIcon icon={ close } />
                        <IonLabel>{ localizedContent.more }</IonLabel>
                    </IonTabButton>
                : 
                    <IonTabButton key="btn-events" selected={ location.pathname.startsWith(dashboardUrl + '/events') } onClick={ e => goToPage(e, dashboardUrl + '/events', 'events') }>
                        <IonIcon icon={ calendar } />
                        <IonLabel>{ localizedContent.events }</IonLabel>
                    </IonTabButton>
                }
                { isUserAdmin ?
                    <IonTabButton key="btn-settings" selected={ location.pathname.startsWith(dashboardUrl + '/settings') } onClick={ e => goToPage(e, dashboardUrl + '/settings', 'settings') }>
                        <IonIcon icon={ settings } />
                        <IonLabel>{ localizedContent.settings }</IonLabel>
                    </IonTabButton>
                : null }
            </IonTabBar>
            <div className="tab-bar-opened">
				<div className="safe" onClick={ closeTabBar } />
				<div className="content">
					<div className="title">{ localizedContent.menu }</div>
					<div className="links">
						{ isUserMilitant ? 
							<Link to="/dashboard/report/substitution" className="link" onClick={ e => handleNewDeclarationClick(e, 'substitution') }>
								<span className="icon"><IonIcon icon={ addCircle } /></span>
								<span className="label">{ localizedContent.substitutionReportDescription }</span>
							</Link>
						: null }
						{ !isUserAdmin ?
							<>
								<button className="link" onClick={ e => { closeTabBar(); openRedirectWarning() } }>
									<span className="icon"><img alt="icon-bandage-gray" src="/assets/icon/bandage-gray.svg" /></span>
									<span className="label" dangerouslySetInnerHTML={{__html: localizedContent.unsafeCareReportTitle}} />
								</button>
								<Link to={ dashboardUrl + '/able-to-work' } className="link" onClick={ e => goToPage(e, dashboardUrl + '/able-to-work', 'able-to-work') }>
									<span className="icon"><img alt="icon-able-to-work-gray" src="/assets/icon/able-to-work-gray.svg" /></span>
									<span className="label">{ localizedContent.ableToWorkQuestion }</span>
								</Link>
								<NavLink to={ dashboardUrl + '/testimonials'} className="link" onClick={ e => goToPage(e, dashboardUrl + '/testimonials', 'testimonials') }>
									<span className="icon"><IonIcon icon={ create }></IonIcon></span>
									<span className="label">{ localizedContent.testimonial }</span>
								</NavLink>
							</>
						: null }
						<NavLink to={ dashboardUrl + '/events'} className="link" onClick={ e => goToPage(e, dashboardUrl + '/events', 'events') }>
							<span className="icon"><IonIcon icon={ calendar }></IonIcon></span>
							<span className="label">{ localizedContent.events }</span>
						</NavLink>
						<NavLink to={ dashboardUrl + '/settings'} className="link" onClick={ e => goToPage(e, dashboardUrl + '/settings', 'settings') }>
							<span className="icon"><IonIcon icon={ settings }></IonIcon></span>
							<span className="label">{ localizedContent.settings }</span>
						</NavLink>
					</div>
				</div>
			</div>
			<ControlledIonModal type="bottom" opened={ redirectWarningOpened } onClose={ closeRedirectWarning }>
				<UnsafeCareRedirectWarning onClose={ closeRedirectWarning } />
			</ControlledIonModal>
			<ControlledIonModal type="bottom" opened={ !!storedDeclarationWarningOpened } onClose={ closeDeclarationWarning }>
				<SavedDeclarationWarning onStartNew={ startNewDeclaration } onResume={ resumeDeclaration } />
			</ControlledIonModal>
            <LoadingOverlay loading={ loginLoading.loading } loadingLabel={ loginLoading.label } />
        </div>
	);
};

export default MobilePages;