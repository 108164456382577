import React from 'react';
import { Link } from 'react-router-dom';
import { DeclarationType } from '../../models';

import './DeclarationSent.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import FiqButton from '../buttons/FiqButton';

interface DeclarationSentProps {
    type: DeclarationType;
    onEnd?(): void;
}

const DeclarationSent: React.FC<DeclarationSentProps> = ({ type, onEnd }) => {

    const localizedContent = useLocalizedContent();

	return (
        <div className="declaration-sent">
            <img src="/assets/icon/validation.svg" alt="icon-validation" height="88" />
            <h2>{ localizedContent.declarationSent }</h2>
            { type === 'tso' ? 
                <>
                    <p>{ localizedContent.tsoSentContent1 }</p>
                    <p>
                        { localizedContent.tsoSentContent2 + ' ' }
                        <Link to="/dashboard/able-to-work">{ localizedContent.theReminder }</Link>.
                    </p>
                    <p>{ localizedContent.tsoSentContent3 }</p>
                </>    
            : 
                <p>{ localizedContent.substitutionSentContent }</p>
            }
            { onEnd ?
                <FiqButton onClick={ e => onEnd() }>{ localizedContent.backToHome }</FiqButton>
            : null }
        </div>
	);
};

export default DeclarationSent;