import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useMemo, useEffect, useRef } from 'react';
import FixedBackButton from '../../../components/buttons/FixedBackButton';
import SingleNewsDetails from '../../../components/common-content/SingleNewsDetails';

import NewsletterSubscribe from '../../../components/NewsletterSubscribe';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import useLocale from '../../../hooks/useLocale';
import FixedContent from '../../../components/mobile/FixedContent';
import ScrollableWhiteContent from '../../../components/mobile/ScrollableWhiteContent';

import './MobileSingleNewsDetails.scss';
import { updateStatusBar } from '../../../lib/status-bar';
import useRouteIdParam from '../../../hooks/useRouteIdParam';
import useElementSize from '../../../hooks/useElementSize';

const MobileSingleNewsDetails: React.FC = () => {

	// Update tab color
    useIonViewWillEnter( () => updateStatusBar('white'));

    const locale = useLocale();
	const history = useHistory();
	const entries = useSelector((state:RootState) => state.entries.data);

	const singleNewsId = useRouteIdParam();
	const singleNews = useMemo(() => entries.news.find(singleNews => singleNews.id === singleNewsId) || null, [entries, singleNewsId]);

	useEffect( () => {
        if (!singleNews) {
            history.replace('/dashboard/news', { direction: 'back' });
        }
	}, [singleNews, history]);

	const scrollableContent = useRef(null);
    const scrollableContentSize = useElementSize(scrollableContent);

	return (
		<IonPage className="mobile-single-news-details" data-page="single-news-details">
			<IonContent>
                <FixedContent image={ singleNews?.images?.large[locale] ? singleNews.images.large[locale] : null } width={ scrollableContentSize?.width } />
                <ScrollableWhiteContent>
					<div className="scrollable-content"  ref={ scrollableContent }>
						<SingleNewsDetails singleNews={ singleNews }/>
						<NewsletterSubscribe />
					</div>                    
                </ScrollableWhiteContent>
                <FixedBackButton defaultHref="/dashboard/news" />
     		</IonContent>
		</IonPage>
	);
};

export default MobileSingleNewsDetails;
