import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { FilteredPage, updatePageFilter } from '../../store/settings-actions';
import { DeclarationsFilter, DeclarationFilterStatus } from '../../store/reducers/settings';
import TooltipSelector from '../desktop/TooltipSelector';
import useLocalizedContent from '../../hooks/useLocalizedContent';

interface DeclarationStatusFilter {
    name: DeclarationFilterStatus;
    label: string;
}

interface DeclarationStatusFilterProps {
    page?: FilteredPage;
}

const DeclarationStatusFilter:React.FC<DeclarationStatusFilterProps> = ({ page = "declarations" }) => {

    const dispatch = useDispatch();
    const localizedContent = useLocalizedContent();
    const pageFilters = useSelector((state:RootState) => state.settings.pageFilters[page]) as DeclarationsFilter;
    
    const statusesFilters:DeclarationStatusFilter[] = [
        {
            name: 'urgencies',
            label: localizedContent.urgent
        }
    ];

    const statusesFiltersIndexes = statusesFilters.map((status, i) => i);
    const selectedStatusFilters = pageFilters?.statuses || [];
    const selectedChoicesIndexes = statusesFiltersIndexes.filter(index => selectedStatusFilters.indexOf(statusesFilters[index].name) === -1);

    const handleChange = (selectedIndexes: number[]) => {
        const filteredIndexes = statusesFiltersIndexes.filter((status, i) => selectedIndexes.indexOf(i) === -1);

        dispatch(updatePageFilter(page, { statuses: filteredIndexes.map(index => statusesFilters[index].name) }));
    }

    return (
        <TooltipSelector label={ localizedContent.status } choices={ statusesFilters } selectedIndexes={ selectedChoicesIndexes } onChange={ handleChange }/>
    );
}

export default DeclarationStatusFilter;