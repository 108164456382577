import { useState, useEffect } from 'react';

interface Size {
    width: number;
    height: number;
}

type Hook = () => Size

const useWindowSize:Hook = () => {

    function getWindowSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    const [size, setSize] = useState<Size>(getWindowSize());

	useEffect(() => {
        
        function onWindowResized() {
            setSize(getWindowSize());
        }
        
		window.addEventListener('resize', onWindowResized);
		return () => {
			window.removeEventListener('resize', onWindowResized);
		};
    }, []);
    
    return size;
}

export default useWindowSize;