import React from 'react';
import CountTitle from '../stats/CountTitle';

import './PageTitleActions.scss';
import useLocalizedContent from '../../hooks/useLocalizedContent';
import { FilteredPage } from '../../store/settings-actions';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';

interface PageTitleActionsProps {    
    count: number;
    title: string;
    dateFilters?: FilteredPage;
}

const PageTitleActions: React.FC<PageTitleActionsProps> = ({ count, title, children, dateFilters }) => {

    const pageFilters = useSelector((state: RootState) => state.settings.pageFilters[dateFilters]);
    const localizedContent = useLocalizedContent();

    if (dateFilters && pageFilters) {
        title += ' ' + ( 
            pageFilters.range_type === 2 ?
                localizedContent.from.toLowerCase() + ' ' + pageFilters.date_from.format('DD/MM/YYYY') + ' ' + localizedContent.to + ' ' + pageFilters.date_to.format('DD/MM/YYYY')
            : pageFilters.range_type === 0 ? 
                localizedContent.inTheLast7days
            :
                localizedContent.inTheLast30days
        )
    }


	return (
        <div className="page-title-actions">
            <div className="page-title-actions-inner">
                <div className="count-title-container">
                    <CountTitle count={count} title={ title } titleSize={24} />
                </div>
                <div className="actions-container">
                    { children }
                </div>
            </div>
        </div>
	);
};

export default PageTitleActions;