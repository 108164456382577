import React from 'react';
import { ControlledIonModalContent } from '../ControlledIonModal';
import useLocalizedContent from '../../../hooks/useLocalizedContent';
import FiqButton from '../../buttons/FiqButton';

interface SavedDeclarationWarningProps extends ControlledIonModalContent {    
    onStartNew(): void;
    onResume(): void;
}

const SavedDeclarationWarning:React.FC<SavedDeclarationWarningProps> = ({ onStartNew, onResume }) => {
    
    const localizedContent = useLocalizedContent();

    return (
        <div className="ion-padding ion-text-center">
            <div className="ion-padding-bottom">
                <img alt="icon-saved-declaration" src="/assets/icon/saved-declaration.svg" height="46" />
            </div>
            <h2>{ localizedContent.declarationInProgress }</h2>
            <p dangerouslySetInnerHTML={{__html: localizedContent.confirmResumeDeclaration}} />
            <div className="buttons-container grow">
                <FiqButton color="white" fill="outline" onClick={ onStartNew }>{ localizedContent.newDeclaration }</FiqButton>
                <FiqButton onClick={ onResume }>{ localizedContent.resume }</FiqButton>
            </div>
        </div>
    );
};

export default SavedDeclarationWarning;