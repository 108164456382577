import { useRouteMatch } from "react-router";

type Hook = () => number

const useRouteIdParam:Hook = () => {    
    const routeMatch = useRouteMatch();
	const idParam:string = (routeMatch.params as any)?.id;
    return idParam ? parseInt(idParam) : undefined;
}

export default useRouteIdParam;